import React from "react";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useIsMobile } from "@app.automotus.io/components/hooks";

export const TransactionSummaryTitle: React.FC<TransactionSummaryTitleProps> = ({
  loading = true,
  accountHolderName = "",
}) => {
  const isMobile = useIsMobile();

  return (
    <Typography sx={{ mb: 3 }} variant={isMobile ? "h6" : "h4"} fontWeight={isMobile ? 400 : 600}>
      {loading ? <Skeleton width="80%" /> : `Transactions for ${accountHolderName}`}
    </Typography>
  );
};

export interface TransactionSummaryTitleProps {
  loading?: boolean;
  accountHolderName?: string;
}

export default TransactionSummaryTitle;
