import React from "react";
import Slider from "@mui/material/Slider";
import { formatCurrency } from "common/format";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

/**
 * A component that allows the user to select an auto refill balance from a limited set of options.
 * @param desiredWalletBalance The current desired wallet balance
 * @param value The current value of the slider
 * @param onChange Handler fired upon change.
 */
export const AutoRefillSlider: React.FC<AutoRefillSliderProps> = ({
  desiredWalletBalance,
  value,
  onChange,
  disabled = false,
}) => {
  const allowedValues = [desiredWalletBalance * 0.25, desiredWalletBalance * 0.35, desiredWalletBalance * 0.5];
  const marks = allowedValues.map((v, i) => ({ value: i, label: `$${formatCurrency(v)}` }));

  const unscaleValue = (value: number): number => {
    return allowedValues.findIndex((v) => v === value) || 0;
  };

  const scaleValue = (value: number) => {
    return allowedValues[value];
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      onChange(scaleValue(newValue));
    }
  };

  return (
    <Box>
      <Slider
        step={1}
        min={0}
        max={2}
        value={unscaleValue(value)}
        scale={scaleValue}
        onChange={handleChange}
        valueLabelDisplay={"off"}
        disabled={disabled}
      />
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        {marks.map(({ label }) => (
          <Typography key={label}>{label}</Typography>
        ))}
      </Box>
    </Box>
  );
};

/** Props passed to initialize an {@link AutoRefillSlider} component */
export interface AutoRefillSliderProps {
  /** Desired wallet balance */
  desiredWalletBalance: number;
  /** Current value of the slider as a number of the smallest denominations of a currency */
  value: number;
  /** Change handler */
  onChange: (value: number) => void;
  /** Indicates whether slider is disabled */
  disabled?: boolean;
}

export default AutoRefillSlider;
