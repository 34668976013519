import { gql } from "@apollo/client";
import { PaymentMethod, paymentMethodFragment } from "common/graphql/fragments";

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod(
    $paymentMethodId: uuid!
    $zipCode: String!
    $expMonth: Int!
    $expYear: Int!
    $isDefault: Boolean = true
  ) {
    updatePaymentMethod: update_payment_method(
      payment_method_id: $paymentMethodId
      payment_method_details: {
        gateway_access: { creditCard: { expMonth: $expMonth, expYear: $expYear } }
        billing_details: { zip: $zipCode }
        is_default: $isDefault
      }
    ) {
      paymentMethod: payment_method {
        ...paymentMethodFragment
      }
    }
  }
  ${paymentMethodFragment}
`;

export interface UpdatePaymentMethodData {
  updatePaymentMethod: {
    paymentMethod: PaymentMethod;
  };
}

export interface UpdatePaymentMethodVars {
  paymentMethodId: string;
  expMonth: number;
  expYear: number;
  zipCode: string;
  isDefault?: boolean;
}
