/**
 * Represents an acceptable onboarding status value and its accompanying label.
 */
export class OnboardingStatus {
  /** User must enter profile information */
  public static readonly PROFILE = new OnboardingStatus({ value: "profile", label: "Profile" });
  /** User must enter payment information */
  public static readonly PAYMENT = new OnboardingStatus({ value: "payment", label: "Payment" });
  /** User must enter vehicle information */
  public static readonly VEHICLE = new OnboardingStatus({ value: "vehicle", label: "Vehicle" });
  /** User has completed onboarding */
  public static readonly FINISH = new OnboardingStatus({ value: "complete", label: "Finish" });
  /** All possible onboarding status values in order that they occur */
  public static readonly ALL = [
    OnboardingStatus.PROFILE,
    OnboardingStatus.VEHICLE,
    OnboardingStatus.PAYMENT,
    OnboardingStatus.FINISH,
  ];

  private static readonly BY_VALUE: Record<OnboardingStatusValue, OnboardingStatus> = OnboardingStatus.ALL.reduce(
    (acc, cur) => ({ ...acc, [cur.value]: cur }),
    {} as Record<OnboardingStatusValue, OnboardingStatus>,
  );

  /** Value of the OnboardingStatus */
  public readonly value: OnboardingStatusValue;
  /** Label Displayed for the Onboarding status */
  public readonly label: OnboardingStatusLabel;

  constructor({ value, label }: OnboardingStatusProps) {
    this.value = value;
    this.label = label;
  }

  /**
   * Returns an {@link OnboardingStatus} by its value.
   */
  public static FromValue(value: OnboardingStatusValue): OnboardingStatus {
    return this.BY_VALUE[value];
  }

  /**
   * Compares the sequence of the steps to determine which one comes first.
   * Returns true if 1st status comes first in order or they are the same steps.
   */
  public static isBefore(value: OnboardingStatusValue, status: OnboardingStatus): boolean {
    const curIndex = this.ALL.findIndex((item) => item.value === value);
    const statusIndex = this.ALL.findIndex((item) => item.value === status.value);
    return curIndex <= statusIndex;
  }
}

interface OnboardingStatusProps {
  value: OnboardingStatusValue;
  label: OnboardingStatusLabel;
}

/** Set of values indicating onboarding status */
export type OnboardingStatusValue = "profile" | "payment" | "vehicle" | "complete";

/** Set of labels indicating onboarding status */
export type OnboardingStatusLabel = "Profile" | "Payment" | "Vehicle" | "Finish";
