import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TermsOfServiceWrapper } from "@app.automotus.io/components/TermsOfService";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import { useTermsOfService } from "@app.automotus.io/components/hooks/useTermsOfService/useTermsOfService";

export const SignUpTermsOfService: React.FC = () => {
  const navigate = useNavigate();
  const state = useLocation().state as Record<string, unknown>;
  const redirectUrl = (state && state.sourceRoute ? state.sourceRoute : "/signup/profile") as string;
  const path = useLocation().pathname as string;
  const { accept, decline } = useTermsOfService({
    onError: (op, err) => {
      console.error(err);
      publishSnackbarMessage({ message: `Failed to ${op} Terms of Service`, severity: "error" });
    },
  });
  const { userProfile } = useUserProfile();
  const { publishSnackbarMessage } = useSnackPackContext();

  const handleChange = async (op: "accept" | "decline") => {
    const action = op === "accept" ? accept : decline;

    try {
      await action();
      navigate("/signup/payment", {
        replace: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <TermsOfServiceWrapper
      variant={path === "/signup/terms-of-service" ? "select" : "display"}
      onBack={() => {
        navigate(redirectUrl, {
          state: {
            ...(state as Record<string, unknown>),
            isAgreedToTermsOfService: !!userProfile?.tosAcceptedAt,
          },
        });
      }}
      onChange={handleChange}
    />
  );
};

export default SignUpTermsOfService;
