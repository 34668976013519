import React, { useState, useEffect } from "react";
import { AccountSection } from "@app.automotus.io/components/scenes/Account/AccountSection";
import { AccountRegisteredVehicle } from "@app.automotus.io/components/scenes/Account/VehicleSection/AccountRegisteredVehicle";
import {
  REGISTER_VEHICLE,
  RegisteredVehicle,
  RegisterVehicleData,
  RegisterVehicleRefetchQueries,
  RegisterVehicleVars,
} from "common/graphql";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { UpdateVehicleModal } from "@app.automotus.io/components/scenes/Account/VehicleSection/UpdateVehicleModal";
import { RemoveVehicleModal } from "@app.automotus.io/components/scenes/Account/VehicleSection/RemoveVehicleModal";
import { ValidatedVehicleInfoFormValues } from "@app.automotus.io/components/forms/VehicleInfoForm";
import { useMutation, useQuery } from "@apollo/client";
import UPDATE_REGISTERED_VEHICLE, {
  UpdateRegisteredVehicleData,
  UpdateRegisteredVehicleRefetchQueries,
  UpdateRegisteredVehicleVars,
} from "common/graphql/mutations/UpdateRegisteredVehicle";
import REMOVE_REGISTERED_VEHICLE, {
  RemoveRegisteredVehicleData,
  RemoveRegisteredVehicleRefetchQueries,
  RemoveRegisteredVehicleVars,
} from "common/graphql/mutations/RemoveRegisteredVehicle";
import {
  GET_CURRENTLY_REGISTERED_VEHICLES,
  GetCurrentlyRegisteredVehiclesData,
  GetCurrentlyRegisteredVehiclesVars,
} from "common/graphql/queries/GetCurrentlyRegisteredVehicles";
import Button from "@mui/material/Button";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { AddVehicleModal } from "@app.automotus.io/components/scenes/Account/VehicleSection/AddVehicleModal";
import {
  useSnackPackContext,
  SnackbarMessageProps,
  SNACKBAR_MSGS,
} from "@app.automotus.io/components/context/SnackPack";
import useIsMobile from "@app.automotus.io/components/hooks/useIsMobile";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const VehicleSection: React.FC = () => {
  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogType, setDialogType] = useState<"add" | "remove" | "update" | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [keepDialogOpen, setKeepDialogOpen] = useState(false);
  const [selectedRegisteredVehicle, setSelectedRegisteredVehicle] = useState<RegisteredVehicle | null>(null);
  const { publishSnackbarMessage } = useSnackPackContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const location = useLocation();

  useEffect(() => {
    const paths = location.pathname.split("/");
    if (paths.includes("account") && paths.includes("add_new_vehicle")) {
      setDialogType("add");
      setDialogOpen(true);
    }
  }, [location]);

  // TODO: handle errors
  const { data, loading, fetchMore } = useQuery<GetCurrentlyRegisteredVehiclesData, GetCurrentlyRegisteredVehiclesVars>(
    GET_CURRENTLY_REGISTERED_VEHICLES,
    {
      variables: {
        offset: 0,
        limit: 20,
      },
      onCompleted: (data) => {
        if (data.registeredVehicles.length < data.registeredVehicleCount.aggregate.count) {
          fetchMore({
            variables: {
              offset: data.registeredVehicles.length,
            },
          });
        }
      },
    },
  );

  const registeredVehicles = data?.registeredVehicles || [];

  const [addRegisteredVehicle] = useMutation<RegisterVehicleData, RegisterVehicleVars>(REGISTER_VEHICLE, {
    refetchQueries: RegisterVehicleRefetchQueries,
  });

  const [updateRegisteredVehicle] = useMutation<UpdateRegisteredVehicleData, UpdateRegisteredVehicleVars>(
    UPDATE_REGISTERED_VEHICLE,
    {
      refetchQueries: UpdateRegisteredVehicleRefetchQueries,
    },
  );

  const [removeRegisteredVehicle] = useMutation<RemoveRegisteredVehicleData, RemoveRegisteredVehicleVars>(
    REMOVE_REGISTERED_VEHICLE,
    {
      refetchQueries: RemoveRegisteredVehicleRefetchQueries,
    },
  );

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleClickAdd = () => {
    if (isMobile) {
      navigate("/account/add_new_vehicle");
    } else {
      setDialogType("add");
      setDialogOpen(true);
    }
  };

  const handleAddVehicle = async (values: ValidatedVehicleInfoFormValues) => {
    try {
      if (values.invoiceNumber) {
        // TODO: Add Handling for Invoice Number here
        throw new Error(`invoiceNumber is ${values.invoiceNumber}`);
      }

      await addRegisteredVehicle({
        variables: {
          licensePlate: values.licensePlate,
          stateCode: values.licensePlateState,
          type: values.vehicleType,
          make: values.make?.name,
          model: values.model?.name,
          year: values.year,
        },
      });

      publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ADDED_SUCCESS as SnackbarMessageProps);

      if (!keepDialogOpen) {
        handleDialogClose();
      }
    } catch (err: unknown) {
      console.error(err);

      if (err instanceof Error) {
        if (!err.message.includes("registered_vehicle exists and is registered: true")) {
          publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ADDED_FAILURE as SnackbarMessageProps);
        } else {
          publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ALREADY_EXISTS as SnackbarMessageProps);
        }

        throw new Error("Failed to add vehicle");
      }
    } finally {
      setKeepDialogOpen(false);
    }
  };

  const handleAddAnotherVehicle = async (values: ValidatedVehicleInfoFormValues) => {
    setKeepDialogOpen(true);
    handleAddVehicle(values);
  };

  const handleClickEdit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setSelectedRegisteredVehicle(registeredVehicles.find((rv) => rv.id === event.currentTarget.value) || null);
    setDialogType("update");
    setDialogOpen(true);
  };

  const handleClickRemove: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setSelectedRegisteredVehicle(registeredVehicles.find((rv) => rv.id === event.currentTarget.value) || null);
    setDialogType("remove");
    setDialogOpen(true);
  };

  const handleUpdateVehicle = async (values: ValidatedVehicleInfoFormValues) => {
    try {
      await updateRegisteredVehicle({
        variables: {
          id: values.id || "",
          licensePlate: values.licensePlate,
          stateCode: values.licensePlateState,
          type: values.vehicleType,
          make: values.make?.name,
          model: values.model?.name,
          year: values.year,
        },
      });

      publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_UPDATED_SUCCESS as SnackbarMessageProps);
      handleDialogClose();
    } catch (err: unknown) {
      console.error(err);

      if (err instanceof Error) {
        if (!err.message.includes("Uniqueness violation")) {
          publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_UPDATED_FAILURE as SnackbarMessageProps);
        } else {
          publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ALREADY_EXISTS as SnackbarMessageProps);
        }

        throw new Error("Failed to update vehicle");
      }
    }
  };

  const handleRemoveVehicle = async (values: ValidatedVehicleInfoFormValues) => {
    try {
      await removeRegisteredVehicle({ variables: { id: values.id || "" } });
      publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_REMOVED_SUCCESS as SnackbarMessageProps);
      handleDialogClose();
    } catch (err) {
      publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_REMOVED_FAILURE as SnackbarMessageProps);

      throw new Error("Failed to remove vehicle");
    }
  };

  const handleDialogExited = () => {
    setDialogType(null);
    setSelectedRegisteredVehicle(null);
  };

  return (
    <AccountSection title={"Vehicles"}>
      <Grid container rowSpacing={4}>
        {(() => {
          if (loading) {
            return (
              <React.Fragment>
                <Grid item xs={12}>
                  <AccountRegisteredVehicle loading={true} />
                </Grid>
                <Grid item xs={12}>
                  <AccountRegisteredVehicle loading={true} />
                </Grid>
                <Grid item xs={12}>
                  <AccountRegisteredVehicle loading={true} />
                </Grid>
              </React.Fragment>
            );
          }

          return registeredVehicles.map((rv, i) => (
            <Grid item xs={12} key={rv.id}>
              <AccountRegisteredVehicle
                title={`Vehicle ${i + 1}`}
                registeredVehicle={rv}
                loading={false}
                onClickEdit={handleClickEdit}
                onClickRemove={handleClickRemove}
              />
            </Grid>
          ));
        })()}
        <Grid item xs={12}>
          <LoadingSkeleton loading={loading} width="100%">
            <Button onClick={handleClickAdd} variant="outlined" fullWidth>
              + Add Vehicle
            </Button>
          </LoadingSkeleton>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreenDialog}
        open={dialogOpen}
        onBackdropClick={handleDialogClose}
        TransitionProps={{ onExited: handleDialogExited }}
        PaperProps={{ sx: { borderRadius: { xs: 0, md: 1.5 } } }}
      >
        <Box sx={{ px: 2, py: 5 }}>
          {dialogType === "add" ? (
            <AddVehicleModal
              onClose={handleDialogClose}
              onAddVehicle={handleAddVehicle}
              onAddAnotherVehicle={handleAddAnotherVehicle}
            />
          ) : dialogType === "update" && selectedRegisteredVehicle ? (
            <UpdateVehicleModal
              loading={false}
              onClose={handleDialogClose}
              currentVehicleInfo={selectedRegisteredVehicle}
              onClickUpdate={handleUpdateVehicle}
            />
          ) : dialogType === "remove" && selectedRegisteredVehicle ? (
            <RemoveVehicleModal
              loading={false}
              onClose={handleDialogClose}
              registeredVehicle={selectedRegisteredVehicle}
              onCancel={handleDialogClose}
              onSubmit={handleRemoveVehicle}
            />
          ) : null}
        </Box>
      </Dialog>
    </AccountSection>
  );
};

export default VehicleSection;
