import React from "react";
import { RegisteredVehicle } from "common/graphql/fragments";
import { VehicleCategory } from "@app.automotus.io/components/common/VehicleCategory";
import { VehicleType } from "@app.automotus.io/components/common/VehicleType";
import Typography from "@mui/material/Typography";

export const RegisteredVehicleTextDescription: React.FC<{ registeredVehicle: RegisteredVehicle; isMobile?: boolean }> =
  ({ registeredVehicle, isMobile = false }) => {
    const categoryLabel = VehicleCategory.FromValue(registeredVehicle.category || "personal").label;
    const typeLabel = VehicleType.FromValue(registeredVehicle.type || "car").label;

    const formatString: React.ReactNode[] = [typeLabel];

    if (registeredVehicle.make) {
      formatString.push(" | ");
      formatString.push(
        registeredVehicle.year ? (
          `${registeredVehicle.year}`
        ) : (
          <Typography component="span" color="text.disabled">
            No Year Added
          </Typography>
        ),
      );
      formatString.push(` ${registeredVehicle.make} `);
      formatString.push(
        registeredVehicle.model ? (
          `${registeredVehicle.model}`
        ) : (
          <Typography component="span" color="text.disabled">
            No Model Added
          </Typography>
        ),
      );
    } else {
      formatString.push(" | ");
      formatString.push(
        <Typography component="span" color="text.disabled">
          No Make Added
        </Typography>,
      );
    }

    const sharedFormat = (
      <>
        {formatString.map((node, index) => (
          <React.Fragment key={index}>{node}</React.Fragment>
        ))}
        {" | "}
        {registeredVehicle.licensePlate}
        {" | "}
        {registeredVehicle.stateCode}
      </>
    );

    if (isMobile) {
      return sharedFormat;
    }

    return (
      <>
        {categoryLabel}
        {" | "}
        {sharedFormat}
      </>
    );
  };

export default RegisteredVehicleTextDescription;
