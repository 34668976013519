import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import Divider from "@mui/material/Divider";

/**
 * Container for a single section of account information (e.g. profile).
 */
export const AccountSection: React.FC<AccountSectionProps> = ({
  title,
  displayRequiredFieldHint = false,
  children,
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Divider />
      <Grid container sx={{ pt: 2, pb: { xs: 3, lg: 6 } }} columnSpacing={2.5} rowSpacing={4}>
        <Grid item xs={12} lg={2}>
          <Typography variant={isMobile ? "body2" : "subtitle1"}>{title}</Typography>
          {displayRequiredFieldHint && (
            <Typography variant="body4" sx={{ mt: { xs: 0.5, lg: 1 }, color: "text.secondary" }}>
              Required Field*
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={10}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

/** Props passed to initialize an {@link AccountSection} */
export interface AccountSectionProps {
  /** Title of the section */
  title: string;
  /** Display hint of required fields */
  displayRequiredFieldHint?: boolean;
}

export default AccountSection;
