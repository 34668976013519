import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

/**
 * Component protected by Auth0 authentication. If user is already logged in, but has not yet completed onboarding,
 * redirects the user to the appropriate onboarding page.
 * @param component The component to protect
 * @param componentProps Props passed to the component
 */
export const ProtectedComponent = <TComponent extends React.ComponentType>({
  component: Component,
  ...componentProps
}: {
  component: TComponent;
} & React.ComponentProps<TComponent>): JSX.Element => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...componentProps} />;
  }

  return <Navigate to={"/login"} />;
};

export default ProtectedComponent;
