import { gql } from "@apollo/client";
import registeredVehicleFragment, { RegisteredVehicle } from "common/graphql/fragments/registeredVehicleFragment";

/**
 * Query that retrieves the vehicle information using state and license plate number.
 */
export const GET_REGISTERED_VEHICLE = gql`
  query GetRegisteredVehicle($id: uuid!) {
    registeredVehicle: registered_vehicle_by_pk(id: $id) {
      ...registeredVehicleFragment
    }
  }
  ${registeredVehicleFragment}
`;

/** Variables provided to the {@link GET_REGISTERED_VEHICLE} query */
export interface GetRegisteredVehicleVars {
  id: string;
}

/** Data returned by the {@link GET_REGISTERED_VEHICLE} query */
export interface GetRegisteredVehicleData {
  /** Registered vehicle */
  registeredVehicle: RegisteredVehicle;
}

export default GET_REGISTERED_VEHICLE;
