import { gql } from "@apollo/client";
import { StateAbbreviation, VehicleTypeValue } from "@app.automotus.io/components/common";
import { GET_CURRENTLY_REGISTERED_VEHICLES, RegisteredVehicle, registeredVehicleFragment } from "common/graphql";

/**
 * Mutation that creates a new registered vehicle in a user's account
 */
export const REGISTER_VEHICLE = gql`
  mutation RegisterVehicle(
    $licensePlate: String!
    $stateCode: String!
    $type: String!
    $category: String = "personal"
    $make: String
    $model: String
    $year: Int
  ) {
    registerVehicle: register_vehicle(
      license_plate: $licensePlate
      state_code: $stateCode
      vehicle_category: $category
      vehicle_type: $type
      make: $make
      model: $model
      vehicle_year: $year
    ) {
      registeredVehicle: registered_vehicle {
        ...registeredVehicleFragment
      }
    }
  }
  ${registeredVehicleFragment}
`;

/** Variables provided to a {@link REGISTER_VEHICLE} mutation */
export interface RegisterVehicleVars {
  licensePlate: string;
  stateCode: StateAbbreviation;
  type: VehicleTypeValue;
  make?: string | null;
  model?: string | null;
  year?: number | null;
}

/** Return type of a {@link REGISTER_VEHICLE} query */
export interface RegisterVehicleData {
  registerVehicle: {
    registeredVehicle: RegisteredVehicle;
  };
}

/** Queries that must be refetched when this query runs */
export const RegisterVehicleRefetchQueries = [GET_CURRENTLY_REGISTERED_VEHICLES];

export default REGISTER_VEHICLE;
