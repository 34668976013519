import { gql } from "@apollo/client";
import { VehicleCategoryValue, VehicleTypeValue } from "@app.automotus.io/components/common";

/** Reusable GraphQL fragment for registered vehicle fields */
export const registeredVehicleFragment = gql`
  fragment registeredVehicleFragment on registered_vehicle {
    id
    licensePlate: license_plate
    stateCode: state_code
    category: vehicle_category
    type: vehicle_type
    make
    model
    year: vehicle_year
    createdAt: created_at
    accounts {
      attachment_type
    }
  }
`;

/** Information about a registered vehicle */
export interface RegisteredVehicle {
  /** Unique ID of the vehicle */
  id: string;
  /** License plate */
  licensePlate: string;
  /** License plate state code */
  stateCode: string;
  /** Vehicle category */
  category?: VehicleCategoryValue;
  /** Vehicle type */
  type: VehicleTypeValue | null;
  /** Make of the vehicle */
  make: string | null;
  /** Model of the vehicle */
  model: string | null;
  /** Year of the vehicle */
  year: number | null;
  /** Date when record was created */
  createdAt: Date;
  /** Account Registered Vehicle*/
  accounts: AccountRegisteredVehicle[];
}

export enum VehicleAccountAttachmentType {
  UNPAID_REGISTRATION = "unpaid_registration",
  PAID_REGISTRATION = "paid_registration",
  UNPAID_NON_REGISTRATION = "unpaid_non_registration",
  PAID_NON_REGISTRATION = "paid_non_registration",
}

export interface AccountRegisteredVehicle {
  attachment_type: VehicleAccountAttachmentType;
}

export default registeredVehicleFragment;
