import { gql } from "@apollo/client";

export const GET_PARKING_RATES_FOR_PAYEE = gql`
  query GetParkingRatesForPayee($payeeAccountId: uuid!) {
    payee_parking_rate(where: { payee_account_id: { _eq: $payeeAccountId } }, order_by: { start_duration: asc }) {
      rate
      rate_unit
      start_duration
      end_duration
      currency
    }
  }
`;

/** Variables passed to the {@link GET_PARKING_RATES_FOR_PAYEE} query */
export interface GetParkingRatesForPayeeVars {
  /** Payee account Id */
  payeeAccountId: string;
}

export interface GetParkingRatesForPayeeData {
  /** parking rate data */
  payee_parking_rate: ParkingRateData[] | null;
}

export interface ParkingRateData {
  /** parking rate */
  rate: number;
  /** parking rate unit */
  rate_unit: string;
  /** start duration */
  start_duration: number;
  /** end duration */
  end_duration: number;
  /** currency type */
  currency: string;
}
