import { gql } from "@apollo/client";
export const GET_PAYEE_ZONE_REGULATIONS = gql`
  query GetPayeeZoneRegulations($payeeAccountId: uuid!) {
    zones(
      where: {
        payee_account_id: { _eq: $payeeAccountId }
        zone_policies: {
          policy: {
            _and: [
              { _or: [{ valid_from: { _is_null: true } }, { valid_from: { _lte: "now()" } }] }
              { _or: [{ valid_to: { _is_null: true } }, { valid_to: { _gte: "now()" } }] }
              { rules: {}, time_spans: {} }
            ]
          }
        }
      }
      order_by: [{ name: asc }]
    ) {
      id
      name
      zonePolicies: zone_policies(
        where: {
          policy: {
            _and: [
              { _or: [{ valid_from: { _is_null: true } }, { valid_from: { _lte: "now()" } }] }
              { _or: [{ valid_to: { _is_null: true } }, { valid_to: { _gte: "now()" } }] }
            ]
          }
        }
        limit: 1
      ) {
        policy {
          policyRules: rules(limit: 1) {
            rule {
              rateApplicationType: rate_application_type
              rates(order_by: [{ start_duration: asc }]) {
                rate
                rateUnit: rate_unit
                startDuration: start_duration
                endDuration: end_duration
              }
            }
          }
          policyTimeSpans: time_spans(order_by: [{ time_span: { time_of_day_start: asc } }]) {
            timeSpan: time_span {
              daysOfWeek: days_of_week
              timeOfDayStartHours: time_of_day_start_hours
              timeOfDayStartMinutes: time_of_day_start_minutes
              timeOfDayEndHours: time_of_day_end_hours
              timeOfDayEndMinutes: time_of_day_end_minutes
            }
          }
        }
      }
    }
  }
`;

export interface GetPayeeZoneRegulationsVars {
  payeeAccountId: string;
}

export interface GetPayeeZoneRegulationsData {
  zones: ZoneWithRegulations[];
}

export interface ZoneWithRegulations {
  id: string;
  name: string;
  zonePolicies: ZonePolicy[];
}

export interface ZonePolicy {
  policy: Policy;
}

export interface Policy {
  policyRules: PolicyRule[];
  policyTimeSpans: PolicyTimeSpan[];
}

export interface PolicyRule {
  rule: Rule;
}

export interface Rule {
  rateApplicationType: RateApplicationType;
  rates: Rate[];
}

export interface Rate {
  rate: number;
  rateUnit: "minute";
  startDuration: number;
  endDuration: number;
}

export type RateApplicationType = "flat" | "additive";

export interface PolicyTimeSpan {
  timeSpan: TimeSpan;
}

export interface TimeSpan {
  daysOfWeek: string[];
  timeOfDayStartHours: number;
  timeOfDayStartMinutes: number;
  timeOfDayEndHours: number;
  timeOfDayEndMinutes: number;
}
