import React from "react";
import Box from "@mui/material/Box";
import { RegisteredVehicle } from "common/graphql";
import VehicleInfoForm, {
  ValidatedVehicleInfoFormValues,
  VehicleInfoFormSubmitAction,
} from "@app.automotus.io/components/forms/VehicleInfoForm";
import VehicleModalHeader from "../VehicleModalHeader";
import { useRegisteredVehicleInitialValues } from "@app.automotus.io/components/hooks";

/** A component that displays a modal allowing the user to add a vehicle to his account. */
export const UpdateVehicleModal: React.FC<UpdateVehicleModalProps> = ({
  loading,
  currentVehicleInfo,
  onClose = () => undefined,
  onClickUpdate,
}) => {
  const { initialValues, loadingMakes, loadingModels } = useRegisteredVehicleInitialValues(currentVehicleInfo);

  const onSubmit = async (
    submitAction: VehicleInfoFormSubmitAction,
    values: ValidatedVehicleInfoFormValues,
  ): Promise<void> => {
    try {
      if (submitAction === "update") {
        await onClickUpdate({ ...values, id: initialValues.id });
      }
    } catch (err) {
      console.error(err);
      throw new Error("Failed to update vehicle");
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <VehicleModalHeader title="Update Vehicle Information" description="Required Field*" onClose={onClose} />
      </Box>
      <Box>
        <VehicleInfoForm
          loading={loading || loadingMakes || loadingModels}
          initialValues={initialValues}
          variant="update"
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
};

/** Props passed to initialize an {@link UpdateVehicleModal} component */
export interface UpdateVehicleModalProps {
  /** Loading status */
  loading: boolean;
  /** Information about the registered vehicle in its current state */
  currentVehicleInfo: RegisteredVehicle;
  /** handler fired when user requests to close modal */
  onClose?: () => void;
  /** handler fired when the user clicks the update vehicle button */
  onClickUpdate: (values: ValidatedVehicleInfoFormValues) => Promise<void>;
}

export default UpdateVehicleModal;
