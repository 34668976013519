import React from "react";
import { useQuery } from "@apollo/client";
import { GET_TRANSACTION_DETAIL, GetTransactionDetailData, GetTransactionDetailVars } from "common/graphql";
import Stack from "@mui/material/Stack";
import SessionDetails from "./SessionDetails";
import TransactionTimeAndRateBreakdown from "./TransactionTimeAndRateBreakdown";
import TransactionTotal from "./TransactionTotal";
import sumBy from "lodash/sumBy";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";
import { DisputeInformation } from "../Dashboard/TransactionDetail/DisputeInformation/DisputeInformation";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { appName } from "common/constants";
export interface TransactionDetailProps {
  /** ID of the transaction to display */
  transactionId: string;
}
export const TransactionDetail: React.FC<TransactionDetailProps> = ({ transactionId }) => {
  const { data, loading, error, refetch } = useQuery<GetTransactionDetailData, GetTransactionDetailVars>(
    GET_TRANSACTION_DETAIL,
    {
      variables: {
        transactionId,
      },
    },
  );

  const isFreeTransaction = data?.transaction?.tags.some(({ tag }) => tag === "first_park");

  if (error) {
    return <LocalLevelError onTryAgain={() => refetch()} />;
  }

  return (
    <Stack spacing={3}>
      <SessionDetails
        loading={loading}
        addressCity={data?.transaction?.addressCity}
        addressState={data?.transaction?.addressState}
        addressStreet={data?.transaction?.addressStreet}
        startTime={data && new Date(data?.transaction?.sessionStartedAtLocal || 0)}
        endTime={data && new Date(data?.transaction?.sessionEndedAtLocal || 0)}
        licensePlateNumber={(data && data?.transaction?.registeredVehicle?.licensePlate) || ""}
        licensePlateStateCode={(data && data?.transaction?.registeredVehicle?.stateCode) || ""}
      />
      <TransactionTimeAndRateBreakdown
        loading={loading}
        isFreeTransaction={isFreeTransaction}
        policyCompliance={data?.transaction?.park?.policyRateDetails || []}
      />

      {data?.transaction?.disputes && data?.transaction?.disputes.length >= 1 && (
        <DisputeInformation dispute={data.transaction.disputes[0].dispute} />
      )}

      <TransactionTotal
        loading={loading}
        isFreeTransaction={isFreeTransaction}
        amountBilled={sumBy(data?.transaction?.park?.policyRateDetails || [], "price")}
        amountRefunded={
          data?.transaction?.disputes &&
          data?.transaction?.disputes.length >= 1 &&
          data.transaction.disputes[0].dispute.status === "accepted"
            ? data.transaction.disputes[0].dispute.amount
            : 0
        }
        sessionDurationSeconds={
          data?.transaction?.park
            ? differenceInSeconds(
                new Date(data.transaction.park?.endTime || 0),
                new Date(data.transaction.park?.startTime || 0),
              )
            : 0
        }
        currencySymbol={"$"}
      />
      {appName === "curbsuite.io" && data?.transaction?.citations?.[0] && (
        <Button
          fullWidth
          size="medium"
          component={Link}
          to={`/dashboard/citations/${data?.transaction?.citations[0]?.id}`}
          variant="contained"
          color="primary"
          sx={(theme) => ({
            textTransform: "capitalize",
            py: theme.spacing(1),
            px: theme.spacing(2.75),
            fontSize: "18px",
          })}
        >
          See Citation Information
        </Button>
      )}
    </Stack>
  );
};

export default TransactionDetail;
