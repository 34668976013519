import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import parkIconSvg from "./ParkIcon.svg";
import setupIconSvg from "./SetupIcon.svg";
import cameraIconSvg from "./CameraIcon.svg";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import BottomSheet from "@app.automotus.io/components/BottomSheet";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { TermsOfServiceWrapper } from "@app.automotus.io/components/TermsOfService";
import { useIsMobile } from "@app.automotus.io/components/hooks";

export const PartnerLandingPageHowItWorks: React.FC<{ isPreLaunch?: boolean }> = ({ isPreLaunch = false }) => {
  const StyledImg = styled("img")({});
  const isMobile = useIsMobile();
  const [isTosOpen, setIsTosOpen] = useState<boolean>(false);
  const onCloseTosModal = () => {
    setIsTosOpen(false);
  };

  const onOpenTosModal = () => {
    setIsTosOpen(true);
  };

  const StepItem = ({ iconSrc, description }: { iconSrc: string; description: string }) => (
    <Grid item container columnSpacing={3} justifyContent="flexStart" alignItems="center" flexWrap={"nowrap"}>
      <Grid item>
        <StyledImg src={iconSrc} />
      </Grid>
      <Grid item>
        <Typography variant={"body1"}>{description}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <Box display={"flex"} flexDirection={"column"} px={{ xs: 2, tiny: 3, md: 4 }} pt={{ xs: 1.5, tiny: 1.5, md: 4 }}>
      <Box sx={{ mb: { xs: 2, md: 4 }, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ typography: { xs: "h6", tiny: "h5", sm: "h4" }, fontWeight: "500 !important" }}>
          How it Works
        </Typography>
      </Box>
      <Grid container rowSpacing={{ xs: 2.5, tiny: 5, md: 7.5 }}>
        <StepItem
          iconSrc={setupIconSvg}
          description="Register your vehicle and set up a CurbPass using a card, Apple Pay or Google Pay"
        />
        <StepItem
          iconSrc={parkIconSvg}
          description="Get instant access to every Smart Loading Zone without logging in every time you park"
        />
        <StepItem
          iconSrc={cameraIconSvg}
          description="License plate readers read your license plate and auto charge your account only for the time you use"
        />
        <Grid item container alignItems="center" flexWrap={"nowrap"}>
          <Link
            variant="body1"
            underline={"hover"}
            component={"a"}
            target={"_blank"}
            onClick={() => window.open("https://www.automotus.co/smart-loading-zones-drivers", "_blank")}
            sx={{ mx: "auto", cursor: "pointer", textDecoration: "underline" }}
          >
            Learn More
          </Link>
        </Grid>
        {!isPreLaunch && (
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              mt: 1.5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link
              component={RouterLink}
              to={isMobile ? location.pathname : "/terms-of-service"}
              state={{ sourceRoute: location.pathname }}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (isMobile) {
                  onOpenTosModal();
                }
              }}
            >
              Terms of Service
            </Link>
          </Box>
        )}
        {isMobile && (
          <BottomSheet open={isTosOpen} onClose={onCloseTosModal} marginTopSmall={75} marginTopMedium={79}>
            <AppBar
              sx={{
                height: "52px",
                position: "relative",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <Box>
                  <Typography variant="body3" sx={{ fontWeight: 500, ml: 1 }}>
                    Terms Of Service
                  </Typography>
                </Box>
                <Box>
                  <IconButton edge="end" color="inherit" onClick={onCloseTosModal} aria-label="close">
                    <Box
                      sx={{
                        width: "28px",
                        height: "28px",
                        borderRadius: "100px",
                        backgroundColor: "rgba(0, 0, 0, 0.135)",
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "14px" }} />
                    </Box>
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{ px: 3, pt: 0, mb: -2.5 }}>
              <TermsOfServiceWrapper variant="display" onBack={() => onCloseTosModal()} />
            </DialogContent>
          </BottomSheet>
        )}
      </Grid>
    </Box>
  );
};

export default PartnerLandingPageHowItWorks;
