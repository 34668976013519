import Grid from "@mui/material/Grid";
import LoadingSkeleton from "../../../LoadingSkeleton/LoadingSkeleton";
import CardNumberField from "../../PaymentMethodFields/CardNumberField/CardNumberField";
import ExpiryField from "../../PaymentMethodFields/ExpiryField/ExpiryField";
import CVCField from "../../PaymentMethodFields/CVCField/CVCField";
import React from "react";
import { ZipCodeField } from "@app.automotus.io/components/forms/PaymentMethodFields/ZipCodeField/ZipCodeField";

export const CardMethodFormFields: React.FC<CardMethodFormFieldsProps> = ({ loading, disabled = false }) => {
  return (
    <>
      <Grid container justifyContent="space-between" rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12}>
          <LoadingSkeleton loading={loading} width="100%">
            <CardNumberField disabled={disabled} />
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6}>
          <LoadingSkeleton loading={loading} width="100%">
            <ExpiryField disabled={disabled} />
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6}>
          <LoadingSkeleton loading={loading} width="100%">
            <CVCField disabled={disabled} />
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={12}>
          <LoadingSkeleton loading={loading} width="100%">
            <ZipCodeField disabled={disabled} />
          </LoadingSkeleton>
        </Grid>
      </Grid>
    </>
  );
};

export interface CardMethodFormFieldsProps {
  loading: boolean;
  disabled?: boolean;
}

export default CardMethodFormFields;
