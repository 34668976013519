import React from "react";
import { useField, useFormikContext } from "formik";
import { Company, CompanyValue } from "@app.automotus.io/components/common";
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";
import MuiTextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import * as yup from "yup";

const validationSchema = yup
  .array()
  .of(yup.mixed().oneOf(Company.VALUES, "Please choose one of the provided values"))
  .required("Please choose one of the provided values")
  .min(1, "Selection required");

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MAX_SELECTIONS = 3;

const CompanyAutocompleteField = Object.assign(
  ({
    name = "company",
    label = "Company",
    placeholder = "Company*",
    multiple = true,
    disablePortal = true,
    fullWidth = true,
    disableClearable = true,
    openOnFocus = true,
    required = true,
    helperText,
    ...rest
  }: CompanyAutocompleteFieldProps) => {
    const { isSubmitting } = useFormikContext();
    const [field, meta, helpers] = useField<typeof rest["value"]>(name);

    const showError = meta.touched && !!meta.error;

    const selectedOptions = meta.value || [];
    const numSelected = selectedOptions.length;

    return (
      <MuiAutocomplete<CompanyValue, typeof multiple, typeof disableClearable, false>
        {...rest}
        limitTags={3}
        multiple={multiple}
        disablePortal={disablePortal}
        disableCloseOnSelect
        fullWidth={fullWidth}
        openOnFocus={openOnFocus}
        options={Company.VALUES}
        getOptionDisabled={(option) => (numSelected === MAX_SELECTIONS ? !selectedOptions.includes(option) : false)}
        getOptionLabel={(opt) => (opt ? Company.FromValue(opt).label : "")}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ mr: 1 }} checked={selected} />
            {Company.FromValue(option).label}
          </li>
        )}
        value={Array.isArray(meta.value) ? meta.value : meta.value ? [meta.value] : undefined}
        onChange={(e, v) => {
          helpers.setValue(v || "");
        }}
        isOptionEqualToValue={(opt, value) => (value as string) === "" || opt === value}
        disabled={rest.disabled ?? isSubmitting}
        onBlur={field.onBlur}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            name={name}
            error={showError}
            helperText={
              showError ? meta.error : numSelected === MAX_SELECTIONS ? "Select up to three options." : helperText
            }
            label={label}
            placeholder={meta.value?.length ? "" : placeholder}
            required={
              required && (Array.isArray(meta.value) ? meta.value : meta.value ? [meta.value] : []).length === 0
            }
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.toString().slice(0, 20);
            }}
          />
        )}
      />
    );
  },
  {
    validationSchema,
    defaultInitialValue: [],
  },
);

export interface CompanyAutocompleteFieldProps<
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = true,
> extends Omit<
    MuiAutocompleteProps<CompanyValue, Multiple, DisableClearable, false>,
    "renderInput" | "options" | "getOptionLabel" | "onChange" | "onBlur"
  > {
  name?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
}

export default CompanyAutocompleteField;
