/**
 * Converts numeric degrees to radians
 * @param value numeric degree
 * @returns converted radian
 */
export const degreesToRad = (value: number): number => {
  return (value * Math.PI) / 180;
};

/**
 * This calculates straight line distance between 2 geolocated positions using longitude and latitude
 * @param pos1 position1
 * @param pos2 position2
 * @returns distance between 2 positions
 */
export const calcDistance = (pos1: Position, pos2: Position): number => {
  const RADIUS_OF_EARTH_KM = 6371;
  const { lat: lat1, long: lon1 } = pos1;
  const { lat: lat2, long: lon2 } = pos2;
  const dLat = degreesToRad(lat2 - lat1);
  const dLon = degreesToRad(lon2 - lon1);
  const lat1Rad = degreesToRad(lat1);
  const lat2Rad = degreesToRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = RADIUS_OF_EARTH_KM * c;
  return d;
};

export interface Position {
  lat: number;
  long: number;
}

/**
 * This finds nearest position to the destination
 * @param positions: array of positions
 * @param destination: destinated position
 * @returns nearest position object
 */
export const findNearestLocation = <TPos extends Position, TDest extends Position>(
  positions: TPos[],
  destination: TDest,
): TPos | null => {
  if (positions.length === 0) return null;

  let nearestId = 0;
  let minDistance = calcDistance(positions[0], destination);
  for (let i = 1; i < positions.length; i++) {
    const curDistance = calcDistance(positions[i], destination);
    if (minDistance > curDistance) {
      minDistance = curDistance;
      nearestId = i;
    }
  }

  return positions[nearestId];
};
