import * as React from "react";
import Box from "@mui/material/Box";
import Paper, { PaperProps } from "@mui/material/Paper";
import LoadingSkeleton from "../../LoadingSkeleton";
import { TransactionPaymentData } from "../../TransactionPayments";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import Link from "@mui/material/Link";
// import { Link as RouterLink } from "react-router-dom";
import { formatCurrency, convertTimeToString, formatSessionDateRange } from "common/format";
import CustomPagination from "@app.automotus.io/components/CustomPagination/CustomPagination";
import { useSearchParams } from "react-router-dom";

const columns: GridColumns = [
  {
    field: "parkPeriod",
    headerName: "Park Period",
    sortable: true,
    width: 243,
    renderCell: (params) => (
      <Box>
        <Typography variant="body1">{formatSessionDateRange(params.row.startTime, params.row.endTime)}</Typography>
        <Typography variant="body4" fontSize={"12px"}>
          {convertTimeToString(params.row.startTime)} - {convertTimeToString(params.row.endTime)}
        </Typography>
      </Box>
    ),
    sortComparator: (v1, v2) => v1.getHours() * 24 + v1.getMinutes() - (v2.getHours() * 24 + v2.getMinutes()),
  },
  {
    field: "address",
    headerName: "Address",
    sortable: true,
    width: 200,
  },
  {
    field: "vehicle",
    headerName: "Vehicle",
    sortable: true,
    width: 201,
  },
  {
    field: "amount",
    headerName: "Amount",
    sortable: true,
    width: 196,
    renderCell: (params) => (
      <Typography variant="body1" color={params.row.violation ? "error.main" : "text.primary"}>
        {params.row.violation ? "Violation" : formatCurrency(params.row.amount)}
      </Typography>
    ),
    valueGetter: (params) => (params.row.violation ? 0 : +params.row.amount),
    sortComparator: (v1, v2) => {
      return v1 - v2;
    },
  },
  // TODO: currently, clicking on this button as an unauthenticated user breaks the application
  // {
  //   field: "action",
  //   headerName: "",
  //   sortable: false,
  //   flex: 1,
  //   renderCell: (params) => (
  //     <Box sx={{ cursor: "pointer", width: "100%", display: "flex", justifyContent: "center" }}>
  //       <Link component={RouterLink} to={`/transactions/${params.row.id}`}>
  //         <ArrowForwardIosIcon sx={{ color: (theme) => theme.palette.action.active }} />
  //       </Link>
  //     </Box>
  //   ),
  // },
];

const CustomerPaper: React.FC<CustomerPaperProps> = ({ hideShadow = false, children, ...rest }) => {
  return hideShadow ? <>{children}</> : <Paper {...rest}>{children}</Paper>;
};

export interface CustomerPaperProps extends PaperProps {
  /** Loading status of component. Defaults to true. */
  hideShadow?: boolean;
}

export const InvoiceTransactionsTableView: React.FC<InvoiceTransactionsTableViewProps> = ({
  loading = false,
  rows = [],
}) => {
  const [searchParams] = useSearchParams();
  const isBillSummaryOpen = searchParams.get("viewTransactions") === "true";

  return (
    <LoadingSkeleton loading={loading} width="100%">
      <Box
        sx={{
          height: isBillSummaryOpen ? 450 : 600,
          width: "100%",
        }}
      >
        <CustomerPaper sx={{ height: "100%" }} hideShadow={isBillSummaryOpen}>
          <DataGridPro
            columns={columns}
            rows={rows}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10, 30, 50]}
            loading={loading}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Typography variant="h6" m={1.5}>
                  There are currently no transactions to report
                </Typography>
              ),
              Pagination: CustomPagination,
            }}
            sx={{
              border: isBillSummaryOpen ? "auto" : "none",
              borderLeft: "none",
              borderRight: "none",
              ".MuiDataGrid-cell": {
                border: "none",
                px: 2,
              },
              ".MuiTablePagination-select": {
                pointerEvents: "none",
              },
              ".MuiDataGrid-cell:first-child": {
                pl: isBillSummaryOpen ? 2 : 6,
              },
              ".MuiDataGrid-row": {
                minHeight: 0,
                maxHeight: "fit-content !important",
                py: 1.75,
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                letterSpacing: 0.17,
              },
              ".MuiDataGrid-columnHeaders": {
                maxHeight: "fit-content !important",
              },
              ".MuiDataGrid-columnHeader": {
                px: 2,
                pt: 3,
                pb: 2,
              },
              ".MuiDataGrid-columnHeader:first-child": {
                pl: isBillSummaryOpen ? 2 : 6,
              },
              ".MuiDataGrid-virtualScroller": {
                marginTop: "70px !important",
              },
            }}
          />
        </CustomerPaper>
      </Box>
    </LoadingSkeleton>
  );
};

export interface InvoiceTransactionsTableViewProps {
  loading?: boolean;
  rows?: TransactionPaymentData[];
}

export default InvoiceTransactionsTableView;
