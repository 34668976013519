import { gql } from "@apollo/client";
import { RealTimeOccupancySpaceStatus } from "@app.automotus.io/components/RTO/RealTimeOccupancySpaceStatusIndicator";

export interface CurbsuiteGetRealTimeOccupancyData {
  offlineZones: OfflineZones;
  onlineZones: OnlineZones;
}

export interface OfflineZones {
  // Array may be empty
  zones: OfflineZone[];
}

export interface OfflineZone {
  id: string;
  street_address: string;
  offlineSince: string;
}

export interface OnlineZones {
  zones: OnlineZone[];
}

export interface CurbSpace {
  /** UUID of the space */
  id: string;
  /** Index of the space within its corresponding zone */
  index: number;
  /** Indicates whether the space is occluded */
  isOccluded: boolean;
  /** Recent usage statistics of the space */
  usage: UsageStatistics;
  /** Occupant currently within the space. If the space is not occupied, this will be null */
  occupant: Occupant | null;
}

export interface UsageStatistics {
  /** ISO string date when the space was last occupied */
  lastOccupiedAt: string | null;
  /** Number of violations since the start of the current day in local time */
  numViolationsToday: number;
  /** Utilization ratio expressed on an interval of [0, 1] since the start of the current day in local time */
  utilizationRatioToday: number;
  /**
   * Types of vehicles seen using the space or zone since the start of the current day in local time.
   */
  vehicleTypesToday: VehicleType[];
}

export interface Location {
  type: string;
  crs: {
    type: string;
    properties: {
      name: string;
    };
  };
  coordinates: [number, number][];
}

export type OnlineZone = OnlineZoneWithSpaces | OnlineZoneWithoutSpaces;

export interface OnlineZoneBase {
  /** GUID of the zone */
  id: string;
  /**
   * Location of the zone, expressed as a GeoJSON object. Zones are represented as a LineString, where the first point
   * in the line string indicates the part of the zone that a vehicle would initially reach if it were travelling on the
   * street towards the zone.
   */
  location: Location;
  /** Street address of the zone */
  street_address: string;
  /** Linear length of the zone, in meters */
  lengthMeters: number;
  /** A URL pointing to a reference image of the zone */
  referenceImageUrl: string;
  /**
   * Current occupants of the zone
   */
  occupants: Occupant[];
}

export interface OnlineZoneWithSpaces extends OnlineZoneBase {
  /**
   * Individually demarcated spaces within the zone.
   */
  spaces: CurbSpace[];
  /**
   * When a zone has individually demarcated spaces, usage is determined on a per-space basis, not
   * at the level of the zone.
   */
  usage: null;
}

export interface OnlineZoneWithoutSpaces extends OnlineZoneBase {
  /**
   * Individually demarcated spaces within the zone.
   */
  spaces: null;
  /** Recent usage statistics of the zone */
  usage: UsageStatistics;
}

export type SessionType = "park" | "double_park";

export type VehicleType =
  | "antique"
  | "bus-commercial"
  | "bus-school"
  | "emergency"
  | "motorcycle"
  | "sedan-compact"
  | "sedan-convertible"
  | "sedan-sports"
  | "sedan-standard"
  | "sedan-wagon"
  | "suv-crossover"
  | "suv-standard"
  | "taxi"
  | "tractor-farm"
  | "tractor-trailer"
  | "trailer-rv"
  | "trailer-utility"
  | "truck-garbage"
  | "truck-standard"
  | "van-full"
  | "van-mini";

export type VehicleColor =
  | "black"
  | "blue"
  | "brown"
  | "gold-beige"
  | "green"
  | "orange"
  | "pink"
  | "purple"
  | "red"
  | "silver-gray"
  | "white"
  | "yellow";

export interface Occupant {
  index: number;
  vehicle: OccupantVehicle;
  session: {
    id: string;
    startTime: string;
    session_start_image_url: string | null;
    sessionType: SessionType;
    violation?: {
      type?: "double_park" | "exceeded_max_time";
      startTime: string;
    };
  };
  status: RealTimeOccupancySpaceStatus;
}

export type OccupantVehicle = OccupantVehicleWithLicensePlate | OccupantVehicleWithoutLicensePlate;

interface OccupantVehicleBase {
  id: string;
  type: VehicleType;
  color: VehicleColor;
}

export interface OccupantVehicleWithoutLicensePlate extends OccupantVehicleBase {
  licensePlateNumber: null;
  licensePlateRegion: null;
}

export interface OccupantVehicleWithLicensePlate extends OccupantVehicleBase {
  licensePlateNumber: string;
  licensePlateRegion: string;
}

export interface MapBounds {
  minLong: number;
  maxLong: number;
  minLat: number;
  maxLat: number;
}

export const GET_MAP_DATA = gql`
  query CurbsuiteGetRealTimeZoneOccupancy(
    $minLong: numeric!
    $maxLong: numeric!
    $minLat: numeric!
    $maxLat: numeric!
  ) {
    offlineZones: curbsuite_get_offline_zones {
      zones {
        id
        location
        street_address
        offlineSince: offline_since
      }
    }
    onlineZones: curbsuite_get_real_time_zone_occupancy(
      map_boundaries: { min_long: $minLong, max_long: $maxLong, min_lat: $minLat, max_lat: $maxLat }
    ) {
      zones {
        id
        location
        street_address
        lengthMeters: length_meters
        referenceImageUrl: reference_image_url
        spaces {
          id
          index
          isOccluded: is_occluded
          usage {
            lastOccupiedAt: last_occupied_at
            numViolationsToday: num_violations_today
            utilizationRatioToday: utilization_ratio_today
            vehicleTypesToday: vehicle_types_today
          }
          occupant {
            index
            vehicle {
              id
              licensePlateNumber: license_plate_number
              licensePlateRegion: license_plate_region
              type
              color
            }
            session {
              id
              type
              startTime: start_time
              session_start_image_url: start_image_url
              violation {
                type
                startTime: start_time
              }
            }
            status
          }
        }
        occupants {
          index
          vehicle {
            id
            licensePlateNumber: license_plate_number
            licensePlateRegion: license_plate_region
            type
            color
          }
          session {
            id
            type
            startTime: start_time
            session_start_image_url: start_image_url
            violation {
              type
              startTime: start_time
            }
          }
          status
        }
        usage {
          lastOccupiedAt: last_occupied_at
          numViolationsToday: num_violations_today
          utilizationRatioToday: utilization_ratio_today
          vehicleTypesToday: vehicle_types_today
        }
      }
    }
  }
`;
