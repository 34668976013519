import React from "react";
import Dialog from "@mui/material/Dialog";
import PartnerLandingPageHowItWorks from "./index";
import Button from "@mui/material/Button";

export const PartnerLandingPageHowItWorksModal: React.FC<PartnerLandingPageHowItWorksModalProps> = ({
  open,
  onClose,
  isPreLaunch = false,
}) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 3,
          minWidth: 288,
          maxWidth: 454,
          height: "80vh",
          display: "flex",
          justifyContent: "space-between",
        },
      }}
      onBackdropClick={onClose}
    >
      <PartnerLandingPageHowItWorks isPreLaunch={isPreLaunch} />
      <Button
        variant={"contained"}
        fullWidth
        onClick={onClose}
        sx={{
          m: 4,
          height: "42px",
          fontSize: "18px",
          fontWeight: 500,
          textTransform: "none",
          width: `auto`,
        }}
      >
        Close
      </Button>
    </Dialog>
  );
};

export interface PartnerLandingPageHowItWorksModalProps {
  open: boolean;
  onClose: () => void;
  isPreLaunch?: boolean;
}

export default PartnerLandingPageHowItWorksModal;
