import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { CreditCardBrandValue } from "common/adaptors";

const CREDIT_CARD_BRAND_ICONS_BY_VALUE: Record<CreditCardBrandValue, IconDefinition> = {
  amex: faCcAmex,
  diners: faCcDinersClub,
  discover: faCcDiscover,
  jcb: faCcJcb,
  mastercard: faCcMastercard,
  visa: faCcVisa,
  unionpay: faCreditCard,
  unknown: faCreditCard,
};
/**
 * Component that renders a credit card icon.
 */
export const CreditCardIcon: React.FC<CreditCardIconProps> = ({ brand, ...rest }) => {
  return <FontAwesomeIcon {...rest} icon={CREDIT_CARD_BRAND_ICONS_BY_VALUE[brand]} />;
};

/**
 * Props passed to intialize a {@link CreditCardIcon} component
 */
export interface CreditCardIconProps extends Omit<FontAwesomeIconProps, "icon"> {
  brand: CreditCardBrandValue;
}

export default CreditCardIcon;
