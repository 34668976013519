import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { ZoneRegulations } from "@app.automotus.io/components/ZoneRegulations";

export const PartnerLandingPageParkingRatesAndHoursModal: React.FC<PartnerLandingPageParkingRatesParkingHoursModalProps> =
  ({ open, onClose, payeeAccountId }) => {
    const [tabValue, setTabValue] = useState<"regulations">("regulations");

    const handleTabChange = (event: React.SyntheticEvent, newValue: "regulations") => {
      setTabValue(newValue);
    };

    return (
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            borderRadius: 2,
            minWidth: 295,
            minHeight: 340,
            height: 345,
          },
        }}
        onBackdropClick={onClose}
      >
        <TabContext value={tabValue}>
          <Box>
            <TabList onChange={handleTabChange} variant={"fullWidth"} sx={{ minHeight: "40px", height: "40px" }}>
              <Tab
                label={"Zone Regulations"}
                value={"regulations"}
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textTransform: "none",
                  alignItems: "flex-start",
                  px: "14px",
                  py: "10px",
                  minHeight: "40px",
                  height: "40px",
                }}
              />
            </TabList>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{
              height: "100%",
              p: 0,
            }}
          >
            <TabPanel
              value={"regulations"}
              sx={{
                p: 0,
                height: "230px",
                overflowY: "scroll",
              }}
            >
              <ZoneRegulations payeeAccountId={payeeAccountId} />
            </TabPanel>
            <Box
              sx={{
                pt: "10px",
                px: 1.5,
                pb: 1.5,
              }}
            >
              <Button
                variant={"contained"}
                fullWidth
                onClick={onClose}
                sx={{
                  textTransform: "none",
                  height: { xs: "30px", tiny: "42px" },
                  fontSize: { xs: "14px", tiny: "18px" },
                  fontWeight: { xs: 600, tiny: 400 },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </TabContext>
      </Dialog>
    );
  };

export interface PartnerLandingPageParkingRatesParkingHoursModalProps {
  open: boolean;
  onClose: () => void;
  payeeAccountId: string;
}

export interface ParkingRates {
  currency: string;
  intervals: ParkingRateInterval[];
}

export interface ParkingRateInterval {
  startMinutes: number;
  endMinutes: number;
  pricePerMinute: number;
}

export interface ParkingHourIntervalDescriptor {
  startDay: number;
  endDay: number;
  startHour: number;
  endHour: number;
  location: string;
}

export default PartnerLandingPageParkingRatesAndHoursModal;
