import React from "react";
import DashboardContentLayout from "@app.automotus.io/components/Layout/DashboardContentLayout";
import Box from "@mui/material/Box";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

/**
 * Component that displays local level erro
 */
export const LocalLevelError: React.FC<LocalLevelErrorProps> = ({ onTryAgain = () => undefined }) => {
  return (
    <Box sx={{ bgcolor: "background.default" }}>
      <DashboardContentLayout
        sx={{
          my: { xs: 4, lg: 8 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "text.secondary",
        }}
      >
        <InfoOutlined sx={{ fontSize: "50px" }} />
        <Typography variant="h5" mt={2}>
          Failed to load data
        </Typography>
        <Typography variant="subtitle2" mt={1.5}>
          There was a problem. Please try again.
        </Typography>
        <Button variant="contained" onClick={onTryAgain} sx={{ mt: 5 }}>
          Try Again
        </Button>
      </DashboardContentLayout>
    </Box>
  );
};

export interface LocalLevelErrorProps {
  onTryAgain?: () => void;
}

export default LocalLevelError;
