import { gql } from "@apollo/client";
import { Park, parkFragment } from "common/graphql/fragments/parkFragment";
import {
  PayerTransactionLogNoBalanceEntry,
  payerTransactionLogNoBalanceEntryFragment,
} from "./payerTransactionLogNoBalanceEntryFragment";

export const invoiceFragment = gql`
  fragment invoiceFragment on financial_invoice {
    id
    admin_fee_due
    amount_paid
    billing_begin_at
    billing_end_at
    due_at
    first_opened_at
    voided_at
    index
    admin_fee_amount_waived
    is_paid_in_full
    dispute {
      disputed_at
      status
    }
    payee_account_id
    registered_vehicle {
      id
      license_plate
      state_code
      accounts(limit: 1) {
        attachment_type
      }
    }
    total_amount_due
    transaction_amount_due
    transactions {
      id
      address_street
      address_city
      address_state
      sessionStartedAtLocal: session_started_at_local
      sessionEndedAtLocal: session_ended_at_local
      transaction_log_no_balance {
        ...payerTransactionLogNoBalanceEntryFragment
      }
      park {
        ...parkFragment
      }
    }
    replaced_by_invoice {
      index
    }
    replaces_invoice {
      index
    }
    created_at
    payment_transaction {
      created_at
    }
    notice_sent_at
    notice_content_url
  }
  ${parkFragment}
  ${payerTransactionLogNoBalanceEntryFragment}
`;

export interface TransactionData {
  id: string;
  address_street: string;
  address_city: string;
  address_state: string;
  sessionStartedAtLocal: string;
  sessionEndedAtLocal: string;
  park: Park;
  transaction_log_no_balance: PayerTransactionLogNoBalanceEntry;
}

export interface InvoiceData {
  id: string;
  admin_fee_due: number;
  amount_paid: number;
  billing_begin_at: string;
  billing_end_at: string;
  first_opened_at: string;
  due_at: string;
  index: number;
  admin_fee_amount_waived: number;
  is_paid_in_full: boolean;
  dispute?: {
    disputed_at: string;
    status: "approved" | "denied" | "pending";
  };
  payee_account_id: string;
  total_amount_due: number;
  transaction_amount_due: number;
  replaced_by_invoice: {
    id: string;
    index: number;
  } | null;
  registered_vehicle: {
    id: string;
    license_plate: string;
    state_code: string;
    accounts: {
      attachment_type: string;
    }[];
  };
  transactions: TransactionData[];
  voided_at: string;
  replaces_invoice?: {
    id: string;
    index: number;
  } | null;
  created_at: string;
  payment_transaction: {
    created_at: string;
  };
  notice_sent_at: string | null;
  notice_content_url: string | null;
}

export enum InvoiceReviewStatus {
  FAILURE = "failure",
  IN_PROGRESS = "in_progress",
  NOT_REQUIRED = "not_required",
  PENDING = "pending",
  SUCCESS = "success",
}

export enum InvoiceDisputeStatue {
  APPROVED = "approved",
  DENIED = "denied",
  PENDING = "pending",
}
