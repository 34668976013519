import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

export const OnboardingInformation: React.FC<{
  title: string;
  subTitle?: string;
  content?: string;
  subContent?: string;
}> = ({ title, subTitle, content, subContent }) => (
  <Box display="flex" alignItems="center">
    <CheckCircleOutline sx={{ width: 20, height: 20, p: "2px", mr: "10px" }} />
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flex="1">
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant={"body1"}>{title}</Typography>
        {!!subTitle && (
          <Typography variant={"caption"} color="text.secondary">
            {subTitle}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end" sx={{ overflowWrap: "anywhere", pl: 1.25 }}>
        {!!content && (
          <Typography variant={"body3"} sx={{ fontWeight: 500 }}>
            {content}
          </Typography>
        )}
        {!!subContent && (
          <Typography variant={"caption"} color="text.secondary" sx={{ textAlign: "right" }}>
            {subContent}
          </Typography>
        )}
      </Box>
    </Box>
  </Box>
);

export default OnboardingInformation;
