import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import { convertTimeToString, formatCurrency, formatSessionDateRange } from "common/format";

export const TransactionInformation: React.FC<TransactionInformationProps> = ({ loading = true, transaction }) => {
  const transactionInfo: TransactionInfoValues = transaction || {
    transactionStartDate: new Date(),
    transactionEndDate: new Date(),
    currencySymbol: "-",
    licensePlateNumber: "-",
    licensePlateStateCode: "-",
    address: "-",
    city: "-",
    transactionAmount: 0,
  };

  return (
    <Box sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
      <Grid container justifyContent="space-between" rowSpacing={[2, 2, 2, 2.5]} columnSpacing={2}>
        <Grid item xs={6} md={4} order={{ xs: 0, md: 0 }}>
          <LoadingSkeleton loading={loading} width="100%">
            <Box>
              <Typography variant="body2">Date:</Typography>
              <Typography variant="body1">
                {formatSessionDateRange(transactionInfo.transactionStartDate, transactionInfo.transactionEndDate)}
              </Typography>
            </Box>
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6} md={4} order={{ xs: 1, md: 1 }}>
          <LoadingSkeleton loading={loading} width="100%">
            <Box>
              <Typography variant="body2" sx={{ textAlign: { xs: "right", md: "left" } }}>
                Time:
              </Typography>
              <Typography variant="body1" sx={{ textAlign: { xs: "right", md: "left" } }}>
                {`${convertTimeToString(transactionInfo.transactionStartDate)} - ${convertTimeToString(
                  transactionInfo.transactionEndDate,
                )}`}
              </Typography>
            </Box>
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6} md={4} order={{ xs: 4, md: 2 }}>
          <LoadingSkeleton loading={loading} width="100%">
            <Box>
              <Typography variant="body2">Vehicle:</Typography>
              <Typography variant="body1">
                {`${transactionInfo.licensePlateStateCode} ${transactionInfo.licensePlateNumber}`}
              </Typography>
            </Box>
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6} md={4} order={{ xs: 2, md: 3 }}>
          <LoadingSkeleton loading={loading} width="100%">
            <Box>
              <Typography variant="body2">Address:</Typography>
              <Typography variant="body1">{`${transactionInfo.address}`}</Typography>
            </Box>
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6} md={4} order={{ xs: 3, md: 4 }}>
          <LoadingSkeleton loading={loading} width="100%">
            <Box>
              <Typography variant="body2" sx={{ textAlign: { xs: "right", md: "left" } }}>
                City:
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: { xs: "right", md: "left" } }}
              >{`${transactionInfo.city}`}</Typography>
            </Box>
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6} md={4} order={{ xs: 5, md: 5 }}>
          <LoadingSkeleton loading={loading} width="100%">
            <Box>
              <Typography variant="body2" sx={{ textAlign: { xs: "right", md: "left" } }}>
                Amount:
              </Typography>
              <Typography variant="body1" sx={{ textAlign: { xs: "right", md: "left" } }}>{`${
                transactionInfo.currencySymbol
              }${formatCurrency(transactionInfo.transactionAmount)}`}</Typography>
            </Box>
          </LoadingSkeleton>
        </Grid>
      </Grid>
    </Box>
  );
};

export interface TransactionInfoValues {
  transactionStartDate: Date;
  transactionEndDate: Date;
  currencySymbol: string;
  licensePlateNumber: string;
  licensePlateStateCode: string;
  address: string;
  city: string;
  transactionAmount: number;
}

export interface TransactionInformationProps {
  loading?: boolean;
  transaction?: TransactionInfoValues;
}

export default TransactionInformation;
