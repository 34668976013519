import { gql } from "@apollo/client";
import {
  Account,
  accountFragment,
  UserPreferences,
  userPreferencesFragment,
  UserProfile,
  userProfileFragment,
} from "common/graphql/fragments";
import { GET_USER_PROFILE } from "common/graphql";

// TODO: this update will not work properly for payees -- need to fix!
// NOTE: can use the existing full name from account if a non-organization user
// is calling
export const UPDATE_PROFILE_ONBOARDING = gql`
  mutation UpdateProfileOnboarding(
    $accountId: uuid!
    $userId: uuid!
    $fullName: String!
    $givenName: String!
    $familyName: String!
    $email: String!
    $selectedCompanies: _text!
    $phoneNumber: String!
    $emailUpdates: Boolean!
  ) {
    updateAccount: update_account_by_pk(pk_columns: { id: $accountId }, _set: { holder_name: $fullName }) {
      ...accountFragment
    }
    updateUserPreferences: insert_auth_user_preferences_payment_product_one(
      object: { email_updates_enabled: $emailUpdates }
      on_conflict: { constraint: user_preferences_payment_product_pkey, update_columns: [email_updates_enabled] }
    ) {
      ...userPreferencesFragment
    }
    updateBasicProfile: update_auth_user_profile_by_pk(
      pk_columns: { id: $userId }
      _set: {
        full_name: $fullName
        given_name: $givenName
        family_name: $familyName
        email: $email
        selected_companies: $selectedCompanies
        phone_number: $phoneNumber
      }
    ) {
      ...userProfileFragment
    }
  }
  ${userProfileFragment}
  ${accountFragment}
  ${userPreferencesFragment}
`;

export interface UpdateProfileOnboardingVars {
  accountId: string;
  userId: string;
  fullName: string;
  givenName: string;
  familyName: string;
  email: string;
  selectedCompanies: string;
  phoneNumber: string;
  emailUpdates: boolean;
}

export interface UpdateProfileOnboardingData {
  updateAccount: Account;
  updateUserPreferences: UserPreferences;
  updateBasicProfile: UserProfile;
}

export const UPDATE_PROFILE_ONBOARING_REFETCH_QUERIES = [GET_USER_PROFILE];

export default UPDATE_PROFILE_ONBOARDING;
