import React, { useEffect, useState } from "react";
import SnackbarAlert, { SnackbarAlertProps } from "@app.automotus.io/components/SnackbarAlert";
import SnackPackContext from "@app.automotus.io/components/context/SnackPack/SnackPackContext";
import { SnackbarMessage, SnackbarMessageProps } from "@app.automotus.io/components/context/SnackPack/types";

/**
 * Context provider that allows any component inside its tree the ability to publish to a single stack of
 * Snackbar messages.
 * @param children
 * @constructor
 */
export const SnackPackProvider: React.FC<SnackPackProviderProps> = ({ children, ...props }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [snackMessage, setSnackMessage] = useState<SnackbarMessage | undefined>();

  useEffect(() => {
    if (snackPack.length && !snackMessage) {
      // Set a new snack when we don't have an active one
      setSnackMessage({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setSnackbarOpen(true);
    } else if (snackPack.length && snackMessage && snackbarOpen) {
      // Close an active snack when a new one is added
      setSnackbarOpen(false);
    }
  }, [snackPack, snackMessage, snackbarOpen]);

  const publishSnackbarMessage = React.useCallback(
    (msg: SnackbarMessageProps) => {
      setSnackPack((prev) => [...prev, { ...msg, key: new Date().getTime() }]);
    },
    [setSnackPack],
  );

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleSnackbarExited = () => {
    setSnackMessage(undefined);
  };

  const { TransitionProps, ...snackbarAlertProps } = props;

  return (
    <SnackPackContext.Provider value={{ publishSnackbarMessage }}>
      {children}
      <SnackbarAlert
        {...snackbarAlertProps}
        autoHideDuration={snackMessage?.autoHideDuration ? snackMessage?.autoHideDuration : 3000}
        key={snackMessage?.key}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionProps={{ onExited: handleSnackbarExited, ...TransitionProps }}
        message={snackMessage?.message}
        severity={snackMessage?.severity}
        action={snackMessage?.action}
        anchorOrigin={snackMessage?.anchorOrigin}
      />
    </SnackPackContext.Provider>
  );
};

export type SnackPackProviderProps = Omit<
  SnackbarAlertProps,
  "key" | "open" | "onClose" | "message" | "severity" | "action" | "children"
>;

export default SnackPackProvider;
