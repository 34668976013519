import React from "react";
import Box from "@mui/material/Box";
import LinkButton, { LinkButtonProps } from "@app.automotus.io/components/LinkButton";
import { PaymentMethod } from "common/graphql";
import Typography from "@mui/material/Typography";
import { SavedPaymentCard } from "@app.automotus.io/components/SavedPaymentMethod/SavedPaymentCard";
import { SavedPaymentMethodPlaceholder } from "@app.automotus.io/components/SavedPaymentMethod/SavedPaymentMethodPlaceholder";
import Tooltip from "@mui/material/Tooltip";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";

export const SavedPaymentMethod = <TMethod extends PaymentMethod>({
  loading = true,
  isDefault = false,
  disabled = false,
  enableRemove = false,
  paymentMethod,
  onClickSetDefault = () => undefined,
  onClickRemove = () => undefined,
  onClickEdit = () => undefined,
}: SavedPaymentMethodProps<TMethod>): JSX.Element => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (paymentMethod) {
      switch (e.currentTarget.value) {
        case "setDefault":
          return onClickSetDefault(e, paymentMethod);
        case "remove":
          return onClickRemove(e, paymentMethod);
        case "edit":
          return onClickEdit(e, paymentMethod);
        default:
          return;
      }
    }
  };

  const title = paymentMethod?.type === "credit_card" ? "Card" : paymentMethod?.type === "wallet" ? "Wallet" : "Check";

  const titleVariant = paymentMethod?.type === "credit_card" ? "body1" : "body2";

  const linkSx = { mr: { xs: 1.5, lg: 3 } };

  return (
    <Box>
      <Box sx={{ mb: { xs: 2, lg: 1 } }} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <LoadingSkeleton loading={loading}>
            <Typography variant={titleVariant}>{title}</Typography>
          </LoadingSkeleton>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <LoadingSkeleton loading={loading} sx={linkSx}>
            <LinkButtonVariant sx={linkSx} value="setDefault" disabled={disabled || isDefault} onClick={handleClick}>
              {isDefault ? "Default" : "Set to Default"}
            </LinkButtonVariant>
          </LoadingSkeleton>
          {isDefault ? (
            <Tooltip title={isDefault && "Choose another default to remove"}>
              <Box display="flex" alignItems="center">
                <LinkButtonVariant
                  sx={{ ...linkSx, verticalAlign: "middle" }}
                  value="remove"
                  disabled
                  onClick={handleClick}
                >
                  Remove
                </LinkButtonVariant>
              </Box>
            </Tooltip>
          ) : (
            <LoadingSkeleton loading={loading} sx={linkSx}>
              <LinkButtonVariant sx={linkSx} value="remove" disabled={disabled || !enableRemove} onClick={handleClick}>
                Remove
              </LinkButtonVariant>
            </LoadingSkeleton>
          )}
          <LoadingSkeleton loading={loading} sx={linkSx}>
            <LinkButtonVariant sx={linkSx} value="edit" disabled={disabled} onClick={handleClick}>
              Edit
            </LinkButtonVariant>
          </LoadingSkeleton>
        </Box>
      </Box>
      {!paymentMethod ||
      (paymentMethod && (paymentMethod.type === "credit_card" || paymentMethod.type === "wallet")) ? (
        <SavedPaymentCard loading={loading} paymentMethod={paymentMethod} />
      ) : (
        <SavedPaymentMethodPlaceholder />
      )}
      {!enableRemove && !loading && (
        <Typography sx={{ mt: 2, color: "text.secondary" }} variant="body2">
          To remove, add another payment method first
        </Typography>
      )}
    </Box>
  );
};

const LinkButtonVariant = (props: LinkButtonProps) => <LinkButton {...props} variant="body1" />;

export interface SavedPaymentMethodProps<TMethod extends PaymentMethod> {
  disabled?: boolean;
  enableRemove?: boolean;
  loading?: boolean;
  isDefault?: boolean;
  paymentMethod?: TMethod;
  onClickSetDefault?: (e: React.MouseEvent<HTMLButtonElement>, paymentMethod: PaymentMethod) => void;
  onClickRemove?: (e: React.MouseEvent<HTMLButtonElement>, paymentMethod: PaymentMethod) => void;
  onClickEdit?: (e: React.MouseEvent<HTMLButtonElement>, paymentMethod: PaymentMethod) => void;
}

export interface SavedPaymentMethodComponentProps<TMethod extends PaymentMethod> {
  loading?: boolean;
  paymentMethod?: TMethod;
}

export default SavedPaymentMethod;
