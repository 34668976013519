import { gql } from "@apollo/client";
import { InvoiceData, invoiceFragment } from "../fragments/invoiceFragment";

export const GET_ALL_INVOICES = gql`
  query GetAllInvoices {
    invoices: financial_invoice {
      ...invoiceFragment
    }
  }
  ${invoiceFragment}
`;

export const GET_INVOICE_BY_ID = gql`
  query GetInvoiceById($invoiceId: uuid!) {
    invoice: financial_invoice_by_pk(id: $invoiceId) {
      ...invoiceFragment
    }
  }
  ${invoiceFragment}
`;

export const GET_INVOICE_BY_INDEX = gql`
  query GetInvoiceByIndex($invoiceIndex: Int!) {
    invoice: financial_invoice(where: { index: { _eq: $invoiceIndex } }, limit: 1) {
      ...invoiceFragment
    }
  }
  ${invoiceFragment}
`;

export const GET_INVOICES_FOR_VEHICLE = gql`
  query GetInvoicesForVehicle($registeredVehicleId: uuid!) {
    invoices: financial_invoice(
      where: { registered_vehicle_id: { _eq: $registeredVehicleId } }
      order_by: [{ created_at: desc }]
    ) {
      ...invoiceFragment
    }
  }
  ${invoiceFragment}
`;

/** Variables passed to the {@link GET_INVOICE_BY_ID} query */
export interface GetInvoiceByIdVars {
  /** Invoice Id */
  invoiceId: string;
}

export interface GetInvoiceByIdData {
  /** Invoice data */
  invoice: InvoiceData | null;
}

export interface GetInvoicesByVehicleVars {
  /** Registered Vehicle Id */
  registeredVehicleId: string;
}

/** Variables passed to the {@link GET_INVOICE_BY_INDEX} query */
export interface GetInvoiceByIndexVars {
  /** Invoice Index */
  invoiceIndex: number;
}

export interface GetInvoiceByIndexData {
  /** Invoice data */
  invoice: InvoiceData[] | null;
}

export interface GetInvoicesByVehicleData {
  /** Invoice array data */
  invoices: InvoiceData[] | null;
}

export interface GetAllInvoicesData {
  /** Invoice array data */
  invoices: InvoiceData[];
}
