import { useEffect, useRef } from "react";

/** Hook that returns the previous value of some ref
 * @param value The value to reference
 * @returns The previous value that the reffed value assumed
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePrevious;
