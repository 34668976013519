import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { useTheme } from "@mui/material/styles";

export const SignUpStepper: React.FC<SignUpStepperProps> = ({ accountRoleType, completedSteps = [], activeStep }) => {
  const theme = useTheme();
  const steps = accountRoleType === "payer" ? PAYER_STEPS : PAYEE_STEPS;

  const getStepIndex = (step: SignUpStepValue) => steps.findIndex((s) => s.value === step);
  const activeStepIndex = getStepIndex(activeStep);

  const isStepCompleted = (step: SignUpStepValue) => completedSteps.includes(step);

  const maxCompletedIndex = Math.max(...completedSteps.map(getStepIndex));

  const isStepEnabled = (step: SignUpStepValue) => {
    if (step === "finish") {
      return completedSteps.length === steps.length - 1;
    }

    return getStepIndex(step) <= maxCompletedIndex + 1;
  };

  return (
    <Stepper
      sx={{ mt: 2, mb: { xs: 1.5, md: 3 } }}
      activeStep={activeStepIndex}
      alternativeLabel
      nonLinear
      connector={null}
    >
      {steps.map((step) => (
        <Step
          sx={{
            width: "100%",
            height: "6px",
            borderRadius: "50px",
            cursor: "auto",
            mx: "5px",
            "&:last-child": {
              mr: 0,
            },
            "&:first-child": {
              ml: 0,
            },
            backgroundColor: "rgba(0, 0, 0, 0.26)",
            ...((isStepCompleted(step.value) || isStepEnabled(step.value)) && {
              backgroundColor: theme.palette.primary.main,
              opacity: 0.5,
            }),
            ...(step.value === activeStep && {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
            }),
          }}
          key={step.value}
          completed={isStepCompleted(step.value)}
        ></Step>
      ))}
    </Stepper>
  );
};

const SIGNUP_STEPS = {
  profile: { label: "Profile", value: "profile" },
  payment: { label: "Payment", value: "payment" },
  vehicle: { label: "Vehicle", value: "vehicle" },
  finish: { label: "Finish", value: "finish" },
} as const;

export type SignUpStep = typeof SIGNUP_STEPS[keyof typeof SIGNUP_STEPS];

export type SignUpStepValue = SignUpStep["value"];

const PAYER_STEPS = [SIGNUP_STEPS.profile, SIGNUP_STEPS.vehicle, SIGNUP_STEPS.payment, SIGNUP_STEPS.finish];

const PAYEE_STEPS = [SIGNUP_STEPS.profile, SIGNUP_STEPS.payment, SIGNUP_STEPS.finish];

export interface SignUpStepperProps {
  accountRoleType: "payer" | "payee";
  completedSteps?: SignUpStepValue[];
  activeStep: SignUpStepValue;
  onClickStep: (s: SignUpStepValue) => void;
}
