import React from "react";
import Container, { ContainerProps } from "@mui/material/Container";
import { useIsMobile } from "@app.automotus.io/components/hooks";

/**
 * Layout component that displays dashboard contents within a specific width
 * constraint, even on larger screens.
 *
 * Note that this layout will not apply to all elements displayed on the dashboard
 * screen; certain elements will take up the entirety of the screen width.
 * However, the contents of such elements should use this layout.
 */
export const DashboardContentLayout: React.FC<DashboardContentLayoutProps> = ({ children, ...containerProps }) => {
  const isMobile = useIsMobile();

  const mergedSx = {
    ...containerProps.sx,
    px: isMobile ? 2 : 0,
    maxWidth: isMobile ? undefined : "1040px",
  };

  return (
    <Container {...containerProps} sx={mergedSx}>
      {children}
    </Container>
  );
};

export type DashboardContentLayoutProps = ContainerProps;

export default DashboardContentLayout;
