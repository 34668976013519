import { gql } from "@apollo/client";

/**
 * Query that retrieves paginated results of vehicles registered to the user's account.
 */
export const COUNT_CURRENTLY_REGISTERED_VEHICLES = gql`
  query CountCurrentlyRegisteredVehicles {
    registeredVehicleCount: registered_vehicle_aggregate {
      aggregate {
        count
      }
    }
  }
`;

/** Data returned by the {@link GET_CURRENTLY_REGISTERED_VEHICLES} query */
export interface CountCurrentlyRegisteredVehiclesData {
  /** Count of vehicles registered to the user's account */
  registeredVehicleCount: { aggregate: { count: number } };
}

export default COUNT_CURRENTLY_REGISTERED_VEHICLES;
