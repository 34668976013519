import React from "react";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import TransactionPaymentsCardList from "./TransactionPaymentsCardList";
import TransactionPaymentsTableView from "./TransactionPaymentsTableView";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export interface TransactionPaymentData {
  id: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  address: string;
  city: string;
  vehicleId?: string;
  vehicle: string;
  amount: number;
  balance?: number;
  violation?: boolean;
  status: string;
  index: number;
  gatewayTransactionType?: "initial_wallet_load" | "wallet_reload" | "payout" | "wallet_refund" | null;
}

export const TransactionPayments: React.FC<TransactionPaymentsProps> = ({ loading = false, data = [] }) => {
  const isMobile = useIsMobile();

  return (
    <Box>
      <Typography
        component="p"
        color="rgba(0, 0, 0, 0.38)"
        mb={isMobile ? 2.5 : 2}
        fontSize={{ xs: "0.875rem", tiny: "1rem" }}
        fontWeight={{ xs: 400, tiny: 300, md: 400 }}
      >
        Transactions can take up to 24 hours to process
      </Typography>
      {isMobile ? (
        <TransactionPaymentsCardList loading={loading} data={data} />
      ) : (
        <TransactionPaymentsTableView loading={loading} rows={data} />
      )}
    </Box>
  );
};

export interface TransactionPaymentsProps {
  loading?: boolean;
  data?: TransactionPaymentData[];
}

export default TransactionPayments;
