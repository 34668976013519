import React from "react";
import { TextField, TextFieldProps } from "formik-mui";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputMask, { InputState } from "react-input-mask";

export const ExpiryField = Object.assign(
  ({
    name = "expiry",
    id = "expiry",
    label = "Expiration",
    placeholder = "MM / YY",
    required = true,
    fullWidth = true,
    disabled = false,
    ...rest
  }: ExpiryFieldProps) => {
    const form = useFormikContext();
    const [field, meta, { setValue }] = useField(name);

    const handleChange = React.useCallback(
      (event) => {
        if (disabled) return;
        setValue(event.target.value);
      },
      [setValue, disabled],
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const beforeMaskedValueChange = (newState: InputState, oldState: InputState, userInput: string): InputState => {
      if (!newState.value) {
        return newState;
      }

      const value = newState.value.replace(/(\s|\/)/g, "").split("");
      if (oldState.selection?.start === 0) {
        if (userInput === "0") {
          return newState;
        } else if (userInput === "1") {
          value[3] = value[2];
          value[2] = value[1];
          value[0] = "1";
          value[1] = value[1] !== "_" ? "0" : "_";
          return {
            ...newState,
            value: value.join(""),
            selection: {
              start: 1,
              end: 1,
            },
          };
        } else if (userInput !== null) {
          value[3] = value[2];
          value[2] = value[1];
          value[1] = userInput;
          value[0] = "0";
          return {
            ...newState,
            value: value.join(""),
            selection: {
              start: 2,
              end: 2,
            },
          };
        }
        return newState;
      } else if (oldState.selection?.start === 1) {
        if (userInput === "0" || userInput === "1" || userInput === "2") {
          if (userInput === "0" && value[0] === "0") return oldState;
          value[1] = userInput;
          value[0] = value[0] === "_" ? "0" : value[0];
          return {
            ...newState,
            value: value.join(""),
          };
        } else if (userInput !== null && value[0] === "1") {
          value[3] = value[2];
          value[2] = userInput;
          value[1] = value[0];
          value[0] = "0";
          return {
            ...newState,
            value: value.join(""),
            selection: {
              start: 6,
              end: 6,
            },
          };
        }
      }
      return newState;
    };

    return (
      <Box>
        <Typography variant="body3" sx={{ fontWeight: 500 }}>
          {label} {required ? "*" : ""}
        </Typography>
        <InputMask
          mask="99 / 99"
          onChange={handleChange}
          autoFocus={rest.autoFocus}
          onBlur={field.onBlur}
          value={meta.value}
          beforeMaskedValueChange={beforeMaskedValueChange}
        >
          {(inputProps: unknown) => (
            <TextField
              InputLabelProps={{ shrink: false }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              {...(inputProps as any)}
              {...rest}
              {...{ id, placeholder, required, fullWidth }}
              label=""
              meta={meta}
              form={form}
              helperText={" "}
              field={{
                ...field,
                onChange: handleChange,
              }}
              sx={{ mt: 1 }}
            />
          )}
        </InputMask>
      </Box>
    );
  },
  {
    defaultInitialValue: "",
    validationSchema: yup
      .string()
      .required("Valid expiration date required.")
      .test("invalid date", "Valid expiration date required.", (value) => {
        if (!value) return false;
        return value.indexOf("_") === -1;
      })
      .test("invalid year in the past", "Your card's expiration year is in the past.", (value) => {
        if (!value) return false;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [month, year] = value.split("/").map((v) => parseInt(v, 10));
        return year >= new Date().getFullYear() % 100;
      })
      .test("invalid date in the past", "Your card's expiration date is in the past.", (value) => {
        if (!value) return false;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [month, year] = value.split("/").map((v) => parseInt(v, 10));
        return year !== new Date().getFullYear() % 100 || month >= new Date().getMonth() + 1;
      })
      .test("invalid year", "Your card's expiration year is invalid.", (value) => {
        if (!value) return false;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [month, year] = value.split("/").map((v) => parseInt(v, 10));
        return year <= (new Date().getFullYear() % 100) + 50;
      }),
  },
);

export interface ExpiryFieldProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  name?: string;
}

export default ExpiryField;
