import React from "react";
import { PublishSnackbarMessageFunc } from "@app.automotus.io/components/context/SnackPack/types";

export const DEFAULT_SNACK_PACK_CONTEXT: ISnackPackContext = {
  publishSnackbarMessage: () => undefined,
};

export const SnackPackContext = React.createContext<ISnackPackContext>(DEFAULT_SNACK_PACK_CONTEXT);

export interface ISnackPackContext {
  /** Publish a {@link SnackBarMessage} to the shared stack of messages */
  readonly publishSnackbarMessage: PublishSnackbarMessageFunc;
}

export default SnackPackContext;
