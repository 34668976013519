import React from "react";
import { Form, Formik, FormikConfig } from "formik";
import {
  DisputeFormFields,
  DisputeFormFieldsValues,
} from "@app.automotus.io/components/forms/DisputeForm/DisputeFormFields";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";

/**
 * Renders the form through which a user can initiate a dispute, or a skeleton if the form is loading.
 * The form will reinitialize when the initial values change.
 */
export const DisputeForm: DisputeFormComponent = ({
  loading,
  onSubmit = (values, { setSubmitting }) => setSubmitting(false),
  initialValues = DisputeForm.defaultInitialValues,
}) => {
  return (
    <Formik<DisputeFormValues>
      enableReinitialize
      initialValues={initialValues || DisputeForm.defaultInitialValues}
      validationSchema={DisputeForm.validationSchema(initialValues)}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <Form {...props}>
            <DisputeFormFields loading={loading} />
            <LoadingSkeleton loading={loading} width="100%">
              <LoadingButton
                sx={{ mt: { xs: 2.5, sm: 5 } }}
                variant="contained"
                type="submit"
                fullWidth
                disabled={props.isSubmitting || !props.isValid || Object.keys(props.touched).length === 0}
                loading={props.isSubmitting}
              >
                Submit Dispute
              </LoadingButton>
            </LoadingSkeleton>
          </Form>
        );
      }}
    </Formik>
  );
};

DisputeForm.defaultInitialValues = { ...DisputeFormFields.defaultInitialValues };
DisputeForm.validationSchema = DisputeFormFields.validationSchema;

export type DisputeFormValues = DisputeFormFieldsValues;

export type DisputeFormValidationSchema = typeof DisputeFormFields["validationSchema"];

export interface DisputeFormComponent extends React.FC<DisputeFormProps> {
  defaultInitialValues: Readonly<DisputeFormValues>;
  validationSchema: DisputeFormValidationSchema;
}

/** Props passed to initialize a {@link DisputeForm} */
export interface DisputeFormProps {
  /** Loading status of the component. Defaults to true */
  loading?: boolean;
  /** Initial values of the form */
  initialValues?: DisputeFormValues;
  /** Handler fired upon form submission. */
  onSubmit: FormikConfig<DisputeFormValues>["onSubmit"];
}

export default DisputeForm;
