import styled from "@emotion/styled";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { formatCurrency } from "common/format";
import { InvoiceData } from "common/graphql";
import React from "react";

const TextContainer = styled(Typography)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
});

export const VehicleCardHeader: React.FC<{
  invoice: InvoiceData;
  isRegistered?: boolean;
}> = ({ invoice, isRegistered = false }) => (
  <Box
    sx={{
      display: "flex",
      paddingLeft: "16px",
      alignItems: "center",
      alignSelf: "stretch",
    }}
  >
    <Box
      sx={{
        display: "flex",
        padding: "16px 0px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flex: "1 0 0",
      }}
    >
      <TextContainer
        sx={{
          fontSize: 16,
          color: "#000",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
          }}
        >
          {String(invoice.index).padStart(6, "0")}{" "}
          {!invoice.first_opened_at && (
            <Typography
              variant="body1"
              component={"span"}
              sx={{
                display: "flex",
                height: "20px",
                padding: "0px 6.5px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "64px",
                background: "#0D55BF",
              }}
            >
              <Typography
                variant="body1"
                component={"span"}
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.14px",
                }}
              >
                New
              </Typography>
            </Typography>
          )}
        </Typography>
      </TextContainer>
      <TextContainer
        sx={{
          fontSize: "14px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "14px",
            textDecoration: isRegistered ? "none" : "line-through",
            color: isRegistered ? "rgba(0, 0, 0, 0.54)" : "rgba(0, 0, 0, 0.38)",
          }}
        >
          ${formatCurrency(invoice.admin_fee_due + invoice.transaction_amount_due)}
        </Typography>
        {!isRegistered && (
          <Typography variant="body1" color={"rgba(0, 0, 0, 0.54)"} fontSize={14}>
            |
          </Typography>
        )}
        {!isRegistered && (
          <Typography variant="body1" color={"rgba(0, 0, 0, 0.54)"} fontSize={14}>
            ${formatCurrency(invoice.admin_fee_due + invoice.transaction_amount_due - invoice.admin_fee_amount_waived)}
          </Typography>
        )}
      </TextContainer>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: "1 0 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flex: "1 0 0",
        }}
      >
        <IconButton sx={{ display: "flex", padding: "12px", alignItems: "center" }}>
          <KeyboardArrowRight sx={{ width: 32, height: 32 }} />
        </IconButton>
      </Box>
    </Box>
  </Box>
);
