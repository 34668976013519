import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BillByMailPaymentForm, {
  ValidatedPaymentFormValues,
} from "@app.automotus.io/components/BillByMailPaymentForm/BillByMailPaymentForm";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars } from "common/graphql";
import { useQuery } from "@apollo/client";
import { useActivePayee, usePaymentsHelpers } from "@app.automotus.io/components/hooks";
import { SNACKBAR_MSGS, useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import { CITY_OPTIONS } from "common/constants";

export const BillByMailPaymentPage: React.FC = () => {
  const { invoiceId = "" } = useParams<{ invoiceId: string }>();
  const { publishSnackbarMessage } = useSnackPackContext();
  const navigate = useNavigate();
  const { setActivePayee } = useActivePayee();

  const {
    data: invoiceData,
    loading: invoiceLoading,
    error: invoiceError,
    refetch: refetchInvoice,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: {
      invoiceId: invoiceId,
    },
  });
  const invoice = invoiceData?.invoice ? invoiceData?.invoice : null;
  useEffect(() => {
    if (invoice?.payee_account_id) {
      const cityOption = CITY_OPTIONS.find(({ payeeAccountId }) => {
        return payeeAccountId === invoice.payee_account_id;
      });

      cityOption &&
        setActivePayee({
          cityName: cityOption.value,
          payeeAccountId: cityOption.payeeAccountId,
        });
    }
  }, [invoice?.payee_account_id, setActivePayee]);

  const [{ payInvoice }, { clientLoading }] = usePaymentsHelpers();

  const onSubmit = async (values: ValidatedPaymentFormValues) => {
    try {
      await payInvoice({
        invoiceId: invoiceId,
        cardInfo: {
          cardNumber: values.cardNumber,
          cvc: values.cvc,
          expiry: values.expiry,
          zipCode: values.zipCode,
        },
      });

      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_SUCCESS);

      navigate(`/paybill/${invoiceId}/confirmation`, { replace: true });
    } catch (err) {
      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_FAILED);
      console.error("failed to pay invoice", err);
    }
  };

  if (invoiceError || (!invoiceLoading && !invoice)) {
    return <LocalLevelError onTryAgain={refetchInvoice} />;
  }

  return (
    <BillByMailPaymentForm
      loading={invoiceLoading || clientLoading}
      onSubmit={onSubmit}
      amount={invoice?.total_amount_due || 0}
    />
  );
};

export default BillByMailPaymentPage;
