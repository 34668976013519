import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PolyInfoOutlined from "../icons/PolyInfoOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useUserProfile } from "@app.automotus.io/components/hooks";

export const NotificationBanner: React.FC = () => {
  const storedIsCollapsed = window.localStorage.getItem("is-domain-migration-notification-banner-collapsed") === "true";

  const [isCollapsed, setIsCollapsed] = useState(storedIsCollapsed);
  const { userProfile } = useUserProfile();

  const handleNotificationCollapse = () => {
    const newV = !storedIsCollapsed;
    window.localStorage.setItem("is-domain-migration-notification-banner-collapsed", newV.toString());
    setIsCollapsed(!isCollapsed);
  };

  const domain = userProfile?.account?.roleType === "payee" ? "curbsuite.io" : "curbpass.io";

  if (!process.env.REACT_APP_DISPLAY_DOMAIN_TRANSFER_BANNER) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      px={{ xs: 2, tiny: 2.5, md: 5 }}
      py={1.5}
      sx={{ cursor: "pointer", backgroundColor: "primary.main" }}
      onClick={handleNotificationCollapse}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <PolyInfoOutlined />
        <Typography variant="h7" sx={{ ml: { xs: 1, md: 1.5 }, color: "#FFB400", flexGrow: 1 }}>
          Exciting News!
        </Typography>
        <KeyboardArrowUpIcon sx={{ color: "primary.white", transform: isCollapsed ? 0 : "rotate(180deg)" }} />
      </Box>
      {isCollapsed && (
        <Box display="flex" color="primary.white" ml={{ xs: 4, md: 4.5 }} mt={0.5}>
          <Typography variant="body1">{`We're moving from app.automotus.io to ${domain} starting next week but don't worry! Your account will not be impacted.`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificationBanner;
