import React, { ReactNode } from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { DesktopNavBarMenu, MobileNavBarMenu } from "@app.automotus.io/components/NavBar/NavBarMenu";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useMatch } from "react-router-dom";
import LogoBlue from "@app.automotus.io/components/images/AppLogo/logoBlue";
import { GET_ALL_INVOICES, GetAllInvoicesData } from "common/graphql";
import { useQuery } from "@apollo/client";

/**
 * Component that renders the nav bar at the top of the dashboard.
 */
export const NavBar: React.FC<NavBarProps> = (props) => {
  const isMobile = useIsMobile();
  const toolbarRef = React.useRef(null);
  // At the time of this writing, we only want transition behavior to take place on the analytics page. Use a match
  // to enforce this.
  const analyticsMatch = useMatch("/analytics/:analyticsTab");
  const scrollTrigger = useScrollTrigger({ threshold: 200, disableHysteresis: true });

  const { data: invoicesData, loading: invoicesLoading } = useQuery<GetAllInvoicesData>(GET_ALL_INVOICES);

  return (
    <Slide appear={false} in={analyticsMatch ? !scrollTrigger : true}>
      <AppBar
        position={"fixed"}
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        <Toolbar
          sx={{
            height: { xs: 54, lg: 88 },
            px: { xs: 2, md: 5 },
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          ref={toolbarRef}
        >
          <LogoBlue sx={{ height: { xs: 22, lg: 40 }, width: "auto" }} />
          {isMobile ? (
            <MobileNavBarMenu invoicesData={invoicesData} invoicesLoading={invoicesLoading} {...props} />
          ) : (
            <DesktopNavBarMenu invoicesData={invoicesData} invoicesLoading={invoicesLoading} {...props} />
          )}
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export const NAVBAR_HEIGHT = { xs: 54, tiny: 54, sm: 54, md: 54, lg: 88, xl: 88 };

export interface NavBarProps {
  targets: NavBarTarget[];
}

export interface NavBarTarget {
  to: string;
  title: string;
  onClick?: (e: React.SyntheticEvent) => void;
  icon?: ReactNode;
}

export default NavBar;
