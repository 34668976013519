import React from "react";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";
import Grid from "@mui/material/Grid";
import { CompanyValue } from "@app.automotus.io/components/common";
import FirstNameField from "@app.automotus.io/components/forms/ProfileInformationFields/FirstNameField";
import LastNameField from "@app.automotus.io/components/forms/ProfileInformationFields/LastNameField";
import EmailField from "@app.automotus.io/components/forms/ProfileInformationFields/EmailField";
import PhoneNumberField from "@app.automotus.io/components/forms/ProfileInformationFields/PhoneNumberField";
import CompanyAutocompleteField from "@app.automotus.io/components/forms/ProfileInformationFields/CompanyAutocompleteField";

const INPUT_SIZE_MOBILE = 12;
const INPUT_SIZE_NON_MOBILE = 12;

/**
 * Component that renders a set of fields that a user can use to populate his profile information.
 * Must be used inside of a Formik context.
 *
 * This component is designed to be reusable and can be composed with other form fields.
 */
export const ProfileInformationFields: React.FC<ProfileInformationFieldsProps> & ProfileInformationFieldsExtension = ({
  disabled,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));
  const { userProfile } = useUserProfile();
  const isPhoneNumberDisabled = !!userProfile?.identities[0].phoneNumber;

  return (
    <Grid
      container
      direction="row"
      alignItems="space-between"
      rowSpacing={isDesktop ? 4.5 : isSmall ? 3.5 : 4}
      columnSpacing={2}
    >
      <Grid item xs={INPUT_SIZE_MOBILE} md={INPUT_SIZE_NON_MOBILE}>
        <FirstNameField />
      </Grid>
      <Grid item xs={INPUT_SIZE_MOBILE} md={INPUT_SIZE_NON_MOBILE}>
        <LastNameField />
      </Grid>
      <Grid item xs={INPUT_SIZE_MOBILE} md={INPUT_SIZE_NON_MOBILE}>
        <EmailField />
      </Grid>
      <Grid item xs={INPUT_SIZE_MOBILE} md={INPUT_SIZE_NON_MOBILE}>
        <PhoneNumberField disabled={isPhoneNumberDisabled} />
      </Grid>
      <Grid item xs={INPUT_SIZE_MOBILE} md={INPUT_SIZE_NON_MOBILE}>
        <CompanyAutocompleteField disabled={disabled} id="companies" name="companies" />
      </Grid>
    </Grid>
  );
};

const validationSchema = yup.object({
  firstName: FirstNameField.validationSchema,
  lastName: LastNameField.validationSchema,
  email: EmailField.validationSchema,
  phoneNumber: PhoneNumberField.validationSchema,
  companies: CompanyAutocompleteField.validationSchema,
});

ProfileInformationFields.validationSchema = validationSchema;

ProfileInformationFields.defaultInitialValues = {
  firstName: FirstNameField.defaultInitialValue,
  lastName: LastNameField.defaultInitialValue,
  email: EmailField.defaultInitialValue,
  phoneNumber: PhoneNumberField.defaultInitialValue,
  companies: CompanyAutocompleteField.defaultInitialValue,
};

/** Values of all fields in contained in the {@link ProfileInformationFields} field set */
export interface ProfileInformationValues {
  /** First name of the user */
  firstName: string;
  /** Last name of the user */
  lastName: string;
  /** Email address */
  email: string;
  /** Phone number */
  phoneNumber: string;
  /** Company selection */
  companies: CompanyValue[] | null;
}

export interface ProfileInformationFieldsExtension {
  /** Validation schema that can be used to validate the field values */
  validationSchema: ReturnType<typeof yup["object"]>;
  /** Default initial values for the fields */
  defaultInitialValues: ProfileInformationValues;
}

export interface ProfileInformationFieldsProps {
  /** True when editing is not allowed */
  disabled: boolean;
}

export default ProfileInformationFields;
