import React from "react";
import { CurbPassSetup } from "@app.automotus.io/components/scenes/SignUp/CurbPassSetup";
import { useLocation } from "react-router-dom";
import InvoiceSignupCurbPass from "@app.automotus.io/components/routes/signup/invoice-curbpass";

export const SignupCurbPass: React.FC = () => {
  const location = useLocation();

  const { invoiceId } = (location.state || {}) as { invoiceId?: string };

  if (invoiceId) {
    return <InvoiceSignupCurbPass />;
  }

  return <CurbPassSetup />;
};

export default SignupCurbPass;
