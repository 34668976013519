import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

/**
 * Renders a modal for display when a user successfully submits a dispute
 */
export const DisputeSuccessfullySubmittedModal: React.FC<DisputeSuccessfullySubmittedProps> = ({
  disputeTicketId,
  email,
  open = false,
  onClose = () => undefined,
  onClickViewDisputeStatus = () => undefined,
  onClickReturnToSummary = () => undefined,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      aria-labelledby="modal-title"
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      PaperProps={{ sx: { borderRadius: { xs: 0, md: 1.5 } } }}
    >
      <ModalContents
        email={email}
        disputeTicketId={disputeTicketId}
        onClickReturnToSummary={onClickReturnToSummary}
        onClickViewDisputeStatus={onClickViewDisputeStatus}
      />
    </Dialog>
  );
};

const ModalContents: React.FC<Omit<DisputeSuccessfullySubmittedProps, "open" | "onClose">> = ({
  email,
  disputeTicketId,
  onClickReturnToSummary,
  onClickViewDisputeStatus,
}) => {
  return (
    <Box sx={{ px: { xs: 2, md: 3 }, py: { xs: 4, md: 3 } }}>
      <Typography id="modal-title" variant="h5" sx={{ mb: 5 }} align="center">
        Dispute Successfully Submitted
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 5 }}>
        <CheckCircleIcon color="success" sx={{ fontSize: 150 }} />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Typography variant="h7" align={"center"}>
          Your dispute ticket number is:
        </Typography>
      </Box>
      <Typography variant={"h6"} align={"center"} sx={{ mb: 2.5 }}>
        {disputeTicketId}
      </Typography>
      <Typography variant="body1" align={"center"} sx={{ mb: 5.5 }}>
        We have sent a dispute confirmation email to: {email}. Your dispute will be reviewed and a representative will
        follow-up via email within 24 hours.
      </Typography>
      <Button variant={"contained"} fullWidth onClick={onClickViewDisputeStatus}>
        View Dispute Status
      </Button>
      <Button variant="outlined" fullWidth onClick={onClickReturnToSummary} sx={{ mt: 2 }}>
        Return to Summary
      </Button>
    </Box>
  );
};

/** Props passed to initialize a {@link DisputeSuccessfullySubmittedModal} component */
export interface DisputeSuccessfullySubmittedProps {
  /** Ticket id */
  disputeTicketId: string;
  /** Email address */
  email: string;
  /** Open status of modal */
  open?: boolean;
  /** Handler fired when user requests to close the modal */
  onClose?: (event: React.SyntheticEvent, reason: "escapeKeyDown" | "backdropClick" | "closeButton") => void;
  /** Handler fired when user clicks the "Return to Summary" button */
  onClickReturnToSummary?: React.MouseEventHandler<HTMLButtonElement>;
  /** Handler fired when user clicks the "View Dispute Status" button */
  onClickViewDisputeStatus?: React.MouseEventHandler<HTMLButtonElement>;
}

export default DisputeSuccessfullySubmittedModal;
