import React from "react";
import automotusLogoWhiteSrc from "./automotusLogoWhite.svg";
import curbPassLogoWhiteSrc from "./curbPassLogoWhite.svg";
import AppLogoBase, { AppLogoProps } from "@app.automotus.io/components/images/AppLogo/AppLogoBase";

export const LogoWhite: React.FC<AppLogoProps> = (props) => (
  <AppLogoBase
    automotusLogoSrc={automotusLogoWhiteSrc}
    curbPassLogoSrc={curbPassLogoWhiteSrc}
    curbSuiteLogoSrc={automotusLogoWhiteSrc}
    {...props}
  />
);

export default LogoWhite;
