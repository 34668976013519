import { useLazyQuery, useQuery } from "@apollo/client";
import { RegisteredVehicle } from "common/graphql";
import {
  GET_ALL_VEHICLE_MAKES,
  GET_ALL_VEHICLE_MODELS_FOR_MAKE,
  GetAllVehicleMakesData,
  GetAllVehicleModelsForMakeData,
  GetAllVehicleModelsForMakeVars,
} from "common/graphql/queries";
import { useEffect, useMemo, useState } from "react";
import { StateAbbreviation } from "../common";
import { VehicleInfoFormValues } from "../forms/VehicleInfoForm";
import throttle from "lodash/throttle";

/** Hook that returns the previous value of some ref
 * @param value The value to reference
 * @returns The previous value that the reffed value assumed
 */
export const useRegisteredVehicleInitialValues = (
  currentVehicleInfo: RegisteredVehicle,
): {
  initialValues: Partial<VehicleInfoFormValues>;
  loadingMakes: boolean;
  loadingModels: boolean;
} => {
  const [initialValues, setInitialValues] = useState<Partial<VehicleInfoFormValues>>({
    id: currentVehicleInfo.id,
    licensePlate: currentVehicleInfo.licensePlate,
    licensePlateState: currentVehicleInfo.stateCode as StateAbbreviation,
    vehicleType: currentVehicleInfo.type,
  });
  const { loading: loadingMakes, data: dataMakes } = useQuery<GetAllVehicleMakesData>(GET_ALL_VEHICLE_MAKES);

  const [getVehicleModelsForMake, { data: dataModels, loading: loadingModels }] = useLazyQuery<
    GetAllVehicleModelsForMakeData,
    GetAllVehicleModelsForMakeVars
  >(GET_ALL_VEHICLE_MODELS_FOR_MAKE);

  const fetch = useMemo(() => throttle(getVehicleModelsForMake, 200), [getVehicleModelsForMake]);

  useEffect(() => {
    if (dataMakes && dataMakes.makes) {
      const make = dataMakes.makes.find((make) => make.name === currentVehicleInfo.make);
      if (make) {
        setInitialValues((prevInitialValues) => ({ ...prevInitialValues, make }));
        fetch({ variables: { makeId: make.makeId } });
      }
    }
  }, [dataMakes, currentVehicleInfo.make, fetch]);

  useEffect(() => {
    if (dataModels && dataModels.models) {
      const model = dataModels.models.find((model) => model.name === currentVehicleInfo.model);
      if (model) {
        setInitialValues((prevInitialValues) => ({ ...prevInitialValues, model, year: currentVehicleInfo.year }));
      }
    }
  }, [dataModels, currentVehicleInfo.model, currentVehicleInfo.year]);

  return { initialValues, loadingMakes, loadingModels };
};

export default useRegisteredVehicleInitialValues;
