import { SnackbarMessageProps } from "@app.automotus.io/components/context/SnackPack";

const PAYMENT_METHOD_FAILED_TO_SAVE: SnackbarMessageProps = {
  severity: "error",
  message: "Payment Method failed to save. Please try again.",
};

const PAYMENT_METHOD_FAILED_TO_UPDATE: SnackbarMessageProps = {
  severity: "error",
  message: "Payment Method failed to update. Please try again.",
};

const PAYMENT_METHOD_FAILED_TO_REMOVE: SnackbarMessageProps = {
  severity: "error",
  message: "Payment Method could not be removed. Please try again.",
};

const PAYMENT_METHOD_REMOVED: SnackbarMessageProps = {
  severity: "success",
  message: "Your payment method has been removed.",
};

const PAYMENT_METHOD_SAVED: SnackbarMessageProps = {
  severity: "success",
  message: "Your payment method has been added.",
};

const PAYMENT_METHOD_UPDATED: SnackbarMessageProps = {
  severity: "info",
  message: "Your payment method has been updated.",
};

const DEFAULT_PAYMENT_METHOD_UPDATED: SnackbarMessageProps = {
  severity: "info",
  message: "Your default payment method has been updated.",
};

export const ALERTS = {
  success: {
    add: PAYMENT_METHOD_SAVED,
    update: PAYMENT_METHOD_UPDATED,
    remove: PAYMENT_METHOD_REMOVED,
    setDefault: DEFAULT_PAYMENT_METHOD_UPDATED,
  },
  failure: {
    add: PAYMENT_METHOD_FAILED_TO_SAVE,
    update: PAYMENT_METHOD_FAILED_TO_UPDATE,
    remove: PAYMENT_METHOD_FAILED_TO_REMOVE,
    setDefault: PAYMENT_METHOD_FAILED_TO_UPDATE,
  },
};

export default ALERTS;
