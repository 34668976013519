import React from "react";
import DashboardContentLayout from "@app.automotus.io/components/Layout/DashboardContentLayout";
import Box from "@mui/material/Box";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FallbackProps } from "react-error-boundary";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { appName } from "common/constants";

/**
 * Component that displays local level erro
 */
export const PageLevelError: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  return (
    <Box sx={{ bgcolor: "background.default" }}>
      <DashboardContentLayout
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "text.secondary",
          height: "80vh",
          position: "relative",
        }}
      >
        <InfoOutlined sx={{ fontSize: "50px" }} />
        <Typography variant="h5" mt={2}>
          This page failed to load
        </Typography>
        <Typography variant="subtitle2" mt={1.5}>
          There was a problem. Please reload the page.
        </Typography>
        <Button variant="contained" onClick={resetErrorBoundary} sx={{ mt: 5 }}>
          Reload
        </Button>
        {appName !== "curbsuite.io" && (
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, position: "absolute", left: "auto", right: "auto", bottom: 0 }}
          >
            <Link component={RouterLink} to="/support" underline="hover" target={"_blank"}>
              Need Help?
            </Link>
          </Typography>
        )}
      </DashboardContentLayout>
    </Box>
  );
};

export default PageLevelError;
