import React from "react";
import AccountSection from "../AccountSection";
import {
  accountFragment,
  UserPreferences,
  userPreferencesFragment,
  UserProfile,
  userProfileFragment,
} from "common/graphql/fragments";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FirstNameField from "@app.automotus.io/components/forms/ProfileInformationFields/FirstNameField";
import LastNameField from "@app.automotus.io/components/forms/ProfileInformationFields/LastNameField";
import { Field, Formik } from "formik";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { gql, useMutation } from "@apollo/client";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import CompanyAutocompleteField from "@app.automotus.io/components/forms/ProfileInformationFields/CompanyAutocompleteField";
import EmailField from "@app.automotus.io/components/forms/ProfileInformationFields/EmailField";
import PhoneNumberField from "@app.automotus.io/components/forms/ProfileInformationFields/PhoneNumberField";
import LoadingButton from "@mui/lab/LoadingButton";
import { CheckboxWithLabel } from "formik-mui";
import * as yup from "yup";
import { ProfileInformationFields, ProfileInformationValues } from "@app.automotus.io/components/forms";
import { CloseAccountLinkButton } from "@app.automotus.io/components/LinkButton/CloseAccountLink";
import Box from "@mui/material/Box";

// TODO: this update will not work properly for payees -- need to fix!
// NOTE: can use the existing full name from account if a non-organization user
// is calling
// TODO: change password action
const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $accountId: uuid!
    $userId: uuid!
    $fullName: String!
    $givenName: String!
    $familyName: String!
    $email: String!
    $selectedCompanies: String!
    $emailUpdates: Boolean!
    $accountHolderName: String!
  ) {
    updateAccount: update_account_by_pk(pk_columns: { id: $accountId }, _set: { holder_name: $accountHolderName }) {
      ...accountFragment
    }
    updateUserPreferences: insert_auth_user_preferences_payment_product_one(
      object: { email_updates_enabled: $emailUpdates }
      on_conflict: { constraint: user_preferences_payment_product_pkey, update_columns: [email_updates_enabled] }
    ) {
      ...userPreferencesFragment
    }
    updateBasicProfile: update_auth_user_profile_by_pk(
      pk_columns: { id: $userId }
      _set: {
        full_name: $fullName
        given_name: $givenName
        family_name: $familyName
        email: $email
        selected_companies: $selectedCompanies
      }
    ) {
      ...userProfileFragment
    }
  }
  ${userProfileFragment}
  ${accountFragment}
  ${userPreferencesFragment}
`;

interface UpdateProfileVars {
  accountId: string;
  userId: string;
  fullName: string;
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
  selectedCompanies: string[];
  emailUpdates: boolean;
  accountHolderName?: string;
}

interface UpdateProfileData {
  updateProfile: UserProfile;
  updateUserPreferences: UserPreferences;
}

/**
 * Component that renders the Profile section of a user's account page. Form submission and result
 * handling must be handled by a component higher in the tree.
 */
export const ProfileSection: ProfileSectionComponent = ({ onClickCloseAccount = () => undefined }) => {
  // TODO: handle error if profile cannot be loaded
  const { userProfile, loading } = useUserProfile();
  const [updateUserProfile] = useMutation<UpdateProfileData, UpdateProfileVars>(UPDATE_PROFILE);
  const { publishSnackbarMessage } = useSnackPackContext();

  const formValuesFromUserProfile: ProfileSectionFormValues | undefined = userProfile && {
    firstName: userProfile?.givenName || "",
    lastName: userProfile?.familyName || "",
    email: userProfile?.email || "",
    companies: userProfile?.selectedCompanies || ["no_company"],
    phoneNumber: userProfile?.phoneNumber || "",
    emailUpdatesEnabled: !!userProfile.preferences?.emailUpdatesEnabled,
  };

  const isPayee = userProfile?.account?.roleType === "payee";

  return (
    <AccountSection title="Profile Information" displayRequiredFieldHint>
      <Formik<ProfileSectionFormValues>
        initialValues={formValuesFromUserProfile || ProfileSection.defaultInitialValues}
        validationSchema={ProfileSection.validationSchema}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          updateUserProfile({
            variables: {
              accountId: userProfile?.account.id || "",
              userId: userProfile?.id || "",
              fullName: `${values.firstName} ${values.lastName}`,
              givenName: values.firstName,
              familyName: values.lastName,
              email: values.email,
              phoneNumber: values.phoneNumber,
              emailUpdates: values.emailUpdatesEnabled,
              selectedCompanies: values.companies || ["no_company"],
              accountHolderName:
                userProfile?.account.ownerType === "individual_driver"
                  ? `${values.firstName} ${values.lastName}`
                  : userProfile?.account.holderName,
            },
            onCompleted: () => {
              setSubmitting(false);
              publishSnackbarMessage({ severity: "info", message: "Profile Information Updated" });
            },
            onError: (err) => {
              console.error(err);
              setSubmitting(false);
              publishSnackbarMessage({
                severity: "error",
                message: "Profile Information Failed to Update. Please try again.",
              });
            },
          });
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} onReset={props.handleReset} action="#">
            <Grid item container xs={12} columnSpacing={2.5} rowSpacing={3}>
              <Grid item xs={12} sm={6}>
                <LoadingSkeleton loading={loading} width="100%">
                  <FirstNameField disabled={props.isSubmitting} helperText=" " />
                </LoadingSkeleton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LoadingSkeleton loading={loading} width="100%">
                  <LastNameField disabled={props.isSubmitting} helperText=" " />
                </LoadingSkeleton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LoadingSkeleton loading={loading} width="100%">
                  <TextField
                    InputProps={{ readOnly: true }}
                    fullWidth
                    disabled
                    value={userProfile?.phoneNumber || ""}
                    label={"Phone Number"}
                    helperText={loading ? " " : "Need to update your phone number? Reach out to support"}
                  />
                </LoadingSkeleton>
              </Grid>
              {!isPayee && (
                <Grid item xs={12} sm={6}>
                  <LoadingSkeleton loading={loading} width={"100%"}>
                    <CompanyAutocompleteField
                      name="company"
                      id="company"
                      disabled={props.isSubmitting}
                      helperText=" "
                    />
                  </LoadingSkeleton>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <LoadingSkeleton loading={loading} width="100%">
                  <EmailField disabled helperText=" " />
                  {/*<EmailField disabled={props.isSubmitting} helperText=" " />*/}
                </LoadingSkeleton>
              </Grid>
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  <LoadingSkeleton loading={loading} width="100%">*/}
              {/*    <TextField*/}
              {/*      type="password"*/}
              {/*      fullWidth*/}
              {/*      InputLabelProps={{ shrink: true }}*/}
              {/*      InputProps={{ readOnly: true }}*/}
              {/*      disabled*/}
              {/*      placeholder="Password"*/}
              {/*    />*/}
              {/*    <Link*/}
              {/*      sx={{*/}
              {/*        mt: 0.5,*/}
              {/*        color: props.isSubmitting ? "text.disabled" : undefined,*/}
              {/*        "&[disabled]": {*/}
              {/*          cursor: "default",*/}
              {/*          textDecoration: "none",*/}
              {/*          "&:hover": {*/}
              {/*            textDecoration: "none",*/}
              {/*          },*/}
              {/*        },*/}
              {/*      }}*/}
              {/*      variant="body2"*/}
              {/*      target={"_blank"}*/}
              {/*      href={`https://${process.env.REACT_APP_AUTH0_DOMAIN}`}*/}
              {/*    >*/}
              {/*      Change Password*/}
              {/*    </Link>*/}
              {/*  </LoadingSkeleton>*/}
              {/*</Grid>*/}
              {!isPayee && (
                <Grid item xs={12}>
                  <LoadingSkeleton loading={loading}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="emailUpdatesEnabled"
                      disabled={props.isSubmitting}
                      Label={{ label: "Email me with parking tips, discounts and other promotions." }}
                    />
                  </LoadingSkeleton>
                </Grid>
              )}
              <Grid item xs={12}>
                <LoadingSkeleton loading={loading} width="100%">
                  <LoadingButton
                    type="submit"
                    variant="outlined"
                    fullWidth
                    loading={props.isSubmitting}
                    disabled={props.isSubmitting || !props.isValid || !props.dirty}
                  >
                    Update
                  </LoadingButton>
                </LoadingSkeleton>
                {!isPayee && (
                  <Box display={"flex"} justifyContent={"center"} sx={{ mt: { xs: 3.5, lg: 5.5 } }}>
                    <CloseAccountLinkButton loading={loading} onClick={onClickCloseAccount} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </AccountSection>
  );
};

ProfileSection.defaultInitialValues = {
  firstName: FirstNameField.defaultInitialValue,
  lastName: LastNameField.defaultInitialValue,
  email: EmailField.defaultInitialValue,
  companies: CompanyAutocompleteField.defaultInitialValue,
  phoneNumber: PhoneNumberField.defaultInitialValue,
  emailUpdatesEnabled: true,
};

const validationSchema = yup
  .object({
    emailUpdatesEnabled: yup.bool(),
  })
  .concat(ProfileInformationFields.validationSchema);

ProfileSection.validationSchema = validationSchema;

export interface ProfileSectionComponent extends React.FC<ProfileSectionProps> {
  defaultInitialValues: ProfileSectionFormValues;
  validationSchema: typeof validationSchema;
}

/** Props passed to initialize a {@link ProfileSection} component */
export interface ProfileSectionProps {
  /** Loading status of the component. Defaults to true */
  loading?: boolean;
  /** Action triggered on click of change password button */
  onClickChangePassword?: React.MouseEventHandler<HTMLAnchorElement>;
  /** Action triggered on click of the close account button */
  onClickCloseAccount?: () => void;
}

/** Values from the {@link ProfileSection} form */
export interface ProfileSectionFormValues extends ProfileInformationValues {
  emailUpdatesEnabled: boolean;
}

export default ProfileSection;
