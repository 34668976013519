import React from "react";
import ErrorPage from "../ErrorPage";

/** Renders the 404 error page */
export const Error404: React.FC = () => (
  <ErrorPage
    code="404"
    message="The URL that you requested has not been found. Check that you have entered the URL correctly"
  />
);

export default Error404;
