import React from "react";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import DepositLogCardList from "./DepositLogCardList";
import DepositLogTableView from "./DepositLogTableView";

export interface DepositLogData {
  id: string;
  date: Date;
  time: Date;
  description: string;
  payment: string;
  amount: number;
  balance: number;
}

export const DepositLog: React.FC<DepositLogProps> = ({ loading = false, data = [] }) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <DepositLogCardList loading={loading} data={data} />
  ) : (
    <DepositLogTableView loading={loading} rows={data} />
  );
};

export interface DepositLogProps {
  loading?: boolean;
  data?: DepositLogData[];
}

export default DepositLog;
