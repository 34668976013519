import React, { useState } from "react";
import { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material/TextField";
import { TextField, TextFieldProps } from "formik-mui";
import { useField, useFormikContext } from "formik";
import CountryCallingCodeSelect, { countries, CountryType } from "./CountryCallingCodeSelect";
import { useFocus } from "@app.automotus.io/components/hooks";
import { phoneNumberRegex } from "common/constants";
import PhoneNumberFormat from "./PhoneNumberFormat";
import InputAdornment from "@mui/material/InputAdornment";
import * as yup from "yup";

/**
 * Component that renders a formatted phone number input along with a country
 * code selector.
 */
export const PhoneNumberInput = Object.assign(
  ({
    name = "phoneNumber",
    defaultCountry = countries.find(({ code }) => code === "US") as CountryType,
    onChange = () => undefined,
    ...rest
  }: PhoneNumberInputProps) => {
    const form = useFormikContext();
    const [field, meta, { setValue }] = useField(name);

    let restProps = { ...DEFAULT_PROPS, ...rest };
    const { value } = restProps;

    const initialCountry =
      value && !(value as string).startsWith("+1")
        ? (countries.find(({ phone }) => (value as string).startsWith(`+${phone}`)) as CountryType)
        : defaultCountry;
    const phoneNumber = (value as string).slice(initialCountry.phone.length + 1);

    restProps = { ...restProps, value: phoneNumber };
    const [country, setCountry] = useState(initialCountry);
    const [inputRef, setInputRefFocus] = useFocus();

    const handleChangeCountry: React.ComponentProps<typeof CountryCallingCodeSelect>["onChange"] = (event) => {
      if (typeof event.target.value === "string") {
        return;
      }

      const newCountry = event.target.value;
      setCountry(newCountry);

      // Have to use a setTimeout to allow dropdown to close prior to changing
      // focus
      setTimeout(() => {
        setInputRefFocus();
      }, 0);
    };

    const handleChangePhoneNumber = React.useCallback(
      (event) => {
        setValue(event.target.value);
        onChange(event);
      },
      [setValue, onChange],
    );

    const { disabled } = restProps;

    return (
      <TextField
        {...restProps}
        inputRef={inputRef}
        type={"tel"}
        meta={meta}
        form={form}
        field={field}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: PhoneNumberFormat as any,
          inputProps: {
            country,
            onChange: handleChangePhoneNumber,
          },
          startAdornment: (
            <InputAdornment position={"start"}>
              <CountryCallingCodeSelect value={country} disabled={disabled} onChange={handleChangeCountry} />
            </InputAdornment>
          ),
        }}
      />
    );
  },
  {
    defaultInitialValue: "",
    validationSchema: yup.string().matches(phoneNumberRegex, " ").required(" "), // We don't need to show helper text
  },
);

const DEFAULT_PROPS = {
  autoComplete: "tel-national",
  autoFocus: false,
  defaultValue: "",
  disabled: false,
  fullWidth: false,
  placeholder: "(714)-769-8192",
  error: false,
  required: false,
  value: "",
  defaultCountry: countries.find((c) => c.code === "US"),
  onChange: () => undefined,
};

interface PhoneNumberInputBaseProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  /** Default selected country */
  defaultCountry?: CountryType;
  name?: string;
}

export type StandardPhoneNumberInputProps = StandardTextFieldProps & PhoneNumberInputBaseProps;
export type OutlinedPhoneNumberInputProps = OutlinedTextFieldProps & PhoneNumberInputBaseProps;
export type FilledPhoneNumberInputProps = FilledTextFieldProps & PhoneNumberInputBaseProps;

/** Props passed to intialize a {@link PhoneNumberInput} component */
export type PhoneNumberInputProps =
  | StandardPhoneNumberInputProps
  | OutlinedPhoneNumberInputProps
  | FilledPhoneNumberInputProps;

export default PhoneNumberInput;
