import { gql } from "@apollo/client";
import { Wallet, walletFragment } from "common/graphql";

export const UPDATE_WALLET_SETTINGS = gql`
  mutation UpdateWalletSettings($payeeAccountId: uuid!, $desiredBalance: Numeric!, $autoRefillAt: Numeric!) {
    updateWalletResult: update_wallet_settings(
      payee_account_id: $payeeAccountId
      desired_balance: $desiredBalance
      minimum_balance: $autoRefillAt
    ) {
      wallet {
        ...walletFragment
      }
    }
  }
  ${walletFragment}
`;

export interface UpdateWalletSettingsData {
  updateWalletResult: {
    wallet: Wallet;
  };
}

export interface UpdateWalletSettingsVars {
  payeeAccountId: string;
  desiredBalance: number;
  autoRefillAt: number;
}
