import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import PasswordlessLogin, { PasswordlessLoginStage } from "./PasswordlessLogin";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";

/** Props passed to initialize a PasswordlessLoginContiner component */
export interface PasswordlessLoginContainerProps {
  /** Login callback */
  loginWithRedirect?: () => void;
  /** Requests a new confirmation code */
  requestConfirmationCode: (phoneNumber: string) => Promise<void>;
  /** Requests to confirm a user's confirmation code */
  requestToConfirmCode: (phoneNumber: string, confirmationCode: string) => Promise<void>;
}

/**
 * Component that renders the passwordless login flow. Includes state tracking.
 */
export const PasswordlessLoginContainer: React.FC<PasswordlessLoginContainerProps> = ({
  loginWithRedirect,
  requestConfirmationCode,
  requestToConfirmCode,
}) => {
  const [searchParams] = useSearchParams();
  const phoneNumberQuery = searchParams.get("phoneNumber");
  const confirm = searchParams.get("confirm");

  const [stage, setStage] = useState<PasswordlessLoginStage>(confirm === "true" ? "confirmation_code" : "phone_number");
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberQuery !== null ? (phoneNumberQuery as string) : "");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [failedToGetConfirmationCodeError, setFailedToGetConfirmationCodeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidPhoneNumberError, setInvalidPhoneNumberError] = useState(false);
  const [invalidConfirmationCodeError, setInvalidConfirmationCodeError] = useState(false);

  const handleChangePhoneNumber = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber);
    setInvalidPhoneNumberError(false);
  };

  const handleChangeConfirmationCode = (newConfirmationCode: string) => {
    setConfirmationCode(newConfirmationCode);
    setInvalidConfirmationCodeError(false);
  };

  const handleSubmitPhoneNumber = async () => {
    if (!isPossiblePhoneNumber(phoneNumber) || !isValidPhoneNumber(phoneNumber)) {
      setInvalidPhoneNumberError(true);
      return;
    }

    setConfirmationCode("");
    setFailedToGetConfirmationCodeError(false);

    setLoading(true);

    try {
      await requestConfirmationCode(phoneNumber);
      setStage("confirmation_code");
    } catch (err) {
      // TODO: error analytics
      console.error(err);
      setFailedToGetConfirmationCodeError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitConfirmationCode = async () => {
    setInvalidConfirmationCodeError(false);
    setLoading(true);
    try {
      await requestToConfirmCode(phoneNumber, confirmationCode);
    } catch (err) {
      setConfirmationCode("");
      setInvalidConfirmationCodeError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PasswordlessLogin
      stage={stage}
      phoneNumber={phoneNumber}
      onChangePhoneNumber={handleChangePhoneNumber}
      confirmationCode={confirmationCode}
      onChangeConfirmationCode={handleChangeConfirmationCode}
      failedToGetConfirmationCodeError={failedToGetConfirmationCodeError}
      loading={loading}
      invalidPhoneNumberError={invalidPhoneNumberError}
      onSubmitPhoneNumber={handleSubmitPhoneNumber}
      invalidConfirmationCodeError={invalidConfirmationCodeError}
      onSubmitConfirmationCode={handleSubmitConfirmationCode}
      loginWithRedirect={loginWithRedirect}
      onBack={() => {
        setConfirmationCode("");
        setInvalidConfirmationCodeError(false);
        setInvalidPhoneNumberError(false);
        setStage("phone_number");
      }}
    />
  );
};

export default PasswordlessLoginContainer;
