import React from "react";
import BillByMailPaymentInvoice from "@app.automotus.io/components/BillByMailPaymentInvoice/BillByMailPaymentInvoice";
import { useParams, useNavigate } from "react-router-dom";

export const BillByMailInvoicePage: React.FC = () => {
  const navigate = useNavigate();
  const { invoiceId = "" } = useParams<{ invoiceId: string }>();

  const onMakeAPayment = () => {
    navigate(`/paybill/${invoiceId}/payment-preferences`);
  };

  const onDisputeTransaction = () => {
    window.open("https://curbpasshelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=5873339775899", "_blank");
  };

  return (
    <BillByMailPaymentInvoice
      invoiceId={invoiceId}
      onMakeAPayment={onMakeAPayment}
      onDisputeTransaction={onDisputeTransaction}
    />
  );
};

export default BillByMailInvoicePage;
