import auth0 from "auth0-js";

const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";

export const webAuth = new auth0.WebAuth({
  domain,
  clientID,
  audience,
  redirectUri: `${window.location.origin}/authorize`,
  responseType: "token id_token",
  scope: "openid profile email",
});

/**
 * Start one-time-password login process using a phone number
 * @param phoneNumber
 */
export const otpStart = async ({ phoneNumber }: { phoneNumber: string }): Promise<void> => {
  const variables = { phoneNumber, connection: "sms", send: "code" };

  return new Promise((resolve, reject) => {
    webAuth.passwordlessStart(variables, (err, res) => {
      if (err) {
        return reject(err);
      }

      resolve(res);
    });
  });
};

/**
 * Login using one-time password
 * @param phoneNumber
 * @param otp
 */
export const otpLogin = ({
  phoneNumber,
  otp,
  redirectUri,
}: {
  phoneNumber: string;
  otp: string;
  redirectUri?: string;
}): Promise<void> => {
  return new Promise((resolve, reject) => {
    webAuth.passwordlessLogin(
      {
        phoneNumber,
        connection: "sms",
        verificationCode: otp,
        redirectUri: redirectUri || `${window.location.origin}/authorize`,
      },
      (err) => {
        if (err) {
          return reject(err);
        }

        resolve();
      },
    );
  });
};
