import React from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

/**
 * Alert message with default properties defined for inclusion inside of a Snackbar.
 */
export const AlertCustom = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      sx={{
        "& .MuiAlert-icon": {
          alignItems: "center",
        },
        "& .MuiAlert-action": {
          alignItems: "center",
        },
        "& .MuiAlert-message": {
          textAlign: "center",
          flex: 1,
        },
        width: "100%",
      }}
    />
  );
});

export type AlertCustomProps = AlertProps;

export default AlertCustom;
