import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { themePrimary } from "@app.automotus.io/components/themes";
import { Auth0ProviderWithHistory } from "@app.automotus.io/components/auth";
import AuthorizedApolloProvider from "@app.automotus.io/components/AuthorizedApolloProvider";
import App from "@curbpass/App";
import { EnvProvider } from "@app.automotus.io/components/context";
import { SnackPackProvider } from "@app.automotus.io/components/context/SnackPack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { datadogLogs } from "@datadog/browser-logs";
import ReactGA from "react-ga4";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY || "");

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
  site: process.env.REACT_APP_DATADOG_SITE,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: "app.automotus.io",
  env: process.env.NODE_ENV === "production" ? "prod" : "dev",
});

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "");

ReactDOM.render(
  <React.StrictMode>
    <EnvProvider>
      <ThemeProvider theme={themePrimary}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <Auth0ProviderWithHistory cacheLocation="localstorage">
              <AuthorizedApolloProvider>
                <SnackPackProvider>
                  <App />
                </SnackPackProvider>
              </AuthorizedApolloProvider>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </EnvProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
