import React from "react";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import LinkButton from "../LinkButton";

export const WalletRefundRequestLinkButton: React.FC<WalletRefundRequestLinkButtonProps> = ({
  loading = false,
  onClick = () => undefined,
}) => {
  return (
    <LoadingSkeleton loading={loading}>
      <LinkButton variant="body1" type="button" onClick={onClick}>
        Request Wallet Refund
      </LinkButton>
    </LoadingSkeleton>
  );
};

export interface WalletRefundRequestLinkButtonProps {
  loading?: boolean;
  onClick?: () => void;
}

export default WalletRefundRequestLinkButton;
