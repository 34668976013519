import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LoadingSkeleton from "../../LoadingSkeleton";
import { DepositLogData } from "../DepositLog";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { convertDateToString, convertTimeToString } from "common/format";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/format";

const columns: GridColumns = [
  {
    field: "date",
    headerName: "Date",
    sortable: true,
    width: 120,
    renderCell: (params) => <Typography variant="body1">{convertDateToString(params.row.date)}</Typography>,
  },
  {
    field: "time",
    headerName: "Time",
    sortable: true,
    width: 120,
    renderCell: (params) => <Typography variant="body1">{convertTimeToString(params.row.time)}</Typography>,
    sortComparator: (v1, v2) => v1.getHours() * 24 + v1.getMinutes() - (v2.getHours() * 24 + v2.getMinutes()),
  },
  {
    field: "description",
    headerName: "Description",
    sortable: true,
    width: 400,
  },
  {
    field: "amount",
    headerName: "Amount",
    sortable: true,
    width: 120,
    renderCell: (params) => <Typography variant="body1">{`$${formatCurrency(params.row.amount)}`}</Typography>,
  },
  {
    field: "balance",
    headerName: "Balance",
    sortable: false,
    width: 120,
    renderCell: (params) => <Typography variant="body1">{`$${formatCurrency(params.row.balance)}`}</Typography>,
  },
];

export const DepositLogTableView: React.FC<DepositLogTableViewProps> = ({
  loading = false,
  rows = [],
  // onClickFilters = () => undefined,
  // onClickExport = () => undefined,
}) => {
  return (
    <LoadingSkeleton loading={loading} width="100%">
      <Box sx={{ height: 600, width: "100%" }}>
        <Paper sx={{ height: "100%" }}>
          <DataGridPro
            columns={columns}
            rows={rows}
            pageSize={30}
            rowsPerPageOptions={[30, 50, 100]}
            loading={loading}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Typography variant="h6" m={1.5}>
                  There are currently no transactions to report
                </Typography>
              ),
            }}
          />
        </Paper>
      </Box>
    </LoadingSkeleton>
  );
};

export interface DepositLogTableViewProps {
  loading?: boolean;
  rows?: DepositLogData[];
  onClickFilters?: () => void;
  onClickExport?: () => void;
}

export default DepositLogTableView;
