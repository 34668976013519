import { gql } from "@apollo/client";

export const payerTransactionLogDepositFragment = gql`
  fragment payerTransactionLogDepositFragment on financial_transaction_log {
    transactionId: transaction_id
    accountId: payer_account_id
    price
    accountBalance: payer_account_balance
    transactionTimestamp: transaction_ts
  }
`;

export interface PayerTransactionLogDeposit {
  transactionId: string;
  accountId: string;
  price: number;
  accountBalance: number;
  transactionTimestamp: string;
}
