import { makeVar } from "@apollo/client";

const temporaryToken = makeVar<string | undefined>(undefined);

const setTemporaryToken = (token: string | undefined) => temporaryToken(token);

export function useTemporaryToken(): [string | undefined, (token: string | undefined) => void] {
  return [temporaryToken(), setTemporaryToken];
}

export default useTemporaryToken;
