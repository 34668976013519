import { PaymentMethod, paymentMethodFragment } from "common/graphql";
import { gql } from "@apollo/client";

export const GET_DEFAULT_PAYMENT_METHOD_WITH_PAYEE = gql`
  query GetDefaultPaymentMethodWithPayee($payeeAccountId: uuid!) {
    paymentMethod: financial_payment_method(
      where: { payee_account_id: { _eq: $payeeAccountId }, is_default: { _eq: true } }
      limit: 1
    ) {
      ...paymentMethodFragment
    }
  }
  ${paymentMethodFragment}
`;

export interface GetDefaultPaymentMethodWithPayeeData {
  paymentMethod: PaymentMethod[];
}

export interface GetDefaultPaymentMethodWithPayeeVars {
  payeeAccountId: string;
}
