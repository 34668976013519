import React from "react";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import LinkButton from "../LinkButton";

export const CloseAccountLinkButton: React.FC<CloseAccountLinkButtonProps> = ({
  loading = false,
  onClick = () => undefined,
}) => {
  return (
    <LoadingSkeleton loading={loading}>
      <LinkButton variant="body1" type="button" onClick={onClick}>
        Close Account
      </LinkButton>
    </LoadingSkeleton>
  );
};

export interface CloseAccountLinkButtonProps {
  loading?: boolean;
  onClick?: () => void;
}

export default CloseAccountLinkButton;
