import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import AmountSelectButton from "./AmountSelectButton";
import { formatCurrency } from "common/format";
import { CurrencyTextField } from "@app.automotus.io/components/CurrencyTextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";

export const AmountSelectInput: React.FC<AmountSelectInputProps> = ({
  amount,
  selectButtonAmounts = [500, 1000, 1500],
  minAmount = 500,
  onChange,
  error,
  helperText,
  loading = false,
  disabled = false,
  onBlur,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const [buttonSelectedValue, setButtonSelectedValue] = useState<number | null>(
    selectButtonAmounts.find((a) => amount === a) || selectButtonAmounts[selectButtonAmounts.length - 1],
  );
  const [textSelectedValue, setTextSelectedValue] = useState<number | null>(
    selectButtonAmounts.includes(amount) ? null : amount / 100,
  );
  const [textHasChanged, setTextHasChanged] = useState(false);

  const amountMatchesButton = selectButtonAmounts.includes(amount);
  useEffect(() => {
    // We use an effect to ensure that, when the amount is provided from a parent component without user input, the
    // proper input is marked as selected
    if (!textHasChanged) {
      if (amountMatchesButton) {
        setButtonSelectedValue(amount);
        setTextSelectedValue(null);
      } else {
        setButtonSelectedValue(null);
        setTextSelectedValue(amount);
      }
    }
  }, [amount, textHasChanged, amountMatchesButton]);

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (e.currentTarget.value) {
      setButtonSelectedValue(+e.currentTarget.value);
      setTextSelectedValue(null);
      onChange(+e.currentTarget.value);
    }
  };

  const handleTextFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    if (e.target.value) {
      setTextHasChanged(true);
      setTextSelectedValue(+e.target.value);
      setButtonSelectedValue(null);
      onChange(+e.target.value * 100);
    }
  };

  return (
    <Stack spacing={1}>
      <Grid container spacing={1}>
        {selectButtonAmounts.map((v) => (
          <Grid item xs={4} key={v}>
            <LoadingSkeleton loading={loading} width={"100%"}>
              <AmountSelectButton
                isSelected={v === buttonSelectedValue}
                value={v}
                onClick={handleButtonClick}
                fullWidth
                disabled={disabled}
              />
            </LoadingSkeleton>
          </Grid>
        ))}
      </Grid>
      <LoadingSkeleton loading={loading} width={"100%"}>
        <CurrencyTextField
          fullWidth
          size={isXs ? "small" : "medium"}
          value={textSelectedValue || ""}
          placeholder={`Other ($${formatCurrency(minAmount)} minimum)`}
          onChange={handleTextFieldChange}
          disabled={disabled}
          onBlur={onBlur}
          {...{ error, helperText }}
        />
      </LoadingSkeleton>
    </Stack>
  );
};

export interface AmountSelectInputProps {
  amount: number;
  minAmount?: number;
  selectButtonAmounts?: number[];
  onChange: (amount: number) => void;
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
}

export default AmountSelectInput;
