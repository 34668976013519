/**
 * Represents the set of valid vehicle type values that a user can input, and encompasses
 * their labels as well.
 */
export class VehicleType {
  public static readonly MotorcycleMoped = new VehicleType({ value: "motorcycle_moped", label: "Motorcycle/Moped" });
  public static readonly Car = new VehicleType({ value: "car", label: "Car" });
  public static readonly CuvSuv = new VehicleType({ value: "cuv_suv", label: "CUV/SUV" });
  public static readonly PickupTruck = new VehicleType({ value: "pickup_truck", label: "Pickup Truck" });
  public static readonly Minivan = new VehicleType({ value: "minivan", label: "Minivan" });
  public static readonly Van = new VehicleType({ value: "van", label: "Van" });
  public static readonly Bus = new VehicleType({ value: "bus", label: "Bus" });
  public static readonly TruckSemiTruck = new VehicleType({ value: "truck_semi_truck", label: "Truck/Semi-Truck" });
  public static readonly All = [
    VehicleType.MotorcycleMoped,
    VehicleType.Car,
    VehicleType.CuvSuv,
    VehicleType.PickupTruck,
    VehicleType.Minivan,
    VehicleType.Van,
    VehicleType.Bus,
    VehicleType.TruckSemiTruck,
  ];
  private static ByValue: Readonly<Record<VehicleTypeValue, VehicleType>> = VehicleType.All.reduce((acc, cur) => {
    return { ...acc, [cur.value]: cur };
  }, {} as Record<VehicleTypeValue, VehicleType>);

  public readonly value: VehicleTypeValue;
  public readonly label: VehicleTypeLabel;

  constructor({ value, label }: VehicleTypeProps) {
    this.value = value;
    this.label = label;
  }

  /**
   * Returns the VehicleType instance corresponding to the value provided
   * @param value The vehicle type value
   */
  public static FromValue(value: VehicleTypeValue): VehicleType {
    return this.ByValue[value];
  }
}

interface VehicleTypeProps {
  value: VehicleTypeValue;
  label: VehicleTypeLabel;
}

export type VehicleTypeValue =
  | "motorcycle_moped"
  | "car"
  | "cuv_suv"
  | "pickup_truck"
  | "minivan"
  | "van"
  | "bus"
  | "truck_semi_truck";

export type VehicleTypeLabel =
  | "Motorcycle/Moped"
  | "Car"
  | "CUV/SUV"
  | "Pickup Truck"
  | "Minivan"
  | "Van"
  | "Bus"
  | "Truck/Semi-Truck";

export default VehicleType;
