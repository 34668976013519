import * as React from "react";
import Box from "@mui/material/Box";
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  gridPageSizeSelector,
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const CustomPagination: React.FC = () => {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const pageIndex = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector) || 1;

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant="body4" color={"text.secondary"} lineHeight={"1.5rem"} fontSize={"12px"}>
          Rows per page:
        </Typography>
        <Typography variant="body4" ml={1} lineHeight={"1.5rem"} fontSize={"12px"}>
          {pageSize}
        </Typography>
        <Typography variant="body4" ml={3.25} lineHeight={"1.5rem"} fontSize={"12px"}>
          {pageIndex + 1} of {pageCount}
        </Typography>
        <Box display={"flex"} alignItems={"center"} ml={3.25}>
          <ArrowBackIosIcon
            sx={{
              p: 0.5,
              width: "24px",
              height: "24px",
              mr: 1,
              boxSizing: "border-box",
              color: (theme) => (pageIndex + 1 === 1 ? theme.palette.action.disabled : theme.palette.action.active),
            }}
            onClick={() => {
              if (pageIndex + 1 === 1) return;
              apiRef.current.setPage(pageIndex - 1);
            }}
          />
          <ArrowForwardIosIcon
            sx={{
              p: 0.5,
              width: "24px",
              height: "24px",
              mr: 2,
              boxSizing: "border-box",
              color: (theme) =>
                pageIndex + 1 === pageCount ? theme.palette.action.disabled : theme.palette.action.active,
            }}
            onClick={() => {
              if (pageIndex + 1 === pageCount) return;
              apiRef.current.setPage(pageIndex + 1);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomPagination;
