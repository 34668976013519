const BRANDS = [
  {
    value: "apple_pay",
    label: "Apple Pay",
  },
  {
    value: "google_pay",
    label: "Google Pay",
  },
] as const;

/** A card wallet brand option */
export type CardWalletBrandOption = typeof BRANDS[number];

/** A particular card wallet brand value */
export type CardWalletBrandValue = CardWalletBrandOption["value"];

/** Labels of a card wallet brand */
export type CardWalletBrandLabel = CardWalletBrandOption["label"];

/**
 * Represents the set of stripe card wallet brands
 */
export class CardWalletBrand {
  static readonly OPTIONS = BRANDS;
  private static readonly OPTIONS_BY_VALUE = BRANDS.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.value]: cur,
    }),
    {} as Record<CardWalletBrandValue, CardWalletBrandOption>,
  );

  /**
   * Returns the {@link CardWalletBrandOption} that corresponds to a particular {@link CardWalletBrandValue}
   */
  static FromValue(value: CardWalletBrandValue): CardWalletBrandOption {
    return this.OPTIONS_BY_VALUE[value];
  }

  /**
   * Returns true if the provided value is a {@link CardWalletBrandValue}
   */
  static IsBrandValue(v: unknown): v is CardWalletBrandValue {
    return typeof v === "string" && !!Object.prototype.hasOwnProperty.call(this.OPTIONS_BY_VALUE, v);
  }
}

export default CardWalletBrand;
