import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box, { BoxProps } from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export const TransactionTotal: React.FC<TransactionTotalProps> = ({
  loading,
  sessionDurationSeconds,
  currencySymbol,
  amountBilled,
  isFreeTransaction = false,
  amountRefunded,
  disputedDurationSeconds,
}) => (
  <Paper>
    <Box sx={{ pt: 3, px: 3, pb: 1.5 }}>
      <Typography variant={"h5"} fontWeight={400}>
        Grand Total
      </Typography>
    </Box>
    {loading ? (
      <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} sx={{ px: 3, py: 1 }}>
        <Skeleton height={64} width={149} />
        <Skeleton height={64} width={102} />
      </Box>
    ) : (
      <Box>
        {!!amountRefunded && amountRefunded > 0 && (
          <Box py={0.25} bgcolor={(theme) => theme.palette.grey[100]}>
            <LineItemRow>
              <LineItemElement
                title={"Billed Park Duration"}
                content={`${Math.round((sessionDurationSeconds || 0) / 60)} min`}
              />
              <LineItemElement
                title={"Amount"}
                content={`${currencySymbol}${((amountBilled || 0) / 100).toFixed(2)}`}
                align={"right"}
              />
            </LineItemRow>
            <LineItemRow>
              <LineItemElement
                title={"Disputed Duration"}
                content={`${Math.round((disputedDurationSeconds || 0) / 60)} min`}
              />
              <LineItemElement
                title={"Refund Amount"}
                content={`-${currencySymbol}${(amountRefunded / 100).toFixed(2)}`}
                align={"right"}
                ContentTypographyProps={{
                  color: "success.dark",
                }}
              />
            </LineItemRow>
          </Box>
        )}
        <Box sx={TOTAL_ROW_SX_PROPS}>
          <LineItemElement
            title={"Total Park Duration"}
            content={`${Math.floor((sessionDurationSeconds || 0) / 60)} min`}
            ContentTypographyProps={{ variant: "h6", fontWeight: 400 }}
          />
          <LineItemElement
            title={"Total Amount"}
            content={
              isFreeTransaction
                ? "$0.00 (free)"
                : `${currencySymbol}${(((amountBilled || 0) - (amountRefunded || 0)) / 100).toFixed(2)}`
            }
            ContentTypographyProps={{ variant: "h6", fontWeight: 400 }}
            align={"right"}
          />
        </Box>
      </Box>
    )}
  </Paper>
);

const LineItemRow: React.FC = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      px: 3,
      py: 1,
    }}
  >
    {children}
  </Box>
);

const LineItemElement: React.FC<LineItemElementProps> = ({
  title,
  content,
  TitleTypographyProps,
  ContentTypographyProps,
  align = "left",
}) => (
  <Box>
    <Typography align={align} variant={"subtitle1"} fontWeight={600} {...TitleTypographyProps}>
      {title}
    </Typography>
    <Typography align={align} variant={"body1"} {...ContentTypographyProps}>
      {content}
    </Typography>
  </Box>
);

interface LineItemElementProps {
  title: string;
  content: string;
  TitleTypographyProps?: TypographyProps;
  ContentTypographyProps?: TypographyProps;
  align?: "left" | "right";
}

const TOTAL_ROW_SX_PROPS: BoxProps["sx"] = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  px: 3,
  py: 2.5,
  bgcolor: "rgba(13, 85, 191, 0.08)",
};

export interface TransactionTotalProps {
  loading?: boolean;
  sessionDurationSeconds?: number;
  amountBilled?: number;
  isFreeTransaction?: boolean;
  disputedDurationSeconds?: number;
  amountRefunded?: number;
  currencySymbol?: string;
}

export default TransactionTotal;
