import React from "react";
import { TextField, TextFieldProps } from "formik-mui";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";

export const InvoiceNumberField = Object.assign(
  ({
    name = "invoiceNumber",
    id = "invoice-number",
    label = "Invoice Number",
    placeholder = "Invoice Number",
    required = true,
    fullWidth = true,
    ...rest
  }: InvoiceNumberFieldProps) => {
    const form = useFormikContext();
    const [field, meta, { setValue }] = useField(name);

    const handleChange = React.useCallback(
      (event) => {
        setValue(event.target.value.toUpperCase());
      },
      [setValue],
    );

    return (
      <TextField
        {...rest}
        {...{ id, label, placeholder: required ? placeholder + "*" : placeholder, required, fullWidth }}
        meta={meta}
        form={form}
        field={{
          ...field,
          onChange: handleChange,
        }}
      />
    );
  },
  {
    defaultInitialValue: "",
    validationSchema: yup.string().required("Required field").matches(/^\d+$/, "Valid invoice number required"),
  },
);

export interface InvoiceNumberFieldProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  name?: string;
}

export default InvoiceNumberField;
