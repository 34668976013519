import VehicleType, { VehicleTypeValue } from "../../../common/VehicleType";
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";

const OPTIONS = VehicleType.All.map((vc) => vc.value);

const VehicleTypeDropdownFieldPrivate = <DisableClearable extends boolean | undefined>({
  name = "vehicleType",
  label = "Vehicle Type",
  placeholder = "Vehicle Type*",
  disablePortal = true,
  fullWidth = true,
  autoSelect = false,
  autoHighlight = true,
  disableClearable = true,
  openOnFocus = true,
  required = true,
  helperText,
  disabled,
  ...rest
}: VehicleTypeDropdownFieldProps<DisableClearable>) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta, helpers] = useField<VehicleTypeValue | null>(name);

  const showError = meta.touched && !!meta.error;

  return (
    <Autocomplete<VehicleTypeValue, false, typeof disableClearable, false>
      {...rest}
      {...{ disablePortal, fullWidth, autoSelect, autoHighlight, disableClearable, openOnFocus }}
      multiple={false}
      value={meta.value}
      onChange={(e, v) => helpers.setValue(v)}
      onBlur={field.onBlur}
      disabled={disabled ?? isSubmitting}
      options={OPTIONS}
      getOptionLabel={(v) => VehicleType.FromValue(v).label}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={showError}
          helperText={showError ? meta.error : helperText}
          label={label}
          placeholder={placeholder}
          required={required}
        />
      )}
    />
  );
};

const validationSchema = yup
  .mixed()
  .oneOf(VehicleType.All.map((vt) => vt.value))
  .required("Selection required");

const defaultInitialValue = null as VehicleTypeValue | null;

export const VehicleTypeDropdownField = Object.assign(VehicleTypeDropdownFieldPrivate, {
  validationSchema,
  defaultInitialValue,
});

/** Props passed to initialize a {@link VehicleTypeDropdownField} */
export interface VehicleTypeDropdownFieldProps<DisableClearable extends boolean | undefined = true>
  extends Omit<
    AutocompleteProps<VehicleTypeValue, false, DisableClearable, false>,
    "renderInput" | "options" | "getOptionLabel" | "onChange" | "onBlur" | "value"
  > {
  name?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
}

export default VehicleTypeDropdownField;
