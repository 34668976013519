import formatDate from "date-fns/format";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);
// TODO: Need to handle internationalization
const timeAgo = new TimeAgo("en-US");

export function formatHourAmPm(h: number): string {
  return `${h % 12 || 12} ${h < 12 ? "AM" : "PM"}`;
}

export const convertDateToString = (date: Date): string => {
  return formatDate(date, "MM/dd/yyyy");
};

export const convertTimeToString = (date: Date): string => {
  return formatDate(date, "hh:mm a");
};

export const convertDateTimeToString = (date: Date): string => {
  return convertDateToString(date) + " " + convertTimeToString(date);
};

export const formatSessionDateRange = (startDate: Date, endDate: Date): string => {
  const startDateString = convertDateToString(startDate);
  const endDateString = convertDateToString(endDate);

  if (startDateString === endDateString) {
    // If the end date is same as the start date, it will return just one date string
    // e.g: formatSessionDateRange("11/23/2023 03:35:23", "11/23/2023 03:45:33") = "11/23/2023"
    return startDateString;
  }

  const startNextDate = new Date(startDate);
  startNextDate.setDate(startDate.getDate() + 1);

  const startNextDateString = convertDateToString(startNextDate);

  if (startNextDateString === endDateString) {
    // If the end date is the next day of the start date, it will return just start date string and 'next day' as end date string
    // e.g: formatSessionDateRange("11/23/2023 23:35:23", "11/24/2023 01:45:33") = "11/23/2023 - next day"
    return `${startDateString} - next day`;
  }

  // other cases, return start and end date strings
  // e.g: formatSessionDateRange("11/23/2023 23:35:23", "11/25/2023 01:45:33") = "11/23/2023 - 11/25/2023"
  return `${startDateString} - ${endDateString}`;
};

export const formatDateAgo = (date: Date): string => {
  return timeAgo.format(date, "round-minute");
};

export const formatDaysOfWeek = (daysOfWeek: string[]): string => {
  return `${daysOfWeek[0].charAt(0).toUpperCase() + daysOfWeek[0].slice(1)} - ${
    daysOfWeek[daysOfWeek.length - 1].charAt(0).toUpperCase() + daysOfWeek[daysOfWeek.length - 1].slice(1)
  }`;
};

export const formatTimeOfDay = (
  timeOfDayStartHours: number,
  timeOfDayStartMinutes: number,
  timeOfDayEndHours: number,
  timeOfDayEndMinutes: number,
): string => {
  const formatHour = (hour: number) => {
    const h = hour % 12 || 12;
    return h < 10 ? `0${h}` : `${h}`;
  };

  const formatMinute = (minute: number) => {
    return minute < 10 ? `0${minute}` : `${minute}`;
  };

  const startHour = formatHour(timeOfDayStartHours);
  const startMinute = formatMinute(timeOfDayStartMinutes);
  const endHour = formatHour(timeOfDayEndHours);
  const endMinute = formatMinute(timeOfDayEndMinutes);

  return `${startHour}:${startMinute} ${timeOfDayStartHours < 12 ? "AM" : "PM"} - ${endHour}:${endMinute} ${
    timeOfDayEndHours < 12 ? "AM" : "PM"
  }`;
};
