import { DispatchDataResponse } from "react-acceptjs";

/**
 * Type of error thrown when an Accept.js dispatch data API call fails with an error.
 */
export class AcceptJsDispatchDataError extends Error {
  /** Raw error messages returned from Accept.js */
  public readonly errorMessages: AcceptJsErrorMessage[];

  constructor(errorMessages: AcceptJsErrorMessage[]) {
    super(
      `failed to dispatch data to AcceptJs:\n${errorMessages
        .map(({ code, text }) => `  [${code}]: ${text}`)
        .join("\n")}`,
    );
    this.errorMessages = errorMessages;
  }
}

/** Raw error message from Accept.js */
export type AcceptJsErrorMessage = DispatchDataResponse["messages"]["message"][0];

export default AcceptJsDispatchDataError;
