import { gql } from "@apollo/client";
import { GeoCoordinatePair } from "./GetLandingPageMapSetting";

export const GET_PAYEE_ZONES_WITH_LOCATIONS = gql`
  query GetPayeeZonesWithLocations($payeeAccountId: uuid!) {
    zones(where: { payee_account_id: { _eq: $payeeAccountId }, is_active: { _eq: true } }) {
      id
      location
    }
  }
`;

/** Variables passed to the {@link GET_PAYEE_ZONES_WITH_LOCATIONS} query */
export interface GetPayeeZoneWithLocationsVars {
  /** Payee account Id */
  payeeAccountId: string;
}

export interface GetPayeeZoneWithLocationsData {
  /** zones data */
  zones: ZoneData[] | null;
}

export interface ZoneData {
  /** Zone Id */
  id: string;
  /** Location Info */
  location: {
    coordinates: [GeoCoordinatePair, GeoCoordinatePair];
  };
}
