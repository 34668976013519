import React from "react";
import TextHeader from "./TextHeader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import VehicleInfoForm, {
  ValidatedVehicleInfoFormValues,
  VehicleInfoFormSubmitAction,
} from "@app.automotus.io/components/forms/VehicleInfoForm";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SNACKBAR_MSGS,
  SnackbarMessageProps,
  useSnackPackContext,
} from "@app.automotus.io/components/context/SnackPack";
import {
  GET_CURRENTLY_REGISTERED_VEHICLES,
  GET_USER_PROFILE,
  GetCurrentlyRegisteredVehiclesData,
  GetCurrentlyRegisteredVehiclesVars,
  REGISTER_VEHICLE,
  RegisterVehicleData,
  RegisterVehicleRefetchQueries,
  RegisterVehicleVars,
  GET_INVOICE_BY_ID,
  GetInvoiceByIdData,
  GetInvoiceByIdVars,
} from "common/graphql";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const AddVehicle: React.FC = () => {
  const navigate = useNavigate();
  const { publishSnackbarMessage } = useSnackPackContext();
  const [createRegisteredVehicle] = useMutation<RegisterVehicleData, RegisterVehicleVars>(REGISTER_VEHICLE, {
    // Also need to refetch get user profile to update onboarding status
    refetchQueries: [...RegisterVehicleRefetchQueries, GET_USER_PROFILE],
  });
  const location = useLocation();
  const { invoiceId = "" } = (location.state || {}) as { invoiceId?: string };

  const { data: getRegisteredVehiclesData } = useQuery<
    GetCurrentlyRegisteredVehiclesData,
    GetCurrentlyRegisteredVehiclesVars
  >(GET_CURRENTLY_REGISTERED_VEHICLES, {
    variables: {
      limit: 1,
      offset: 0,
    },
    fetchPolicy: "cache-only",
  });

  let licensePlateData = "";

  const {
    data: invoiceData,
    loading: invoiceLoading,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const refetchOnError = () => {
    if (invoiceError) {
      refetchInvoice();
    }
  };

  if (invoiceId && invoiceError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  if (!invoiceLoading && invoiceData?.invoice) {
    licensePlateData = invoiceData.invoice.registered_vehicle?.license_plate
      ? invoiceData.invoice.registered_vehicle.license_plate
      : "";
  }

  const onSaveAndContinue = () => {
    navigate("/signup/payment");
    return;
  };

  const handleSubmit = async (
    submitAction: VehicleInfoFormSubmitAction,
    values: ValidatedVehicleInfoFormValues,
  ): Promise<void> => {
    const { licensePlate, licensePlateState: stateCode, vehicleType: type, make, model, year } = values;
    try {
      await createRegisteredVehicle({
        variables: {
          licensePlate,
          stateCode,
          type,
          make: make?.name || null,
          model: model?.name || null,
          year,
        },
      });

      if (submitAction === "save") {
        navigate("/signup/payment", { state: location.state });
      } else {
        publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ADDED_SUCCESS as SnackbarMessageProps);
      }
    } catch (err: unknown) {
      console.error(err);

      if (err instanceof Error) {
        if (err.message.includes("registered_vehicle exists and is registered: true")) {
          publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ALREADY_EXISTS as SnackbarMessageProps);
        } else {
          publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ADDED_FAILURE as SnackbarMessageProps);
        }

        throw new Error("Error registering vehicle");
      }
    }
  };

  return (
    <AddVehicleContainer>
      {invoiceId ? (
        <InvoiceVehicleFormDisplay
          licensePlateNumber={invoiceData?.invoice?.registered_vehicle?.license_plate}
          licensePlateStateCode={invoiceData?.invoice?.registered_vehicle?.state_code}
          onClickContinue={() => navigate("/signup/payment", { state: location.state })}
          loading={invoiceLoading}
        />
      ) : (
        <VehicleInfoForm
          licensePlate={licensePlateData}
          allowCancelAndFinish={!!getRegisteredVehiclesData?.registeredVehicles.length}
          onSaveAndContinue={onSaveAndContinue}
          variant="add"
          onSubmit={handleSubmit}
          buttonTexts={{ addVehicle: "Save and Continue" }}
        />
      )}
    </AddVehicleContainer>
  );
};

export const AddVehicleContainer: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));

  return (
    <Box sx={{ mt: isMobile ? 1.5 : 3, px: isMobile ? 0 : 2 }}>
      <Box sx={{ mb: 3 }}>
        <TextHeader />
      </Box>
      <Box sx={{ mb: 2 }}>{children}</Box>
      <Button
        variant={"text"}
        size={isSmall ? "small" : isMobile ? "medium" : "large"}
        fullWidth
        sx={{ textTransform: "none" }}
        onClick={() => navigate("/signup/profile")}
      >
        Back
      </Button>
    </Box>
  );
};

export const InvoiceVehicleFormDisplay: React.FC<InvoiceVehicleFormDisplayProps> = ({
  licensePlateNumber,
  licensePlateStateCode,
  onClickContinue,
  loading,
}) => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 1,
        height: "100%",
      }}
      spacing={6}
    >
      <Stack spacing={1} width={"100%"}>
        <LoadingSkeleton loading={loading} width={"100%"}>
          <TextField
            label={"License Plate"}
            disabled
            fullWidth
            value={`${licensePlateStateCode}${licensePlateNumber}`}
          />
        </LoadingSkeleton>
        <LoadingSkeleton loading={loading} width={"100%"}>
          <Alert severity={"info"}>You are in the process of paying an invoice linked to this license plate.</Alert>
        </LoadingSkeleton>
      </Stack>
      <Box sx={{ width: "100%" }}>
        <LoadingSkeleton loading={loading} width={"100%"}>
          <Button variant="contained" onClick={onClickContinue} fullWidth>
            Continue
          </Button>
        </LoadingSkeleton>
      </Box>
    </Stack>
  );
};

export interface InvoiceVehicleFormDisplayProps {
  licensePlateNumber?: string;
  licensePlateStateCode?: string;
  onClickContinue?: () => void;
  loading?: boolean;
}

export default AddVehicle;
