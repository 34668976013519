import { gql } from "@apollo/client";
import {
  EventPolicyCompliance,
  eventPolicyComplianceFragment,
} from "common/graphql/fragments/eventPolicyComplianceFragment";

export const parkFragment = gql`
  fragment parkFragment on parks {
    startTime: start_time
    endTime: end_time
    startTimeLocal: start_time_local
    endTimeLocal: end_time_local
    policyRateDetails: event_policy_compliance_records {
      ...eventPolicyComplianceFragment
    }
  }
  ${eventPolicyComplianceFragment}
`;

export interface Park {
  startTime: string;
  endTime: string;
  startTimeLocal: string;
  endTimeLocal: string;
  policyRateDetails: EventPolicyCompliance[];
}
