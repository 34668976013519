import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import React from "react";
import { InvoiceData } from "common/graphql";
import VehicleInvoiceCardStatus from "./VehicleInvoiceCardStatus";
import { VehicleCardFooter } from "./VehicleCardFooter";
import { VehicleCardHeader } from "./VehicleCardHeader";

export const VehicleInvoiceCard: React.FC<{
  invoice: InvoiceData;
  isRegistered?: boolean;
}> = ({ invoice, isRegistered = false }) => {
  return (
    <Box
      key={invoice.index}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "stretch",
        borderRadius: "8px",
        border: "1px solid #0000003B",
        background: "#FFFFFF",
      }}
    >
      <VehicleCardHeader isRegistered={isRegistered} invoice={invoice} />
      {invoice.replaced_by_invoice?.id && (
        <VehicleInvoiceCardStatus
          message={
            <Typography>
              This invoice has been issued in place of a{" "}
              <Link
                sx={{ color: "rgba(0,0,0,0.6)", textDecorationColor: "rgba(0,0,0,0.6)" }}
                href={`/invoices/${invoice.replaced_by_invoice?.id}`}
              >
                voided invoice
              </Link>
              .
            </Typography>
          }
        />
      )}
      {/* {(invoice.review_status === InvoiceReviewStatus.PENDING ||
        invoice.review_status === InvoiceReviewStatus.IN_PROGRESS) && (
        <VehicleInvoiceCardStatus
          status="warning"
          message={
            <Typography>
              We’re currently reviewing this invoice. Please{" "}
              <Link target="_blank" sx={{ color: "#ED6C02" }} href={`/support`}>
                contact us
              </Link>
              {` with questions.`}
            </Typography>
          }
        />
      )} */}
      <VehicleCardFooter invoice={invoice} />
    </Box>
  );
};

export type VehicleInvoiceCardViewProps = {
  invoices: ReadonlyArray<InvoiceData>;
  isRegistered?: boolean;
};

export const VehicleInvoiceCardView: React.FC<VehicleInvoiceCardViewProps> = ({ invoices, isRegistered = false }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        alignSelf: "stretch",
      }}
    >
      {invoices.map((inv) => {
        return <VehicleInvoiceCard isRegistered={isRegistered} key={inv.id} invoice={inv} />;
      })}
    </Box>
  );
};
