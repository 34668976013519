/**
 * Encapsulates the set of valid vehicle category values along with their labels
 * for display.
 */
export class VehicleCategory {
  /** Personal vehicle category */
  public static readonly Personal = new VehicleCategory({ value: "personal", label: "Personal" });

  /** Commercial vehicle category */
  public static readonly Commercial = new VehicleCategory({ value: "commercial", label: "Commercial" });

  /** Array containing all vehicle categories in default sort order (personal first) */
  public static readonly All = [VehicleCategory.Personal, VehicleCategory.Commercial];

  /** Value of the category */
  public readonly value: VehicleCategoryValue;

  /** Label for displaying the category */
  public readonly label: string;

  constructor({ value, label }: VehicleCategoryProps) {
    this.value = value;
    this.label = label;
  }

  /**
   * Returns a VehicleCategory identified by its value.
   * @param value The vehicle category value string
   * @returns The VehicleCategory that corresponds to the provided value
   */
  public static FromValue(value: VehicleCategoryValue): VehicleCategory {
    if (value === "personal") {
      return VehicleCategory.Personal;
    }

    return VehicleCategory.Commercial;
  }
}

export interface VehicleCategoryProps {
  value: VehicleCategoryValue;
  label: string;
}

export type VehicleCategoryValue = "personal" | "commercial";

export default VehicleCategory;
