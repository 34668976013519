import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { InputBaseComponentProps } from "@mui/material/InputBase";

export const CurrencyTextField: React.FC<TextFieldProps> = ({ InputProps, ...rest }) => (
  <TextField
    InputProps={{
      ...InputProps,
      inputComponent: CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>,
    }}
    {...rest}
  />
);

const CurrencyInput = React.forwardRef<NumberFormatProps, CurrencyInputProps>(function CurrencyFormat(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
});

interface CurrencyInputProps {
  onChange: (event: { target: { value: string } }) => void;
}

export default CurrencyTextField;
