import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { FullScreenLoader } from "@app.automotus.io/components/FullScreenLoader";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { SignUpStepper, SignUpStepValue } from "@app.automotus.io/components/scenes/SignUp/SignUpStepper/SignUpStepper";
import { useProfileWithCompletedSteps } from "@app.automotus.io/components/hooks";
import OnboardingLayout from "@app.automotus.io/components/Layout/OnboardingLayout";

/** Props passed to the {@link SignUp} component */
export interface SignUpProps {
  /** Indicates whether it's BillByMail sign-up flow */
  isBillByMail?: boolean;
}

/**
 * Parent route for all other signup routes
 */
export const SignUp: React.FC<SignUpProps> = () => {
  const navigate = useNavigate();
  const matchStep = useMatch("/signup/:activeStep");

  const { userProfile, loading, completedSteps } = useProfileWithCompletedSteps();

  const activeStep: SignUpStepValue = (matchStep?.params.activeStep as SignUpStepValue) || "profile";

  const handleClickStep = (step: SignUpStepValue) => {
    navigate(`/signup/${step}`);
  };

  if (loading || !userProfile) {
    return <FullScreenLoader loading={true} />;
  }

  return (
    <OnboardingLayout marginTopSmall={59} marginTopMedium={63} paddingHorizontal={0}>
      <Box sx={{ px: 2, mt: -2 }}>
        <SignUpStepper
          accountRoleType={userProfile.account.roleType || "payer"}
          completedSteps={completedSteps}
          activeStep={activeStep.toString() === "terms-of-service" ? "payment" : activeStep}
          onClickStep={handleClickStep}
        />
      </Box>
      <Divider />
    </OnboardingLayout>
  );
};

export default SignUp;
