import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import { formatCurrency } from "common/format";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import CardNumberField from "@app.automotus.io/components/forms/PaymentMethodFields/CardNumberField";
import ExpiryField from "@app.automotus.io/components/forms/PaymentMethodFields/ExpiryField";
import CVCField from "@app.automotus.io/components/forms/PaymentMethodFields/CVCField";
import ZipCodeField from "@app.automotus.io/components/forms/PaymentMethodFields/ZipCodeField";
import { CardMethodFormFields } from "@app.automotus.io/components/forms/PaymentMethodForm/CardMethodForm/CardMethodFormFields";
import FirstNameField from "@app.automotus.io/components/forms/ProfileInformationFields/FirstNameField";
import LastNameField from "@app.automotus.io/components/forms/ProfileInformationFields/LastNameField";
import EmailField from "@app.automotus.io/components/forms/ProfileInformationFields/EmailField";
import { DesktopLayout, MobileLayout } from "@app.automotus.io/components/Layout/OnboardingLayout";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import LoadingSkeleton from "../LoadingSkeleton";

/**
 * The standalone payments page is a standardized form page that allows users to fill in details and authorize a payment.
 * It uses existing components from our library.
 */
export const BillByMailPaymentForm: React.FC<BillByMailPaymentFormProps> = ({
  loading,
  amount,
  onSubmit = () => undefined,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const handleSubmit = async (
    values: ValidatedPaymentFormValues,
    helpers: FormikHelpers<ValidatedPaymentFormValues>,
  ) => {
    try {
      await onSubmit(values);
      helpers.setSubmitting(false);
    } catch (err) {
      console.error(err);
    }
  };

  const topBar = (
    <Typography
      sx={{
        fontSize: "20px",
        fontWeight: 500,
        zIndex: 999,
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.primary.main,
        position: "sticky",
        top: isMobile ? "56px" : 0,
        px: isMobile ? 2 : 4,
        py: { xs: 1, tiny: 1.5 },
      }}
    >
      Pay ${formatCurrency(amount)}
    </Typography>
  );

  const paymentFormContent = (
    <Box>
      {isMobile && topBar}
      <Box sx={{ px: isMobile ? 2 : 4 }}>
        <LoadingSkeleton loading={loading}>
          <Typography
            sx={{
              mt: { xs: 0, md: 1.5, lg: 0 },
              pt: 1.5,
              fontSize: {
                xs: "24px",
                tiny: "28px",
                lg: "34px",
              },
              fontWeight: 500,
            }}
          >
            Payment Method
          </Typography>
        </LoadingSkeleton>
        <Formik
          initialValues={{
            amount: amount,
            cardNumber: "",
            expiry: "",
            cvc: "",
            zipCode: "",
            firstName: "",
            lastName: "",
            email: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            amount: yup.string().required("Required Field"),
            cardNumber: CardNumberField.validationSchema,
            expiry: ExpiryField.validationSchema,
            cvc: CVCField.validationSchema,
            zipCode: ZipCodeField.validationSchema,
            firstName: yup.string().required("Required Field"),
            lastName: yup.string().required("Required Field"),
            email: yup.string().email("Invalid Email").required("Required Field"),
          })}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
              {/* <Box sx={{ my: 3 }}>
                <LoadingSkeleton loading={loading}>
                  <Typography variant={"body1"} sx={{ color: theme.palette.text.secondary }}>
                    <Divider>or</Divider>
                  </Typography>
                </LoadingSkeleton>
              </Box> */}
              <Box mt={3}>
                <CardMethodFormFields loading={loading} />
              </Box>
              <Box sx={{ mt: 3 }}>
                <LoadingSkeleton loading={loading}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "24px",
                        tiny: "28px",
                        lg: "34px",
                      },
                      fontWeight: 500,
                    }}
                  >
                    Billing Information
                  </Typography>
                </LoadingSkeleton>
                <Grid container justifyContent="space-between" mt={3} rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} lg={6}>
                    <LoadingSkeleton loading={loading}>
                      <FirstNameField autoFocus={false} helperText={" "} />
                    </LoadingSkeleton>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <LoadingSkeleton loading={loading}>
                      <LastNameField helperText={" "} />
                    </LoadingSkeleton>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingSkeleton loading={loading}>
                      <EmailField helperText={" "} />
                    </LoadingSkeleton>
                  </Grid>
                </Grid>
              </Box>

              <Grid item xs={12} sm={12}>
                <LoadingButton
                  sx={{
                    mt: 3,
                    fontWeight: { xs: 500, tiny: 600, sm: 500 },
                    fontSize: { xs: "14px", tiny: "16px", sm: "18px" },
                    textTransform: "none",
                    width: "100%",
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                  }}
                  variant="contained"
                  fullWidth
                  id="form-submit"
                  type="submit"
                  loading={props.isSubmitting}
                >
                  Pay
                </LoadingButton>
              </Grid>
              {isMobile && (
                <Grid item xs={12} sm={12} mt={{ xs: 2, tiny: 3 }}>
                  <LoadingButton
                    sx={{
                      fontWeight: { xs: 500, tiny: 600, sm: 500 },
                      fontSize: { xs: "14px", tiny: "16px", sm: "18px" },
                      textTransform: "none",
                      width: "100%",
                      height: { xs: "30px", tiny: "36px", lg: "42px" },
                    }}
                    variant="text"
                    fullWidth
                    onClick={() => navigate(-1)}
                    loading={props.isSubmitting}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} mt={{ xs: 1.5, tiny: 3 }} mb={4}>
                <LoadingSkeleton loading={loading}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 400,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Link variant={"body1"} component={RouterLink} to={"/support"} target={"_blank"}>
                      Need Help?
                    </Link>
                  </Typography>
                </LoadingSkeleton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <MobileLayout noPadding relative>
        {paymentFormContent}
      </MobileLayout>
    );
  } else {
    return (
      <DesktopLayout noPadding topBar={topBar}>
        {paymentFormContent}
      </DesktopLayout>
    );
  }
};

export interface PaymentFormValues {
  amount: number;
  cardNumber: string;
  expiry: string;
  cvc: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  email: string;
}

export type ValidatedPaymentFormValues = {
  [P in keyof PaymentFormValues]: P extends keyof PaymentFormValues
    ? NonNullable<PaymentFormValues[P]>
    : PaymentFormValues[P];
};

export interface BillByMailPaymentFormProps {
  loading: boolean;
  /** Lower-case currency code of the amount */
  currencyCode?: string;
  /** Bill amount */
  amount: number;
  /** Pay button handler */
  onSubmit?: (values: ValidatedPaymentFormValues) => Promise<void>;
  /** Google pay button handler */
  onGooglePay?: () => Promise<void>;
  /** Apple pay button handler */
  onApplePay?: () => Promise<void>;
}

export default BillByMailPaymentForm;
