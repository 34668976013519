import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";

export const SessionDetailsItem: React.FC<SessionDetailsItemProps> = ({ loading, title, content, skeletonWidth }) => (
  <Grid item xs={3}>
    <Typography variant={"subtitle1"} fontWeight={500}>
      {title}
    </Typography>
    <LoadingSkeleton loading={loading} width={skeletonWidth}>
      <Typography variant={"body1"}>{content || "_"}</Typography>
    </LoadingSkeleton>
  </Grid>
);

interface SessionDetailsItemProps {
  loading?: boolean;
  title: string;
  content?: string;
  skeletonWidth?: number;
  skeletonHeight?: number;
}

export default SessionDetailsItem;
