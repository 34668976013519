import { gql } from "@apollo/client";

export const GET_LANDING_PAGE_MAP_SETTING = gql`
  query GetLandingPageMapSetting($payeeAccountId: uuid!) {
    mapSetting: landing_page_map_setting(where: { payee_account_id: { _eq: $payeeAccountId } }) {
      location_centerpoint
      landing_page_google_maps_default_zoom
    }
  }
`;

/** Variables passed to the {@link GET_LANDING_PAGE_MAP_SETTING} query */
export interface GetLandingPageMapSettingVars {
  /** Payee account Id */
  payeeAccountId: string;
}

export interface GetLandingPageMapSettingData {
  /** map setting data */
  mapSetting: MapSettingData[] | null;
}

export type GeoCoordinatePair = [number, number];
export interface MapSettingData {
  /** Center point coordinates */
  location_centerpoint: {
    coordinates: GeoCoordinatePair; // [longitude, latitude]
  };
  /** Map zoom level */
  landing_page_google_maps_default_zoom: number;
}
