import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Support from "@app.automotus.io/components/routes/support";
import Error404 from "@app.automotus.io/components/scenes/Errors/Error404";
import BBMLandingPage from "@app.automotus.io/components/scenes/BillByMail/LandingPage";
import BBMPaymentPage from "@app.automotus.io/components/scenes/BillByMail/PaymentPage";
import BBMInvoicePage from "@app.automotus.io/components/scenes/BillByMail/InvoicePage";
import BBMSignUp from "@app.automotus.io/components/scenes/BillByMail/SignUp";
import BBMInvoiceActivationPage from "@app.automotus.io/components/scenes/BillByMail/InvoiceActivationPage";
import BBMInvoiceClaimsPage from "@app.automotus.io/components/scenes/BillByMail/InvoiceClaimsPage";
import BBMConfirmationPage from "@app.automotus.io/components/scenes/BillByMail/ConfirmationPage";
import {
  FleetOnboardingManualEntry,
  FleetOnboardingPageLayout,
  FleetOnboardingUpload,
} from "@app.automotus.io/components/scenes/PartnerLandingPage/FleetOnboardingPage";
import Authorize from "@app.automotus.io/components/routes/authorize";
import Login from "@app.automotus.io/components/routes/login";
import SignUp from "@app.automotus.io/components/routes/signup";
import SignUpProfile from "@app.automotus.io/components/routes/signup/profile";
import SignUpTermsOfService from "@app.automotus.io/components/routes/signup/terms-of-service";
import SignUpVehicle from "@app.automotus.io/components/routes/signup/vehicle";
import SignUpCurbPass from "@app.automotus.io/components/routes/signup/curbpass";
import { DashboardLayoutRoute } from "@curbpass/routes/layout/DashboardLayoutRoute";
import { ProtectedComponent } from "@app.automotus.io/components/auth";
import { PublicComponent } from "@app.automotus.io/components/auth/PublicComponent/PublicComponent";
import { SignUpFinish } from "@app.automotus.io/components/routes/signup/finish";
import { Account } from "@app.automotus.io/components/routes/account";
import withAuthorizedRedirection from "@app.automotus.io/components/auth/withAuthorizedRedirection/withAuthorizedRedirection";
import ReactGA from "react-ga4";
import { ErrorBoundary } from "react-error-boundary";
import PageLevelError from "@app.automotus.io/components/common/PageLevelError";
import OnboardingLayout from "@app.automotus.io/components/Layout/OnboardingLayout";
import BillByMailLayout from "@app.automotus.io/components/Layout/BillByMailLayout";
import { DisputeTransaction } from "@app.automotus.io/components/routes/transactions/transaction/dispute";
import { TransactionLayout } from "@app.automotus.io/components/routes/transactions/transaction/transaction-layout";
import { Transaction } from "@app.automotus.io/components/routes/transactions/transaction/transaction";
import startCase from "lodash/startCase";
import { LandingPageLayout } from "@app.automotus.io/components/scenes/PartnerLandingPage/LandingPageLayout";
import { PayerDashboard } from "@app.automotus.io/components/routes/dashboard/PayerDashboard";
import { Logout } from "@app.automotus.io/components/routes/logout";
import { VerifyCrossOrigin } from "@app.automotus.io/components/routes/verify-cross-origin";
import { VehicleInvoice } from "@app.automotus.io/components/VehicleInvoice/VehicleInvoice";
import { InvoiceDetail } from "@app.automotus.io/components/InvoiceDetail/InvoiceDetail";

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    const paths = location.pathname.split("/");

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title:
        location.pathname === "/"
          ? "Home"
          : `${paths
              .slice(1)
              .map((pathElement) => startCase(pathElement))
              .join(" - ")}`,
    });

    window.scrollTo(0, 0);
  }, [location]);
}

export const AppRoutes: React.FC = () => {
  usePageViews();

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/verify-cross-origin" element={<VerifyCrossOrigin />} />
      <Route path={"/logout"} element={<Logout />} />
      <Route
        path="/pittsburgh"
        element={<PublicComponent component={LandingPageLayout} partnerName={"pittsburgh"} />}
      />
      <Route path="/omaha" element={<PublicComponent component={LandingPageLayout} partnerName={"omaha"} />} />
      <Route path="/paybill/:invoiceId/signup" element={<BBMSignUp />} />
      <Route path="/paybill/claims" element={<BBMInvoiceClaimsPage />} />
      <Route path="/paybill" element={<PublicComponent component={OnboardingLayout} />}>
        <Route path=":invoiceId" element={<PublicComponent component={BBMInvoicePage} />} />
        <Route
          path=":invoiceId/payment-preferences"
          element={<PublicComponent component={BBMInvoiceActivationPage} />}
        />
        <Route path=":invoiceId/confirmation" element={<BBMConfirmationPage />} />
        <Route index element={<PublicComponent component={BBMLandingPage} />} />
      </Route>
      <Route element={<PublicComponent component={BillByMailLayout} />}>
        <Route path="/paybill/:invoiceId/payments" element={<PublicComponent component={BBMPaymentPage} />} />
      </Route>
      <Route path="/bethlehem" element={<PublicComponent component={LandingPageLayout} partnerName={"bethlehem"} />} />
      <Route path="/fortlee" element={<PublicComponent component={LandingPageLayout} partnerName={"fortlee"} />} />
      <Route path="/fleets" element={<PublicComponent component={FleetOnboardingPageLayout} />}>
        <Route index element={<FleetOnboardingUpload />} />
        <Route path="manual-entry" element={<FleetOnboardingManualEntry />} />
      </Route>
      <Route path="/authorize" element={<Authorize />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/terms-of-service"
        element={
          <Box sx={{ p: 4 }}>
            <SignUpTermsOfService />
          </Box>
        }
      />
      <Route path="/signup" element={<ProtectedComponent component={SignUp} />}>
        <Route path="profile" element={<SignUpProfile />} />
        <Route path="payment" element={<SignUpCurbPass />} />
        <Route path="terms-of-service" element={<SignUpTermsOfService />} />
        <Route path="vehicle" element={<SignUpVehicle />} />
        <Route path="finish" element={<SignUpFinish />} />
      </Route>
      <Route
        element={
          <ErrorBoundary
            FallbackComponent={PageLevelError}
            onReset={() => {
              window.location.reload();
            }}
          >
            <ProtectedComponent component={withAuthorizedRedirection(DashboardLayoutRoute)} />
          </ErrorBoundary>
        }
      >
        <Route path="/account" element={<Account />} />
        <Route path="/account/add_new_vehicle" element={<Account />} />
        <Route path="/dashboard" element={<PayerDashboard />} />
        <Route path="/invoices/:invoiceIndex" element={<InvoiceDetail />} />
        <Route path="/vehicles/:vehicleId/invoices" element={<VehicleInvoice />} />
        <Route path="/transactions/:transactionId" element={<TransactionLayout />}>
          <Route path="dispute" element={<DisputeTransaction />} />
          <Route index element={<Transaction />} />
        </Route>
      </Route>
      <Route path="/support" element={<Support />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRoutes;
