import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import useIsMobile from "@app.automotus.io/components/hooks/useIsMobile";
import LogoBlue from "@app.automotus.io/components/images/AppLogo/logoBlue";

/**
 * Full-page responsive component used to define layout for error pages
 *
 * This component functions ONLY as a container and does not implement any logic aside
 * from responsively determining page layout.
 * @param props Standard react component props
 * @returns A JSX element representing container used for the signup process.
 */
export const ErrorPageLayout: React.FC = (props) => {
  const isMobile = useIsMobile();

  return isMobile ? <MobileLayout>{props.children}</MobileLayout> : <DesktopLayout>{props.children}</DesktopLayout>;
};

const MobileLayout: React.FC = ({ children }) => {
  return (
    <Container sx={{ my: 4 }}>
      <Box sx={{ mb: 6 }}>
        <LogoBlue width={186} height={"auto"} />
      </Box>
      <main>{children}</main>
    </Container>
  );
};

const DesktopLayout: React.FC = ({ children }) => {
  const MAX_WIDTH = 572;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ minWidth: "100%", minHeight: "100vh" }}
      style={{
        backgroundImage: `url(${process.env.REACT_APP_ASSET_ENDPOINT}/images/app.automotus.io/background.png)`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Card
        sx={{ my: "11vh", minHeight: "78vh", width: "40vw", maxWidth: MAX_WIDTH, borderRadius: "12px", boxShadow: 3 }}
      >
        <Box sx={{ my: 5, mx: 4 }}>
          <LogoBlue style={{ width: "186px", height: "auto" }} />
          <Box component="main" sx={{ mt: 3 }}>
            {children}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export interface ErrorPageProps {
  code: string;
  message: string;
}

export default ErrorPageLayout;
