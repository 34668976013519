import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Hook that returns true if the device on which it is called is a mobile device
 * @returns True if the device rendering the page has a mobile-size screen
 */
export function useIsMobile(): boolean {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  return matches;
}

export default useIsMobile;
