import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CurbPassSettings from "@app.automotus.io/components/CurbPassSettings/CurbPassSettings";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { useMutation, useQuery } from "@apollo/client";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";
import {
  GET_WALLET_WITH_PAYEE,
  GetWalletWithPayeeData,
  GetWalletWithPayeeVars,
  UPDATE_WALLET_SETTINGS,
  UpdateWalletSettingsData,
  UpdateWalletSettingsVars,
} from "common/graphql";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { formatCurrency } from "common/format";
import { SNACKBAR_MSGS, useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import { PartnerLandingPageParkingRatesAndHoursModal } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal";

const validationSchema = yup.object({
  autoRefillAt: yup.number().required(),
  desiredBalance: yup
    .number()
    .required()
    .min(500, `Please enter an amount greater than $${formatCurrency(500)}`),
});

export const CurbPassSettingsDialog: React.FC<CurbPassSettingsDialogProps> = ({
  open = false,
  onClose,
  payeeAccountId,
  loading,
}) => {
  const isMobile = useIsMobile();
  const {
    data: walletData,
    loading: walletLoading,
    error: walletError,
    refetch: refetchWallet,
  } = useQuery<GetWalletWithPayeeData, GetWalletWithPayeeVars>(GET_WALLET_WITH_PAYEE, {
    variables: {
      payeeAccountId,
    },
  });
  const { publishSnackbarMessage } = useSnackPackContext();

  const { wallet } = walletData || {};

  const [updateWalletSettings, { reset: resetMutation }] = useMutation<
    UpdateWalletSettingsData,
    UpdateWalletSettingsVars
  >(UPDATE_WALLET_SETTINGS);

  const formik = useFormik({
    initialValues: {
      autoRefillAt: wallet?.minWalletBalance || 375,
      desiredBalance: wallet?.desiredWalletBalance || 1500,
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateWalletSettings({
          variables: {
            payeeAccountId,
            ...values,
          },
          onCompleted: () => {
            publishSnackbarMessage(SNACKBAR_MSGS.CURBPASS_SETTINGS_UPDATE_SUCCESS);
            onClose && onClose({}, "backdropClick");
          },
          onError: (err) => {
            console.error(err);
            publishSnackbarMessage(SNACKBAR_MSGS.CURBPASS_SETTINGS_UPDATE_FAILED);
          },
        });
      } catch (err) {
        console.error(err);
        publishSnackbarMessage(SNACKBAR_MSGS.CURBPASS_SETTINGS_UPDATE_FAILED);
      } finally {
        resetMutation();
      }
    },
  });

  const [isSaved, setIsSaved] = useState(!formik.dirty);
  useEffect(() => {
    if (formik.dirty) {
      setIsSaved(false);
    }
  }, [formik.dirty]);

  const [parkingInfoModalOpen, setParkingInfoModalOpen] = useState(false);
  const handleOpenParkingInfoModal: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    setParkingInfoModalOpen(true);
  };

  const handleCloseParkingInfoModal = () => {
    setParkingInfoModalOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          py: isMobile ? 1.5 : 4,
          px: isMobile ? 2 : 4,
          borderRadius: isMobile ? 0 : 3,
          width: isMobile ? "100%" : undefined,
          mx: isMobile ? 0 : "auto",
          // TODO: add mt property for mobile to ensure navbar visible
          my: isMobile ? 0 : "auto",
          height: isMobile ? "100%" : undefined,
          maxWidth: isMobile ? "100%" : "450px",
          maxHeight: isMobile ? "100%" : "calc(100% - 64px)",
        },
      }}
    >
      {walletError ? (
        <LocalLevelError onTryAgain={refetchWallet} />
      ) : (
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <CurbPassSettings
              autoRefillAt={formik.values.autoRefillAt}
              desiredBalance={formik.values.desiredBalance}
              onChangeAmount={(newAmount) => {
                formik.setFieldValue("desiredBalance", newAmount, true);
                formik.setFieldValue("autoRefillAt", newAmount * 0.25, false);
              }}
              onChangeRefillAt={(newRefillAt) => formik.setFieldValue("autoRefillAt", newRefillAt)}
              amountError={!!formik.errors?.desiredBalance}
              amountHelperText={formik.errors?.desiredBalance ?? " "}
              loading={walletLoading || loading}
              isSaved={isSaved}
              isSaving={formik.isSubmitting}
              isSaveDisabled={!!(formik.errors.autoRefillAt || formik.errors.desiredBalance)}
              onCancel={(e) => onClose && onClose(e, "backdropClick")}
              onClickParkingRates={handleOpenParkingInfoModal}
            />
          </form>
        </Box>
      )}
      <PartnerLandingPageParkingRatesAndHoursModal
        open={parkingInfoModalOpen}
        onClose={handleCloseParkingInfoModal}
        payeeAccountId={payeeAccountId}
      />
    </Dialog>
  );
};

/** Components passed to initialize the {@link CurbPassSettingsDialog} component */
export interface CurbPassSettingsDialogProps {
  /** When true, dialog will be open */
  open?: boolean;
  /** Handler fired when closed */
  onClose?: DialogProps["onClose"];
  /** ID of the payee with hom the settings apply */
  payeeAccountId: string;
  /** When true, component will display as loading */
  loading?: boolean;
}

export default CurbPassSettingsDialog;
