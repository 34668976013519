import { gql } from "@apollo/client";

export const payerTransactionLogNoBalanceEntryFragment = gql`
  fragment payerTransactionLogNoBalanceEntryFragment on financial_transaction_log_no_balance {
    transactionId: transaction_id
    endTime: end_time
    startTime: start_time
    addressStreet: address_street
    addressCity: address_city
    addressState: address_state
    licensePlateNumber: license_plate_number
    licensePlateStateCode: license_plate_state_code
    price
    index
    status
  }
`;

export interface PayerTransactionLogNoBalanceEntry {
  transactionId: string;
  endTime: number;
  startTime: number;
  addressStreet: string;
  addressCity: string;
  addressState: string;
  licensePlateNumber: string;
  licensePlateStateCode: string;
  price: number;
  index: number;
  status: string;
}
