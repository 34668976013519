import { gql } from "@apollo/client";
import { WalletConfig, walletConfigFragment } from "common/graphql";

export const GET_WALLET_CONFIG_WITH_PAYEE = gql`
  query GetWalletWithPayee($payeeAccountId: uuid) {
    wallet: get_wallet_with_payee(args: { payee_account_id: $payeeAccountId }) {
      ...walletConfigFragment
    }
  }
  ${walletConfigFragment}
`;

export interface GetWalletConfigWithPayeeData {
  wallet: WalletConfig | null;
}

export interface GetWalletConfigWithPayeeVars {
  payeeAccountId: string;
}
