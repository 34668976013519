import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PAYMENT_METHODS,
  GetPaymentMethodsData,
  InvoiceData,
  PAY_INVOICE,
  PayInvoiceData,
  PayInvoiceVars,
} from "common/graphql";
import React, { useEffect, useMemo, useState } from "react";
import { SNACKBAR_MSGS, useSnackPackContext } from "../context/SnackPack";
import { useIsMobile } from "../hooks";
import { getPaymentMethodDisplay } from "./util";
import ActionButton from "./ActionButton";
import { ButtonProps } from "@mui/material/Button";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const VehicleCardFooterAction: React.FC<
  Pick<ButtonProps, "variant"> & {
    buttonText: string;
    disabled?: boolean;
    invoice?: InvoiceData;
    invoices?: InvoiceData[];
  }
> = ({ invoices, invoice, disabled, buttonText, variant = "outlined" }) => {
  const { data: paymentMethodsData } = useQuery<GetPaymentMethodsData>(GET_PAYMENT_METHODS);
  const [payInvoice] = useMutation<PayInvoiceData, PayInvoiceVars>(PAY_INVOICE);
  const { publishSnackbarMessage } = useSnackPackContext();
  const isMobile = useIsMobile();

  const paymentMethods = useMemo(() => {
    if (paymentMethodsData) {
      return paymentMethodsData.paymentMethods;
    }

    return [];
  }, [paymentMethodsData]);

  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);

  useEffect(() => {
    if (paymentMethods.length === 0) {
      setPaymentMethodId(null);
    } else {
      setPaymentMethodId(paymentMethods[0].id);
    }
  }, [paymentMethods]);

  const handlePayOne = async (invoiceId: string) => {
    if (!paymentMethodId) return;

    try {
      await payInvoice({
        variables: {
          invoiceId,
          paymentMethodId,
        },
      });
      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_SUCCESS);
    } catch (err) {
      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_FAILED);
      console.error("failed to pay invoice", err);
    }
  };

  const onPay = async () => {
    if (!paymentMethodId) return;

    if (invoice?.id) {
      handlePayOne(invoice?.id);
    }

    if (invoices) {
      for (let i = 0; i < invoices.length; i++) {
        await handlePayOne(invoices[i].id);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "flex-start",
        gap: "16px",
        flex: "1 0 0",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Select
          sx={{ width: "100%", height: 40 }}
          variant="outlined"
          value={paymentMethodId}
          autoWidth
          disabled={disabled}
          onChange={(e) => {
            setPaymentMethodId(e.target.value);
          }}
        >
          {paymentMethods.length !== 0 &&
            paymentMethods.map((paymentMethod) => (
              <MenuItem value={paymentMethod.id}>{getPaymentMethodDisplay(paymentMethod)}</MenuItem>
            ))}
        </Select>
      </Box>
      <Box sx={{ width: "100%" }}>
        <ActionButton
          variant={variant}
          size="medium"
          disabled={disabled}
          onClick={onPay}
          sx={{
            fontWeight: invoices && isMobile ? "500 !important" : 600,
            fontSize: { xs: "14px", tiny: "16px" },
            p: invoices && isMobile ? 0.5 : "auto",
          }}
        >
          {buttonText}
        </ActionButton>
      </Box>
    </Box>
  );
};

export const VehicleCardFooter: React.FC<{ invoice: InvoiceData }> = ({ invoice }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "flex-start",
      gap: "10px",
      padding: "16px",
      alignSelf: "stretch",
    }}
  >
    <VehicleCardFooterAction disabled={invoice.amount_paid > 0} invoice={invoice} buttonText="Pay" />
  </Box>
);
