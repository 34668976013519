import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

/**
 * Component that renders a link to the support page.
 */
export const NeedHelp: React.FC = () => {
  return (
    <Box sx={{ width: "100%", typography: "body1", display: "flex", justifyContent: "center" }}>
      <Link to={"/support"} component={RouterLink} target="_blank">
        Need Help?
      </Link>
    </Box>
  );
};

export default NeedHelp;
