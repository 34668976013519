import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormikContext } from "formik";
import { BBMInfoFormSubmitAction, BBMInfoFormValues } from "./BBMInvoiceForm";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import NeedHelp from "@app.automotus.io/components/scenes/SignUp/AddVehicle/NeedHelp";

export const BBMInvoiceFormButtons: React.FC<BBMInvoiceFormButtonsProps> = ({ loading, disabled }) => {
  const { setFieldValue, isSubmitting } = useFormikContext<BBMInfoFormValues>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFieldValue("submitAction", event.currentTarget.id as BBMInfoFormSubmitAction, false);
  };

  const searchButtonProps = {
    disabled,
    fullWidth: true,
    onClick: handleClick,
    type: "submit" as const,
  };

  return loading ? (
    <Skeleton width="100%">
      <LoadingButton>Loading</LoadingButton>
    </Skeleton>
  ) : (
    <Stack spacing={{ xs: 1.5, tiny: 3, lg: 5 }}>
      <LoadingButton
        {...searchButtonProps}
        id={"search" as const}
        sx={{
          textTransform: "none",
          py: { xs: 0.5, tiny: 0.75, lg: 1 },
          fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
          height: { xs: "30px", tiny: "36px", lg: "42px" },
          fontWeight: 500,
        }}
        variant="contained"
        loading={isSubmitting}
      >
        Search
      </LoadingButton>
      <NeedHelp />
    </Stack>
  );
};

export interface BBMInvoiceFormButtonsProps {
  loading: boolean;
  disabled?: boolean;
}

export default BBMInvoiceFormButtons;
