import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

/** Renders a full-screen circular progress indicator when loading, or the children of the component if not */
export const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({ loading = false, children }) => {
  return loading ? (
    <Box sx={{ display: "flex", width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Box>
  ) : (
    <>{children}</>
  );
};

export interface FullScreenLoaderProps {
  /** Defaults to false */
  loading?: boolean;
}

export default FullScreenLoader;
