import { gql } from "@apollo/client";

export const CREATE_APPLE_PAY_PAYMENT_SESSION = gql`
  mutation CreateApplePayMerchantSession($validationUrl: String!, $payeeAccountId: uuid!) {
    create_apple_pay_merchant_session(validation_url: $validationUrl, payee_account_id: $payeeAccountId) {
      session
    }
  }
`;

export interface CreateApplePayPaymentSessionRequestData {
  create_apple_pay_merchant_session: {
    session: ApplePaySession;
  };
}

export interface CreateApplePayMerchantSessionVars {
  payeeAccountId: string;
  validationUrl: string;
}
