import { AcceptJsOpaqueData, useAcceptJs } from "@app.automotus.io/components/hooks";
import {
  DeletePaymentMethodResponseBody,
  PatchPaymentMethodResponseBody,
  PostPaymentMethodResponseBody,
  SetDefaultPaymentMethodResponseBody,
} from "@app.automotus.io/components/hooks/useAtmtsRest";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import {
  CREATE_PAYMENT_METHOD,
  CREATE_WALLET,
  CreatePaymentMethodData,
  CreatePaymentMethodVars,
  CreateWalletData,
  CreateWalletVars,
  DELETE_PAYMENT_METHOD,
  DeletePaymentMethodData,
  DeletePaymentMethodVars,
  GET_PAYMENT_METHODS,
  GET_USER_PROFILE,
  PaymentMethod,
  Wallet,
  UpdatePaymentMethodData,
  UpdatePaymentMethodVars,
  UPDATE_PAYMENT_METHOD,
  SetDefaultPaymentMethodData,
  SetDefaultPaymentMethodVars,
  SET_DEFAULT_PAYMENT_METHOD,
  PayInvoiceData,
  PayInvoiceVars,
  PAY_INVOICE,
  GET_INVOICE_BY_ID,
  GET_INVOICES,
  GET_INVOICE_BY_INDEX,
} from "common/graphql";
import { useActivePayee } from "@app.automotus.io/components/hooks/useActivePayee";

export function usePaymentsHelpers(): UsePaymentsHelpersResultTuple {
  const { error: acceptJsError, loading: acceptJsLoading, dispatchData } = useAcceptJs();
  const [createPaymentMethod, { reset: resetCreatePaymentMethod }] = useMutation<
    CreatePaymentMethodData,
    CreatePaymentMethodVars
  >(CREATE_PAYMENT_METHOD, { refetchQueries: [GET_PAYMENT_METHODS, GET_USER_PROFILE] });
  const [updatePaymentMethod] = useMutation<UpdatePaymentMethodData, UpdatePaymentMethodVars>(UPDATE_PAYMENT_METHOD, {
    refetchQueries: [GET_PAYMENT_METHODS, GET_USER_PROFILE],
  });
  const [deletePaymentMethod] = useMutation<DeletePaymentMethodData, DeletePaymentMethodVars>(DELETE_PAYMENT_METHOD, {
    refetchQueries: [GET_PAYMENT_METHODS, GET_USER_PROFILE],
  });
  const [setDefaultPaymentMethod] = useMutation<SetDefaultPaymentMethodData, SetDefaultPaymentMethodVars>(
    SET_DEFAULT_PAYMENT_METHOD,
    {
      refetchQueries: [GET_PAYMENT_METHODS, GET_USER_PROFILE],
    },
  );
  const [createWallet] = useMutation<CreateWalletData, CreateWalletVars>(CREATE_WALLET);
  const { activePayee } = useActivePayee();
  const [payInvoiceMutation, { reset: resetPayInvoice }] = useMutation<PayInvoiceData, PayInvoiceVars>(PAY_INVOICE, {
    refetchQueries: [GET_INVOICE_BY_ID, GET_INVOICES, GET_INVOICE_BY_INDEX],
  });

  const generateOpaqueData = useCallback(
    (data: { cardNumber: string; expiry: string; cvc: string }) => {
      const [expMonth, expYear] = data.expiry.split(" / ");

      return dispatchData({
        cardData: {
          cardNumber: data.cardNumber,
          cardCode: data.cvc,
          month: expMonth,
          year: `20${expYear}`,
        },
      });
    },
    [dispatchData],
  );

  const addPaymentMethodCard = useCallback(
    async (input: AddPaymentMethodCardInput) => {
      if (!activePayee) {
        console.error("no payee is defined");
        throw new Error("cannot add card without specifying payee to attach to");
      }

      let opaqueData: AcceptJsOpaqueData;
      try {
        opaqueData = await generateOpaqueData(input);
      } catch (err) {
        console.error(err, "failed to encode card data");
        throw err;
      }

      const { data, errors } = await createPaymentMethod({
        variables: {
          payeeAccountId: activePayee.payeeAccountId,
          gatewayAccess: {
            opaqueData,
          },
          billingZip: input.zipCode,
          isDefault: true,
        },
      });

      resetCreatePaymentMethod();

      if (!data) {
        console.error("failed to create payment method", errors);
        throw new Error("failed to create payment method");
      }

      return {
        paymentMethodId: data.createPaymentMethod.paymentMethod.id,
      };
    },
    [generateOpaqueData, createPaymentMethod, activePayee, resetCreatePaymentMethod],
  );

  const payInvoice = useCallback(
    async (input: PayInvoiceInput) => {
      if (!activePayee) {
        console.error("no payee is defined");
        throw new Error("cannot add card without specifying payee to attach to");
      }

      let payInvoiceMutationVars: PayInvoiceVars;
      if ("paymentMethodId" in input) {
        payInvoiceMutationVars = {
          invoiceId: input.invoiceId,
          paymentMethodId: input.paymentMethodId,
        };
      } else {
        let opaqueData: AcceptJsOpaqueData;
        try {
          opaqueData = await generateOpaqueData(input.cardInfo);
        } catch (err) {
          console.error(err, "failed to encode card data");
          throw err;
        }

        payInvoiceMutationVars = {
          invoiceId: input.invoiceId,
          paymentMethodDetails: {
            gateway_access: {
              opaqueData,
            },
            billing_details: {
              zip: input.cardInfo.zipCode,
            },
          },
        };
      }

      const { data, errors } = await payInvoiceMutation({
        variables: payInvoiceMutationVars,
      });

      resetPayInvoice();

      if (!data) {
        console.error("failed to pay invoice", errors);
        throw new Error("failed to pay invoice");
      }

      return data.payInvoice;
    },
    [generateOpaqueData, payInvoiceMutation, activePayee, resetPayInvoice],
  );

  const updatePaymentMethodCard = useCallback(
    async (input: UpdatePaymentMethodCardInput) => {
      if (!activePayee) {
        console.error("no payee is defined");
        throw new Error("cannot update card without specifying payee to attach to");
      }

      const [expMonth, expYear] = input.expiry.split(" / ");
      const { data, errors } = await updatePaymentMethod({
        variables: {
          paymentMethodId: input.paymentMethodId,
          expMonth: +expMonth,
          expYear: +`20${expYear}`,
          zipCode: input.zipCode,
          isDefault: true,
        },
      });

      if (!data) {
        console.error("failed to update payment method", errors);
        throw new Error("failed to update payment method");
      }

      return {
        paymentMethodId: data.updatePaymentMethod.paymentMethod.id,
      };
    },
    [updatePaymentMethod, activePayee],
  );

  const removePaymentMethod = useCallback(
    async ({ paymentMethodId }: DeletePaymentMethodInput) => {
      try {
        const { data, errors } = await deletePaymentMethod({ variables: { paymentMethodId } });
        if (!data) {
          throw new Error(`failed to remove payment method: ${errors}`);
        }

        return { paymentMethodId };
      } catch (err) {
        console.error(err, "failed to remove payment method");
        throw err;
      }
    },
    [deletePaymentMethod],
  );

  const updateDefaultPaymentMethod = useCallback(
    async ({ paymentMethodId }: SetDefaultPaymentMethodInput) => {
      try {
        const { data, errors } = await setDefaultPaymentMethod({ variables: { paymentMethodId } });
        if (!data) {
          throw new Error(`failed to set the default payment method: ${errors}`);
        }

        return { paymentMethodId };
      } catch (err) {
        console.error(err, "failed to set the default payment method");
        throw err;
      }
    },
    [setDefaultPaymentMethod],
  );

  const setupProfile = useCallback(
    async ({ desiredBalance, minimumBalance, cardNumber, expiry, cvc, zipCode = "" }) => {
      if (!activePayee) {
        console.error("no payee is defined");
        throw new Error("cannot add card without specifying payee to attach to");
      }

      let opaqueData: AcceptJsOpaqueData | undefined;
      if (cardNumber && expiry && cvc) {
        try {
          opaqueData = await generateOpaqueData({ cardNumber, expiry, cvc });
        } catch (err) {
          console.error(err, "failed to encode card data");
          throw err;
        }
      }

      if (!opaqueData) {
        console.error("failed to get opaque data");
        throw new Error("failed to get opaque data");
      }

      const { data, errors } = await createWallet({
        variables: { payeeAccountId: activePayee.payeeAccountId, desiredBalance, minimumBalance },
      });
      if (!data) {
        console.error("failed to create wallet", errors);
        throw new Error("failed to create wallet");
      }
      const wallet = data.createWallet.wallet;

      const { data: createPaymentMethodData, errors: createPaymentMethodErrors } = await createPaymentMethod({
        variables: {
          payeeAccountId: activePayee.payeeAccountId,
          gatewayAccess: { opaqueData },
          billingZip: zipCode,
          isDefault: true,
        },
      });
      if (!createPaymentMethodData) {
        console.error("failed to create payment method", createPaymentMethodErrors);
        throw new Error("failed to create payment method");
      }
      const paymentMethod = createPaymentMethodData.createPaymentMethod.paymentMethod;

      return { wallet, paymentMethod };
    },
    [generateOpaqueData, createPaymentMethod, createWallet, activePayee],
  );

  return [
    {
      setupProfile,
      addPaymentMethodCard,
      updatePaymentMethodCard,
      removePaymentMethod,
      updateDefaultPaymentMethod,
      payInvoice,
    },
    { clientLoading: acceptJsLoading, clientError: acceptJsError },
  ];
}

export type UsePaymentsHelpersResultTuple = [PaymentsHelpers, { clientLoading: boolean; clientError: boolean }];

export interface PaymentsHelpers {
  addPaymentMethodCard(input: AddPaymentMethodCardInput): Promise<PostPaymentMethodResponseBody>;
  updatePaymentMethodCard(input: UpdatePaymentMethodCardInput): Promise<PatchPaymentMethodResponseBody>;
  removePaymentMethod(input: DeletePaymentMethodInput): Promise<DeletePaymentMethodResponseBody>;
  updateDefaultPaymentMethod(input: SetDefaultPaymentMethodInput): Promise<SetDefaultPaymentMethodResponseBody>;
  setupProfile(input: SetupProfileInput): Promise<SetupProfileResult>;
  payInvoice(input: PayInvoiceInput): Promise<PayInvoiceData["payInvoice"]>;
}

export interface SetupProfileInput {
  zipCode: string;
  desiredBalance: number;
  minimumBalance: number;
  cardNumber: string;
  expiry: string;
  cvc: string;
}

export interface SetupProfileResult {
  paymentMethod: PaymentMethod;
  wallet: Wallet;
}

export interface AddPaymentMethodCardInput {
  zipCode: string;
  cardNumber: string;
  expiry: string;
  cvc: string;
  makeDefault: boolean;
}

export type PayInvoiceInput = PayInvoiceWithoutSavedPaymentMethodInput | PayInvoiceWithSavedPaymentMethodInput;

export interface PayInvoiceWithSavedPaymentMethodInput {
  invoiceId: string;
  paymentMethodId: string;
}

export interface PayInvoiceWithoutSavedPaymentMethodInput {
  invoiceId: string;
  cardInfo: {
    zipCode: string;
    cardNumber: string;
    expiry: string;
    cvc: string;
  };
}

export interface UpdatePaymentMethodCardInput {
  zipCode: string;
  paymentMethodId: string;
  cardNumber: string;
  expiry: string;
  makeDefault?: boolean;
}

export interface DeletePaymentMethodInput {
  paymentMethodId: string;
}

export interface SetDefaultPaymentMethodInput {
  paymentMethodId: string;
}

export default usePaymentsHelpers;
