import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import { useLocation } from "react-router-dom";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { formatCurrency } from "common/format";
import SelectAmount, { LastTouchedStatus } from "../CurbPassSetup/SelectAmount";
import SeeParkingRates from "../CurbPassSetup/SeeParkingRates";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_WALLET_WITH_PAYEE,
  CREATE_WALLET,
  CreateWalletData,
  CreateWalletVars,
  GET_USER_PROFILE,
  GET_INVOICE_BY_ID,
  GetInvoiceByIdData,
  GetInvoiceByIdVars,
} from "common/graphql";
import { useActivePayee } from "../../../hooks/useActivePayee";
import { OnboardingStatus } from "@app.automotus.io/components/common";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";
import { CurbPassPaymentMethodSetup } from "@app.automotus.io/components/scenes/SignUp/CurbPassSetup/CurbPassPaymentMethodSetup";
import { SignUpTermsOfServiceCheckbox } from "@app.automotus.io/components/SignUp/SignUpTermsOfServiceCheckbox";

export const DEFAULT_AMOUNT = 1500;
const MINIMUM_AMOUNT = 500;

export const InvoiceCurbPassSetup: React.FC = () => {
  const paymentFormRef = useRef<HTMLDivElement>(null);
  const { userProfile } = useUserProfile();
  const [selectedAmount, setAmount] = useState<number>(0);
  const [isFreeTrialDlgShown, setIsFreeTrialDlgShown] = useState<boolean>(false);
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState<boolean>(false);
  const [isValidAmount, setIsValidAmount] = useState<boolean>(true);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));
  const { activePayee } = useActivePayee();
  const [createWallet, { loading: createWalletLoading, reset: resetCreateWallet }] = useMutation<
    CreateWalletData,
    CreateWalletVars
  >(CREATE_WALLET, { refetchQueries: [GET_WALLET_WITH_PAYEE, GET_USER_PROFILE] });
  const location = useLocation();
  const { invoiceId } = (location.state || {}) as { invoiceId: string };
  const isPaymentEnabled = userProfile?.tosAcceptedAt && isValidAmount;

  const handleClosePaymentForm = () => {
    setIsPaymentFormVisible(false);
  };

  const updateSettings = useCallback(async () => {
    const isFreeTrial = selectedAmount === 0;
    const desiredBalance = isFreeTrial ? 1500 : selectedAmount;
    const minimumBalance = desiredBalance * 0.25;

    try {
      await createWallet({
        variables: {
          payeeAccountId: activePayee?.payeeAccountId || "",
          desiredBalance,
          minimumBalance,
          isFreeTrial,
        },
      });
      resetCreateWallet();
    } catch (err) {
      console.error(err);
    }
  }, [createWallet, resetCreateWallet, activePayee, selectedAmount]);

  useEffect(() => {
    if (isPaymentFormVisible) {
      (async () => {
        await updateSettings();
      })();
    }
  }, [selectedAmount, isPaymentFormVisible, updateSettings]);

  const {
    data: invoiceData,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const refetchOnError = () => {
    if (invoiceError) {
      refetchInvoice();
    }
  };

  if (invoiceId && invoiceError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const addPayment = async () => {
    hideFreeTrialDisclaimer();
    await updateSettings();
    setIsPaymentFormVisible(true);
    setTimeout(() => {
      paymentFormRef?.current?.scrollIntoView();
    }, 0);
  };

  const handleChangeAmount = (value: number, type: number) => {
    setAmount(value);
    setIsValidAmount(type === LastTouchedStatus.Option || value >= MINIMUM_AMOUNT);
  };

  const onboardingStatusValue = userProfile?.onboardingStatus ?? "profile";
  const isEditable = OnboardingStatus.isBefore(onboardingStatusValue, OnboardingStatus.PAYMENT);

  const showFreeTrialDisclaimer = () => {
    setIsFreeTrialDlgShown(true);
  };

  const hideFreeTrialDisclaimer = () => {
    setIsFreeTrialDlgShown(false);
  };

  const handleClickAddPaymentMethod = () => {
    const isFreeTrial = selectedAmount === 0;
    if (isFreeTrial) {
      showFreeTrialDisclaimer();
    } else {
      addPayment();
    }
  };

  return (
    <Box sx={{ mt: isMobile ? 1.5 : 3, px: isMobile ? 0 : 2 }}>
      <Typography sx={{ mb: 1.5 }} variant={isDesktop ? "h4" : isSmall ? "h6" : "h5"}>
        <span style={{ color: theme.palette.primary.main }}>CurbPass</span> Payment
      </Typography>
      <Box display={"flex"} flexDirection={"row"} sx={{ mb: 1 }} justifyContent="space-between">
        <Typography variant={"body1"}>Total Invoice Charge</Typography>
        {invoiceData && invoiceData.invoice && (
          <Typography variant={"body1"}>${formatCurrency(invoiceData.invoice.transaction_amount_due)}</Typography>
        )}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{ mb: 1, color: "rgba(0, 0, 0, 0.38)", textDecoration: "line-through" }}
        justifyContent="space-between"
      >
        <Typography variant={"body1"}>Administrative Fee</Typography>
        {invoiceData && invoiceData.invoice && (
          <Typography variant={"body1"}>${formatCurrency(invoiceData.invoice.admin_fee_due)}</Typography>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"row"} sx={{ mb: 2 }} justifyContent="space-between">
        <Typography variant={"body1"}>CurbPass Balance</Typography>
        <Typography variant={"body1"}>
          {selectedAmount === 0 ? "Free Trial" : `$${formatCurrency(selectedAmount)}`}
        </Typography>
      </Box>
      <Dialog
        open={isFreeTrialDlgShown}
        onBackdropClick={hideFreeTrialDisclaimer}
        PaperProps={{
          sx: {
            borderRadius: 1.5,
            minWidth: { lg: 572 },
            px: 3,
            py: 2,
            display: "flex",
          },
        }}
      >
        <Box mb={3}>
          <Typography variant="h7" sx={{ fontWeight: 500 }}>
            Terms of Service
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1">
            By selecting 'try for free' your CurbPass won't be loaded until after your next park.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            onClick={hideFreeTrialDisclaimer}
            sx={{ mr: "19px", textTransform: "none", fontWeight: 600, fontSize: "18px" }}
            variant="text"
          >
            Select Amount
          </LoadingButton>
          <LoadingButton
            onClick={addPayment}
            sx={{ textTransform: "none", fontWeight: 600, fontSize: "18px" }}
            variant="text"
          >
            Agree
          </LoadingButton>
        </Box>
      </Dialog>
      <fieldset disabled={!isEditable} style={{ border: 0, padding: 0, margin: 0 }}>
        <Box borderRadius={2} sx={{ p: 1.5, backgroundColor: `rgba(13, 85, 191, 0.08)` }}>
          <Typography variant={"h7"} sx={{ fontWeight: 500 }}>
            Fund your CurbPass
          </Typography>
          <Typography variant={"body1"} sx={{ mb: 2 }}>
            Add funds now to save on fees later.
          </Typography>
          <SelectAmount isBillByMail disabled={!isEditable} amount={selectedAmount} onChange={handleChangeAmount} />
        </Box>
        <SeeParkingRates isBillByMail />
        <Divider sx={{ mt: 2.5, mb: 1 }} />
        <Box display={"flex"} flexDirection={"row"} sx={{ mb: 3 }} justifyContent="space-between">
          <Typography variant={"h7"}>Total Due</Typography>
          {invoiceData && invoiceData.invoice && (
            <Typography variant={"body1"}>
              $
              {formatCurrency(
                selectedAmount +
                  invoiceData.invoice.transaction_amount_due +
                  invoiceData.invoice.admin_fee_due -
                  invoiceData.invoice.admin_fee_amount_waived,
              )}
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box>
            <Grid container direction="row" alignItems="left" rowSpacing={1} sx={{ pl: 1 }}>
              <Grid item xs={12}>
                <SignUpTermsOfServiceCheckbox
                  linkTo={isMobile ? "/signup/payment" : "/signup/terms-of-service"}
                  linkState={{ sourceRoute: "/signup/payment", invoiceId }}
                />
              </Grid>
            </Grid>
          </Box>
          {!isMobile && isPaymentFormVisible && <Divider sx={{ mt: 3 }} />}
          {(isMobile || !isPaymentFormVisible) && (
            <Grid container direction="row" sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <LoadingButton
                  id="add-payment-method"
                  disabled={!isPaymentEnabled}
                  onClick={handleClickAddPaymentMethod}
                  variant="contained"
                  fullWidth
                  loading={createWalletLoading}
                  sx={{
                    textTransform: "unset",
                    py: isMobile ? 0.75 : 1,
                    fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                    fontWeight: 400,
                  }}
                >
                  Add Payment Method
                </LoadingButton>
              </Grid>
            </Grid>
          )}
          <CurbPassPaymentMethodSetup
            invoiceId={invoiceId}
            isOpen={isPaymentFormVisible}
            paymentAmount={
              invoiceData?.invoice
                ? selectedAmount +
                  invoiceData.invoice.transaction_amount_due +
                  invoiceData.invoice.admin_fee_due -
                  invoiceData.invoice.admin_fee_amount_waived
                : selectedAmount
            }
            onClose={handleClosePaymentForm}
            disabled={!isPaymentEnabled}
            ref={paymentFormRef}
          />
        </Box>
      </fieldset>
    </Box>
  );
};

export default InvoiceCurbPassSetup;
