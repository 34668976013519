import React from "react";

export interface EnvContextValue {
  REACT_APP_API_ENDPOINT?: string;
  [k: string]: string | undefined;
}

const EnvContext = React.createContext<EnvContextValue>(process.env);

export const EnvProvider: React.FC<EnvProviderProps> = ({ value = process.env, children }) => (
  <EnvContext.Provider value={value}>{children}</EnvContext.Provider>
);

export interface EnvProviderProps {
  value?: EnvContextValue;
}

export default EnvContext;
