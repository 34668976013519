import React from "react";
import BillByMailInvoiceActivation from "@app.automotus.io/components/BillByMailInvoiceActivation/BillByMailInvoiceActivation";
import { useParams, useNavigate } from "react-router-dom";

export const BillByMailInvoiceActivationPage: React.FC = () => {
  const { invoiceId = "" } = useParams<{ invoiceId: string }>();
  const navigate = useNavigate();

  const onRegisterAndPay = () => {
    navigate(`/paybill/${invoiceId}/signup`);
  };

  const onPayAsGuest = () => {
    navigate(`/paybill/${invoiceId}/payments`);
  };

  return (
    <BillByMailInvoiceActivation
      invoiceId={invoiceId}
      onRegisterAndPay={onRegisterAndPay}
      onPayAsGuest={onPayAsGuest}
    />
  );
};

export default BillByMailInvoiceActivationPage;
