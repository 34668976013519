import { useActivePayee } from "@app.automotus.io/components/hooks/useActivePayee";
import { GET_WALLET_WITH_PAYEE, GetWalletWithPayeeData, GetWalletWithPayeeVars, Wallet } from "common/graphql";
import { useQuery } from "@apollo/client";
import { CITY_OPTIONS } from "common/constants";

/**
 * Returns a result describing the wallet for the currently selected payee
 */
export function useActiveWallet(): UseActiveWalletResult {
  const { activePayee, loading: activePayeeLoading, error: activePayeeError } = useActivePayee();
  const {
    data: walletData,
    loading: walletLoading,
    error: walletError,
  } = useQuery<GetWalletWithPayeeData, GetWalletWithPayeeVars>(GET_WALLET_WITH_PAYEE, {
    variables: {
      payeeAccountId: activePayee?.payeeAccountId || CITY_OPTIONS[0].payeeAccountId,
    },
  });

  return {
    loading: activePayeeLoading || walletLoading,
    error: activePayeeError || walletError,
    wallet: walletData?.wallet || undefined,
  };
}

export interface UseActiveWalletResult {
  loading: boolean;
  error?: Error;
  wallet?: Wallet;
}

export default useActiveWallet;
