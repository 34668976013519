import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PAYEE_ZONE_REGULATIONS, GetPayeeZoneRegulationsData, GetPayeeZoneRegulationsVars } from "common/graphql";
import { ZoneRegulationAccordion } from "@app.automotus.io/components/ZoneRegulationAccordion";
import _times from "lodash/times";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";

/**
 * Component that loads and displays zone regulations
 */
export const ZoneRegulations: React.FC<ZoneRegulationsProps> = ({ payeeAccountId }) => {
  const [expandedZoneId, setExpandedZoneId] = useState<string | null>();

  const handleChangeAccordion = (zoneId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedZoneId(zoneId);
    } else {
      setExpandedZoneId(null);
    }
  };

  const { data, loading, error, refetch } = useQuery<GetPayeeZoneRegulationsData, GetPayeeZoneRegulationsVars>(
    GET_PAYEE_ZONE_REGULATIONS,
    {
      variables: {
        payeeAccountId,
      },
    },
  );

  if (loading) {
    return (
      <>
        {_times(4, (i) => (
          <ZoneRegulationAccordion key={i} loading={true} />
        ))}
      </>
    );
  }

  if (error || !data) {
    if (error) {
      console.error(error);
    }
    return <LocalLevelError onTryAgain={refetch} />;
  }

  return (
    <>
      {data.zones.map(({ id, name, zonePolicies }) => {
        const rateApplicationType = zonePolicies[0].policy.policyRules[0].rule.rateApplicationType;
        return (
          <ZoneRegulationAccordion
            key={id}
            loading={false}
            address={name}
            rateApplicationType={rateApplicationType}
            operationPolicy={zonePolicies[0].policy.policyTimeSpans[0].timeSpan}
            rates={zonePolicies[0].policy.policyRules[0].rule.rates}
            expanded={id === expandedZoneId}
            onChange={handleChangeAccordion(id)}
          />
        );
      })}
    </>
  );
};

/**
 * Props passed to initialize the {@link ZoneRegulations} component
 */
export interface ZoneRegulationsProps {
  payeeAccountId: string;
}

export default ZoneRegulations;
