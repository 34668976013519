import React from "react";
import LicensePlateState from "../common/LicensePlateState";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";

const OPTIONS = LicensePlateState.All.map((lps) => lps.abbreviation);

export const StateDropdown: React.FC<StateDropdownProps> = ({
  label,
  error,
  helperText,
  required,
  name,
  placeholder,
  ...autocompleteProps
}: StateDropdownProps) => {
  return (
    <Autocomplete
      {...autocompleteProps}
      options={OPTIONS}
      getOptionLabel={(opt) => LicensePlateState.FromAbbreviation(opt).fullName}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          required={required}
        />
      )}
    />
  );
};

export interface StateDropdownProps
  extends Omit<
    AutocompleteProps<LicensePlateState["abbreviation"], false, boolean | undefined, false>,
    "options" | "renderInput"
  > {
  name?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  placeholder?: string;
}

export default StateDropdown;
