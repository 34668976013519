import React from "react";
import { useLocation } from "react-router-dom";
import { ProfileInformationFields } from "@app.automotus.io/components/forms/ProfileInformationFields";
import { ProfileSetup } from "@app.automotus.io/components/scenes/SignUp/ProfileSetup";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { useReactiveVar } from "@apollo/client";
import { profileInfoVar } from "./helpers/reactiveVar";

export const SignupProfile: React.FC = () => {
  const { userProfile } = useUserProfile();
  const location = useLocation();
  const profileInfo = useReactiveVar(profileInfoVar);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialValuesFromSearchParams = (location.state || {}) as any;

  const initialValues = {
    firstName:
      profileInfo.firstName !== null
        ? profileInfo.firstName
        : initialValuesFromSearchParams.firstName ||
          userProfile?.givenName ||
          ProfileInformationFields.defaultInitialValues.firstName,
    lastName:
      profileInfo.lastName !== null
        ? profileInfo.lastName
        : initialValuesFromSearchParams.lastName ||
          userProfile?.familyName ||
          ProfileInformationFields.defaultInitialValues.lastName,
    email:
      profileInfo.email !== null
        ? profileInfo.email
        : initialValuesFromSearchParams.email ||
          userProfile?.email ||
          ProfileInformationFields.defaultInitialValues.email,
    phoneNumber: profileInfo.phoneNumber
      ? profileInfo.phoneNumber
      : initialValuesFromSearchParams.phoneNumber ||
        userProfile?.phoneNumber ||
        ProfileInformationFields.defaultInitialValues.phoneNumber,
    companies:
      profileInfo.companies !== null
        ? profileInfo.companies
        : initialValuesFromSearchParams.companies ||
          userProfile?.selectedCompanies ||
          ProfileInformationFields.defaultInitialValues.companies,
    isAgreedToEmailUpdates:
      profileInfo.isAgreedToEmailUpdates !== null
        ? profileInfo.isAgreedToEmailUpdates
        : initialValuesFromSearchParams.isAgreedToEmailUpdates ?? userProfile?.preferences?.emailUpdatesEnabled ?? true,
    isAgreedToTermsOfService:
      profileInfo.isAgreedToTermsOfService !== null
        ? profileInfo.isAgreedToTermsOfService
        : initialValuesFromSearchParams.isAgreedToTermsOfService ?? !!userProfile?.tosAcceptedAt,
  };

  return <ProfileSetup initialValues={initialValues} />;
};

export default SignupProfile;
