import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

/** A wrapper component that displays a drawer menu on mobile view. */
export const DrawerMenu: React.FC<DrawerMenuProps> = ({
  children,
  onClose = () => undefined,
  open = true,
  submenu = false,
}) => {
  const theme = useTheme();
  return (
    <Dialog
      BackdropProps={{ invisible: true }}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        left: "30%",
      }}
      fullScreen
    >
      <Box
        sx={{
          py: 1,
          backgroundColor: submenu ? theme.palette.primary.white : theme.palette.primary.main,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        onClick={() => {
          !submenu && onClose();
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
};

/** Props passed to initialize an {@link DrawerMenu} component */
export interface DrawerMenuProps {
  /** handler fired when user requests to close modal */
  onClose?: () => void;
  /** open state */
  open?: boolean;
  submenu?: boolean;
}

export default DrawerMenu;
