import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { CreditCardIcon } from "@app.automotus.io/components/icons/CreditCardIcon";
import { creditCardBrandValueFromString } from "common/adaptors";
import LoadingButton from "@mui/lab/LoadingButton";
import { CustomerPaymentProfile } from "api/adaptors/AuthorizeNet";
import { Breakpoint } from "@mui/material/styles";

export const RemovePaymentMethodForm: React.FC<RemovePaymentMethodFormProps> = ({
  loading = false,
  submitting = false,
  onClose = () => undefined,
  onSubmit = () => undefined,
  paymentMethod,
}) => {
  const { cardType = "unknown", cardLast4 = "0000" } = paymentMethod?.payment?.creditCard || {};

  const handleSubmit = () => {
    if (paymentMethod) {
      onSubmit({ action: "remove", paymentMethodId: paymentMethod?.customerPaymentProfileId || "" });
    }
  };

  return (
    <Box>
      <Typography variant={"body1"} sx={{ mb: 3 }}>
        Please confirm that you want to remove the following payment method:
      </Typography>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        columnSpacing={2}
        rowSpacing={2}
      >
        <CardField loading={loading} title={"Card Number"} xs={12}>
          <Grid item xs={10}>
            <LoadingSkeleton loading={loading}>
              <Typography
                sx={{ color: submitting ? "grey.400" : undefined }}
                variant={"body1"}
              >{`●●●● ●●●● ●●●● ${cardLast4}`}</Typography>
            </LoadingSkeleton>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={2}>
            <LoadingSkeleton loading={loading} width={30}>
              <Typography variant={"body1"} sx={{ color: submitting ? "grey.400" : undefined }}>
                <CreditCardIcon size={"xl" as unknown as "lg"} brand={creditCardBrandValueFromString(cardType)} />
              </Typography>
            </LoadingSkeleton>
          </Grid>
        </CardField>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant={"contained"}
            disabled={loading}
            loading={submitting}
            fullWidth
            sx={{ mb: 2, mt: 2 }}
            onClick={handleSubmit}
          >
            Remove Payment Method
          </LoadingButton>
          <LoadingButton variant="outlined" disabled={loading || submitting} fullWidth onClick={onClose}>
            Cancel
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export interface RemovePaymentMethodFormProps {
  loading?: boolean;
  submitting?: boolean;
  onClose?: () => void;
  paymentMethod?: CustomerPaymentProfile;
  onSubmit?: RemovePaymentMethodFormSubmitFunc;
}

export type RemovePaymentMethodFormSubmitFunc = (params: {
  action: "remove";
  paymentMethodId: string;
}) => Promise<void>;

const CardField: React.FC<CardFieldProps> = ({ loading, title, children, ...breakpoints }) => (
  <Grid item container {...breakpoints}>
    <Box sx={{ mb: 1 }}>
      <LoadingSkeleton loading={loading} height={22} width={`${title.length}ch`}>
        <Typography variant={"body3"}>{title}</Typography>
      </LoadingSkeleton>
    </Box>
    <Grid
      item
      container
      justifyContent={"space-between"}
      flexDirection={"row"}
      alignItems={"center"}
      sx={{ borderRadius: 2, border: 1, borderColor: "grey.400", py: 1.5, px: 2, height: 50 }}
    >
      {children}
    </Grid>
  </Grid>
);

interface CardFieldProps extends Partial<Record<Breakpoint, number>> {
  title: string;
  loading?: boolean;
}

export default RemovePaymentMethodForm;
