import { gql } from "@apollo/client";
import { userProfileFragment } from "common/graphql";
import { UserProfile } from "common/graphql/fragments";

export const ACCEPT_TERMS_OF_SERVICE = gql`
  mutation AcceptTermsOfService($userId: uuid!, $tosAcceptedVersion: String!) {
    updateTermsOfService: update_auth_user_profile_by_pk(
      pk_columns: { id: $userId }
      _set: { tos_accepted_version: $tosAcceptedVersion, tos_accepted_at: "now()" }
    ) {
      ...userProfileFragment
    }
  }
  ${userProfileFragment}
`;

export const DECLINE_TERMS_OF_SERVICE = gql`
  mutation DeclineTermsOfService($userId: uuid!) {
    updateTermsOfService: update_auth_user_profile_by_pk(
      pk_columns: { id: $userId }
      _set: { tos_accepted_version: null, tos_accepted_at: null }
    ) {
      ...userProfileFragment
    }
  }
  ${userProfileFragment}
`;

export interface UpdateTermsOfServiceData {
  updateTermsOfService: UserProfile;
}

export interface UpdateTermsOfServiceAcceptVars {
  userId: string;
  tosAcceptedVersion: string;
}

export interface UpdateTermsOfServiceDeclineVars {
  userId: string;
}
