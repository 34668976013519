import React from "react";
import { TextField, TextFieldProps } from "formik-mui";
import { useField, useFormikContext } from "formik";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as yup from "yup";

export const ZipCodeField = Object.assign(
  ({
    name = "zipCode",
    id = "zip-code",
    label = "Zip Code",
    placeholder = "Zip Code",
    required = true,
    fullWidth = true,
    ...rest
  }: ZipCodeFieldProps) => {
    const form = useFormikContext();
    const [field, meta, { setValue }] = useField(name);

    const handleChange = React.useCallback(
      (event) => {
        const zipCode = event.target.value.substr(0, 5).replace(/[^0-9]/g, "");
        setValue(zipCode);
      },
      [setValue],
    );

    return (
      <Box>
        <Typography variant="body3" sx={{ fontWeight: 500 }}>
          {label} *
        </Typography>
        <TextField
          InputLabelProps={{ shrink: false }}
          {...rest}
          {...{ id, placeholder, required, fullWidth }}
          label=""
          meta={meta}
          form={form}
          field={{
            ...field,
            onChange: handleChange,
          }}
          sx={{ mt: 1 }}
        />
      </Box>
    );
  },
  {
    defaultInitialValue: "",
    validationSchema: yup
      .string()
      .required("Valid zip code required")
      .max(5, "5 numbers maximum")
      .min(5, "5 numbers minimum"),
  },
);

export interface ZipCodeFieldProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  name?: string;
}

export default ZipCodeField;
