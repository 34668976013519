import { gql } from "@apollo/client";

export const eventPolicyComplianceDetailFragment = gql`
  fragment eventPolicyComplianceDetailFragment on event_policy_compliance_detail {
    id
    rateStartAt: rate_start_at
    rateEndAt: rate_end_at
    rate
    rateUnit: rate_unit
    price
    currency
    violation
  }
`;

export interface EventPolicyComplianceDetail {
  id: string;
  rateStartAt: string;
  rateEndAt: string;
  rate: number;
  rateUnit: string;
  price: number;
  currency: string;
  violation: boolean;
}
