import { gql } from "@apollo/client";

/** Mutation that creates a stripe checkout session where a user can set up his digital wallet */
export const CREATE_SETUP_DIGITAL_WALLET_CHECKOUT_SESSION = gql`
  mutation CreateSetupDigitalWalletCheckoutSession(
    $cancelUrl: String!
    $successUrl: String!
    $currency: String!
    $amount: Int!
  ) {
    createStripeCheckoutSession: create_stripe_checkout_session(
      args: {
        cancel_url: $cancelUrl
        mode: payment
        success_url: $successUrl
        line_items: [
          { price_data: { currency: $currency, product_data: { name: "Wallet Load" } }, unit_amount: $amount }
        ]
        submit_type: pay
      }
    ) {
      url
    }
  }
`;

/** Variables type for the {@link CREATE_SETUP_DIGITAL_WALLET_CHECKOUT_SESSION} mutation */
export interface CreateSetupDigitalWalletCheckoutSessionVars {
  /** URL that the user is redirected to upon canceling the checkout session */
  cancelUrl: string;
  /** URL that the user is redirected to upon successfully completing the checkout session */
  successUrl: string;
  /** Currency that the user is entering */
  currency: string;
  /** Amount (in cents or other smallest denomination) of currency being loaded into the user's wallet */
  amount: number;
  /** Zip Code */
  zipCode: string;
}

/** Type of data returned by the {@link CREATE_SETUP_DIGITAL_WALLET_CHECKOUT_SESSION} mutation */
export interface CreateSetupDigitalWalletCheckoutSessionData {
  createStripeCheckoutSession: {
    /** URL where the user can intiate the checkout session */
    url: string;
  };
}

export default CREATE_SETUP_DIGITAL_WALLET_CHECKOUT_SESSION;
