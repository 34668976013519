import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { HASURA_CLAIMS_NAMESPACE, USER_ID_CLAIM, UserClaims } from "common/models";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_PROFILE, GetUserProfileData, GetUserProfileVars } from "common/graphql";
import { redirectToApplicationByRoleType } from "@app.automotus.io/components/auth";

/**
 * This route authorizes a user who logged in with passwordless via the universal login flow, ensuring that the Auth0
 * context contains the user's authentication information.
 */
export const Authorize: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, user: userClaims } = useAuth0<UserClaims>();
  const [getUserProfile] = useLazyQuery<GetUserProfileData, GetUserProfileVars>(GET_USER_PROFILE);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const { data: userProfileData } = await getUserProfile({
          variables: {
            userId: userClaims?.[HASURA_CLAIMS_NAMESPACE]?.[USER_ID_CLAIM] || "",
          },
        });

        redirectToApplicationByRoleType(userProfileData?.userProfile);

        navigate("/");
        return;
      }

      const claimInvoiceId = sessionStorage.getItem("claimInvoiceId");

      await loginWithRedirect({
        redirectUri: claimInvoiceId ? `${window.location.origin}/paybill/claims` : `${window.location.origin}`,
      });
    })();
  }, [isAuthenticated, loginWithRedirect, navigate, userClaims, getUserProfile]);

  return (
    <Box sx={{ display: "flex", width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Box>
  );
};

export default Authorize;
