import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { formatCurrency, formatSessionDateRange } from "common/format";
import LoadingSkeleton from "../../LoadingSkeleton";
import { TransactionPaymentData } from "../TransactionPayments";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { convertDateToString, convertTimeToString } from "common/format";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { CustomPagination } from "@app.automotus.io/components/CustomPagination";

const TransactionIdSubTitle = (data: TransactionPaymentData) => {
  const isViolation = data.violation;
  const isRefund = false;
  const isUnderReview = data.status === "under_review";
  if (isViolation) {
    return (
      <Tooltip title="Violation Type">
        <Typography variant="body4" sx={{ fontSize: "12px", color: (theme) => theme.palette.error.main }}>
          Violation
        </Typography>
      </Tooltip>
    );
  }
  if (isRefund) {
    return (
      <>
        <Typography variant="body4" sx={{ fontSize: "12px", color: "#3A833C" }}>
          Refund:{" "}
        </Typography>
        {/* TODO: Implement Refund Link */}
        <Typography variant="body4" sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}>
          AB02C1252
        </Typography>
      </>
    );
  }
  if (isUnderReview) {
    return (
      <Typography variant="body4" sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}>
        Under Review
      </Typography>
    );
  }
  return null;
};

const columns: GridColumns = [
  {
    field: "date",
    headerName: "Date",
    sortable: true,
    width: 103,
    renderCell: ({ row }) => (
      <Typography variant="body1">
        {row.gatewayTransactionType ? row.statement : convertDateToString(row.date)}
      </Typography>
    ),
    colSpan: ({ row }) => {
      const isStatementRow = row?.gatewayTransactionType;
      if (isStatementRow) {
        return 8;
      }
      return undefined;
    },
    cellClassName: (params) => (params.row?.gatewayTransactionType ? "StatementRow" : ""),
  },
  {
    field: "index",
    headerName: "Transaction ID",
    sortable: true,
    width: 163,
    renderCell: (params) => (
      <div>
        <div>{params.row.index.toString().padStart(6, "0")}</div>
        <div>{TransactionIdSubTitle(params.row)}</div>
      </div>
    ),
  },
  {
    field: "endTime",
    headerName: "Park Period",
    sortable: true,
    width: 145,
    renderCell: (params) => (
      <div>
        <div>{formatSessionDateRange(params.row.startTime, params.row.endTime)}</div>
        <div>
          <Typography variant="body4">
            {convertTimeToString(params.row.startTime)} - {convertTimeToString(params.row.endTime)}
          </Typography>
        </div>
      </div>
    ),
    // sortComparator: (v1, v2) => v1.getHours() * 24 + v1.getMinutes() - (v2.getHours() * 24 + v2.getMinutes()),
  },
  {
    field: "address",
    headerName: "Address",
    sortable: true,
    width: 145,
  },
  {
    field: "vehicle",
    headerName: "Vehicle",
    sortable: true,
    width: 116,
  },
  {
    field: "amount",
    headerName: "Amount",
    sortable: true,
    width: 120,
    renderCell: (params) => (
      <Typography variant="body1" color={params.row.violation ? "error.main" : "text.primary"}>
        {params.row.violation
          ? "Violation"
          : `${params.row.amount > 0 ? "-" : ""}$${(params.row.amount / 100).toFixed(2)}`}
      </Typography>
    ),
    valueGetter: (params) => (params.row.violation ? 0 : +params.row.amount),
    sortComparator: (v1, v2) => {
      return v1 - v2;
    },
  },
  {
    field: "balance",
    headerName: "Available",
    sortable: false,
    width: 112,
    renderCell: (params) => (
      <Typography variant="body1" color={params.row.balance <= 0 ? "error.main" : "text.primary"}>
        {params.row.balance >= 0
          ? `$${(params.row.balance / 100).toFixed(2)}`
          : `-$${(-params.row.balance / 100).toFixed(2)}`}
      </Typography>
    ),
  },
  {
    field: "action",
    headerName: "",
    sortable: false,
    width: 70,
    renderCell: () => (
      <IconButton
        onClick={() => {
          console.log("Arrow clicked");
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    ),
  },
];

export const DepositLogTableView: React.FC<DepositLogTableViewProps> = ({
  loading = false,
  rows = [],
  // onClickFilters = () => undefined,
  // onClickExport = () => undefined,
}) => {
  const newRows = rows.map((row) => {
    if (row.gatewayTransactionType) {
      const { gatewayTransactionType } = row;
      if (gatewayTransactionType === "initial_wallet_load") {
        return { ...row, statement: `You added ${formatCurrency(row.balance || 0)} to your digital wallet.` };
      } else {
        return {
          ...row,
          statement: `Your payment method was charged for ${formatCurrency(
            Math.abs(row.amount),
          )} to refill your digital wallet as your
        balance was low.`,
        };
      }
    }
    return row;
  });
  console.log({ newRows });
  return (
    <LoadingSkeleton loading={loading} width="100%">
      <Box
        sx={{
          height: 600,
          width: "100%",
          "& .StatementRow": {
            backgroundColor: "rgba(13, 85, 191, 0.08)",
            color: "rgba(0, 0, 0, 0.6)",
            justifyContent: "center !important",
            height: "28px !important",
            minHeight: "28px !important",
          },
          "& .StatementRow > p": {
            fontSize: "12px",
          },
          "& .StatementRowContainer": {
            height: "28px !important",
            minHeight: "28px !important",
          },
        }}
      >
        <Paper sx={{ height: "100%" }}>
          <DataGridPro
            columns={columns}
            getRowClassName={(params) => (params.row.gatewayTransactionType ? "StatementRowContainer" : "")}
            rows={newRows}
            pagination
            pageSize={30}
            rowsPerPageOptions={[30]}
            loading={loading}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Typography variant="h6" m={1.5}>
                  There are currently no transactions to report
                </Typography>
              ),
              Pagination: CustomPagination,
            }}
          />
        </Paper>
      </Box>
    </LoadingSkeleton>
  );
};

export interface DepositLogTableViewProps {
  loading?: boolean;
  rows?: TransactionPaymentData[];
  onClickFilters?: () => void;
  onClickExport?: () => void;
}

export default DepositLogTableView;
