import { gql } from "@apollo/client";
import { CompanyValue, OnboardingStatusValue } from "@app.automotus.io/components/common";
import { UserPreferences, userPreferencesFragment } from "./userPreferencesFragment";
import accountFragment, { Account } from "./accountFragment";

/** GraphQL fragment representing user profile fields */
export const userProfileFragment = gql`
  fragment userProfileFragment on auth_user_profile {
    id
    account {
      ...accountFragment
    }
    accountId: account_id
    fullName: full_name
    givenName: given_name
    familyName: family_name
    email
    selectedCompanies: selected_companies
    tosAcceptedVersion: tos_accepted_version
    tosAcceptedAt: tos_accepted_at
    phoneNumber: phone_number
    onboardingStatus: onboarding_status
    identities {
      subjectIdentifier: subject_identifier
      phoneNumber: phone_number
    }
    preferences: payment_product_preferences {
      ...userPreferencesFragment
    }
  }
  ${accountFragment}
  ${userPreferencesFragment}
`;

/**
 * User profile data. Equivalent representation to the contents of {@link userProfileFragment}
 */
export interface UserProfile {
  /** Unique ID of the user profile */
  id: string;
  /** Account that the user belongs to */
  account: Account;
  /** Full name of the user. Null if the user has not yet set his given and family names */
  fullName: string | null;
  /** Given (first) name of the user. Null if the user has not yet set her given name */
  givenName: string | null;
  /** Family (last) name of the user. Null if the user has not yet set his family name */
  familyName: string | null;
  /** Email address of the user. Can be null if the user has not yet set her email */
  email: string | null;
  /** Company that the user drives for. Null if the user has not yet set his company */
  selectedCompanies: CompanyValue[] | null;
  /** Version of terms of service that the user has accepted. Null if the user has not yet accepted the ToS */
  tosAcceptedVersion: string | null;
  /** Date-time when the user accepted the terms of service */
  tosAcceptedAt: Date | null;
  /** User's phone number. Can only be null for payee type users */
  phoneNumber: string | null;
  /** User preferences */
  preferences: UserPreferences | null;
  onboardingStatus: OnboardingStatusValue;
  identities: {
    subjectIdentifier: string | null;
    phoneNumber: string | null;
  }[];
}
