import { gql } from "@apollo/client";

export const GET_TRANSACTION_IMAGES = gql`
  mutation GetTransactionImages($transactionId: uuid!) {
    transactionImages: get_transaction_image_urls(transaction_id: $transactionId) {
      licensePlateImageUrl: license_plate_image_url
      sessionStartImageUrl: session_start_image_url
      sessionEndImageUrl: session_end_image_url
    }
  }
`;

export interface GetTransactionImagesVars {
  transactionId: string;
}

export interface GetTransactionImagesData {
  transactionImages: TransactionImages;
}

export interface TransactionImages {
  licensePlateImageUrl: string;
  sessionStartImageUrl: string;
  sessionEndImageUrl: string;
}
