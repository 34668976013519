import { gql } from "@apollo/client";
import { OrderBy } from "common/graphql/queryString";
import { CitationSummary, citationSummaryFragment } from "common/graphql";

export const GET_CITATIONS = gql`
  query GetCitations($limit: Int!, $offset: Int!, $orderBy: [enforcement_citation_order_by!]) {
    citations: enforcement_citation(
      where: { notice_sent_at: { _is_null: false } }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...citationSummaryFragment
    }
    citationsAggregate: enforcement_citation_aggregate(where: { notice_sent_at: { _is_null: false } }) {
      aggregate {
        count
        sum {
          paidAmount: paid_amount
        }
      }
    }
  }
  ${citationSummaryFragment}
`;

export interface GetCitationsVars {
  limit: number;
  offset: number;
  orderBy?: CitationOrderBy[];
}

export type CitationOrderBy = CitationOrderByIndex | CitationOrderByNoticeDate | CitationOrderByPaidDate;

export interface CitationOrderByIndex {
  citation_number: OrderBy;
}

export interface CitationOrderByNoticeDate {
  notice_sent_at: OrderBy;
}

export interface CitationOrderByPaidDate {
  paid_at: OrderBy;
}

export interface GetCitationsData {
  citations: CitationSummary[];
  citationsAggregate: {
    aggregate: {
      count: number;
      sum: {
        paidAmount: number;
      };
    };
  };
}
