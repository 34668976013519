import { gql } from "@apollo/client";

export const payerTransactionLogEntryFragment = gql`
  fragment payerTransactionLogEntryFragment on financial_transaction_log {
    transactionId: transaction_id
    firstPark: first_park
    unregisteredVehicleViolation: unregistered_vehicle_violation
    policyViolation: policy_violation
    transactionType: transaction_type
    accountId: payer_account_id
    price
    gatewayTransactionType: gateway_transaction_type
    status
    index
    accountBalance: payer_account_balance
    startTime: start_time
    endTime: end_time
    licensePlateNumber: license_plate_number
    licensePlateStateCode: license_plate_state_code
    addressStreet: address_street
    addressCity: address_city
    addressState: address_state
    transactionTimestamp: transaction_ts
  }
`;

export interface PayerTransactionLogEntry {
  transactionId: string;
  firstPark: boolean;
  unregisteredVehicleViolation: boolean;
  policyViolation: boolean;
  transactionType: string;
  accountId: string;
  price: number;
  accountBalance: number;
  startTime: number;
  endTime: number;
  licensePlateNumber: string;
  licensePlateStateCode: string;
  addressStreet: string;
  addressCity: string;
  addressState: string;
  transactionTimestamp: string;
  gatewayTransactionType: "initial_wallet_load" | "wallet_reload" | "payout" | "wallet_refund" | null;
  status: string;
  index: number;
}
