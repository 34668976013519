import { gql } from "@apollo/client";

export const disputeFragment = gql`
  fragment disputeFragment on financial_dispute {
    id
    status
    currency
    amount
    ticketNumber: ticket_number
    startDisputedTime: start_disputed_time
    endDisputedTime: end_disputed_time
    comment
    createdAt: created_at
  }
`;

export interface Dispute {
  id: string;
  status: DisputeStatus;
  currency: string;
  amount: number;
  ticketNumber: string;
  startDisputedTime: string;
  endDisputedTime: string;
  comment: string;
  createdAt: string;
}

export type DisputeStatus = "filed" | "under_review" | "accepted" | "refunded" | "denied";
