import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { SNACKBAR_MSGS, useSnackPackContext } from "../context/SnackPack";
import { useIsMobile } from "../hooks";
import { InvoiceData, PAY_INVOICE, PayInvoiceData, PayInvoiceVars } from "common/graphql";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import { formatCurrency } from "common/format";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { VehicleInvoiceCardStatus } from "./VehicleInvoiceCardStatus";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

export const InvoiceTable: React.FC<{
  invoices: ReadonlyArray<InvoiceData>;
  paymentMethodId?: string | null;
  isRegistered?: boolean;
}> = ({ invoices, paymentMethodId = null, isRegistered = false }) => {
  const navigate = useNavigate();
  const [payInvoice] = useMutation<PayInvoiceData, PayInvoiceVars>(PAY_INVOICE);
  const { publishSnackbarMessage } = useSnackPackContext();
  const isMobile = useIsMobile();

  const onPay = async (invoiceId: string) => {
    if (!paymentMethodId) {
      return;
    }
    try {
      await payInvoice({
        variables: {
          invoiceId,
          paymentMethodId,
        },
      });
      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_SUCCESS);
    } catch (err) {
      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_FAILED);
      console.error("failed to pay invoice", err);
    }
  };

  return (
    <>
      <Box sx={{ mt: 3, height: "268px", overflow: "hidden", overflowY: "scroll" }}>
        {invoices.map(
          (
            {
              id: invoiceId,
              index: invoiceIndex,
              admin_fee_due,
              transaction_amount_due,
              admin_fee_amount_waived,
              amount_paid,
              replaces_invoice,
              first_opened_at,
            },
            index,
          ) => {
            const isPayable = amount_paid === 0;
            return (
              <Box key={invoiceIndex} sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                <Box sx={{ flex: 1, mr: 3 }}>
                  <Box
                    sx={{
                      height: "67px",
                      borderBottom: index === invoices.length - 1 ? "1px solid" : "none",
                      borderTop: "1px solid",
                      borderColor: "rgba(0, 0, 0, 0.12)",
                      py: 1,
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          alignSelf: "stretch",
                        }}
                      >
                        {String(invoiceIndex).padStart(6, "0")}{" "}
                        {!first_opened_at && (
                          <Typography
                            variant="body1"
                            component={"span"}
                            sx={{
                              fontSize: "12px",
                              fontWeight: 600,
                              display: "flex",
                              height: "20px",
                              padding: "0px 6.5px",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "64px",
                              background: "#0D55BF",
                              color: "#FFF",
                            }}
                          >
                            New
                          </Typography>
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          alignSelf: "stretch",
                        }}
                      >
                        {isRegistered && (
                          <Typography
                            sx={{
                              color: "rgba(0, 0, 0, 0.54)",
                              fontSize: isMobile ? "14px" : "16px",
                            }}
                            variant="body4"
                          >
                            ${formatCurrency(admin_fee_due + transaction_amount_due)}
                          </Typography>
                        )}
                        {!isRegistered && (
                          <Typography
                            sx={{
                              textDecoration: "line-through",
                              color: "rgba(0, 0, 0, 0.38)",
                            }}
                            variant="body4"
                          >
                            ${formatCurrency(admin_fee_due + transaction_amount_due)}
                          </Typography>
                        )}
                        {!isRegistered && (
                          <Typography variant="body4" sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            |
                          </Typography>
                        )}
                        {!isRegistered && (
                          <Typography variant="body4" sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            ${formatCurrency(admin_fee_due + transaction_amount_due - admin_fee_amount_waived)}
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                    <IconButton
                      aria-label={"right-arrow"}
                      sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                      onClick={() => {
                        navigate(`/invoices/${invoiceIndex}`);
                      }}
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </Box>
                  {replaces_invoice?.index && (
                    <Box>
                      <VehicleInvoiceCardStatus
                        message={
                          <Typography>
                            This invoice has been issued in place of a{" "}
                            <Link
                              sx={{ color: "rgba(0,0,0,0.6)", textDecorationColor: "rgba(0,0,0,0.6)" }}
                              href={`/invoices/${replaces_invoice?.index}`}
                            >
                              voided invoice
                            </Link>
                            .
                          </Typography>
                        }
                      />
                    </Box>
                  )}
                  {/** TODO: Need to revisit */}
                  {/* {(review_status === InvoiceReviewStatus.PENDING ||
                    review_status === InvoiceReviewStatus.IN_PROGRESS) && (
                    <Box>
                      <VehicleInvoiceCardStatus
                        status="warning"
                        message={
                          <Typography>
                            We’re currently reviewing this invoice. Please{" "}
                            <Link target="_blank" sx={{ color: "#ED6C02" }} href={`/support`}>
                              contact us
                            </Link>
                            {` with questions.`}
                          </Typography>
                        }
                      />
                    </Box>
                  )} */}
                </Box>
                <Box sx={{ height: "67px", display: "flex", alignItems: "center" }}>
                  <Button
                    variant="outlined"
                    onClick={() => onPay(invoiceId)}
                    fullWidth
                    sx={{
                      textTransform: "unset",
                      py: { xs: 0.5, tiny: 0.75, lg: 1 },
                      fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                      height: { xs: "30px", tiny: "36px", lg: "42px" },
                      fontWeight: 500,
                      width: "202px",
                      mr: 2,
                    }}
                    disabled={!isPayable}
                  >
                    {isPayable ? "Pay" : "Paid"}
                  </Button>
                </Box>
              </Box>
            );
          },
        )}
      </Box>
    </>
  );
};

export default InvoiceTable;
