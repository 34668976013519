const options: readonly CompanyOption[] = [
  { value: "no_company" as const, label: "No Company" as const },
  { value: "uber" as const, label: "Uber" as const },
  { value: "uber_eats" as const, label: "Uber Eats" as const },
  { value: "lyft" as const, label: "Lyft" as const },
  { value: "doordash" as const, label: "DoorDash" as const },
  { value: "postmates" as const, label: "Postmates" as const },
  { value: "grubhub" as const, label: "GrubHub" as const },
  { value: "ups" as const, label: "UPS" as const },
  { value: "fedex" as const, label: "FedEx" as const },
  { value: "amazon" as const, label: "Amazon" as const },
  { value: "amazon_dsp" as const, label: "Amazon DSP" as const },
  { value: "usps" as const, label: "USPS" as const },
  { value: "independent" as const, label: "Independent company" as const },
  { value: "other" as const, label: "Other" as const },
];

const COMPANIES_BY_VALUE: Readonly<Record<CompanyValue, CompanyOption>> = options.reduce((acc, cur) => {
  return { ...acc, [cur.value]: cur };
}, {} as Record<CompanyValue, CompanyOption>);

/** Enum class encompassing utility functions for working with company values */
export class Company {
  /** All company options, sorted in default order */
  static readonly OPTIONS = options;
  /** All company values, sorted in default order */
  static readonly VALUES = options.map((opt) => opt.value);
  /**
   * Returns the company option that corresponds to a given company value
   * @param value
   */
  static FromValue(value: CompanyValue): CompanyOption {
    return COMPANIES_BY_VALUE[value];
  }

  /**
   * Returns true if the value is a {@link CompanyValue}
   * @param value
   */
  static IsValue(value: unknown): value is CompanyValue {
    return typeof value === "string" && this.VALUES.includes(value as CompanyValue);
  }
}

/** Option for selecting a company */
export interface CompanyOption {
  /** Value of the company */
  value: CompanyValue;
  /** Label for displaying the company to a user */
  label: string;
}

/** Value indicating a particular company */
export type CompanyValue =
  | "no_company"
  | "uber"
  | "uber_eats"
  | "lyft"
  | "doordash"
  | "postmates"
  | "grubhub"
  | "fedex"
  | "ups"
  | "amazon"
  | "usps"
  | "independent"
  | "amazon_dsp"
  | "other";

export default Company;
