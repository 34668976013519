import useUserProfile from "../useUserProfile";
import { ApolloError, FetchResult, useMutation } from "@apollo/client";
import {
  ACCEPT_TERMS_OF_SERVICE,
  DECLINE_TERMS_OF_SERVICE,
  UpdateTermsOfServiceAcceptVars,
  UpdateTermsOfServiceData,
  UpdateTermsOfServiceDeclineVars,
} from "common/graphql";

export function useTermsOfService({ onError }: UseTermsOfServiceProps): UseTermsOfServiceResult {
  const { userProfile, loading: profileLoading, error: profileError } = useUserProfile();
  const [acceptTermsOfService, { loading: acceptLoading, error: acceptError }] = useMutation<
    UpdateTermsOfServiceData,
    UpdateTermsOfServiceAcceptVars
  >(ACCEPT_TERMS_OF_SERVICE, {
    onError: onError ? (err) => onError("accept", err) : undefined,
  });
  const [declineTermsOfService, { loading: declineLoading, error: declineError }] = useMutation<
    UpdateTermsOfServiceData,
    UpdateTermsOfServiceDeclineVars
  >(DECLINE_TERMS_OF_SERVICE, {
    onError: onError ? (err) => onError("decline", err) : undefined,
  });

  function accept() {
    return acceptTermsOfService({
      variables: {
        userId: userProfile?.id || "",
        tosAcceptedVersion: "2024-09-05",
      },
    });
  }

  function decline() {
    return declineTermsOfService({
      variables: {
        userId: userProfile?.id || "",
      },
    });
  }

  return {
    accept,
    decline,
    isAccepted: !!userProfile?.tosAcceptedAt && !!userProfile?.tosAcceptedVersion,
    loading: profileLoading || acceptLoading || declineLoading,
    error: profileError || acceptError || declineError,
  };
}

export interface UseTermsOfServiceProps {
  onError?: (operation: "accept" | "decline", err: ApolloError) => void;
}

export interface UseTermsOfServiceResult {
  accept: () => Promise<FetchResult<UpdateTermsOfServiceData>>;
  decline: () => Promise<FetchResult<UpdateTermsOfServiceData>>;
  isAccepted: boolean;
  loading: boolean;
  error?: ApolloError;
}
