import { gql } from "@apollo/client";
import registeredVehicleFragment, { RegisteredVehicle } from "common/graphql/fragments/registeredVehicleFragment";

/**
 * Query that retrieves paginated results of vehicles registered to the user's account.
 */
export const GET_CURRENTLY_REGISTERED_VEHICLES = gql`
  query GetCurrentlyRegisteredVehicles($limit: Int = 20, $offset: Int = 0) {
    registeredVehicleCount: registered_vehicle_aggregate {
      aggregate {
        count
      }
    }
    registeredVehicles: registered_vehicle(order_by: [{ created_at: desc }], limit: $limit, offset: $offset) {
      ...registeredVehicleFragment
    }
  }
  ${registeredVehicleFragment}
`;

/** Variables provided to the {@link GET_CURRENTLY_REGISTERED_VEHICLES} query */
export interface GetCurrentlyRegisteredVehiclesVars {
  /** Maximum number of results to return */
  limit?: number;
  /** Offset of results to retrieve */
  offset?: number;
}

/** Data returned by the {@link GET_CURRENTLY_REGISTERED_VEHICLES} query */
export interface GetCurrentlyRegisteredVehiclesData {
  /** Count of vehicles registered to the user's account */
  registeredVehicleCount: { aggregate: { count: number } };
  /** Registered vehicles */
  registeredVehicles: RegisteredVehicle[];
}

export default GET_CURRENTLY_REGISTERED_VEHICLES;
