import { gql } from "@apollo/client";

export const GET_PAYEE_TRANSACTIONS_OVERVIEW = gql`
  query GetPayeeTransactionsOverview(
    $prevPeriodStartDate: date
    $prevPeriodEndDate: date
    $curPeriodStartDate: date
    $curPeriodEndDate: date
  ) {
    prevPeriodAggregate: financial_transaction_log_daily_summary_aggregate(
      where: { date: { _gte: $prevPeriodStartDate, _lte: $prevPeriodEndDate } }
    ) {
      aggregate {
        sum {
          potentialRevenueAmount: potential_revenue
          registeredTransactionsAmount: registered_price
          walletTransactionsAmount: gateway_transaction_price
        }
      }
    }
    curPeriodAggregate: financial_transaction_log_daily_summary_aggregate(
      where: { date: { _gte: $curPeriodStartDate, _lte: $curPeriodEndDate } }
    ) {
      aggregate {
        sum {
          potentialRevenueAmount: potential_revenue
          registeredTransactionsAmount: registered_price
          walletTransactionsAmount: gateway_transaction_price
        }
      }
    }
  }
`;

/** Variables passed to the {@link GET_PAYEE_TRANSACTIONS_OVERVIEW} query */
export interface GetPayeeTransactionsOverviewVars {
  /** Inclusive start date of the previous period expressed as a string with the format yyyy-mm-dd */
  prevPeriodStartDate: string;
  /** Inclusive end date of the previous period expressed as a string with the format yyyy-mm-dd */
  prevPeriodEndDate: string;
  /** Inclusive start date of the current period expressed as a string with the format yyyy-mm-dd */
  curPeriodStartDate: string;
  /** Inclusive end date of the current period expressed as a string with the format yyyy-mm-dd */
  curPeriodEndDate: string;
}

export interface PayeeTransactionsSummaryAggregate {
  aggregate: {
    sum: {
      potentialRevenueAmount: number;
      registeredTransactionsAmount: number;
      walletTransactionsAmount: number;
    };
  };
}

export interface GetPayeeTransactionsOverviewData {
  /** Sum totals in the previous period */
  prevPeriodAggregate: PayeeTransactionsSummaryAggregate;
  /** Sum totals in the current period */
  curPeriodAggregate: PayeeTransactionsSummaryAggregate;
}
