import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import { MobileDateTimePicker } from "formik-mui-lab";
import addMinutes from "date-fns/addMinutes";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import { usePrevious } from "@app.automotus.io/components/hooks";

/**
 * Renders the fields of a dispute submission form.
 *
 * It is assumed that the initial values of the disputed start time and end time represent the start and end time of the
 * transaction respectively, so these are used to establish bounds for the minimum and maximum time that a user can
 * select.
 * @param loading
 */
export const DisputeFormFields: DisputeFormFieldsComponent = ({ loading = false }) => {
  const { values, initialValues, isSubmitting, setFieldValue } = useFormikContext<DisputeFormFieldsValues>();
  const prevDisputedStartTime = usePrevious(values.disputedStartTime);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { disputeType, disputedStartTime, disputedEndTime } = values;
  const disputedStartTimeEpoch = disputedStartTime?.getTime() || 0;
  const disputedEndTimeEpoch = disputedEndTime?.getTime() || 0;

  const { disputedStartTime: initialDisputedStartTime, disputedEndTime: initialDisputedEndTime } = initialValues;
  const initialDisputedStartTimeEpoch = initialDisputedStartTime?.getTime() || 0;
  const initialDisputedEndTimeEpoch = initialDisputedEndTime?.getTime() || 0;

  useEffect(() => {
    if (disputeType === "full") {
      setFieldValue("disputedStartTime", initialDisputedStartTime, false);
      setFieldValue("disputedEndTime", initialDisputedEndTime, false);
    }
  }, [disputeType, initialDisputedStartTime, initialDisputedEndTime, setFieldValue]);

  useEffect(() => {
    if (!disputedStartTimeEpoch || !disputedEndTimeEpoch) {
      return;
    }

    const prevDisputedStartTimeEpoch = prevDisputedStartTime?.getTime();

    if (disputedStartTimeEpoch !== prevDisputedStartTimeEpoch) {
      if (disputedStartTimeEpoch >= disputedEndTimeEpoch) {
        const nextDisputedEndTime = addMinutes(disputedStartTimeEpoch, 1);
        // Do not modify the disputed end time if it would extend beyond the boundary of the selection
        if (nextDisputedEndTime.getTime() < initialDisputedEndTimeEpoch) {
          setFieldValue("disputedEndTime", nextDisputedEndTime);
        }
      }
      return;
    }

    // End time has changed, move start if necessary
    if (disputedStartTimeEpoch >= disputedEndTimeEpoch) {
      const nextDisputedStartTime = addMinutes(disputedEndTimeEpoch, -1);
      // Only modify the start time if it would remain in the correct bounds
      if (nextDisputedStartTime.getTime() > initialDisputedStartTimeEpoch) {
        setFieldValue("disputedStartTime", nextDisputedStartTime, true);
      }
    }
  }, [
    prevDisputedStartTime,
    disputedStartTimeEpoch,
    disputedEndTimeEpoch,
    setFieldValue,
    initialDisputedEndTimeEpoch,
    initialDisputedStartTimeEpoch,
  ]);

  return (
    <Grid container rowSpacing={{ xs: 2, sm: 5 }}>
      <Grid item xs={12}>
        <Field component={RadioGroup} name="disputeType">
          <LoadingSkeleton loading={loading}>
            <FormControlLabel
              value="full"
              control={<Radio disabled={isSubmitting} />}
              label="Dispute entire transaction"
            />
          </LoadingSkeleton>
          <LoadingSkeleton loading={loading}>
            <FormControlLabel
              value="partial"
              control={<Radio disabled={isSubmitting} />}
              label="Dispute part of the transaction"
            />
          </LoadingSkeleton>
        </Field>
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" columnSpacing={2.5}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <LoadingSkeleton loading={loading}>
            <Typography variant={"body1"}>Enter the time period that you would like to dispute:</Typography>
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6}>
          <LoadingSkeleton loading={loading} width="100%">
            <Field
              component={MobileDateTimePicker}
              name="disputedStartTime"
              label="Start Time"
              disabled={values.disputeType === "full"}
              minDateTime={initialDisputedStartTime}
              maxDateTime={addMinutes(initialDisputedEndTime || 0, -1)}
              textField={{
                fullWidth: true,
              }}
              openTo="hours"
            />
          </LoadingSkeleton>
        </Grid>
        <Grid item xs={6}>
          <LoadingSkeleton loading={loading} width="100%">
            <Field
              component={MobileDateTimePicker}
              name="disputedEndTime"
              label="End Time"
              disabled={values.disputeType === "full"}
              minDateTime={addMinutes(initialDisputedStartTime || 0, 1)}
              maxDateTime={initialDisputedEndTime}
              textField={{ fullWidth: true }}
              openTo="hours"
            />
          </LoadingSkeleton>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <LoadingSkeleton loading={loading}>
          <Typography variant="body1" sx={{ mb: theme.spacing(1) }}>
            Tell us a bit more about your dispute:
          </Typography>
        </LoadingSkeleton>
        <LoadingSkeleton loading={loading} width="100%">
          <Field
            component={TextField}
            placeholder={`${DisputeFormFields.maxCommentLength} character maximum`}
            name="comment"
            multiline
            fullWidth
            minRows={isMobile ? 5 : 4}
            inputProps={{ maxLength: DisputeFormFields.maxCommentLength }}
            sx={{ mb: 0.5 }}
            helperText={`${DisputeFormFields.maxCommentLength - values.comment.length} characters remaining`}
          />
        </LoadingSkeleton>
      </Grid>
    </Grid>
  );
};

const DEFAULT_START_DATE = new Date(2022, 2, 4);

DisputeFormFields.defaultInitialValues = {
  disputeType: "full",
  disputedStartTime: DEFAULT_START_DATE,
  disputedEndTime: addMinutes(DEFAULT_START_DATE, 15),
  comment: "",
};

DisputeFormFields.maxCommentLength = 300;

DisputeFormFields.validationSchema = (initialValues: DisputeFormFieldsValues) =>
  yup.object({
    disputeType: yup
      .mixed()
      .oneOf(["full", "partial"], "Select a valid option")
      .required("Select an option to continue"),
    disputedStartTime: yup.date().when("disputeType", {
      is: "partial",
      then: (schema) =>
        schema
          .required("Required field")
          .min(initialValues.disputedStartTime, "Must be between transaction start and end")
          .when("disputedEndTime", (disputedEndTime, schema) => {
            return disputedEndTime && schema.max(addMinutes(disputedEndTime, -1), "Must be before end time");
          }),
    }),
    disputedEndTime: yup.date().when("disputeType", {
      is: "partial",
      then: (schema) =>
        schema
          .required("Required field")
          .max(initialValues.disputedEndTime, "Must be between transaction start and end"),
    }),
    comment: yup.string().required("Required field").max(DisputeFormFields.maxCommentLength),
  });

export type DisputeType = "full" | "partial";

/** Values of the fields contained in {@link DisputeFormFields} */
export interface DisputeFormFieldsValues {
  /** Type of dispute */
  disputeType: DisputeType | null;
  /** Disputed start time */
  disputedStartTime: Date | null;
  /** Disputed end time */
  disputedEndTime: Date | null;
  /** Comment on the dispute */
  comment: string;
}

export type DisputeFormFieldsComponent = React.FC<DisputeFormFieldsProps> & {
  defaultInitialValues: DisputeFormFieldsValues;
  validationSchema: (initialValues: DisputeFormFieldsValues) => ReturnType<typeof yup["object"]>;
  maxCommentLength: number;
};

/** Props passed to initialize a {@link DisputeFormFields} component */
export interface DisputeFormFieldsProps {
  /** Loading status */
  loading?: boolean;
}

export default DisputeFormFields;
