import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import throttle from "lodash/throttle";
import { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { usePrevious } from "../../../hooks";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";
import {
  GET_ALL_VEHICLE_MODELS_FOR_MAKE,
  GetAllVehicleModelsForMakeData,
  GetAllVehicleModelsForMakeVars,
  VehicleModel,
} from "common/graphql/queries";
import { VirtualizedAutocomplete } from "@app.automotus.io/components/VirtualizedAutocomplete";

const VehicleModelDropdownFieldPrivate = <DisableClearable extends boolean | undefined>({
  makeId = null,
  name = "model",
  label = "Model",
  placeholder = "Model",
  disablePortal = true,
  fullWidth = true,
  autoSelect = false,
  autoHighlight = true,
  disableClearable = false,
  openOnFocus = true,
  required = false,
  helperText,
  disabled,
  ...rest
}: VehicleModelDropdownFieldProps<DisableClearable>) => {
  const prevMakeId = usePrevious(makeId);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<VehicleModel[]>([]);
  const { isSubmitting } = useFormikContext();
  const [field, meta, { setValue }] = useField<VehicleModel | null>(name);
  // TODO: what if an error occurs?
  const [getVehicleModelsForMake, { loading }] = useLazyQuery<
    GetAllVehicleModelsForMakeData,
    GetAllVehicleModelsForMakeVars
  >(GET_ALL_VEHICLE_MODELS_FOR_MAKE, {
    onCompleted(data) {
      setOptions(data.models);
    },
  });

  const fetch = useMemo(() => throttle(getVehicleModelsForMake, 200), [getVehicleModelsForMake]);

  const makeIdHasChanged = makeId !== prevMakeId;
  useEffect(() => {
    let active = true;

    if (active && makeIdHasChanged) {
      setValue(null);
      setOptions([]);

      if (makeId !== null) {
        fetch({ variables: { makeId } });
      }
    }

    return () => {
      active = false;
    };
  }, [makeId, makeIdHasChanged, fetch, setValue, setInputValue]);

  const showError = meta.touched && !!meta.error;

  return (
    <VirtualizedAutocomplete<VehicleModel, false, typeof disableClearable, false>
      {...rest}
      {...{ disablePortal, fullWidth, autoSelect, autoHighlight, disableClearable, openOnFocus }}
      multiple={false}
      loading={loading}
      onBlur={field.onBlur}
      value={meta.value}
      disabled={disabled ?? (makeId === null || isSubmitting)}
      onChange={(e, v) => setValue(v)}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
      isOptionEqualToValue={(option, value) => option.modelId === value.modelId}
      options={options}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={showError}
          helperText={showError ? meta.error : helperText}
          label={label}
          placeholder={placeholder}
          required={required}
        />
      )}
    />
  );
};

/**
 * Component that renders an autocomplete dropdown allowing a user to select her
 * vehicle model.
 */
export const VehicleModelDropdownField = Object.assign(VehicleModelDropdownFieldPrivate, {
  validationSchema: yup
    .object({
      makeId: yup.number().required(),
      modelId: yup.number().required(),
      name: yup.string().required(),
    })
    .nullable(),
  defaultInitialValue: null as VehicleModel | null,
});

/** Props passed to initialize a {@link VehicleModelDropdownField} component */
export interface VehicleModelDropdownFieldProps<DisableClearable extends boolean | undefined>
  extends Omit<
    AutocompleteProps<VehicleModel, false, DisableClearable, false>,
    "renderInput" | "options" | "getOptionLabel" | "onChange" | "onBlur" | "value"
  > {
  makeId?: number | null;
  name?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
}

export default VehicleModelDropdownField;
