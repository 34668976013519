import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { useEnv } from "@app.automotus.io/components/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import {
  DeletePaymentMethodResponseBody,
  GetPaymentProfileResponseBody,
  GetTransactionImagesResponseBody,
  PatchPaymentMethodRequestBody,
  PatchPaymentMethodResponseBody,
  PostPaymentMethodRequestBody,
  PostPaymentMethodResponseBody,
  PostPaymentProfileResponseBody,
  PostPaymentsLoadWalletResponseBody,
  PostTransactionDisputeRequestBody,
  PostTransactionDisputeResponseBody,
} from "@app.automotus.io/components/hooks/useAtmtsRest/types";
import { useCallback } from "react";
import { PostPaymentsProfileRequestBody } from "api/controllers/payments/profile";

axiosRetry(axios, { retries: 3 });

/**
 * Returns a rest client for use with the app.automotus.io rest API.
 */
export function useAtmtsRest(): AtmtsRestClient {
  const { apiEndpoint } = useEnv();
  const { getAccessTokenSilently } = useAuth0();

  const requestConfig = useCallback(
    async (): Promise<AxiosRequestConfig> => ({
      baseURL: apiEndpoint,
      headers: {
        authorization: `Bearer ${await getAccessTokenSilently()}`,
        "x-atmts-request-origin": "app.automotus.io",
      },
      transformResponse: (resp) => {
        const { data, error } = JSON.parse(resp);

        if (data) {
          return data;
        }

        if (error) {
          return error;
        }

        return resp;
      },
    }),
    [apiEndpoint, getAccessTokenSilently],
  );

  const postTransactionDispute = useCallback(
    async (id: string, reqData: PostTransactionDisputeRequestBody) => {
      return axios.post<PostTransactionDisputeResponseBody>(
        `/transactions/${id}/dispute`,
        reqData,
        await requestConfig(),
      );
    },
    [requestConfig],
  );

  const getTransactionImages = useCallback(
    async (id: string) => {
      return axios.get<GetTransactionImagesResponseBody>(`/transactions/${id}/images`, await requestConfig());
    },
    [requestConfig],
  );

  const postPaymentMethod = useCallback(
    async (req: PostPaymentMethodRequestBody) => {
      return axios.post<PostPaymentMethodResponseBody>("/payments/methods", req, await requestConfig());
    },
    [requestConfig],
  );

  const patchPaymentMethod = useCallback(
    async (id: string, reqData: PatchPaymentMethodRequestBody) => {
      return axios.patch<PatchPaymentMethodResponseBody>(`/payments/methods/${id}`, reqData, await requestConfig());
    },
    [requestConfig],
  );

  const deletePaymentMethod = useCallback(
    async (id: string) => {
      return axios.delete<DeletePaymentMethodResponseBody>(`/payments/methods/${id}`, await requestConfig());
    },
    [requestConfig],
  );

  const getPaymentProfile = useCallback(async () => {
    return axios.get<GetPaymentProfileResponseBody>("/payments/profile", await requestConfig());
  }, [requestConfig]);

  const postPaymentProfile = useCallback(
    async (reqData: PostPaymentsProfileRequestBody) => {
      return axios.post<PostPaymentProfileResponseBody>("/payments/profile", reqData, await requestConfig());
    },
    [requestConfig],
  );

  const postPaymentsLoadWallet = useCallback(async () => {
    return axios.post<PostPaymentsLoadWalletResponseBody>("/payments/load-wallet", {}, await requestConfig());
  }, [requestConfig]);

  return {
    postTransactionDispute,
    postPaymentProfile,
    getTransactionImages,
    postPaymentMethod,
    postPaymentsLoadWallet,
    patchPaymentMethod,
    deletePaymentMethod,
    getPaymentProfile,
  };
}

/**
 * Client for issuing requests to the app.automotus.io REST API.
 */
export interface AtmtsRestClient {
  /**
   * Issues a POST request to the /payments/methods endpoint
   * @param reqData data to provide with the request
   * @returns An axios response
   * @throws An {@link AtmtsClientError} upon failure
   */
  postPaymentMethod(reqData: PostPaymentMethodRequestBody): Promise<AxiosResponse<PostPaymentMethodResponseBody>>;
  /** Issues a PATCH request to the /payments/methods/:methodId endpoint */
  patchPaymentMethod(
    id: string,
    reqData: PatchPaymentMethodRequestBody,
  ): Promise<AxiosResponse<PatchPaymentMethodResponseBody>>;
  /** Issues a DELETE request to the /payments/methods/:methodId endpoint */
  deletePaymentMethod(id: string): Promise<AxiosResponse<DeletePaymentMethodResponseBody>>;
  /** Issues a POST request to the /transactions/:transactionId/dispute endpoint */
  postTransactionDispute(
    id: string,
    reqData: PostTransactionDisputeRequestBody,
  ): Promise<AxiosResponse<PostTransactionDisputeResponseBody>>;
  /** Issues a GET request to the /transactions/:transactionId/images endpoint */
  getTransactionImages(id: string): Promise<AxiosResponse<GetTransactionImagesResponseBody>>;
  /** Issues a GET request to the /payments/profile endpoint */
  getPaymentProfile(): Promise<AxiosResponse<GetPaymentProfileResponseBody>>;
  /** Issues a POST request to the /payments/profile endpoint */
  postPaymentProfile(reqData: PostPaymentsProfileRequestBody): Promise<AxiosResponse<PostPaymentProfileResponseBody>>;
  postPaymentsLoadWallet(): Promise<AxiosResponse<PostPaymentsLoadWalletResponseBody>>;
}

export default useAtmtsRest;
