import { UserProfile } from "common/graphql";

/**
 * Redirects a user to the appropriate application if they attempt to access the wrong one.
 * @param userProfile
 */
export function redirectToApplicationByRoleType(userProfile: UserProfile | undefined): void {
  if (userProfile?.account?.roleType === "payee" && process.env.REACT_APP_APP_NAME !== "curbsuite.io") {
    // If the user is logged in as a payee and is not accessing the correct app, we want to immediately redirect
    // him to the curbsuite application.
    window.location.href = process.env.REACT_APP_CURBSUITE_ORIGIN || "";
    return;
  } else if (userProfile?.account?.roleType === "payer" && process.env.REACT_APP_APP_NAME !== "curbpass.io") {
    // If the user is logged in as a payer and is not accessing the correct app, we want to immediately redirect
    // him to the curbpass application.
    window.location.href = process.env.REACT_APP_CURBPASS_ORIGIN || "";
    return;
  }
}

export default redirectToApplicationByRoleType;
