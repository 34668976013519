import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LogoImageWhite } from "@app.automotus.io/components/SignUp/LogoImage/LogoImage";
// TODO: change source of image
import landingImage from "@app.automotus.io/components/images/Landing.png";
import { FullScreenLoader } from "@app.automotus.io/components/FullScreenLoader";
import { Link, Outlet } from "react-router-dom";
import NotificationBanner from "@app.automotus.io/components/NotificationBanner/NotificationBanner";

const PabblyFormFrame = styled("iframe")({
  width: "100%",
  height: "900px",
  border: "none",
  display: "flex",
  justifyContent: "center",
});

const PabblyForm: React.FC<PabblyFormProps> = ({ formId }) => {
  const resourceUrl = `https://forms.pabbly.com/form/share/${formId}`;
  const isUploadForm = formId === process.env.REACT_APP_PABBLY_FORM_ID_FLEET_UPLOAD;
  const [formLoaded, setFormLoaded] = useState(false);

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <FullScreenLoader loading={!formLoaded} />
      <Box
        sx={{
          flex: 1,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          "& :first-of-type": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <PabblyFormFrame
          src={resourceUrl}
          onLoad={() => setFormLoaded(true)}
          allowTransparency
          name="pabbly_form"
          id="pabbly_form"
          allowFullScreen
        />
      </Box>

      {formLoaded && isUploadForm && (
        <Box sx={{ flex: 1, width: "351px", mx: "auto", mt: -6, zIndex: 200, position: "relative" }}>
          <Typography variant="body1" align={"center"}>
            <Link to={"manual-entry"}>I'd prefer to manually enter vehicle info</Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

interface PabblyFormProps {
  formId: string;
}

export const FleetOnboardingUpload: React.FC = () => {
  return <PabblyForm formId={process.env.REACT_APP_PABBLY_FORM_ID_FLEET_UPLOAD || ""} />;
};

export const FleetOnboardingManualEntry: React.FC = () => {
  return <PabblyForm formId={process.env.REACT_APP_PABBLY_FORM_ID_FLEET_MANUAL_INPUT || ""} />;
};

export const FleetOnboardingPageLayout: React.FC = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isWide = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "600px",
        position: "relative",
      }}
    >
      {isMobile && <NotificationBanner />}
      <Box
        sx={{
          display: "flex",
          flexDirection: isWide ? "row" : "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: isWide ? "50%" : "100%",
            position: "relative",
            maxWidth: isWide ? "720px" : "100%",
            height: isMobile ? "70%" : "100%",
            minHeight: isMobile ? "70%" : "100%",
          }}
        >
          <Box sx={{ height: "100%", background: theme.palette.primary.main }}>
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                background: `url(${landingImage})`,
                backgroundPosition: isMobile ? "initial" : "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                mixBlendMode: "hard-light",
                opacity: 0.3,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                left: isMobile ? theme.spacing(3) : theme.spacing(5),
                top: isMobile ? theme.spacing(4) : theme.spacing(4),
                right: isMobile ? theme.spacing(2) : theme.spacing(5),
                display: "flex",
                alignItems: "center",
              }}
            >
              <LogoImageWhite />
            </Box>
            <Box
              sx={{
                position: "absolute",
                color: theme.palette.primary.white,
                left: isMobile ? theme.spacing(3) : theme.spacing(5),
                right: isMobile ? theme.spacing(2) : theme.spacing(5),
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: isMobile ? "flex-end" : "center",
              }}
            >
              <Box>
                <Typography variant={isMobile ? "h6" : "h2"}>Pull Up,</Typography>
                <Typography variant={isMobile ? "h6" : "h2"}>Get Things Done,</Typography>
                <Typography variant={isMobile ? "h6" : "h2"}>Leave</Typography>
              </Box>
              <Typography variant={isMobile ? "subtitle2" : "h6"} sx={{ mb: isMobile ? theme.spacing(2) : 0 }}>
                Our cameras auto charge your account
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ flex: 1, overflow: isMobile ? "initial" : "scroll" }}>
          {!isMobile && <NotificationBanner />}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
