import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Skeleton from "@mui/material/Skeleton";
import GoogleIcon from "@app.automotus.io/components/icons/GoogleIcon";
import GoogleIconDisabled from "@app.automotus.io/components/icons/GoogleIconDisabled";

export const GooglePayButton: React.FC<GooglePayButtonProps> = ({
  loading = false,
  disabled = false,
  onClick = () => undefined,
}) => {
  return loading ? (
    <Skeleton width="100%">
      <LoadingButton>Loading</LoadingButton>
    </Skeleton>
  ) : (
    <LoadingButton
      fullWidth={true}
      id="google-pay"
      variant="text"
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      startIcon={disabled ? <GoogleIconDisabled /> : <GoogleIcon />}
      loadingPosition="start"
      sx={{
        border: "1px solid black",
        boxShadow: 0,
        textTransform: "unset",
        height: "42px",
        backgroundColor: "white",
        color: "black",
        fontSize: "20px",
        fontWeight: 400,
        "& .MuiButton-startIcon": {
          marginRight: "4px",
        },
      }}
    >
      Pay
    </LoadingButton>
  );
};

export interface GooglePayButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export default GooglePayButton;
