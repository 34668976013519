import { gql } from "@apollo/client";
import { Wallet, walletFragment } from "common/graphql/fragments";

export const LOAD_WALLET = gql`
  mutation LoadWallet($payeeAccountId: uuid!, $paymentMethodId: uuid) {
    loadWallet: load_wallet(payee_account_id: $payeeAccountId, payment_method_id: $paymentMethodId) {
      wallet {
        ...walletFragment
      }
    }
  }
  ${walletFragment}
`;

export interface LoadWalletData {
  loadWallet: {
    wallet: Wallet;
  };
}

export interface LoadWalletVars {
  payeeAccountId: string;
  paymentMethodId?: string;
}
