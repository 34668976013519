import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PartnerLandingPageParkingRatesAndHoursModal } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal";
import Link from "@mui/material/Link";
import { useActivePayee } from "../../../../hooks/useActivePayee";

/**
 * Component that renders a component to open parking rates modal.
 */
export const SeeParkingRates: React.FC<SeeParkingRatesProps> = ({ isBillByMail = false }: SeeParkingRatesProps) => {
  const [parkingInfoModalOpen, setParkingInfoModalOpen] = useState(false);
  const { activePayee } = useActivePayee();
  const payeeAccountId = activePayee ? activePayee.payeeAccountId : process.env.REACT_APP_PITTSBURGH_ACCOUNT_ID ?? "";

  const handleOpenParkingInfoModal = () => {
    setParkingInfoModalOpen(true);
  };

  const handleCloseParkingInfoModal = () => {
    setParkingInfoModalOpen(false);
  };

  const redirectUrl = isBillByMail ? "/bbm-signup/payment" : "/signup/payment";
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="subtitle1" sx={{ mt: 0.5, mb: 3 }}>
        Not sure what to select?{" "}
        <Link component={RouterLink} to={redirectUrl} onClick={handleOpenParkingInfoModal}>
          See Parking Rates
        </Link>
      </Typography>

      <PartnerLandingPageParkingRatesAndHoursModal
        open={parkingInfoModalOpen}
        onClose={handleCloseParkingInfoModal}
        payeeAccountId={payeeAccountId}
      />
    </Box>
  );
};

type SeeParkingRatesProps = {
  isBillByMail?: boolean;
};

export default SeeParkingRates;
