import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

export const SavedPaymentMethodPlaceholder: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{ border: 1, borderRadius: 1, borderColor: "grey.400", height: theme.spacing(6) }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    />
  );
};

export default SavedPaymentMethodPlaceholder;
