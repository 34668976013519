import { useContext } from "react";
import EnvContext from "@app.automotus.io/components/context/EnvContext";

/**
 * Hook that returns the environment configured for the application.
 * @returns An object containing environment-specific values
 */
export function useEnv(): Environment {
  const envContext = useContext(EnvContext);

  return {
    apiEndpoint: envContext.REACT_APP_API_ENDPOINT ?? "",
  };
}

export default useEnv;

export interface Environment {
  apiEndpoint: string;
}
