import React, { useEffect, useMemo, useState } from "react";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "common/format";
import formatDate from "date-fns/format";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import { useMutation, useQuery } from "@apollo/client";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import DashboardContentLayout from "@app.automotus.io/components/Layout/DashboardContentLayout";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InvoiceTransactionsCardList from "@app.automotus.io/components/InvoiceTransactions/InvoiceTransactionsCardList";
import InvoiceTransactionsTableView from "@app.automotus.io/components/InvoiceTransactions/InvoiceTransactionsTableView";
import {
  GET_INVOICE_BY_INDEX,
  GetInvoiceByIndexData,
  GetInvoiceByIndexVars,
  GET_PAYMENT_METHODS,
  GetPaymentMethodsData,
  PAY_INVOICE,
  PayInvoiceData,
  PayInvoiceVars,
  GET_INVOICES,
} from "common/graphql";
import { PaymentMethod } from "common/graphql/fragments";
import { SNACKBAR_MSGS, useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import VehicleInvoiceCardStatus from "../VehicleInvoice/VehicleInvoiceCardStatus";
import ActionButton from "../VehicleInvoice/ActionButton";

export const InvoiceDetail: React.FC = () => {
  const { loading: profileLoading, refetch: refetchProfile, error: profileError } = useUserProfile();
  const { invoiceIndex = "" } = useParams<{ invoiceIndex: string }>();
  const navigate = useNavigate();
  const { publishSnackbarMessage } = useSnackPackContext();
  const isMobile = useIsMobile();

  const {
    data: paymentMethodsData,
    error: paymentMethodError,
    refetch: refetchPaymentMethod,
    loading: paymentMethodLoading,
  } = useQuery<GetPaymentMethodsData>(GET_PAYMENT_METHODS);

  const [payInvoice] = useMutation<PayInvoiceData, PayInvoiceVars>(PAY_INVOICE);

  const paymentMethods = useMemo(() => {
    if (paymentMethodsData) {
      return paymentMethodsData.paymentMethods;
    }

    return [];
  }, [paymentMethodsData]);

  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);

  useEffect(() => {
    if (paymentMethods.length === 0) {
      setPaymentMethodId(null);
    } else {
      setPaymentMethodId(paymentMethods[0].id);
    }
  }, [paymentMethods]);

  const getPaymentMethodDisplay = (paymentMethod: PaymentMethod) => {
    if (paymentMethod.type === "credit_card") {
      return `Card (${paymentMethod.maskedNumber})`;
    } else {
      return "Bank";
    }
  };

  const {
    data: invoiceData,
    loading: invoiceLoading,
    error: invoiceError,
    refetch: refetchInvoice,
  } = useQuery<GetInvoiceByIndexData, GetInvoiceByIndexVars>(GET_INVOICE_BY_INDEX, {
    variables: {
      invoiceIndex: +invoiceIndex,
    },
  });

  const loading = profileLoading || paymentMethodLoading || invoiceLoading;

  const invoice = invoiceData?.invoice ? invoiceData?.invoice[0] : null;
  const invoiceTransactions =
    invoice?.transactions.map((transaction) => {
      const t = transaction.transaction_log_no_balance;
      return {
        id: t.transactionId,
        date: new Date(transaction.sessionEndedAtLocal),
        startTime: new Date(transaction.sessionStartedAtLocal),
        endTime: new Date(transaction.sessionEndedAtLocal),
        address: t.addressStreet,
        city: `${t.addressCity}, ${t.addressState}`,
        vehicleId: invoice.registered_vehicle.id,
        vehicle: `${t.licensePlateStateCode} ${t.licensePlateNumber}`,
        amount: t.price,
        index: t.index,
        status: t.status,
      };
    }) || [];

  const isVoid = invoice?.voided_at;
  const isUnderReview = false;
  const isPaid = invoice?.amount_paid !== 0;
  const isUnPaid = !isPaid;

  const paidPaymentMethod = useMemo(() => {
    return paymentMethods.find((paymentMethod) => paymentMethod.payeeAccountId === invoice?.payee_account_id);
  }, [paymentMethods, invoice]);

  if (profileError) {
    return <LocalLevelError onTryAgain={refetchProfile} />;
  }

  if (invoiceError) {
    return <LocalLevelError onTryAgain={refetchInvoice} />;
  }

  if (paymentMethodError) {
    return <LocalLevelError onTryAgain={refetchPaymentMethod} />;
  }

  const handleClickPay = async () => {
    try {
      await payInvoice({
        variables: {
          invoiceId: invoice?.id || "",
          paymentMethodId: paymentMethodId || undefined,
        },
        refetchQueries: [GET_INVOICE_BY_INDEX, GET_INVOICES],
      });

      refetchInvoice();
    } catch (err) {
      console.error(err, "failed to pay invoice");
      publishSnackbarMessage(SNACKBAR_MSGS.SOMETHING_WENT_WRONG);
    }
  };

  return (
    <>
      <DashboardContentLayout sx={{ my: { xs: 1.5, lg: 3 } }}>
        <Box sx={{ gap: isMobile ? "8px" : "12px", display: "flex", flexDirection: "column" }}>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <IconButton
                aria-label={"back-button"}
                sx={{ color: "black" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIos />
              </IconButton>
              <LoadingSkeleton loading={loading}>
                <Typography sx={{ fontSize: { xs: "24px", tiny: "28px", lg: "34px", fontWeight: 500 } }}>
                  Invoice Details
                </Typography>
              </LoadingSkeleton>
            </Box>
            <LoadingSkeleton loading={loading}>
              <Typography color="text.secondary" sx={{ ml: 5, fontSize: "12px" }}>
                Invoice issued on {formatDate(new Date(invoice?.created_at || 0), "MMMM dd, yyyy")}
              </Typography>
            </LoadingSkeleton>
          </Box>
          {isVoid && (
            <LoadingSkeleton loading={loading}>
              <Box sx={{ ml: isMobile ? 0 : 5 }}>
                <VehicleInvoiceCardStatus
                  message={
                    <Typography>
                      This invoice has been voided as the result of a resolved dispute.{" "}
                      <Link
                        sx={{ color: "rgba(0,0,0,0.6)", textDecorationColor: "rgba(0,0,0,0.6)" }}
                        href={`/invoices/${invoice?.replaced_by_invoice?.id}`}
                      >
                        View your new invoice
                      </Link>
                      .
                    </Typography>
                  }
                />
              </Box>
            </LoadingSkeleton>
          )}
          {isUnderReview && (
            <LoadingSkeleton loading={loading}>
              <Box sx={{ ml: isMobile ? 0 : 5 }}>
                <VehicleInvoiceCardStatus
                  status="warning"
                  message={
                    <span>
                      {" "}
                      We’re currently reviewing this invoice. Please{" "}
                      <Link
                        component={RouterLink}
                        to="/support"
                        underline="hover"
                        target={"_blank"}
                        style={{ color: "inherit", textDecorationColor: "inherit" }}
                      >
                        contact us
                      </Link>
                      with questions.
                    </span>
                  }
                />
              </Box>
            </LoadingSkeleton>
          )}
          <Box sx={{ ml: isMobile ? 0 : 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <LoadingSkeleton loading={loading}>
              <Typography>Invoice Number</Typography>
            </LoadingSkeleton>
            <LoadingSkeleton loading={loading}>
              <Typography>{String(invoiceIndex).padStart(6, "0")}</Typography>
            </LoadingSkeleton>
          </Box>
          <Box sx={{ ml: isMobile ? 0 : 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <LoadingSkeleton loading={loading}>
              <Typography>License Plate Number</Typography>
            </LoadingSkeleton>
            <LoadingSkeleton loading={loading}>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {invoice?.registered_vehicle ? (
                  <CheckIcon sx={{ color: "#3A833C", width: "18px", height: "18px" }} />
                ) : (
                  <ErrorOutlineIcon sx={{ color: "#E31B0C", width: "18px", height: "18px" }} />
                )}
                <Typography sx={{ ml: isMobile ? 0.5 : 1 }}>
                  {`${invoice?.registered_vehicle?.state_code} ${invoice?.registered_vehicle?.license_plate}`}
                </Typography>
              </Box>
            </LoadingSkeleton>
          </Box>
          {!isVoid && !invoice?.registered_vehicle && (
            <LoadingSkeleton loading={loading}>
              <Box sx={{ ml: isMobile ? 0 : 5 }}>
                <VehicleInvoiceCardStatus
                  status="critical"
                  message="This vehicle cannot be registered until all associated invoices are paid in full."
                />
              </Box>
            </LoadingSkeleton>
          )}

          {!isVoid && isUnPaid && (
            <Box ml={isMobile ? 0 : 5} mt={isMobile ? 2 : 2.5}>
              <LoadingSkeleton loading={loading}>
                <Typography sx={{ fontSize: { xs: "24px", tiny: "28px", lg: "34px" }, fontWeight: 500 }}>
                  Pay Invoice
                </Typography>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading}>
                <Typography color="text.secondary" sx={{ fontSize: "12px" }}>
                  Your payment is due by {formatDate(new Date(invoice?.due_at || 0), "MMMM dd, yyyy")}
                </Typography>
              </LoadingSkeleton>
            </Box>
          )}

          {!isVoid && isUnPaid && (
            <Box
              sx={{
                mt: isMobile ? 0.5 : 0,
                ml: isMobile ? 0 : 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <LoadingSkeleton loading={loading}>
                <Typography>Total Parking Charges</Typography>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading}>
                <Typography>${formatCurrency(invoice?.transaction_amount_due || 0)}</Typography>
              </LoadingSkeleton>
            </Box>
          )}

          {!isVoid && isUnPaid && (
            <Box sx={{ ml: isMobile ? 0 : 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <LoadingSkeleton loading={loading}>
                <Typography>Administrative Fee</Typography>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  {invoice?.admin_fee_amount_waived > 0 && (
                    <Typography color="rgba(0, 0, 0, 0.36)" sx={{ textDecoration: "line-through" }}>
                      ${formatCurrency(invoice?.admin_fee_due || 0)}
                    </Typography>
                  )}
                  {invoice?.admin_fee_amount_waived > 0 && (
                    <Typography color="rgba(0, 0, 0, 0.36)" sx={{ mx: 0.5 }}>
                      |
                    </Typography>
                  )}
                  <Typography>
                    ${formatCurrency((invoice?.admin_fee_due || 0) - (invoice?.admin_fee_amount_waived || 0))}
                  </Typography>
                </Box>
              </LoadingSkeleton>
            </Box>
          )}

          {!isVoid && (
            <>
              <Divider sx={{ ml: isMobile ? 0 : 5, backgroundColor: "rgba(0, 0, 0, 0.12)" }} />
              <Box
                sx={{
                  ml: isMobile ? 0 : 5,
                  mb: isMobile || isUnPaid ? 0 : 4.5,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <LoadingSkeleton loading={loading}>
                  <Typography variant="h7" fontWeight={500} fontSize={{ xs: "1rem", tiny: "1.25rem" }}>
                    {isUnPaid ? "Total Due" : "Invoice Amount"}
                  </Typography>
                </LoadingSkeleton>
                <LoadingSkeleton loading={loading}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <Typography variant="h7" fontWeight={500} fontSize={{ xs: "1rem", tiny: "1.25rem" }}>
                      $
                      {formatCurrency(
                        (invoice?.transaction_amount_due || 0) +
                          (invoice?.admin_fee_due || 0) -
                          (invoice?.admin_fee_amount_waived || 0),
                      )}
                    </Typography>
                    {isPaid && paymentMethods && paidPaymentMethod && (
                      <Typography variant="body1" color="text.secondary">
                        {paidPaymentMethod ? `Paid with ${getPaymentMethodDisplay(paidPaymentMethod)}` : "Paid"}
                      </Typography>
                    )}
                  </Box>
                </LoadingSkeleton>
              </Box>
            </>
          )}
          {!isVoid && isUnPaid && (
            <Box
              sx={{
                ml: isMobile ? 0 : 5,
                mt: isMobile ? 3.5 : 2.5,
                mb: isMobile ? 0 : 4.5,
                display: "flex",
                flexDirection: "row",
                justifyContent: isMobile ? "space-between" : "flex-end",
              }}
            >
              <LoadingSkeleton loading={loading}>
                <Select
                  sx={{
                    "&&&:before": {
                      borderBottom: "none",
                    },
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                  }}
                  variant="outlined"
                  value={paymentMethodId}
                  autoWidth
                  onChange={(e) => {
                    setPaymentMethodId(e.target.value);
                  }}
                >
                  {paymentMethods.length !== 0 &&
                    paymentMethods.map((paymentMethod) => (
                      <MenuItem value={paymentMethod.id}>
                        {isMobile
                          ? getPaymentMethodDisplay(paymentMethod)
                          : `Pay with ${getPaymentMethodDisplay(paymentMethod)}`}
                      </MenuItem>
                    ))}
                </Select>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading}>
                <Button
                  variant="contained"
                  onClick={handleClickPay}
                  fullWidth
                  sx={{
                    ml: 2,
                    textTransform: "unset",
                    py: { xs: 0.5, tiny: 0.75, lg: 1 },
                    fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                    fontWeight: 500,
                    width: "217px",
                  }}
                >
                  Pay
                </Button>
              </LoadingSkeleton>
            </Box>
          )}
          {isVoid && (
            <LoadingSkeleton loading={loading}>
              <Button
                variant="contained"
                onClick={() => {
                  if (invoice?.replaced_by_invoice) {
                    navigate(`/invoices/${invoice?.replaced_by_invoice.index}`);
                  }
                }}
                fullWidth
                sx={{
                  ml: isMobile ? 0 : 5,
                  mt: isMobile ? 10 : 2.5,
                  mb: isMobile ? 0 : 4.5,
                  textTransform: "unset",
                  py: { xs: 0.5, tiny: 0.75, lg: 1 },
                  fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                  height: { xs: "30px", tiny: "36px", lg: "42px" },
                  fontWeight: 500,
                  width: "auto",
                }}
              >
                View New Invoice
              </Button>
            </LoadingSkeleton>
          )}
          {isMobile && (
            <Box sx={{ mt: isMobile ? 1 : 2, mb: 0.5, width: "100%" }}>
              <LoadingSkeleton loading={loading}>
                <ActionButton
                  onClick={() => navigate(-1)}
                  variant="text"
                  sx={{
                    fontWeight: 500,
                    fontSize: {
                      xs: "14px",
                      tiny: "16px",
                    },
                  }}
                >
                  Back
                </ActionButton>
              </LoadingSkeleton>
            </Box>
          )}
          {isMobile && !isVoid && !isPaid && (
            <LoadingSkeleton loading={loading}>
              <Typography py={1.5} mt={1.5} mb={0.5} textAlign={"center"}>
                Incorrectly billed?{" "}
                <Link
                  sx={{ color: "primary.main" }}
                  target="_blank"
                  href={`https://curbpasshelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=5873339775899`}
                >
                  Dispute invoice
                </Link>
              </Typography>
            </LoadingSkeleton>
          )}
        </Box>
      </DashboardContentLayout>
      <Box sx={{ backgroundColor: "#EFF1F2", display: "flex", flexDirection: "column", alignItems: "center" }}>
        {!isVoid && (
          <Box sx={{ pl: isMobile ? 2 : 9, width: isMobile ? "100%" : "1040px", pr: isMobile ? 2 : 0, mb: 4 }}>
            <LoadingSkeleton loading={loading}>
              <Typography
                sx={{
                  mt: isMobile ? 2 : 7.5,
                  mb: isMobile ? 2 : 5,
                  fontSize: { xs: "24px", tiny: "28px", lg: "34px" },
                  fontWeight: 500,
                }}
              >
                Invoice Transactions
              </Typography>
            </LoadingSkeleton>
            {isMobile ? (
              <InvoiceTransactionsCardList loading={invoiceLoading} data={invoiceTransactions} />
            ) : (
              <InvoiceTransactionsTableView loading={invoiceLoading} rows={invoiceTransactions} />
            )}
          </Box>
        )}
        {!isMobile && !isVoid && !isPaid && (
          <LoadingSkeleton loading={loading}>
            <Typography py={1.5} mb={7.5}>
              Incorrectly billed?{" "}
              <Link
                sx={{ color: "primary.main" }}
                target="_blank"
                href={`https://curbpasshelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=5873339775899`}
              >
                Dispute invoice
              </Link>
            </Typography>
          </LoadingSkeleton>
        )}
      </Box>
    </>
  );
};

export default InvoiceDetail;
