import React, { useRef } from "react";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import LicensePlateState, { StateAbbreviation } from "../../common/LicensePlateState";
import { VehicleTypeValue } from "../../common/VehicleType";
import StateDropdown from "../../StateDropdownField";
import { VehicleMake, VehicleModel } from "common/graphql";
import Skeleton from "@mui/material/Skeleton";
import VehicleInfoFormButtons from "./VehicleInfoFormButtons";
import {
  LicensePlateField,
  VehicleMakeDropdownField,
  VehicleModelDropdownField,
  VehicleTypeDropdownField,
  YearDropdownField,
} from "../VehicleInfoFields";
import { FormObserver } from "@app.automotus.io/components/routes/signup/helpers/FormObserver";
import { useQuery } from "@apollo/client";
import {
  GET_CURRENTLY_REGISTERED_VEHICLES,
  GetCurrentlyRegisteredVehiclesData,
  GetCurrentlyRegisteredVehiclesVars,
} from "common/graphql/queries/GetCurrentlyRegisteredVehicles";
import { SNACKBAR_MSGS, useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      "&.Mui-error": {
        marginBottom: -22.91,
      },
    },
  },
});

const REQUIRED_FIELD_ERROR = "Selection required";

const emptyValues = {
  licensePlate: "",
  licensePlateState: null as StateAbbreviation | null,
  invoiceNumber: undefined,
  vehicleType: null as VehicleTypeValue | null,
  make: null as VehicleMake | null,
  model: null as VehicleModel | null,
  year: null as number | null,
  submitAction: null as VehicleInfoFormSubmitAction | null,
};

export const VehicleInfoForm: React.FC<VehicleInfoFormProps> = ({
  id,
  variant = "add",
  onSubmit = () => undefined,
  allowCancelAndFinish = false,
  onSaveAndContinue = () => undefined,
  initialValues,
  disabled,
  loading = false,
  buttonTexts,
  licensePlate,
}) => {
  const classes = useStyles();
  const firstInputRef = useRef<HTMLInputElement>();
  const { data, loading: data_loading } = useQuery<
    GetCurrentlyRegisteredVehiclesData,
    GetCurrentlyRegisteredVehiclesVars
  >(GET_CURRENTLY_REGISTERED_VEHICLES);
  const { publishSnackbarMessage } = useSnackPackContext();

  const registeredVehicles = data?.registeredVehicles || [];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        licensePlate: (initialValues && initialValues.licensePlate) || emptyValues.licensePlate,
        licensePlateState: (initialValues && initialValues.licensePlateState) || emptyValues.licensePlateState,
        vehicleType: (initialValues && initialValues.vehicleType) || emptyValues.vehicleType,
        make: (initialValues && initialValues.make) || emptyValues.make,
        model: (initialValues && initialValues.model) || emptyValues.model,
        year: (initialValues && initialValues.year) || emptyValues.year,
        invoiceNumber: (initialValues && initialValues.invoiceNumber) || emptyValues.invoiceNumber,
        submitAction: (initialValues && initialValues.submitAction) || emptyValues.submitAction,
      }}
      onSubmit={async (values, actions) => {
        const { submitAction, ...valuesToSubmit } = values;
        let submitSuccess = false;

        if (submitAction) {
          if (
            (submitAction === "add" || submitAction === "save") &&
            registeredVehicles.findIndex((vehicle) => vehicle.licensePlate === values.licensePlate) !== -1
          ) {
            publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ALREADY_REGISTERED);
            return;
          }

          try {
            await onSubmit(submitAction, valuesToSubmit as ValidatedVehicleInfoFormValues);
            submitSuccess = true;
            if (submitAction === "add" || submitAction === "save") {
              publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ADDED_SUCCESS);
            } else if (submitAction === "update") {
              publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_UPDATED_SUCCESS);
            } else if (submitAction === "remove") {
              publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_REMOVED_SUCCESS);
            }
          } catch (err) {
            console.error(err);
            submitSuccess = false;
            if (submitAction === "add" || submitAction === "save") {
              publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ADDED_FAILURE);
            } else if (submitAction === "update") {
              publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_UPDATED_FAILURE);
            } else if (submitAction === "remove") {
              publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_REMOVED_FAILURE);
            }
          }
          actions.setSubmitting(false);

          // Reset form to add another vehicle if needed
          if (submitAction === "add") {
            submitSuccess &&
              actions.resetForm({
                values: {
                  licensePlate: "",
                  licensePlateState: null,
                  vehicleType: null,
                  make: null,
                  model: null,
                  year: null,
                  invoiceNumber: undefined,
                  submitAction: "add",
                },
              });
            firstInputRef.current && firstInputRef.current.focus();
          }
        }
      }}
      validationSchema={yup.object({
        licensePlate: LicensePlateField.validationSchema,
        licensePlateState: yup
          .mixed()
          .oneOf(LicensePlateState.All.map((lps) => lps.abbreviation))
          .required(REQUIRED_FIELD_ERROR),
        vehicleType: VehicleTypeDropdownField.validationSchema,
        make: VehicleMakeDropdownField.validationSchema,
        model: VehicleModelDropdownField.validationSchema,
        year: YearDropdownField.validationSchema,
      })}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate id={id} className={classes.root}>
          <FormObserver formType="vehicle" />
          <Grid container justifyContent="space-between" rowSpacing={{ xs: 3.5, tiny: 4, lg: 4 }} columnSpacing={2}>
            <Grid item xs={12} lg={12}>
              {loading || data_loading ? (
                <Skeleton width="100%">
                  <LicensePlateField />
                </Skeleton>
              ) : (
                <LicensePlateField
                  disabled={props.isSubmitting || disabled || variant === "remove" || !!licensePlate}
                  inputRef={firstInputRef}
                  {...(licensePlate ? { value: licensePlate } : undefined)}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={12}>
              {loading || data_loading ? (
                <Skeleton width="100%">
                  <StateDropdown />
                </Skeleton>
              ) : (
                <StateDropdown
                  autoHighlight
                  disabled={disabled || props.isSubmitting || variant === "remove"}
                  fullWidth
                  autoComplete
                  openOnFocus
                  onBlur={props.handleBlur}
                  required
                  id="license-plate-state"
                  name="licensePlateState"
                  label="License Plate State"
                  placeholder="License Plate State"
                  value={props.values.licensePlateState}
                  onChange={(e, v) => props.setFieldValue("licensePlateState", v)}
                  error={props.touched.licensePlateState && Boolean(props.errors.licensePlateState)}
                  helperText={(props.touched.licensePlateState && props.errors.licensePlateState) || ""}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={12}>
              {loading || data_loading ? (
                <Skeleton width="100%">
                  <VehicleTypeDropdownField />
                </Skeleton>
              ) : (
                <VehicleTypeDropdownField disabled={props.isSubmitting || disabled || variant === "remove"} />
              )}
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "24px !important" }}>
              <VehicleInfoFormButtons
                loading={loading || data_loading}
                allowCancelAndFinish={allowCancelAndFinish}
                allowAddAnotherVehicle={!props.values.invoiceNumber}
                onSaveAndContinue={onSaveAndContinue}
                disabled={
                  props.isSubmitting ||
                  disabled ||
                  !props.values.licensePlate ||
                  !props.values.licensePlateState ||
                  !props.values?.vehicleType
                }
                variant={variant}
                buttonTexts={buttonTexts}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

type RequiredInfoFormValues = "licensePlate" | "licensePlateState" | "vehicleType";

export interface VehicleInfoFormValues {
  id?: string;
  licensePlate: string;
  licensePlateState: StateAbbreviation | null;
  invoiceNumber?: string;
  vehicleType: VehicleTypeValue | null;
  make: VehicleMake | null;
  model: VehicleModel | null;
  year: number | null;
  submitAction: VehicleInfoFormSubmitAction | null;
}

export type ValidatedVehicleInfoFormValues = {
  [P in keyof VehicleInfoFormValues]: P extends RequiredInfoFormValues
    ? NonNullable<VehicleInfoFormValues[P]>
    : VehicleInfoFormValues[P];
};

export interface VehicleInfoFormProps {
  id?: string;
  allowCancelAndFinish?: boolean;
  onSaveAndContinue?: () => void;
  loading?: boolean;
  disabled?: boolean;
  initialValues?: Partial<VehicleInfoFormValues>;
  variant?: VehicleInfoFormVariant;
  licensePlate?: string;
  onSubmit?: (submitAction: VehicleInfoFormSubmitAction, values: ValidatedVehicleInfoFormValues) => Promise<void>;
  buttonTexts?: {
    addVehicle?: string;
    addAnotherVehicle?: string;
    update?: string;
    remove?: string;
    cancel?: string;
  };
}

export type VehicleInfoFormVariant = "add" | "update" | "remove";

export type VehicleInfoFormSubmitAction = "add" | "save" | "update" | "remove" | "cancel";

export default VehicleInfoForm;
