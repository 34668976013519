import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import {
  CREATE_APPLE_PAY_PAYMENT_SESSION,
  CreateApplePayMerchantSessionVars,
  CreateApplePayPaymentSessionRequestData,
} from "common/graphql/mutations";
import { useActivePayee } from "@app.automotus.io/components/hooks/useActivePayee";

// Extend global object
declare global {
  interface Window {
    ApplePaySession: ApplePaySession | undefined;
  }
}

/** Apple Pay Version Number */
const APPLE_PAY_VERSION_NUMBER = 10;

/** Apple Pay JS API is available or not */
const existsApplePayJsApi = !!(window.ApplePaySession && ApplePaySession.canMakePayments());

/** Apple Pay Merchant Identifier */
const merchantIdentifier = process.env.REACT_APP_APPLE_PAY_MERCHANT_IDENTIFIER || "";

/** The payment networks supported by the merchant. */
const supportedNetworks: string[] = ["amex", "discover", "masterCard", "visa"];

/** The payment capabilities supported by the merchant. The value must at least contain ApplePayMerchantCapability.supports3DS. */
const merchantCapabilities: ApplePayJS.ApplePayMerchantCapability[] = [
  "supports3DS",
  "supportsCredit",
  "supportsDebit",
];

/**
 * Apple Pay specific logic for staring a session, getting a charge token, and finally actually submitting a transaction
 *
 * @returns User Apple Pay Feature
 */
export const useApplePay = (): UseApplePayResult => {
  const [createApplePayPaymentSession] = useMutation<
    CreateApplePayPaymentSessionRequestData,
    CreateApplePayMerchantSessionVars
  >(CREATE_APPLE_PAY_PAYMENT_SESSION);
  const { activePayee } = useActivePayee();

  /**
   * Returns the boolean value of whether Apple Pay is available or not.
   *
   * @returns - Boolean value of whether Apple Pay is available or not.
   */
  const isApplePayAvailable = useCallback(async () => {
    return existsApplePayJsApi && (await ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier));
  }, []);

  /**
   * Returns the Apple Pay payment request with the given parameters.
   *
   * @param - The payment request parameters.
   *
   * @returns - Apple pay payment request object
   */
  const getPaymentRequest = ({
    countryCode,
    currencyCode,
    items,
    label,
    amount,
  }: PaymentRequestParams): ApplePayJS.ApplePayPaymentRequest => {
    return {
      countryCode: countryCode,
      currencyCode: currencyCode,
      lineItems: items,
      total: {
        type: "final",
        label: label,
        amount: amount,
      },
      supportedNetworks: supportedNetworks,
      merchantCapabilities: merchantCapabilities,
    };
  };

  /**
   * Perform Apple Pay Payment
   *
   * @param paymentRequestParams - The payment request parameters.
   * @param onpaymentauthorized - The callback function when payment is authorized.
   * @param oncancel - The callback function when payment is canceled.
   *
   * @returns void
   */
  const performApplePayPayment = useCallback(
    (
      paymentRequestParams: PaymentRequestParams,
      onpaymentauthorized: (session: ApplePaySession, event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => void,
      oncancel: (session: ApplePaySession, event: ApplePayJS.Event) => void,
    ) => {
      const session = new ApplePaySession(APPLE_PAY_VERSION_NUMBER, getPaymentRequest(paymentRequestParams));

      session.onvalidatemerchant = async (event) => {
        try {
          const { data } = await createApplePayPaymentSession({
            variables: {
              validationUrl: event.validationURL,
              payeeAccountId: activePayee?.payeeAccountId || "",
            },
          });

          if (data) {
            const {
              create_apple_pay_merchant_session: { session: merchantSession },
            } = data;

            session.completeMerchantValidation(merchantSession);
          }
        } catch (err) {
          console.error("failed to create apple pay payment session", err);
          session.abort();
          return;
        }
      };

      session.onpaymentauthorized = (event) => onpaymentauthorized(session, event);
      session.oncancel = (event) => oncancel(session, event);

      session.begin();
    },
    [createApplePayPaymentSession, activePayee],
  );

  return {
    isApplePayAvailable,
    performApplePayPayment,
  };
};

export interface PaymentRequestParams {
  /** The merchant's two-letter ISO 3166 country code. */
  countryCode: string;
  /** The three-letter ISO 4217 currency code for the payment. */
  currencyCode: string;
  /** A set of line items that explain recurring payments and/or additional charges. */
  items?: ApplePayJS.ApplePayLineItem[];
  /** The label for total payment */
  label: string;
  /** The amount of total payment - currency doesn't matter, it depends on currencyCode field */
  amount: string;
}

export interface UseApplePayResult {
  /** Returns the boolean value of whether Apple Pay is available or not */
  isApplePayAvailable: () => Promise<boolean>;
  /** Perform Apple Pay Payment */
  performApplePayPayment: (
    paymentRequestParams: PaymentRequestParams,
    onpaymentauthorized: (session: ApplePaySession, event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => void,
    oncancel: (session: ApplePaySession, event: ApplePayJS.Event) => void,
  ) => void;
}

export default useApplePay;
