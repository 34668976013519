import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BBMInvoiceForm, { BBMInfoFormProps } from "@app.automotus.io/components/forms/BBMInvoiceForm";
import { useMutation } from "@apollo/client";
import { PAY_BILL_SEARCH_INVOICE, PayBillSearchInvoiceData, PayBillSearchInvoiceVars } from "common/graphql";
import { useActivePayee, useTemporaryToken } from "@app.automotus.io/components/hooks";
import {
  SNACKBAR_MSGS,
  SnackbarMessageProps,
  useSnackPackContext,
} from "@app.automotus.io/components/context/SnackPack";
import { useNavigate } from "react-router-dom";
import { CITY_OPTIONS } from "common/constants";

export const BBMLandingPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isWide = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));
  const [payBillSearchInvoice] = useMutation<PayBillSearchInvoiceData, PayBillSearchInvoiceVars>(
    PAY_BILL_SEARCH_INVOICE,
  );
  const [, setTemporaryToken] = useTemporaryToken();
  const { publishSnackbarMessage } = useSnackPackContext();
  const navigate = useNavigate();
  const { setActivePayee } = useActivePayee();

  const handleSubmit: BBMInfoFormProps["onSubmit"] = async (submitAction, values) => {
    await payBillSearchInvoice({
      variables: {
        invoiceNumber: Number(values.invoiceNumber),
        licensePlateNumber: values.licensePlate,
        licensePlateState: values.licensePlateState.toUpperCase(),
      },
      onCompleted({ searchResult: { authToken, invoiceId, invoicePayeeAccountId, code, registeredVehicleId } }) {
        const cityOption = CITY_OPTIONS.find(({ payeeAccountId }) => {
          return payeeAccountId === invoicePayeeAccountId;
        });

        cityOption &&
          setActivePayee({
            cityName: cityOption.value,
            payeeAccountId: cityOption.payeeAccountId,
          });

        if (code === "ClaimedNewInvoiceVehicleAndAccount") {
          setTemporaryToken(authToken);
          navigate(`/paybill/${invoiceId}`);
        } else {
          navigate(`/vehicles/${registeredVehicleId}/invoices`);
        }
      },
      onError(err) {
        console.error("failed to search invoice", err);

        let snackbarMessage: SnackbarMessageProps = SNACKBAR_MSGS.INVOICE_SEARCH_ERROR_GENERIC;

        err.graphQLErrors.some(({ extensions }) => {
          switch (extensions.code) {
            case "InvoiceAlreadyPaid":
              snackbarMessage = SNACKBAR_MSGS.INVOICE_SEARCH_ERROR_ALREADY_PAID;
              return true;
            case "VehicleAlreadyRegistered":
              snackbarMessage = SNACKBAR_MSGS.INVOICE_SEARCH_ERROR_ALREADY_REGISTERED;
              return true;
            case "NotFound":
              snackbarMessage = SNACKBAR_MSGS.INVOICE_SEARCH_ERROR_NOT_FOUND;
              return true;
          }

          return false;
        });

        publishSnackbarMessage(snackbarMessage);
      },
    });
  };

  return (
    <Box sx={{ px: isMobile ? 0 : 2, mt: 2 }}>
      <Typography sx={{ fontWeight: 500 }} variant={isWide ? "h4" : isSmall ? "h6" : "h5"}>
        Invoice Payments
      </Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        We just need a few details to pull up your invoice.
      </Typography>
      <Box sx={{ mb: 2 }}>
        <BBMInvoiceForm onSubmit={handleSubmit} />
      </Box>
    </Box>
  );
};

export default BBMLandingPage;
