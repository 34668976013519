import { createTheme } from "@mui/material/styles";

const fontFamily = ["DM Sans", '"Helvetica"', '"Arial"', "sans-serif"].join(",");

export const themePrimary = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      tiny: 360,
      sm: 450,
      md: 700,
      lg: 1024,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#0D55BF",
      white: "#FFFFFF",
      black: "#000000",
    },
    error: {
      main: "#E31B0C",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-sizeSmall": {
            fontFamily,
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: "1.375rem",
            letterSpacing: "0.4px",
          },
          "&.MuiButton-sizeMedium": {
            fontFamily,
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "1.5rem",
            letterSpacing: "0.4px",
          },
          "&.MuiButton-sizeLarge": {
            fontFamily,
            fontSize: "1.125rem",
            fontWeight: 500,
            lineHeight: "1.625rem",
            letterSpacing: "0.46px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily,
          fontSize: "6rem",
        },
        h2: {
          fontFamily,
          fontSize: "3.75rem",
        },
        h3: {
          fontFamily,
          fontSize: "3rem",
        },
        h4: {
          fontFamily,
          fontSize: "2.125rem",
        },
        h5: {
          fontFamily,
          fontSize: "1.75rem",
        },
        h6: {
          fontFamily,
          fontSize: "1.5rem",
        },
        subtitle1: {
          fontFamily,
          fontSize: "1rem",
          fontWeight: 500,
        },
        subtitle2: {
          fontFamily,
          fontSize: "0.875rem",
          fontWeight: 500,
        },
        body1: {
          fontFamily,
          fontSize: "1rem",
        },
        body2: {
          fontFamily,
          fontSize: "1rem",
          fontWeight: 500,
        },
        button: {
          fontSize: "0.875rem",
        },
        caption: {
          fontFamily,
          fontSize: "0.75rem",
          letterSpacing: "0.4px",
        },
        overline: {
          fontFamily,
          fontSize: "0.75rem",
          letterSpacing: "1px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: "0.75rem",
          lineHeight: "0.75rem",
          letterSpacing: "0.15px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: "0.75rem",
          lineHeight: "1.25rem",
          letterSpacing: "0.4px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily,
          fontSize: "1rem",
          lineHeight: "1.5rem",
          letterSpacing: "0.15px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: "1.25rem",
          lineHeight: "1.25rem",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: "0.8125rem",
          lineHeight: "1.125rem",
          letterSpacing: "0.16px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily,
          fontSize: "0.625rem",
          lineHeight: "0.875rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily,
          fontSize: "1.125rem",
          fontWeight: 500,
          lineHeight: "1.5rem",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontFamily,
          fontSize: "0.75rem",
          lineHeight: "1.25rem",
        },
      },
    },
  },
  typography: {
    fontFamily: fontFamily,
    fontWeightMedium: 500,
    body3: {
      fontFamily,
      fontSize: "1rem",
      fontWeight: 700,
    },
    body4: {
      fontFamily,
      fontSize: "0.875rem",
    },
    subtitle1small: {
      fontFamily,
      fontSize: "0.9375rem",
      fontWeight: 500,
      letterSpacing: "0.15px",
    },
    subtitle2small: {
      fontFamily,
      fontSize: "0.75rem",
      fontWeight: 500,
      letterSpacing: "0.4px",
    },

    h1b: {
      fontFamily,
      fontSize: "6rem",
      fontWeight: 500,
    },
    h1small: {
      fontFamily,
      fontSize: "3.75rem",
    },
    h1bsmall: {
      fontFamily,
      fontSize: "3.75rem",
      fontWeight: 500,
    },
    h1xs: {
      fontFamily,
      fontSize: "3rem",
    },
    h1bxs: {
      fontFamily,
      fontSize: "3rem",
      fontWeight: 500,
    },
    h2b: {
      fontFamily,
      fontSize: "3.75rem",
      fontWeight: 500,
    },
    h2small: {
      fontFamily,
      fontSize: "3rem",
    },
    h2bsmall: {
      fontFamily,
      fontSize: "3rem",
      fontWeight: 500,
    },
    h2xs: {
      fontFamily,
      fontSize: "2.125rem",
    },
    h2bxs: {
      fontFamily,
      fontSize: "2.125rem",
      fontWeight: 500,
    },
    h3b: {
      fontFamily,
      fontSize: "3rem",
      fontWeight: 500,
    },
    h3small: {
      fontFamily,
      fontSize: "2.125rem",
    },
    h3bsmall: {
      fontFamily,
      fontSize: "2.125rem",
      fontWeight: 500,
    },
    h3xs: {
      fontFamily,
      fontSize: "1.75rem",
    },
    h3bxs: {
      fontFamily,
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h4b: {
      fontFamily,
      fontSize: "2.125rem",
      fontWeight: 500,
    },
    h4small: {
      fontFamily,
      fontSize: "1.75rem",
    },
    h4bsmall: {
      fontFamily,
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h4xs: {
      fontFamily,
      fontSize: "1.5rem",
    },
    h4bxs: {
      fontFamily,
      fontSize: "1.5rem",
      fontWeight: 500,
    },

    h5b: {
      fontFamily,
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h5small: {
      fontFamily,
      fontSize: "1.5rem",
    },
    h5bsmall: {
      fontFamily,
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h5xs: {
      fontFamily,
      fontSize: "1.25rem",
    },
    h5bxs: {
      fontFamily,
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6b: {
      fontFamily,
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h6small: {
      fontFamily,
      fontSize: "1.25rem",
    },
    h6bsmall: {
      fontFamily,
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6xs: {
      fontFamily,
      fontSize: "1.125rem",
    },
    h6bxs: {
      fontFamily,
      fontSize: "1.125rem",
      fontWeight: 500,
    },
    h7: {
      fontFamily,
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h7b: {
      fontFamily,
      fontSize: "1.25rem",
      fontWeight: 700,
    },
    h7small: {
      fontFamily,
      fontSize: "1.125rem",
      fontWeight: 500,
    },
    h7bsmall: {
      fontFamily,
      fontSize: "1.125rem",
      fontWeight: 700,
    },
    tooltip: {
      fontFamily,
      fontSize: "0.625rem",
      lineHeight: "0.875rem",
    },
  },
});

/** Allow for new material Typography variants */
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    tiny: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  interface SimplePaletteColorOptions {
    white?: string;
    black?: string;
  }
  interface PaletteColor {
    white?: string;
    black?: string;
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    subtitle1small: React.CSSProperties;
    subtitle2small: React.CSSProperties;
    h1b: React.CSSProperties;
    h1small: React.CSSProperties;
    h1bsmall: React.CSSProperties;
    h1xs: React.CSSProperties;
    h1bxs: React.CSSProperties;
    h2b: React.CSSProperties;
    h2small: React.CSSProperties;
    h2bsmall: React.CSSProperties;
    h2xs: React.CSSProperties;
    h2bxs: React.CSSProperties;
    h3b: React.CSSProperties;
    h3small: React.CSSProperties;
    h3bsmall: React.CSSProperties;
    h3xs: React.CSSProperties;
    h3bxs: React.CSSProperties;
    h4b: React.CSSProperties;
    h4small: React.CSSProperties;
    h4bsmall: React.CSSProperties;
    h4xs: React.CSSProperties;
    h4bxs: React.CSSProperties;
    h5b: React.CSSProperties;
    h5small: React.CSSProperties;
    h5bsmall: React.CSSProperties;
    h5xs: React.CSSProperties;
    h5bxs: React.CSSProperties;
    h6b: React.CSSProperties;
    h6small: React.CSSProperties;
    h6bsmall: React.CSSProperties;
    h6xs: React.CSSProperties;
    h6bxs: React.CSSProperties;
    h7: React.CSSProperties;
    h7b: React.CSSProperties;
    h7small: React.CSSProperties;
    h7bsmall: React.CSSProperties;
    tooltip: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    subtitle1small?: React.CSSProperties;
    subtitle2small?: React.CSSProperties;
    h1b?: React.CSSProperties;
    h1small?: React.CSSProperties;
    h1bsmall?: React.CSSProperties;
    h1xs?: React.CSSProperties;
    h1bxs?: React.CSSProperties;
    h2b?: React.CSSProperties;
    h2small?: React.CSSProperties;
    h2bsmall?: React.CSSProperties;
    h2xs?: React.CSSProperties;
    h2bxs?: React.CSSProperties;
    h3b?: React.CSSProperties;
    h3small?: React.CSSProperties;
    h3bsmall?: React.CSSProperties;
    h3xs?: React.CSSProperties;
    h3bxs?: React.CSSProperties;
    h4b?: React.CSSProperties;
    h4small?: React.CSSProperties;
    h4bsmall?: React.CSSProperties;
    h4xs?: React.CSSProperties;
    h4bxs?: React.CSSProperties;
    h5b?: React.CSSProperties;
    h5small?: React.CSSProperties;
    h5bsmall?: React.CSSProperties;
    h5xs?: React.CSSProperties;
    h5bxs?: React.CSSProperties;
    h6b?: React.CSSProperties;
    h6small?: React.CSSProperties;
    h6bsmall?: React.CSSProperties;
    h6xs?: React.CSSProperties;
    h6bxs?: React.CSSProperties;
    h7?: React.CSSProperties;
    h7b?: React.CSSProperties;
    h7small?: React.CSSProperties;
    h7bsmall?: React.CSSProperties;
    tooltip: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    subtitle1small: true;
    subtitle2small: true;
    h1b: true;
    h1small: true;
    h1bsmall: true;
    h1xs: true;
    h1bxs: true;
    h2b: true;
    h2small: true;
    h2bsmall: true;
    h2xs: true;
    h2bxs: true;
    h3b: true;
    h3small: true;
    h3bsmall: true;
    h3xs: true;
    h3bxs: true;
    h4b: true;
    h4small: true;
    h4bsmall: true;
    h4xs: true;
    h4bxs: true;
    h5b: true;
    h5small: true;
    h5bsmall: true;
    h5xs: true;
    h5bxs: true;
    h6b: true;
    h6small: true;
    h6bsmall: true;
    h6xs: true;
    h6bxs: true;
    h7: true;
    h7b: true;
    h7small: true;
    h7bsmall: true;
    tooltip: true;
  }
}
