import React from "react";
import VehicleInfoForm, {
  ValidatedVehicleInfoFormValues,
  VehicleInfoFormSubmitAction,
} from "@app.automotus.io/components/forms/VehicleInfoForm";
import Box from "@mui/material/Box";
import VehicleModalHeader from "../VehicleModalHeader";

/** A component that displays a modal allowing the user to add a vehicle to his account. */
export const AddVehicleModal: React.FC<AddVehicleModalProps> = ({
  onClose = () => undefined,
  onAddVehicle,
  onAddAnotherVehicle,
}) => {
  const onSubmit = async (
    submitAction: VehicleInfoFormSubmitAction,
    values: ValidatedVehicleInfoFormValues,
  ): Promise<void> => {
    try {
      if (submitAction === "save") {
        await onAddVehicle(values);
      } else if (submitAction === "add") {
        await onAddAnotherVehicle(values);
      }
    } catch (err) {
      console.error(err);
      throw new Error("Failed to add vehicle");
    }
  };

  return (
    <Box>
      <Box sx={{ mb: { xs: 3, lg: 4 } }}>
        <VehicleModalHeader title="Add Vehicle" description="Required Field*" onClose={onClose} />
      </Box>
      <Box>
        <VehicleInfoForm variant="add" onSubmit={onSubmit} buttonTexts={{ addAnotherVehicle: "Add Another Vehicle" }} />
      </Box>
    </Box>
  );
};

/** Props passed to initialize an {@link AddVehicleModal} component */
export interface AddVehicleModalProps {
  /** handler fired when user requests to close modal */
  onClose?: () => void;
  /** handler fired when user requests to add the vehicle */
  onAddVehicle: (values: ValidatedVehicleInfoFormValues) => Promise<void>;
  /** handler fired when user requests to add another vehicle */
  onAddAnotherVehicle: (values: ValidatedVehicleInfoFormValues) => Promise<void>;
}

export default AddVehicleModal;
