import React from "react";

const AppleIconDisabled = (): React.ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1771_38397)">
        <path
          d="M15.4581 10.6254C15.4862 13.652 18.1133 14.6592 18.1424 14.672C18.1202 14.743 17.7226 16.1074 16.7583 17.5166C15.9247 18.735 15.0596 19.9488 13.6967 19.974C12.3575 19.9986 11.9269 19.1799 10.3959 19.1799C8.8653 19.1799 8.38686 19.9489 7.11919 19.9987C5.80367 20.0485 4.80187 18.6812 3.96139 17.4673C2.24389 14.9842 0.931365 10.4508 2.69376 7.39061C3.56927 5.87092 5.13389 4.90859 6.83214 4.88391C8.12395 4.85927 9.34323 5.753 10.133 5.753C10.9222 5.753 12.4039 4.67821 13.9616 4.83606C14.6137 4.8632 16.4442 5.09947 17.6195 6.81993C17.5248 6.87864 15.4354 8.09499 15.4581 10.6254ZM12.9413 3.1933C13.6397 2.34789 14.1098 1.171 13.9816 0C12.9749 0.0404618 11.7575 0.670851 11.0354 1.5158C10.3883 2.26404 9.82152 3.46164 9.97444 4.60946C11.0966 4.69628 12.2429 4.03925 12.9413 3.1933Z"
          fill="#808080"
        />
      </g>
      <defs>
        <clipPath id="clip0_1771_38397">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppleIconDisabled;
