import { makeVar } from "@apollo/client";
import { StateAbbreviation, VehicleCategoryValue, VehicleTypeValue } from "@app.automotus.io/components/common";
import { VehicleMake, VehicleModel } from "common/graphql";

export interface ProfileInfo {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  companies: string[] | null;
  phoneNumber: string | null;
  isAgreedToEmailUpdates: boolean | null;
  isAgreedToTermsOfService: boolean | null;
}

export interface PaymentInfo {
  cardNumber: string | null;
  expiry: string | null;
  cvc: string | null;
  zipCode: string | null;
  walletBalance: number | null;
}

export interface VehicleInfo {
  licensePlate: string | null;
  licensePlateState: StateAbbreviation | null;
  invoiceNumber?: string;
  vehicleCategory: VehicleCategoryValue | null;
  vehicleType: VehicleTypeValue | null;
  make: VehicleMake | null;
  model: VehicleModel | null;
  year: number | null;
}

export const profileInfoVar = makeVar({
  firstName: null,
  lastName: null,
  email: null,
  companies: null,
  phoneNumber: null,
  isAgreedToEmailUpdates: null,
  isAgreedToTermsOfService: null,
} as ProfileInfo);

export const paymentInfoVar = makeVar({
  cardNumber: null,
  expiry: null,
  cvc: null,
  zipCode: null,
  walletBalance: null,
} as PaymentInfo);

export const vehicleInfoVar = makeVar({
  licensePlate: null,
  licensePlateState: null,
  vehicleCategory: null,
  vehicleType: null,
  make: null,
  model: null,
  year: null,
} as VehicleInfo);
