import { gql } from "@apollo/client";

export const REQUEST_WALLET_REFUND = gql`
  mutation RequestWalletRefund($walletLedgerAccountId: uuid!, $refundAmount: numeric!) {
    requestRefund: request_refund(wallet_ledger_account_id: $walletLedgerAccountId, refund_amount: $refundAmount) {
      requestId: request_id
      sentToEmail: sent_to_email
      submittedAt: submitted_at
      refundAmount: refund_amount
      walletLedgerAccountId: wallet_ledger_account_id
    }
  }
`;

export interface RequestWalletRefundData {
  requestRefund: RequestRefundOutput;
}

export interface RequestRefundOutput {
  requestId: string;
  sentToEmail: string;
  submittedAt: string;
  refundAmount: number;
  walletLedgerAccountId: string;
}

export interface RequestWalletRefundVars {
  walletLedgerAccountId: string;
  refundAmount: number;
}
