import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BillByMailPaymentConfirmation from "@app.automotus.io/components/BillByMailPaymentConfirmation/BillByMailPaymentConfirmation";
import {
  GET_INVOICE_BY_ID,
  GetInvoiceByIdData,
  GetInvoiceByIdVars,
  GET_PAYMENT_METHODS,
  GetPaymentMethodsData,
} from "common/graphql";
import { useQuery } from "@apollo/client";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { useTemporaryToken } from "@app.automotus.io/components/hooks";

export const BillByMailConfirmationPage: React.FC = () => {
  const { invoiceId = "" } = useParams<{ invoiceId: string }>();
  const navigate = useNavigate();
  const [, setTemporaryToken] = useTemporaryToken();

  const {
    data: invoiceData,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const { data: paymentMethodsData } = useQuery<GetPaymentMethodsData>(GET_PAYMENT_METHODS);

  let paymentMethod;
  if (paymentMethodsData) {
    const { paymentMethods } = paymentMethodsData;
    paymentMethod = paymentMethods[0];
  }

  const refetchOnError = () => {
    if (invoiceError) {
      refetchInvoice();
    }
  };

  if (invoiceId && invoiceError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  return (
    <BillByMailPaymentConfirmation
      onPayAnotherBill={() => {
        setTemporaryToken(undefined);
        navigate("/paybill");
      }}
      paymentMethod={paymentMethod}
      amount={invoiceData?.invoice?.amount_paid ?? 0}
      invoiceNumber={`${invoiceData?.invoice?.index || ""}`}
      licensePlate={
        invoiceData?.invoice?.registered_vehicle?.license_plate
          ? invoiceData.invoice.registered_vehicle.license_plate
          : ""
      }
    />
  );
};

export default BillByMailConfirmationPage;
