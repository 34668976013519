import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import PasswordlessLoginContainer from "@app.automotus.io/components/Login/PasswordlessLogin";
import { otpLogin, otpStart } from "@app.automotus.io/components/auth";
import AuthLayout from "@app.automotus.io/components/Layout/AuthLayout";
import { FullScreenLoader } from "@app.automotus.io/components/FullScreenLoader";
import { useUserProfile } from "@app.automotus.io/components/hooks";

export const SignUp: React.FC = () => {
  const { userProfile, loading } = useUserProfile();
  const { invoiceId = "" } = useParams<{ invoiceId: string }>();

  useEffect(() => {
    sessionStorage.setItem("claimInvoiceId", invoiceId);
  }, [invoiceId]);

  return (
    <FullScreenLoader loading={loading}>
      {userProfile ? (
        <Navigate to={`/paybill/claims`} />
      ) : (
        <AuthLayout>
          <PasswordlessLoginContainer
            requestConfirmationCode={(phoneNumber) => otpStart({ phoneNumber })}
            requestToConfirmCode={(phoneNumber, otp) => otpLogin({ phoneNumber, otp })}
          />
        </AuthLayout>
      )}
    </FullScreenLoader>
  );
};

export default SignUp;
