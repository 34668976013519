import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { InvoiceData } from "common/graphql";
import React from "react";
import { VehicleCardFooterAction } from "./VehicleCardFooter";
import { formatCurrency } from "common/format";

export const VehicleInvoiceTotalFooter: React.FC<{
  invoices: InvoiceData[];
  totalDueAmount: number;
  disabled?: boolean;
}> = ({ invoices, totalDueAmount, disabled = false }) => {
  return (
    <Box
      sx={{
        display: "flex",
        paddingTop: "16px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
      }}
    >
      {invoices.length !== 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            alignSelf: "stretch",
          }}
        >
          <Typography variant="h7" fontWeight={500}>
            Total Due
          </Typography>
          <Typography variant="h7" fontWeight={500}>
            ${formatCurrency(totalDueAmount)}
          </Typography>
        </Box>
      )}
      {invoices.length !== 0 && (
        <VehicleCardFooterAction
          variant="contained"
          invoices={invoices}
          disabled={disabled}
          buttonText="Pay all Invoices"
        />
      )}
    </Box>
  );
};
