import { gql } from "@apollo/client";
import { UserProfile, userProfileFragment } from "./userProfileFragment";

/** Reusable graphql fragment for selecting the standard set of fields for a user's identity */
export const identityFragment = gql`
  fragment identityFragment on auth_identity {
    email
    emailVerified: email_verified
    phoneNumber: phone_number
    phoneNumberVerified: phone_number_verified
    subjectIdentifier: subject_identifier
    userProfile: user_profile {
      ...userProfileFragment
    }
  }
  ${userProfileFragment}
`;

export interface Identity {
  /** Email attached to the identity, if any */
  email: string | null;
  /** Email verification status */
  emailVerified: boolean;
  /** Phone number attached to the identity, if any */
  phoneNumber: string | null;
  /** Phone number verification status */
  phoneNumberVerified: boolean;
  /** Unique identifier of the identity */
  subjectIdentifier: string;
  /** User profile attached to the identity */
  userProfile: UserProfile;
}

export default identityFragment;
