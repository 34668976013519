import React from "react";
import Link, { LinkProps } from "@mui/material/Link";
import { styled } from "@mui/material/styles";

const StyledLink = styled<React.FC<LinkProps<"button", { component: "button" }>>>(Link)<
  LinkProps<"button", { component: "button" }>
>(({ disabled, theme }) => ({
  color: disabled ? theme.palette.text.disabled : undefined,
  "&[disabled]": {
    cursor: "default",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
})) as React.FC<
  Omit<LinkProps<"button", { component: "button" }>, "onClick"> & {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }
>;

/** Props passed to initialize a {@link LinkButton} */
export type LinkButtonProps = Omit<LinkProps<"button", { component: "button" }>, "component" | "onClick"> & {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

/**
 * A link button component that displays properly when disabled.
 */
export const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(({ sx, disabled, ...props }, ref) => (
  <StyledLink
    {...props}
    component="button"
    disabled={disabled}
    ref={ref}
    sx={{ pointerEvents: disabled ? "none" : undefined, ...sx }}
  />
));

export default LinkButton;
