import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "@app.automotus.io/components/FullScreenLoader";
import { CLAIM_INVOICE, ClaimInvoiceData, ClaimInvoiceVars } from "common/graphql/mutations/ClaimInvoice";
import { useMutation } from "@apollo/client";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { useAuth0 } from "@auth0/auth0-react";
import { UserClaims } from "common/models";

export const BillByMailInvoiceClaimsPage: React.FC = () => {
  const navigate = useNavigate();
  const invoiceId = sessionStorage.getItem("claimInvoiceId");
  const [claimInvoice] = useMutation<ClaimInvoiceData, ClaimInvoiceVars>(CLAIM_INVOICE);
  const { isAuthenticated } = useAuth0<UserClaims>();

  useEffect(() => {
    if (!isAuthenticated || !invoiceId || !claimInvoice || !navigate) {
      return;
    }

    const claim = async () => {
      try {
        await claimInvoice({
          variables: {
            invoiceId,
          },
        });

        sessionStorage.removeItem("claimInvoiceId");

        navigate(`/signup/profile`, { replace: true, state: { invoiceId } });
      } catch (err) {
        console.error(err);
      }
    };

    claim();
  }, [isAuthenticated, invoiceId, claimInvoice, navigate]);

  if (!invoiceId) {
    return (
      <LocalLevelError
        onTryAgain={() => {
          navigate("/paybill");
        }}
      />
    );
  }

  return <FullScreenLoader loading />;
};

export default BillByMailInvoiceClaimsPage;
