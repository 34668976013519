import { gql } from "@apollo/client";

export const GET_GATEWAY_AUTHORIZATION_METADATA = gql`
  query GetGatewayAuthorizationMetadata($payeeAccountId: uuid!) {
    gatewayAuthorizationMetadata: financial_gateway_authorization_metadata(
      where: { payee_account_id: { _eq: $payeeAccountId } }
      limit: 1
    ) {
      payeeAccountId: payee_account_id
      acceptJsLoginId: payee_gateway_account_id
      acceptJsClientKey: payee_acceptjs_client_key
    }
  }
`;

export interface GetGatewayAuthorizationMetadataData {
  gatewayAuthorizationMetadata: {
    payeeAccountId: string;
    acceptJsLoginId: string;
    acceptJsClientKey: string | null;
  }[];
}

export interface GetGatewayAuthorizationMetadataVars {
  payeeAccountId: string;
}
