import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Routes from "./routes";
import { FullScreenLoader } from "@app.automotus.io/components/FullScreenLoader";

export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  return (
    <FullScreenLoader loading={isLoading}>
      <Routes />
    </FullScreenLoader>
  );
};

export default App;
