import { useState, useEffect } from "react";

/**
 * Returns inner window dimensions
 */
function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * Hook that returns the inner dimensions of the current window.
 * @returns The current inner dimensions of the window
 */
export function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

/** Dimensions of the window */
export interface WindowDimensions {
  /** Width in pixels */
  width: number;
  /** Height in pixels */
  height: number;
}

export default useWindowDimensions;
