import { GET_USER_PROFILE, GetUserProfileData, GetUserProfileVars, UserProfile } from "common/graphql";
import { useAuth0 } from "@auth0/auth0-react";
import { HASURA_CLAIMS_NAMESPACE, USER_ID_CLAIM, UserClaims } from "common/models/UserClaims";
import { ApolloClient, ApolloError, NetworkStatus, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";

export function useUserProfile(options?: UseUserProfileOptions): UseUserProfileResult {
  const { user: userClaims } = useAuth0<UserClaims>();
  const userId = (userClaims && userClaims[HASURA_CLAIMS_NAMESPACE][USER_ID_CLAIM]) || "";
  const { data, previousData, ...rest } = useQuery<GetUserProfileData, GetUserProfileVars>(GET_USER_PROFILE, {
    ...options,
    variables: { userId },
  });

  return {
    userProfile: data?.userProfile,
    previousUserProfile: previousData?.userProfile,
    ...rest,
  };
}

export type UseUserProfileOptions = Omit<QueryHookOptions<GetUserProfileData, GetUserProfileVars>, "variables">;

export interface UseUserProfileResult {
  userProfile?: UserProfile;
  previousUserProfile?: UserProfile;
  loading: boolean;
  error?: ApolloError;
  networkStatus: NetworkStatus;
  called: boolean;
  refetch: QueryResult<GetUserProfileData, GetUserProfileVars>["refetch"];
  client: ApolloClient<unknown>;
}

export default useUserProfile;
