import { gql } from "@apollo/client";
import {
  EventPolicyComplianceDetail,
  eventPolicyComplianceDetailFragment,
} from "common/graphql/fragments/eventPolicyComplianceDetailFragment";
import { RateApplicationType } from "common/graphql";

export const eventPolicyComplianceFragment = gql`
  fragment eventPolicyComplianceFragment on event_policy_compliance {
    price
    policy {
      policyRules: rules {
        rule {
          rateApplicationType: rate_application_type
        }
      }
    }
    details(order_by: [{ rate_start_at: asc }]) {
      ...eventPolicyComplianceDetailFragment
    }
  }
  ${eventPolicyComplianceDetailFragment}
`;

export interface EventPolicyCompliance {
  id: string;
  price: number;
  details: EventPolicyComplianceDetail[];
  policy: {
    policyRules: {
      rule: {
        rateApplicationType: RateApplicationType;
      };
    }[];
  };
}
