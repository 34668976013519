import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Component that renders the text header for the AddVehicle component.
 */
export const TextHeader: React.FC<TextHeaderProps> = ({ isAddingAnotherVehicle }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));

  return (
    <Box>
      <Typography variant={isDesktop ? "h4b" : isSmall ? "h4bxs" : "h3bxs"}>
        {`${isAddingAnotherVehicle ? "Another " : ""}`}{" "}
        <span style={{ color: theme.palette.primary.main }}>CurbPass</span> Vehicle
      </Typography>
    </Box>
  );
};

export interface TextHeaderProps {
  /** Determines whether user is adding his first vehicle or an additonal vehicle */
  isAddingAnotherVehicle?: boolean;
}

export default TextHeader;
