import React from "react";
import {
  VirtualizedAutocomplete,
  VirtualizedAutocompleteProps,
} from "@app.automotus.io/components/VirtualizedAutocomplete";
import TextField from "@mui/material/TextField";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";
import GET_ALL_VEHICLE_MAKES, { GetAllVehicleMakesData, VehicleMake } from "common/graphql/queries/GetAllVehicleMakes";
import { useQuery } from "@apollo/client";

const VehicleMakeDropdownFieldPrivate: React.FC<VehicleMakeDropdownFieldProps> = ({
  name = "make",
  label = "Make",
  placeholder = "Make",
  disablePortal = true,
  fullWidth = true,
  autoSelect = false,
  autoHighlight = true,
  disableClearable = false,
  openOnFocus = true,
  required = false,
  helperText,
  disabled,
  ...rest
}) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta, helpers] = useField<VehicleMake | null>(name);
  // TODO: what if an error occurs?
  const { loading, data } = useQuery<GetAllVehicleMakesData>(GET_ALL_VEHICLE_MAKES);

  const showError = meta.touched && !!meta.error;

  return (
    <VirtualizedAutocomplete<VehicleMake, false, typeof disableClearable, false>
      {...rest}
      {...{ disablePortal, fullWidth, autoSelect, autoHighlight, disableClearable, openOnFocus }}
      multiple={false}
      loading={loading}
      value={meta.value}
      onChange={(e, v) => helpers.setValue(v)}
      onBlur={field.onBlur}
      disabled={disabled ?? isSubmitting}
      options={data?.makes || []}
      isOptionEqualToValue={(option, value) => option.makeId === value.makeId}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={showError}
          helperText={showError ? meta.error : helperText}
          label={label}
          placeholder={placeholder}
          required={required}
        />
      )}
    />
  );
};

/**
 * Component that renders an autocomplete dropdown allowing the user to input his
 * vehicle's make.
 */
export const VehicleMakeDropdownField = Object.assign(VehicleMakeDropdownFieldPrivate, {
  validationSchema: yup
    .object({
      makeId: yup.number().required(),
      name: yup.string().required(),
    })
    .nullable(),
  defaultInitialValue: null as VehicleMake | null,
});

/** Props passed to initialize a {@link VehicleMakeDropdownField} component */
export interface VehicleMakeDropdownFieldProps<DisableClearable extends boolean | undefined = true>
  extends Omit<
    VirtualizedAutocompleteProps<VehicleMake, false, DisableClearable, false>,
    "renderInput" | "options" | "getOptionLabel" | "onChange" | "onBlur" | "value"
  > {
  name?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
}

export default VehicleMakeDropdownField;
