import { gql } from "@apollo/client";
import {
  PayerTransactionLogEntry,
  payerTransactionLogEntryFragment,
} from "common/graphql/fragments/payerTransactionLogEntryFragment";

export const GET_PAYMENT_TRANSACTIONS = gql`
  query GetPaymentTransactions($limit: Int = 50, $offset: Int = 0) {
    transactions: financial_transaction_log(order_by: [{ transaction_ts: desc }], limit: $limit, offset: $offset) {
      ...payerTransactionLogEntryFragment
    }
  }
  ${payerTransactionLogEntryFragment}
`;

export interface GetPaymentTransactionsData {
  transactions: PayerTransactionLogEntry[];
}

export interface GetPaymentTransactionsVars {
  limit?: number;
  offset?: number;
}
