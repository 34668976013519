import React from "react";
import {
  CARD_METHOD_FORM_DEFAULT_INITIAL_VALUES,
  CardMethodForm,
  CardMethodFormProps,
  cardMethodFormValidationSchema,
} from "@app.automotus.io/components/forms/PaymentMethodForm/CardMethodForm";
import { Form, Formik } from "formik";

export const AddPaymentMethodForm: React.FC<AddPaymentMethodFormProps> = ({
  loading = false,
  onSubmit = () => Promise.resolve(),
}) => {
  const handleSubmit: CardMethodFormProps["onSubmit"] = (formValues) => {
    return onSubmit({ action: "add", ...formValues });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ ...CARD_METHOD_FORM_DEFAULT_INITIAL_VALUES }}
      validationSchema={cardMethodFormValidationSchema}
    >
      <Form>
        <CardMethodForm onSubmit={handleSubmit} loading={loading} />
      </Form>
    </Formik>
  );
};

export interface AddPaymentMethodFormProps {
  loading?: boolean;
  onSubmit?: AddPaymentMethodFormSubmitFunc;
  onClose?: () => void;
}

export type AddPaymentMethodFormSubmitFunc = (params: {
  zipCode: string;
  action: "add";
  cvc: string;
  expiry: string;
  cardNumber: string;
}) => Promise<void>;
