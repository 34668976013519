import React, { ReactNode } from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const MobileNavBarItem: React.FC<MobileNavBarItemProps> = ({ onClick, title, icon, submenuItem = false }) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      sx={{
        color: submenuItem ? theme.palette.text.primary : theme.palette.primary.white,
        px: 2,
        py: 1.5,
        width: "100%",
        textAlign: "start",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        typography: "body1",
        textTransform: "capitalize",
      }}
    >
      {title}
      {!!icon && icon}
    </Button>
  );
};

export interface MobileNavBarItemProps {
  title: string;
  onClick: (e: React.SyntheticEvent) => void;
  icon?: ReactNode;
  submenuItem?: boolean;
}
