import React from "react";
import { TextField, TextFieldProps } from "formik-mui";
import InputAdornment from "@mui/material/InputAdornment";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";
import CardCVC from "@app.automotus.io/components/icons/CardCVC";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const CVCField = Object.assign(
  ({
    name = "cvc",
    id = "cvc",
    label = "CVC",
    placeholder = "CVC",
    required = true,
    fullWidth = true,
    ...rest
  }: CVCFieldProps) => {
    const form = useFormikContext();
    const [field, meta, { setValue }] = useField(name);

    const handleChange = React.useCallback(
      (event) => {
        const onlyNums = event.target.value.substr(0, 4).replace(/[^0-9]/g, "");
        setValue(onlyNums);
      },
      [setValue],
    );

    return (
      <Box>
        <Typography variant="body3" sx={{ fontWeight: 500 }}>
          {label} *
        </Typography>
        <TextField
          InputLabelProps={{ shrink: false }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CardCVC />
              </InputAdornment>
            ),
          }}
          {...rest}
          {...{ id, placeholder, required, fullWidth }}
          label=""
          meta={meta}
          form={form}
          helperText={" "}
          field={{
            ...field,
            onChange: handleChange,
          }}
          sx={{ mt: 1 }}
        />
      </Box>
    );
  },
  {
    defaultInitialValue: "",
    validationSchema: yup
      .string()
      .required("Valid CVC required.")
      .max(4, "4 numbers maximum")
      .min(3, "3 numbers minimum"),
  },
);

export interface CVCFieldProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  name?: string;
}

export default CVCField;
