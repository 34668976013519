import React from "react";
import AutoCapitalizeField, { AutoCapitalizeFieldProps } from "../AutoCapitalizeField";
import * as yup from "yup";

export const LastNameField: LastNameFieldComponent = ({
  autoComplete = "family-name",
  required = true,
  fullWidth = true,
  id = "last-name",
  name = "lastName",
  label = "Last Name",
  placeholder = "Last Name*",
  ...rest
}) => (
  <AutoCapitalizeField
    {...{ autoComplete, required, fullWidth, id, name, label, placeholder }}
    {...rest}
    inputProps={{ maxLength: 20 }}
  />
);

const validationSchema = yup.string().required("Last name required.");
LastNameField.validationSchema = validationSchema;

LastNameField.defaultInitialValue = "";

LastNameField.defaultProps = {
  autoComplete: "family-name",
  required: true,
  fullWidth: true,
  id: "last-name",
  name: "lastName",
  label: "Last Name",
  placeholder: "Last Name*",
};

export type LastNameFieldProps = Partial<AutoCapitalizeFieldProps>;

export interface LastNameFieldComponent extends React.FC<LastNameFieldProps> {
  validationSchema: typeof validationSchema;
  defaultInitialValue: string;
}

export default LastNameField;
