import React from "react";
import LogoBlue from "@app.automotus.io/components/images/AppLogo/logoBlue";
import LogoWhite from "@app.automotus.io/components/images/AppLogo/LogoWhite";

/**
 * A component used to render the Automotus logo image displayed during the sign-up
 * process.
 * @returns A JSX element that renders the Automotus logo image
 */
export const LogoImage: React.FC = () => <LogoBlue height={24} width={186} />;
export const LogoImageWhite: React.FC = () => <LogoWhite height={24} />;

export default LogoImage;
