import { gql } from "@apollo/client";
import { TransactionDetail, transactionDetailFragment } from "common/graphql/fragments";

export const GET_TRANSACTION_DETAIL = gql`
  query GetTransactionDetail($transactionId: uuid!) {
    transaction: financial_transaction_by_pk(id: $transactionId) {
      ...transactionDetailFragment
    }
  }
  ${transactionDetailFragment}
`;

export interface GetTransactionDetailData {
  transaction: TransactionDetail | null;
}

export interface GetTransactionDetailVars {
  transactionId: string;
}
