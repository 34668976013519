import React from "react";
import { RegisteredVehicle } from "common/graphql";
import Grid from "@mui/material/Grid";
import { LinkButton } from "@app.automotus.io/components/LinkButton";
import Typography from "@mui/material/Typography";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import RegisteredVehicleTextDescription from "@app.automotus.io/components/common/RegisteredVehicleTextDescription";

/**
 * Renders a registered vehicle on a user's account page with buttons that allow the user to remove or modify the vehicle.
 * The remove operation should be disabled if the user only has one vehicle registered to her account.
 * The click handlers provided to the component should expect that the click event's current target value is the id of
 * the registered vehicle being rendered.
 */
export const AccountRegisteredVehicle: React.FC<AccountRegisteredVehicleProps> = ({
  title,
  loading,
  registeredVehicle,
  removeDisabled = false,
  onClickRemove = () => undefined,
  onClickEdit = () => undefined,
}) => {
  const theme = useTheme();

  return (
    <Grid container rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <LoadingSkeleton loading={loading} width="100px">
            <Typography variant="body3">{title || "Vehicle X"}</Typography>
          </LoadingSkeleton>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end" columnSpacing={3}>
          <Grid item>
            <LoadingSkeleton loading={loading}>
              <LinkButton
                variant="body1"
                disabled={loading || removeDisabled}
                value={registeredVehicle?.id}
                onClick={onClickRemove}
              >
                Remove
              </LinkButton>
            </LoadingSkeleton>
          </Grid>
          <Grid item>
            <LoadingSkeleton loading={loading}>
              <LinkButton variant="body1" disabled={loading} value={registeredVehicle?.id} onClick={onClickEdit}>
                Edit
              </LinkButton>
            </LoadingSkeleton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{ border: 1, borderRadius: 1, borderColor: "grey.400", height: theme.spacing(6), px: 1.5 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <LoadingSkeleton loading={loading} width="100%">
            <Typography variant="body1">
              {registeredVehicle ? (
                <RegisteredVehicleTextDescription registeredVehicle={registeredVehicle} />
              ) : (
                "placeholder"
              )}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Grid>
    </Grid>
  );
};

/** Props passed to initialize an {@link AccountRegisteredVehicle} component */
export interface AccountRegisteredVehicleProps {
  /** Title of the registered vehicle */
  title?: string;
  /** Loading status */
  loading?: boolean;
  /** Registered vehicle */
  registeredVehicle?: RegisteredVehicle;
  /** Disables remove button */
  removeDisabled?: boolean;
  /** Handler fired when remove is clicked. The value of the event's current target is the id of the registered vehicle */
  onClickRemove?: React.MouseEventHandler<HTMLButtonElement>;
  /** Handler fired when edit is clicked. The value of the event's current target is the id of the registered vehicle */
  onClickEdit?: React.MouseEventHandler<HTMLButtonElement>;
}

export default AccountRegisteredVehicle;
