import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";

export const DropdownModal: React.FC<DropdownModalProps> = ({
  loading = false,
  options = [],
  defaultValue = "",
  onSubmit = () => undefined,
  onClose = () => undefined,
  paperProps = {},
  open = true,
  dismissible = false,
}) => {
  return (
    <Dialog open={open} onBackdropClick={!dismissible ? undefined : onClose} PaperProps={paperProps}>
      <Box>
        <Formik<DropdownRequestValues>
          enableReinitialize={true}
          initialValues={{ value: defaultValue }}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form onReset={props.handleReset} noValidate>
              <LoadingSkeleton loading={loading} width="100%">
                <Box my={2} ml={3}>
                  <Typography variant="h7" sx={{ fontWeight: 500 }}>
                    Select Zone Location
                  </Typography>
                </Box>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading} width="100%">
                <Box sx={{ display: "flex" }}>
                  <Select
                    sx={{
                      "&&&:before": {
                        borderBottom: "none",
                      },
                      my: 1,
                      mx: 3,
                      height: { xs: "40px", tiny: "56px" },
                    }}
                    defaultValue={props.initialValues.value}
                    value={props.values.value}
                    onChange={(event) => {
                      props.setFieldValue("value", event.target.value);
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {options.map((item: DropdownData) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          <Box>{item.label}</Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading} width="100%">
                <Box sx={{ my: 1, display: "flex", justifyContent: "flex-end" }}>
                  <LoadingButton
                    sx={{ width: "79px", textTransform: "none", fontWeight: 500, fontSize: "1rem" }}
                    variant="text"
                    type={"submit"}
                    loading={loading}
                  >
                    Confirm
                  </LoadingButton>
                </Box>
              </LoadingSkeleton>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export interface DropdownRequestValues {
  value: string;
}

export interface DropdownData {
  label?: string;
  value?: string;
}

export interface DropdownModalProps {
  loading?: boolean;
  options: DropdownData[];
  defaultValue?: string;
  dismissible?: boolean;
  onSubmit?: (data: DropdownRequestValues) => void;
  onClose?: () => void;
  paperProps?: DialogProps["PaperProps"];
  open: boolean;
}

export default DropdownModal;
