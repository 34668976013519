import React from "react";
import AutoCapitalizeField, { AutoCapitalizeFieldProps } from "../AutoCapitalizeField";
import * as yup from "yup";

export const FirstNameField: FirstNameFieldComponent = ({
  autoComplete = "given-name",
  required = true,
  fullWidth = true,
  autoFocus = true,
  id = "first-name",
  name = "firstName",
  label = "First Name",
  placeholder = "First Name",
  ...rest
}) => (
  <AutoCapitalizeField
    {...rest}
    autoComplete={autoComplete}
    required={required}
    fullWidth={fullWidth}
    autoFocus={autoFocus}
    id={id}
    name={name}
    label={label}
    placeholder={placeholder}
    inputProps={{ maxLength: 20 }}
  />
);

const validationSchema = yup.string().required("First name required.");

FirstNameField.validationSchema = validationSchema;

FirstNameField.defaultInitialValue = "";

export type FirstNameFieldProps = Partial<AutoCapitalizeFieldProps>;

export interface FirstNameFieldComponent extends React.FC<FirstNameFieldProps> {
  validationSchema: typeof validationSchema;
  defaultInitialValue: string;
}

export default FirstNameField;
