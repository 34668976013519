import React from "react";
import Box from "@mui/material/Box";
import { RegisteredVehicle } from "common/graphql";
import VehicleInfoForm, {
  ValidatedVehicleInfoFormValues,
  VehicleInfoFormSubmitAction,
} from "@app.automotus.io/components/forms/VehicleInfoForm";
import VehicleModalHeader from "../VehicleModalHeader";
import { useRegisteredVehicleInitialValues } from "@app.automotus.io/components/hooks";

/** A component that displays a modal allowing the user to add a vehicle to his account. */
export const RemoveVehicleModal: React.FC<RemoveVehicleModalProps> = ({
  loading,
  registeredVehicle,
  onClose = () => undefined,
  onCancel,
  onSubmit,
}) => {
  const { initialValues, loadingMakes, loadingModels } = useRegisteredVehicleInitialValues(registeredVehicle);

  const onSubmitFunc = async (
    submitAction: VehicleInfoFormSubmitAction,
    values: ValidatedVehicleInfoFormValues,
  ): Promise<void> => {
    try {
      if (submitAction === "remove") {
        await onSubmit({ ...values, id: initialValues.id });
      } else if (submitAction === "cancel") {
        onCancel();
      }
    } catch (err) {
      console.error(err);
      throw new Error("Failed to remove vehicle");
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <VehicleModalHeader
          title="Remove Vehicle"
          description="Please confirm that you want to remove the following vehicle:"
          onClose={onClose}
        />
      </Box>
      <Box>
        <VehicleInfoForm
          loading={loading || loadingMakes || loadingModels}
          initialValues={initialValues}
          variant="remove"
          onSubmit={onSubmitFunc}
        />
      </Box>
    </Box>
  );
};

/** Props passed to initialize an {@link RemoveVehicleModal} component */
export interface RemoveVehicleModalProps {
  /** Loading status */
  loading: boolean;
  /** Information about the registered vehicle in its current state */
  registeredVehicle: RegisteredVehicle;
  /** handler fired when user requests to close modal */
  onClose?: () => void;
  /** handler fired when the user clicks the cancel button */
  onCancel: () => void;
  /** handler fired when user submits the request to remove the vehicle */
  onSubmit: (values: ValidatedVehicleInfoFormValues) => Promise<void>;
}

export default RemoveVehicleModal;
