import React from "react";
import { AppState, Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

/**
 * An {@link Auth0Provider} that uses react router to handle redirects.
 *
 * Users are redirected to the origin upon successful login, unless the user tried to
 * reach a protected route without being logged in.
 * @param props Props passed to initialize the component
 */
export const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children, ...rest }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!(domain && clientId && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      {...rest}
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={`${window.location.origin}`}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

/** Props used to initialize an {@link Auth0ProviderWithHistory} */
export type Auth0ProviderWithHistoryProps = Omit<
  Auth0ProviderOptions,
  "domain" | "clientId" | "audience" | "redirectUri" | "onRedirectCallback"
>;

export default Auth0ProviderWithHistory;
