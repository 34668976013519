import React, { useState } from "react";
import { FinishSignUp } from "@app.automotus.io/components/scenes/SignUp/FinishSignUp";
import { useLocation, useNavigate } from "react-router-dom";

export const SignUpFinish: React.FC = () => {
  const navigate = useNavigate();
  const [finishing, setFinishing] = useState(false);
  const location = useLocation();
  const { invoiceId } = (location.state || {}) as { invoiceId?: string };

  const handleClickFinish = async () => {
    setFinishing(true);

    navigate("/dashboard", { replace: true });

    setFinishing(false);
  };

  return (
    <FinishSignUp isBillByMail={!!invoiceId} onClickFinishSignUp={handleClickFinish} finishingSignUp={finishing} />
  );
};
