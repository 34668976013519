import React from "react";

const CheckCircle = (): React.ReactElement => {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M75 12.5C40.5 12.5 12.5 40.5 12.5 75C12.5 109.5 40.5 137.5 75 137.5C109.5 137.5 137.5 109.5 137.5 75C137.5 40.5 109.5 12.5 75 12.5ZM62.5 106.25L31.25 75L40.0625 66.1875L62.5 88.5625L109.938 41.125L118.75 50L62.5 106.25Z"
        fill="#3A833C"
      />
    </svg>
  );
};

export default CheckCircle;
