import { useRef, RefObject } from "react";

/**
 * Hook that returns a ref and a function that allows the user to set focus
 * to the refed element.
 * @returns A two item array where the first item is a ref to an HTML element
 * and the second is a function that sets the focus to the ref element.
 */
export const useFocus = (): [RefObject<HTMLElement>, () => void] => {
  const htmlElRef = useRef<HTMLElement>(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

export default useFocus;
