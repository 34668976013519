import { gql } from "@apollo/client";
import { PaymentMethod, paymentMethodFragment } from "common/graphql/fragments";

export const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod(
    $payeeAccountId: uuid!
    $gatewayAccess: json!
    $billingZip: String!
    $isDefault: Boolean = true
  ) {
    createPaymentMethod: create_payment_method(
      payee_account_id: $payeeAccountId
      payment_method_details: {
        gateway_access: $gatewayAccess
        billing_details: { zip: $billingZip }
        is_default: $isDefault
      }
    ) {
      paymentMethod: payment_method {
        ...paymentMethodFragment
      }
    }
  }
  ${paymentMethodFragment}
`;

export interface CreatePaymentMethodData {
  createPaymentMethod: {
    paymentMethod: PaymentMethod;
  };
}

export interface CreatePaymentMethodVars {
  payeeAccountId: string;
  gatewayAccess: {
    opaqueData?: unknown;
    applePayToken?: unknown;
  };
  billingZip: string;
  isDefault?: boolean;
}
