import React from "react";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import useIsMobile from "@app.automotus.io/components/hooks/useIsMobile";

/**
 * Component that renders the modal header for the add/update/remove vehicle component.
 */
export const VehicleModalHeader: React.FC<VehicleModalHeaderProps> = ({
  title,
  description,
  onClose = () => undefined,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <Grid container rowSpacing={{ xs: 1.5, lg: 2 }}>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        {!isMobile && (
          <Grid item container xs={6} justifyContent="flex-end" columnSpacing={3}>
            <IconButton onClick={onClose} size={"large"}>
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body4" color={theme.palette.text.secondary}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export interface VehicleModalHeaderProps {
  title: string;
  description: string;
  onClose?: () => void;
}

export default VehicleModalHeader;
