import React, { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PaymentMethod } from "common/graphql/fragments";
import { formatCurrency } from "common/format";
import currencySymbols from "currency-symbol-map";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { PartnerLandingPageHowItWorksModal } from "../scenes/PartnerLandingPage/PartnerLandingPageHowItWorks";

/**
 * The confirmation page is designed to provide feedback for the payment authorization initiated by the user.
 * This involves: status of the payment, status of the bill, and value addition.
 */
export const BillByMailPaymentConfirmation = ({
  currencyCode = "usd",
  amount,
  invoiceNumber,
  licensePlate,
  paymentMethod,
  onPayAnotherBill,
}: BillByMailPaymentConfirmationProps): JSX.Element => {
  const currencySymbol = currencySymbols(currencyCode.toUpperCase());
  const theme = useTheme();
  const [howItWorksModalOpen, setHowItWorksModalOpen] = useState(false);

  const handleOpenHowItWorksModal = () => {
    setHowItWorksModalOpen(true);
  };

  const handleCloseHowItWorksModal = () => {
    setHowItWorksModalOpen(false);
  };

  const getPaymentMethodDisplay = (paymentMethod: PaymentMethod) => {
    // TODO: Need to update the message when we have more information.
    if (paymentMethod.type === "credit_card") {
      return `Card (${paymentMethod.maskedNumber})`;
    } else {
      return "Bank";
    }
  };

  return (
    <Box py={2}>
      <Typography sx={{ typography: { xs: "h6", tiny: "h5", sm: "h4" } }}>Payment Successful!</Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        We've sent a confirmation to your email.
      </Typography>
      <Divider sx={{ mb: { xs: 0.5, tiny: 1.5, sm: 1.5 } }} />
      <Box sx={{ mb: 1.5, display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ typography: { xs: "body3", tiny: "h7" }, fontWeight: "500 !important" }}>
          Amount Paid
        </Typography>
        <Typography sx={{ typography: { xs: "body3", tiny: "h7" }, fontWeight: "500 !important" }}>
          {currencySymbol}
          {formatCurrency(amount)}
        </Typography>
      </Box>
      <Box
        sx={{
          mb: { xs: 2, tiny: 3 },
          py: 2,
          px: { xs: 0, tiny: 2 },
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.text.secondary,
        }}
      >
        <Stack spacing={1}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body4">Invoice Number</Typography>
            <Typography variant="body4">{invoiceNumber}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body4">License Plate Number</Typography>
            <Typography variant="body4">{licensePlate}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body4">Payment Method</Typography>
            {paymentMethod && (
              <Typography variant="body4">Paid with {getPaymentMethodDisplay(paymentMethod)}</Typography>
            )}
          </Box>
        </Stack>
      </Box>
      <Button
        id="pay-another-bill"
        sx={{
          fontWeight: { xs: 600, tiny: 600, sm: 500 },
          fontSize: { xs: "14px", tiny: "16px", sm: "18px" },
          textTransform: "none",
          width: "100%",
          height: { xs: "30px", tiny: "36px", lg: "42px" },
          mb: 3,
        }}
        onClick={onPayAnotherBill}
        variant="outlined"
      >
        Pay Another Bill
      </Button>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ typography: { xs: "h6", tiny: "h5", sm: "h4" } }}>
          Save With <span style={{ color: theme.palette.primary.main }}>CurbPass</span>
        </Typography>
        <Typography variant="body1">
          Avoid future administrative fees and save time by registering for CurbPass.
        </Typography>
      </Box>
      <Button
        id="register-in-bill-by-mail"
        component={RouterLink}
        fullWidth
        variant="contained"
        to="/login" // TODO: Update the cta handler
        sx={{
          mb: 2,
          fontWeight: { xs: 600, tiny: 600, sm: 500 },
          fontSize: { xs: "14px", tiny: "16px", sm: "18px" },
          textTransform: "none",
          width: "100%",
          height: { xs: "30px", tiny: "36px", lg: "42px" },
        }}
      >
        Register for a free trial
      </Button>
      <Button
        id="how-it-works"
        sx={{
          fontWeight: { xs: 600, tiny: 600, sm: 500 },
          fontSize: { xs: "14px", tiny: "16px", sm: "18px" },
          textTransform: "none",
          width: "100%",
          height: { xs: "30px", tiny: "36px", lg: "42px" },
        }}
        variant="outlined"
        fullWidth
        onClick={handleOpenHowItWorksModal}
      >
        How it Works
      </Button>
      <PartnerLandingPageHowItWorksModal open={howItWorksModalOpen} onClose={handleCloseHowItWorksModal} />
    </Box>
  );
};

export interface BillByMailPaymentConfirmationProps {
  /** Lower-case currency code of the amount */
  currencyCode?: string;
  /** Bill amount */
  amount: number;
  /** Invoice number */
  invoiceNumber: string;
  /** License plate */
  licensePlate: string;
  /** Payment method */
  paymentMethod?: PaymentMethod;
  /** PayAnother Bill handler */
  onPayAnotherBill: () => void;
}

export default BillByMailPaymentConfirmation;
