import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const VehicleStatus: React.FC<{ isRegistered: boolean }> = ({ isRegistered }: { isRegistered: boolean }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      color: isRegistered ? "#3A833C" : "#E31B0C",
    }}
  >
    {isRegistered ? (
      <CheckIcon sx={{ width: "20px", height: "20px" }} />
    ) : (
      <ErrorOutlineIcon sx={{ width: "20px", height: "20px" }} />
    )}
    <Typography sx={{ ml: 1 }}>{isRegistered ? "Registered" : "On Hold"}</Typography>
  </Box>
);

export default VehicleStatus;
