import { gql } from "@apollo/client";
import { Account, accountFragment, Wallet, walletFragment } from "common/graphql/fragments";

export const CREATE_WALLET = gql`
  mutation CreateWallet(
    $payeeAccountId: uuid!
    $desiredBalance: numeric!
    $minimumBalance: numeric!
    $isFreeTrial: Boolean = false
  ) {
    create_wallet(
      payee_account_id: $payeeAccountId
      desired_balance: $desiredBalance
      minimum_balance: $minimumBalance
      is_free_trial: $isFreeTrial
    ) {
      wallet {
        ...walletFragment
      }
      account {
        ...accountFragment
      }
    }
  }
  ${walletFragment}
  ${accountFragment}
`;

export interface CreateWalletData {
  createWallet: {
    wallet: Wallet;
    account: Account;
  };
}

export interface CreateWalletVars {
  payeeAccountId: string;
  desiredBalance: number;
  minimumBalance: number;
  isFreeTrial?: boolean;
}
