import { InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

/**
 * Creates and returns the in-memory cache for this application with
 * policies defined to enable pagination etc.
 *
 * When used in testing, it's recommended to create a new cache instance
 * per test.
 * @returns A new in-memory cache for an Apollo client
 */
export const createApolloInMemoryCache = (): InMemoryCache =>
  new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Query: {
        fields: {
          registered_vehicle: offsetLimitPagination(),
        },
      },
    },
  });

export default createApolloInMemoryCache;
