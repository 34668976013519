import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Skeleton from "@mui/material/Skeleton";
import AppleIcon from "@app.automotus.io/components/icons/AppleIcon";
import AppleIconDisabled from "@app.automotus.io/components/icons/AppleIconDisabled";

export const ApplePayButton: React.FC<ApplePayButtonProps> = ({
  loading = false,
  disabled = false,
  onClick = () => undefined,
}) => {
  return loading ? (
    <Skeleton width="100%">
      <LoadingButton>Loading</LoadingButton>
    </Skeleton>
  ) : (
    <LoadingButton
      fullWidth={true}
      id="apple-pay"
      variant="text"
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      startIcon={disabled ? <AppleIconDisabled /> : <AppleIcon />}
      loadingPosition="start"
      sx={{
        border: "1px solid black",
        boxShadow: 0,
        textTransform: "unset",
        height: "42px",
        backgroundColor: "white",
        color: "black",
        fontSize: "20px",
        fontWeight: 400,
        "& .MuiButton-startIcon": {
          marginRight: "4px",
        },
      }}
    >
      Pay
    </LoadingButton>
  );
};

export interface ApplePayButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export default ApplePayButton;
