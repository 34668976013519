import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { Link } from "react-router-dom";

/** Button that takes a user to the support page upon click */
export const NeedHelpButton: React.FC<NeedHelpButtonProps> = (props) => {
  return (
    <Button {...props} component={Link} to="/support" rel="noopener noreferrer" target="_blank" variant="outlined">
      Need Help?
    </Button>
  );
};

/** Props passed to the {@link NeedHelpButton} component */
export interface NeedHelpButtonProps {
  /** Expand to fill width of container */
  fullWidth?: boolean;
  /** MUI system stylings applied to button */
  sx?: ButtonProps["sx"];
}

export default NeedHelpButton;
