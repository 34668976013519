import auth0 from "auth0-js";

/**
 * VerifyCrossOrigin is designed to be a route that allows cross-origin authentication
 * requests to succeed when a user's browser does not enable 3rd-party cookies.
 * @constructor
 */
export const VerifyCrossOrigin: React.FC = () => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";

  const webAuth = new auth0.WebAuth({
    domain,
    clientID,
    audience,
    redirectUri: `${window.location.origin}/login`,
  });

  webAuth.crossOriginVerification();
  return null;
};
