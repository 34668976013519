import { gql } from "@apollo/client";
import { InvoiceData, invoiceFragment } from "../fragments";

export const CLAIM_INVOICE = gql`
  mutation ClaimInvoice($invoiceId: uuid!) {
    claimInvoice: claim_invoice(invoice_id: $invoiceId) {
      invoice {
        ...invoiceFragment
      }
    }
  }
  ${invoiceFragment}
`;

export interface ClaimInvoiceVars {
  invoiceId: string;
}

export interface ClaimInvoiceData {
  claimInvoice: {
    invoice: InvoiceData | null;
  };
}
