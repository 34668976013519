import React from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails, { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { formatCurrency, formatDaysOfWeek, formatTimeOfDay } from "common/format";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, alpha } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import { Rate, TimeSpan } from "common/graphql";

const Accordion = styled((props: AccordionProps) => <MuiAccordion square elevation={0} disableGutters {...props} />)(
  ({ theme }) => ({
    border: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&.Mui-disabled": {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const AccordionDetails = styled((props: AccordionDetailsProps) => <MuiAccordionDetails {...props} />)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(0.75),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

/**
 * A component used to display policy hours and rates for a singular zone
 */
export const ZoneRegulationAccordion: React.FC<ZoneRegulationAccordionProps> = ({
  loading,
  address,
  operationPolicy,
  rateApplicationType,
  rates: ratesRaw,
  ...accordionProps
}) => {
  if (loading) {
    return (
      <Accordion TransitionProps={{ unmountOnExit: true }} disabled>
        <AccordionSummary>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Skeleton>
              <Typography>Address Address</Typography>
            </Skeleton>
          </Box>
        </AccordionSummary>
      </Accordion>
    );
  }

  let rates: Rate[];
  if (rateApplicationType === "flat") {
    rates = ratesRaw || [];
  } else {
    // For additive policies, if two consecutive rates are the same,
    // merge them into a single rate
    rates = (ratesRaw || []).reduce((acc, cur) => {
      if (acc.length > 0 && acc[acc.length - 1].rate === cur.rate) {
        acc[acc.length - 1].endDuration = cur.endDuration;
        return acc;
      }

      return [...acc, cur];
    }, [] as Rate[]);

    // For additive policies, rates should be displayed as the maximum total billing that
    // can be achieved in a given rate interval
    rates = rates.reduce((acc, cur) => {
      const curSum = acc.length === 0 ? 0 : acc[acc.length - 1].rate;
      return [
        ...acc,
        {
          ...cur,
          rate: curSum + cur.rate * (cur.endDuration - cur.startDuration),
        },
      ];
    }, [] as Rate[]);
  }

  return (
    <Accordion {...accordionProps} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          sx={{
            pr: { xs: 2, md: 1.5 },
          }}
        >
          <Box sx={{ mr: 1 }}>
            <Typography>{address}</Typography>
          </Box>
          <Box>{rateApplicationType === "flat" && <Chip variant="outlined" label="Flat Rate" size={"small"} />}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: { xs: 1.5, md: 1 } }}>
        <Box
          sx={{
            width: "calc(100% + 16px)",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: "rgba(0, 0, 0, 0.12)",
            paddingBottom: "6px",
          }}
        >
          {!!operationPolicy && (
            <Typography variant="h7" sx={{ fontSize: 18, fontWeight: 500 }}>
              {formatDaysOfWeek(operationPolicy.daysOfWeek)} |{" "}
              {formatTimeOfDay(
                operationPolicy.timeOfDayStartHours,
                operationPolicy.timeOfDayStartMinutes,
                operationPolicy.timeOfDayEndHours,
                operationPolicy.timeOfDayEndMinutes,
              )}
            </Typography>
          )}
        </Box>
        <Stack spacing={0.75} paddingTop={"6px"}>
          <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant={"caption"}>Duration</Typography>
            <Typography variant={"caption"}>Amount</Typography>
          </Box>
          {rates.map(({ startDuration, endDuration, rate }) => (
            <Box
              key={`${startDuration}-${endDuration}`}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"body2"}>
                {startDuration} - {endDuration} mins
              </Typography>
              <Typography variant={"body2"}>
                {rate === 0 ? "free" : `${rateApplicationType === "additive" ? "up to " : ""}$${formatCurrency(rate)}`}
              </Typography>
            </Box>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

/**
 * Props passed to initialize a {@link ZoneRegulationAccordion} component
 */
export type ZoneRegulationAccordionProps = ZoneRegulationAccordionLoadingProps | ZoneRegulationAccordionLoadedProps;

export type ZoneRegulationAccordionLoadingProps = Omit<AccordionProps, "children"> & {
  loading: true;
  address?: undefined;
  operationPolicy?: undefined;
  rateApplicationType?: undefined;
  rates?: undefined;
};

export type ZoneRegulationAccordionLoadedProps = Omit<AccordionProps, "children"> & {
  loading: false;
  address: string;
  operationPolicy: TimeSpan;
  rateApplicationType: "flat" | "additive";
  rates: ZoneRegulationRate[];
};

export interface ZoneRegulationRate {
  rateUnit: "minute";
  startDuration: number;
  endDuration: number;
  rate: number;
}

export default ZoneRegulationAccordion;
