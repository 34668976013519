import styled from "@emotion/styled";
import Button, { ButtonProps } from "@mui/material/Button";

export const ActionButton = styled(Button)<ButtonProps>(({ disabled, variant }) => {
  return {
    lineHeight: "24px",
    fontStyle: "normal",
    fontSize: "16px",
    letterSpacing: "0.4px",
    height: 40,
    width: "100%",
    textTransform: "none",
    ...(variant !== "text" && {
      borderRadius: "4px",
      border: "1px solid #0D55BF",
    }),

    ...(variant === "contained" && {
      color: "#FFF",
      fontWeight: 600,
    }),

    ...(variant === "outlined" && {
      color: "#0D55BF",
      fontWeight: 500,
    }),
    ...(disabled && {
      "&:disabled": {
        background: "rgba(0, 0, 0, 0.12)",
        border: "none",
      },
    }),
  };
});

export default ActionButton;
