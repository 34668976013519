import { gql } from "@apollo/client";
import { CitationDetail, citationDetailFragment } from "../fragments";

export const GET_CITATION_BY_ID = gql`
  query GetCitationById($id: uuid!) {
    citation: enforcement_citation_by_pk(id: $id) {
      ...citationDetailFragment
    }
  }
  ${citationDetailFragment}
`;

/** Variables passed to the {@link GET_CITATION_BY_ID} query */
export interface GetCitationByIdVars {
  id: string;
}

export interface GetCitationByIdData {
  citation: CitationDetail | null;
}
