import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { ContainerProps } from "@mui/material/Container";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DesktopNavbar from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageDesktopNavbar";
import MobileNavbar from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageMobileNavbar";
import PartnerLandingPageHowItWorks from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks";
import { PartnerLandingPageParkingRatesAndHoursModal } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal";
import Button from "@mui/material/Button";
import { BottomSheet } from "@app.automotus.io/components/BottomSheet";
import { useActivePayee } from "@app.automotus.io/components/hooks/useActivePayee";
import Card from "@mui/material/Card";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import { ErrorBoundary } from "react-error-boundary";
import PageLevelError from "@app.automotus.io/components/common/PageLevelError";
import NotificationBanner from "@app.automotus.io/components/NotificationBanner/NotificationBanner";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";

export const MobileLayout: React.FC<PaddingConfigurableProps> = ({ noPadding, relative, children }) => {
  return (
    <Box
      sx={{
        py: noPadding ? 0 : 1.5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: relative ? "relative" : "initial",
      }}
    >
      {children}
    </Box>
  );
};

export const DesktopLayout: React.FC<PaddingConfigurableProps> = ({ noPadding, topBar = null, children }) => {
  const [searchParams] = useSearchParams();
  const isBillSummaryOpen = searchParams.get("viewTransactions") === "true";
  const MAX_WIDTH = !isBillSummaryOpen ? 454 : 1038;
  const { invoiceId = "" } = useParams<{ invoiceId: string }>();
  const navigate = useNavigate();

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} margin={"auto"} sx={{ minWidth: "100%" }}>
      {isBillSummaryOpen && (
        <Card
          sx={{
            p: noPadding ? 0 : 1.5,
            width: MAX_WIDTH,
            maxWidth: MAX_WIDTH,
            boxSizing: "border-box",
            borderRadius: "12px",
            boxShadow: 3,
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden",
            mt: "5vh",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", color: "primary.main" }}>
            <ArrowBack
              sx={{ cursor: "pointer", mr: 1 }}
              onClick={() => {
                navigate(`/paybill/${invoiceId}?viewTransactions=false`);
              }}
            />
            <Typography variant="body1" fontSize={"18px"} fontWeight={500}>
              Invoice Details
            </Typography>
          </Box>
        </Card>
      )}
      <Card
        sx={{
          py: noPadding ? 0 : 2,
          px: isBillSummaryOpen ? 4 : noPadding ? 0 : 2,
          width: MAX_WIDTH,
          maxWidth: MAX_WIDTH,
          maxHeight: "min(651px, 90%)",
          borderRadius: "12px",
          boxSizing: "border-box",
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
          mb: "5vh",
        }}
      >
        <ErrorBoundary
          FallbackComponent={PageLevelError}
          onReset={() => {
            window.location.reload();
          }}
        >
          {topBar && topBar}
          <Box
            sx={{
              overflowX: "hidden",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
            }}
          >
            {children}
          </Box>
        </ErrorBoundary>
      </Card>
    </Box>
  );
};

export const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  marginTopSmall = 45,
  marginTopMedium = 49,
  paddingHorizontal = 2,
  children,
}) => {
  const { activePayee } = useActivePayee();
  const payeeAccountId = activePayee ? activePayee.payeeAccountId : process.env.REACT_APP_PITTSBURGH_ACCOUNT_ID ?? "";

  const theme = useTheme();
  const [parkingInfoModalOpen, setParkingInfoModalOpen] = useState(false);
  const [howItWorksSheetOpen, setHowItWorksSheetOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isWide = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));

  const Layout = useIsMobile() ? MobileLayout : DesktopLayout;

  const handleOpenHowItWorksSheet = () => {
    setHowItWorksSheetOpen(true);
  };

  const handleCloseHowItWorksSheet = () => {
    setHowItWorksSheetOpen(false);
  };

  const handleOpenParkingInfoModal = () => {
    setParkingInfoModalOpen(true);
  };

  const handleCloseParkingInfoModal = () => {
    setParkingInfoModalOpen(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
      }}
    >
      {isWide && <DesktopNavbar openParkingInfoModal={handleOpenParkingInfoModal} />}
      <NotificationBanner />
      {isMobile && (
        <MobileNavbar openHowItWorks={handleOpenHowItWorksSheet} openParkingInfoModal={handleOpenParkingInfoModal} />
      )}
      {isMobile && (
        <BottomSheet
          marginTopSmall={marginTopSmall}
          marginTopMedium={marginTopMedium}
          open={howItWorksSheetOpen}
          onClose={handleCloseHowItWorksSheet}
        >
          <PartnerLandingPageHowItWorks />
          <Box sx={{ flex: 1 }} />
          <Button
            variant={"contained"}
            fullWidth
            onClick={handleCloseHowItWorksSheet}
            sx={{
              m: 2,
              height: isSmall ? "30px" : "36px",
              fontSize: isSmall ? "14px" : "16px",
              fontWeight: 600,
              textTransform: "none",
              width: `auto`,
            }}
          >
            Back
          </Button>
        </BottomSheet>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: isWide ? "row" : "column-reverse",
          height: isWide ? "calc(100% - 88px)" : "calc(100% - 81px)",
        }}
      >
        <Layout>
          {children}
          <Box sx={{ px: isMobile ? 2 : paddingHorizontal }}>
            <Outlet />
          </Box>
        </Layout>
      </Box>
      <PartnerLandingPageParkingRatesAndHoursModal
        open={parkingInfoModalOpen}
        onClose={handleCloseParkingInfoModal}
        payeeAccountId={payeeAccountId}
      />
    </Box>
  );
};

export type OnboardingLayoutProps = ContainerProps & {
  marginTopSmall?: number;
  marginTopMedium?: number;
  paddingHorizontal?: number;
};

export type PaddingConfigurableProps = {
  noPadding?: boolean;
  relative?: boolean;
  topBar?: React.ReactElement | null;
};

export default OnboardingLayout;
