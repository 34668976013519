import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import withAuthorizedRedirection from "@app.automotus.io/components/auth/withAuthorizedRedirection/withAuthorizedRedirection";

export const PublicComponent = <TComponent extends React.ComponentType>({
  component: Component,
  ...componentProps
}: {
  component: TComponent;
} & React.ComponentPropsWithoutRef<TComponent>): JSX.Element => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    const WithRedirection = withAuthorizedRedirection(Component);
    return <WithRedirection {...componentProps} />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Component {...componentProps} />;
};
