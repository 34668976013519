import React from "react";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";

const LANDING_PAGE_HEADER_HEIGHT_SMALL = 81;
const LANDING_PAGE_HEADER_HEIGHT_XSMALL = 77;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/** A wrapper component that displays a drawer menu on mobile view. */
export const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  marginTopSmall = LANDING_PAGE_HEADER_HEIGHT_XSMALL,
  marginTopMedium = LANDING_PAGE_HEADER_HEIGHT_SMALL,
  onClose = () => undefined,
  open = true,
}) => {
  const theme = useTheme();
  const isTiny = useMediaQuery(theme.breakpoints.down("tiny"));
  const topBarHeight = isTiny ? marginTopSmall : marginTopMedium;
  return (
    <Dialog
      BackdropProps={{ invisible: true }}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        top: `${topBarHeight}px !important`,
      }}
      fullScreen
    >
      {children}
    </Dialog>
  );
};

/** Props passed to initialize an {@link BottomSheet} component */
export interface BottomSheetProps {
  /** handler fired when user requests to close modal */
  onClose?: () => void;
  /** open state */
  open?: boolean;
  /** Top margin on extra small screen */
  marginTopSmall?: number;
  /** Top margin on small screen */
  marginTopMedium?: number;
}

export default BottomSheet;
