import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { RetryLink } from "@apollo/client/link/retry";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ATMTS_REQUEST_ORIGIN_HEADER_NAME, ATMTS_REQUEST_ORIGIN_HEADER_VALUE } from "common/constants";
import createApolloInMemoryCache from "common/graphql/createApolloInMemoryCache";
import { useTemporaryToken } from "@app.automotus.io/components/hooks";

const cache = createApolloInMemoryCache();

/**
 * Apollo context provider that uses Auth0 to fetch a token and inserts a few additional links
 * for error handling
 */
const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [temporaryToken] = useTemporaryToken();

  const uri = process.env.REACT_APP_GRAPHQL_ENDPOINT || "";

  if (!uri) {
    return null;
  }

  const httpLink = createHttpLink({
    uri,
  });

  const authLink = setContext(async (_, { headers, ...context }) => {
    let token: string | undefined;
    if (isAuthenticated) {
      token = await getAccessTokenSilently();
    } else {
      token = temporaryToken;
    }

    return {
      headers: {
        ...headers,
        // Set request origin header so that the auth hook knows to apply the correct role
        [ATMTS_REQUEST_ORIGIN_HEADER_NAME]: ATMTS_REQUEST_ORIGIN_HEADER_VALUE,
        ...(token && { Authorization: isAuthenticated ? `Bearer ${token}` : token }),
      },
      ...context,
    };
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([authLink, new RetryLink(), httpLink]),
    cache,
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
