import React from "react";
import { Field } from "formik";
import { TextField, TextFieldProps } from "formik-mui";
import * as yup from "yup";

export const EmailField: EmailFieldComponent = ({
  autoComplete = "email",
  required = true,
  fullWidth = true,
  id = "email",
  name = "email",
  label = "Email",
  placeholder = "Email*",
  type = "email",
  ...rest
}) => (
  <Field
    {...rest}
    {...{ name, autoComplete, required, fullWidth, id, label, placeholder, type }}
    component={TextField}
  />
);

const validationSchema = yup.string().required("Valid email required.").email("Valid email required.");

EmailField.validationSchema = validationSchema;

EmailField.defaultInitialValue = "";

export type EmailFieldProps = Partial<TextFieldProps> & {
  name?: string;
};

export interface EmailFieldComponent extends React.FC<EmailFieldProps> {
  validationSchema: typeof validationSchema;
  defaultInitialValue: string;
}

export default EmailField;
