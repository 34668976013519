import React from "react";
import Box from "@mui/material/Box";
import CurbPassSettingsUnsavedIndicator from "./CurbPassSettingsUnsavedIndicator";
import Typography from "@mui/material/Typography";
import BackButton from "@app.automotus.io/components/BackButton";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import Stack from "@mui/material/Stack";

/**
 * Component that displays the header for CurbPass settings
 */
export const CurbPassSettingsHeader: React.FC<CurbPassSettingsHeaderProps> = ({ isSaved, onClickBack }) => {
  const isMobile = useIsMobile();

  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      {isMobile && <BackButton onClick={onClickBack} />}
      <Stack spacing={1.5} direction={"row"} minWidth={0} alignItems={"center"} width={"100%"}>
        <Typography variant={"h4"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
          CurbPass Settings
        </Typography>
        {isMobile ? (
          !isSaved && <CurbPassSettingsUnsavedIndicator />
        ) : (
          <CurbPassSettingsUnsavedIndicator BoxProps={isSaved ? { sx: { visibility: "hidden" } } : {}} />
        )}
      </Stack>
    </Box>
  );
};

/** Props passed to initialize a {@link CurbPassSettingsHeader} */
export interface CurbPassSettingsHeaderProps {
  /** Indicates whether settings have been saved as defined */
  isSaved: boolean;
  /** Handler fired upon click of back button */
  onClickBack?: React.MouseEventHandler<HTMLButtonElement>;
}

export default CurbPassSettingsHeader;
