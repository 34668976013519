import React from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";

const REASONS = [
  "I do not plan on using the zones again",
  "Use of the zones is too expensive",
  "I don’t have a car anymore",
  "The vehicle I registered was a rental car",
  "I found better alternatives",
  "I only wanted to try it out",
  "Other",
];

const CloseAccountReasonDropdownFieldPrivate = <DisableClearable extends boolean | undefined>({
  name = "reason",
  label = "Feedback",
  placeholder = "Feedback",
  disablePortal = true,
  fullWidth = true,
  autoSelect = false,
  autoHighlight = true,
  disableClearable = false,
  openOnFocus = true,
  required = false,
  helperText,
  filterOptions = (options: string[]) => options,
  disabled,
  ...rest
}: CloseAccountReasonDropdownFieldProps<DisableClearable>) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta, { setValue }] = useField<string | null>(name);
  const [otherField, otherMeta, { setValue: setOtherValue }] = useField<string | null>("other");

  const showError = meta.touched && !!meta.error;
  const showOtherError = otherMeta.touched && !!otherMeta.error;

  return (
    <Box>
      <Autocomplete
        {...rest}
        {...{ disablePortal, fullWidth, autoSelect, autoHighlight, disableClearable, openOnFocus }}
        multiple={false}
        onChange={(e, v) => {
          setValue(v);
          setOtherValue("");
        }}
        getOptionLabel={(option) => `${option}`}
        options={REASONS}
        value={meta.value}
        filterOptions={filterOptions}
        onBlur={field.onBlur}
        disabled={disabled ?? isSubmitting}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            error={showError}
            helperText={showError ? meta.error : helperText}
            label={label}
            placeholder={placeholder}
            required={required}
          />
        )}
      />
      {meta.value === "Other" && (
        <TextField
          sx={{ mt: { xs: 3.5, sm: 4 }, width: "100%" }}
          value={otherMeta.value}
          onChange={(e) => {
            setOtherValue(e.target.value);
          }}
          name="other"
          onBlur={otherField.onBlur}
          error={showOtherError}
          helperText={showOtherError ? otherMeta.error : ""}
          label="Other"
          placeholder="Other"
          required={required}
        />
      )}
    </Box>
  );
};

/**
 * Component that renders an autocomplete dropdown allowing a user to select the
 * reason why user requests closing account or refund.
 */
export const CloseAccountReasonDropdownField = Object.assign(CloseAccountReasonDropdownFieldPrivate, {
  defaultInitialValue: null as string | null,
  validationSchema: yup.string().nullable().required("Selection required."),
});

/** Props passed to initialize a {@link CloseAccountReasonDropdownField} component */
export interface CloseAccountReasonDropdownFieldProps<DisableClearable extends boolean | undefined>
  extends Omit<
    AutocompleteProps<string, false, DisableClearable, false>,
    "renderInput" | "options" | "getOptionLabel" | "onChange" | "onBlur" | "value"
  > {
  name?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
}

export default CloseAccountReasonDropdownField;
