import { gql } from "@apollo/client";

export const GET_ZONES_WITH_PARKING_HOURS = gql`
  query GetZonesWithParkingHours($payeeAccountId: uuid!) {
    zones(where: { payee_account_id: { _eq: $payeeAccountId }, is_active: { _eq: true } }, order_by: { name: asc }) {
      name
      zone_policy_time_spans(order_by: [{ time_span: { time_of_day_start: asc } }]) {
        time_span {
          days_of_week
          time_of_day_start_hours
          time_of_day_start_minutes
          time_of_day_end_hours
          time_of_day_end_minutes
        }
      }
    }
  }
`;

/** Variables passed to the {@link GET_ZONES_WITH_PARKING_HOURS} query */
export interface GetZonesWithParkingHoursVars {
  /** Payee account Id */
  payeeAccountId: string;
}

export interface GetZonesWithParkingHoursData {
  /** zones data */
  zones: ParkingHoursData[] | null;
}

export interface ParkingHoursData {
  /** Zone Id */
  name: string;
  /** Policy Time Spans Info */
  zone_policy_time_spans: {
    time_span: {
      days_of_week: string[];
      time_of_day_start_hours: number;
      time_of_day_start_minutes: number;
      time_of_day_end_hours: number;
      time_of_day_end_minutes: number;
    };
  }[];
}
