import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import useIsMobile from "../../hooks/useIsMobile";
import React from "react";
import LogoBlue from "@app.automotus.io/components/images/AppLogo/logoBlue";
import NotificationBanner from "@app.automotus.io/components/NotificationBanner/NotificationBanner";

/**
 * Full-page responsive component used to define layout for the auth process.
 *
 * This component functions ONLY as a container and does not implement any logic aside
 * from responsively determining page layout.
 * @param props Standard react component props
 * @returns A JSX element representing container used for the signup process.
 */
export const AuthLayout: React.FC = (props) => {
  const isMobile = useIsMobile();

  return (
    <>
      <NotificationBanner />
      {isMobile ? <MobileLayout>{props.children}</MobileLayout> : <DesktopLayout>{props.children}</DesktopLayout>}
    </>
  );
};

const MobileLayout: React.FC = ({ children }) => {
  return (
    <Container sx={{ display: "flex", flexDirection: "column", height: "100%", boxSizing: "border-box", p: 2 }}>
      <Box sx={{ mb: 1.5 }}>
        <LogoBlue height={24} width={"auto"} />
      </Box>
      {children}
    </Container>
  );
};

const DesktopLayout: React.FC = ({ children }) => {
  const MAX_WIDTH = 454;
  const MAX_HEIGHT = "80vh";

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ minWidth: "100%", minHeight: "100vh" }}
      style={{
        backgroundColor: "#E8EAEC",
      }}
    >
      <Card sx={{ my: "10vh", width: MAX_WIDTH, height: MAX_HEIGHT, borderRadius: "12px", boxShadow: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", boxSizing: "border-box", p: 4 }}>
          {children}
        </Box>
      </Card>
    </Box>
  );
};

export default AuthLayout;
