import { gql } from "@apollo/client";
import { StateAbbreviation, VehicleTypeValue } from "@app.automotus.io/components/common";
import { GET_CURRENTLY_REGISTERED_VEHICLES, RegisteredVehicle, registeredVehicleFragment } from "common/graphql";

/** TODO: Validate this mutation */

/**
 * Mutation that updates a new registered vehicle in a user's account
 */
export const UPDATE_REGISTERED_VEHICLE = gql`
  mutation UpdateRegisteredVehicle(
    $id: uuid!
    $licensePlate: String!
    $stateCode: String!
    $type: registered_vehicle_type_enum
    $make: String
    $model: String
    $year: Int
  ) {
    updatedRegisteredVehicle: update_registered_vehicle_by_pk(
      pk_columns: { id: $id }
      _set: {
        license_plate: $licensePlate
        state_code: $stateCode
        vehicle_type: $type
        make: $make
        model: $model
        vehicle_year: $year
      }
    ) {
      ...registeredVehicleFragment
    }
  }
  ${registeredVehicleFragment}
`;

/** Variables provided to a {@link UPDATE_REGISTERED_VEHICLE} mutation */
export interface UpdateRegisteredVehicleVars {
  id: string;
  licensePlate: string;
  stateCode: StateAbbreviation;
  type?: VehicleTypeValue | null;
  make?: string | null;
  model?: string | null;
  year?: number | null;
}

/** Return type of a {@link UPDATE_REGISTERED_VEHICLE} query */
export interface UpdateRegisteredVehicleData {
  updatedRegisteredVehicle: RegisteredVehicle;
}

/** Queries that must be refetched when this query runs */
export const UpdateRegisteredVehicleRefetchQueries = [GET_CURRENTLY_REGISTERED_VEHICLES];

export default UPDATE_REGISTERED_VEHICLE;
