import { gql } from "@apollo/client";
import { PaymentMethod, paymentMethodFragment } from "common/graphql/fragments";

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation SetDefaultPaymentMethod($paymentMethodId: uuid!) {
    defaultPaymentMethod: set_default_payment_method(payment_method_id: $paymentMethodId) {
      paymentMethod: payment_method {
        ...paymentMethodFragment
      }
    }
  }
  ${paymentMethodFragment}
`;

export interface SetDefaultPaymentMethodData {
  defaultPaymentMethod: {
    paymentMethod: PaymentMethod;
  };
}

export interface SetDefaultPaymentMethodVars {
  paymentMethodId: string;
}
