import React from "react";

const CarouselLeftButton = (): React.ReactElement => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ddd_2483_144892)">
        <rect x="10" y="9" width="40" height="40" rx="4" fill="white" />
        <rect x="10.5" y="9.5" width="39" height="39" rx="3.5" stroke="black" strokeOpacity="0.23" />
        <path
          d="M28.1675 23.5L26.875 24.7925L31.0733 29L26.875 33.2075L28.1675 34.5L33.6675 29L28.1675 23.5Z"
          fill="black"
          fillOpacity="0.87"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd_2483_144892"
          x="0"
          y="0"
          width="60"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2483_144892" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2483_144892" result="effect2_dropShadow_2483_144892" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_2483_144892" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_2483_144892" result="effect3_dropShadow_2483_144892" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2483_144892" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default CarouselLeftButton;
