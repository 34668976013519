import { gql } from "@apollo/client";

export const CREATE_CLOSE_ACCOUNT_REQUEST = gql`
  mutation CreateCloseAccountRequest {
    closeAccount: close_account {
      requestId: request_id
      sentToEmail: sent_to_email
      submittedAt: submitted_at
    }
  }
`;

export interface CreateCloseAccountRequestData {
  closeAccount: {
    /** ID of the request that was created */
    requestId: string;
    /** Email that the request was sent to */
    sentToEmail: string;
    /** Submission timestamp in ISO format */
    submittedAt: string;
  };
}
