import { gql } from "@apollo/client";
import { PaymentMethod, paymentMethodFragment } from "common/graphql";

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods {
    paymentMethods: financial_payment_method(order_by: [{ is_default: desc }, { created_at: desc }]) {
      ...paymentMethodFragment
    }
  }
  ${paymentMethodFragment}
`;

export interface GetPaymentMethodsData {
  paymentMethods: PaymentMethod[];
}
