import React from "react";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";

/**
 * When loading, renders a skeleton around its children. Otherwise, simply renders the children.
 */
export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ loading, children, ...skeletonProps }) => {
  return loading ? <Skeleton {...skeletonProps}>{children}</Skeleton> : <>{children}</>;
};

/** Props passed to initialize a {@link LoadingSkeleton} */
export interface LoadingSkeletonProps extends SkeletonProps {
  /** Loading status of component. Defaults to true. */
  loading?: boolean;
}

export default LoadingSkeleton;
