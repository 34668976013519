import { RegisteredVehicle } from "common/graphql";
import { useEffect, useState } from "react";
import { useIsMobile } from "../hooks";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { VehicleType, VehicleTypeValue } from "../common";
import React from "react";

export type VehicleInfoFieldType = "type" | "make" | "model" | "year";

export const VehicleInfo: React.FC<{ registerVehicleInfo: RegisteredVehicle }> = ({ registerVehicleInfo }) => {
  const [vehicleInfoValues, setVehicleInfoValues] = useState<Partial<RegisteredVehicle>>({
    type: registerVehicleInfo.type,
    make: registerVehicleInfo.make,
    model: registerVehicleInfo.model,
    year: registerVehicleInfo.year,
  });

  const isMobile = useIsMobile();

  useEffect(() => {
    setVehicleInfoValues({
      type: registerVehicleInfo.type,
      make: registerVehicleInfo.make,
      model: registerVehicleInfo.model,
      year: registerVehicleInfo.year,
    });
  }, [registerVehicleInfo]);

  // const [vehicleInfoEditing, setVehicleInfoEditing] = useState({
  //   type: false,
  //   make: false,
  //   model: false,
  //   year: false,
  // });

  // const [updateRegisteredVehicle] = useMutation<UpdateRegisteredVehicleData, UpdateRegisteredVehicleVars>(
  //   UPDATE_REGISTERED_VEHICLE,
  // );

  // const { publishSnackbarMessage } = useSnackPackContext();

  // const handleResetVehicleInfoValue = async (propName: VehicleInfoFieldType) => {
  //   setVehicleInfoEditing({
  //     ...vehicleInfoEditing,
  //     [propName]: false,
  //   });

  //   setVehicleInfoValues({
  //     ...vehicleInfoValues,
  //     [propName]: registerVehicleInfo[propName],
  //   });
  // };

  // const handleAddOrEditVehicleInfo = async (propName: VehicleInfoFieldType) => {
  //   setVehicleInfoEditing({
  //     ...vehicleInfoEditing,
  //     [propName]: false,
  //   });

  //   try {
  //     await updateRegisteredVehicle({
  //       variables: {
  //         id: registerVehicleInfo.id,
  //         stateCode: registerVehicleInfo.stateCode as StateAbbreviation,
  //         licensePlate: registerVehicleInfo.licensePlate,
  //         type: vehicleInfoValues.type,
  //         make: vehicleInfoValues.make ? vehicleInfoValues.make : null,
  //         model: vehicleInfoValues.model ? vehicleInfoValues.model : null,
  //         year: vehicleInfoValues.year ? vehicleInfoValues.year : null,
  //       },
  //     });

  //     publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_UPDATED_SUCCESS as SnackbarMessageProps);
  //   } catch (err: unknown) {
  //     console.error(err);

  //     if (err instanceof Error) {
  //       if (!err.message.includes("Uniqueness violation")) {
  //         publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_UPDATED_FAILURE as SnackbarMessageProps);
  //       } else {
  //         publishSnackbarMessage(SNACKBAR_MSGS.VEHICLE_ALREADY_EXISTS as SnackbarMessageProps);
  //       }

  //       throw new Error("Failed to update vehicle");
  //     }
  //   }
  // };

  // const handleAddOrEdit = (propName: VehicleInfoFieldType) => {
  //   setVehicleInfoEditing({
  //     ...vehicleInfoEditing,
  //     [propName]: true,
  //   });
  // };

  return (
    <Box sx={{ width: { xs: "100%" } }}>
      {Object.keys(vehicleInfoValues).map((field) => {
        const propName = field as VehicleInfoFieldType;
        const data = propName === "type" ? vehicleInfoValues[propName] || "car" : vehicleInfoValues[propName];
        const label = propName === "type" ? VehicleType.FromValue(data as VehicleTypeValue).label : data;
        return (
          <Box
            key={field}
            sx={{ mb: 0.5, display: "flex", width: { xs: "100%" }, flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontWeight: 500,
                minWidth: { xs: "59px", tiny: "59px", lg: "63px" },
              }}
            >
              {propName}
            </Typography>
            {/* TODO: Re-Enable edit vehicle information feature in the future */}
            <Box
              sx={{
                display: "flex",
                width: { xs: "100%" },
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* {!vehicleInfoEditing[propName] && ( */}
              <Typography
                sx={{
                  mr: 3.5,
                  color: data ? "black" : "gray",
                  width: { xs: "100%" },
                  minWidth: !isMobile ? "130px" : "auto",
                  maxWidth: !isMobile ? "130px" : "auto",
                }}
              >
                {/* {label ?? "Add"} */}
                {label}
              </Typography>
              {/* )} */}
              {/* {vehicleInfoEditing[propName] && propName !== "type" && (
                <TextField
                  value={label}
                  type={propName === "year" ? "number" : "text"}
                  sx={{
                    ".MuiOutlinedInput-input": {
                      px: 1,
                      py: 0,
                      minWidth: "115px",
                      maxWidth: "115px",
                    },
                  }}
                  onChange={(e) => {
                    setVehicleInfoValues({
                      ...vehicleInfoValues,
                      [propName]: e.currentTarget.value,
                    });
                  }}
                />
              )}
              {vehicleInfoEditing[propName] && propName === "type" && (
                <Select
                  id={propName}
                  value={data}
                  sx={{
                    ".MuiOutlinedInput-input": {
                      px: 1,
                      py: 0,
                      minWidth: "95px",
                      maxWidth: "95px",
                    },
                  }}
                  onChange={(e) => {
                    setVehicleInfoValues({
                      ...vehicleInfoValues,
                      [propName]: e.target.value as VehicleTypeValue,
                    });
                  }}
                >
                  {VehicleType.All.map((vc) => (
                    <MenuItem key={vc.value} value={vc.value}>
                      {vc.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {!vehicleInfoEditing[propName] && (
                <IconButton
                  sx={{ color: data ? "rgba(0, 0, 0, 0.6)" : "#0D55BF" }}
                  onClick={() => handleAddOrEdit(propName)}
                >
                  {data ? (
                    <EditIcon sx={{ width: "15px", height: "15px" }} />
                  ) : (
                    <AddIcon sx={{ width: "12px", height: "12px" }} />
                  )}
                </IconButton>
              )}
              {vehicleInfoEditing[propName] && (
                <Box>
                  <IconButton sx={{ color: "rgba(0, 0, 0, 0.6)" }} onClick={() => handleAddOrEditVehicleInfo(propName)}>
                    <CheckIcon sx={{ width: "12px", height: "12px" }} />
                  </IconButton>
                  <IconButton
                    sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                    onClick={() => handleResetVehicleInfoValue(propName)}
                  >
                    <ClearOutlined sx={{ width: "12px", height: "12px" }} />
                  </IconButton>
                </Box>
              )} */}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default VehicleInfo;
