import React from "react";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";

export const BackButton: React.FC<ButtonBaseProps> = ({ sx, ...rest }) => {
  return (
    <ButtonBase
      {...rest}
      disableRipple={true}
      sx={{
        ...sx,
        "& :hover": {
          color: (theme) => theme.palette.action.active,
        },
      }}
    >
      <Box display={"flex"} alignItems={"center"} sx={{ p: 1 }}>
        <ArrowBackIos />
      </Box>
    </ButtonBase>
  );
};

export default BackButton;
