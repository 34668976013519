import { gql } from "@apollo/client";
import { PaymentMethod, paymentMethodFragment } from "common/graphql/fragments";

export const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePaymentMethod($paymentMethodId: uuid!) {
    deletePaymentMethod: delete_payment_method(payment_method_id: $paymentMethodId) {
      paymentMethod: payment_method {
        ...paymentMethodFragment
      }
    }
  }
  ${paymentMethodFragment}
`;

export interface DeletePaymentMethodData {
  deletePaymentMethod: {
    paymentMethod: PaymentMethod;
  };
}

export interface DeletePaymentMethodVars {
  paymentMethodId: string;
}
