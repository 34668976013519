import React, { useRef } from "react";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import LicensePlateState, { StateAbbreviation } from "../../common/LicensePlateState";
import StateDropdown from "../../StateDropdownField";
import * as yup from "yup";
import Skeleton from "@mui/material/Skeleton";
import { LicensePlateField } from "../VehicleInfoFields";
import BBMInvoiceFormButtons from "./BBMInvoiceFormButtons";
import { FormObserver } from "@app.automotus.io/components/routes/signup/helpers/FormObserver";
import InvoiceNumberField from "@app.automotus.io/components/BillByMailPayAsGuest/InvoiceNumberField";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const REQUIRED_FIELD_ERROR = "This field is required";

const emptyValues = {
  licensePlate: "",
  licensePlateState: null as StateAbbreviation | null,
  invoiceNumber: undefined,
  submitAction: null as BBMInfoFormSubmitAction | null,
};

export const BBMInfoForm: React.FC<BBMInfoFormProps> = ({
  onSubmit = () => undefined,
  initialValues,
  disabled,
  loading = false,
  licensePlate,
}) => {
  const firstInputRef = useRef<HTMLInputElement>();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Formik
      enableReinitialize
      initialValues={{
        licensePlate: (initialValues && initialValues.licensePlate) || emptyValues.licensePlate,
        licensePlateState: (initialValues && initialValues.licensePlateState) || emptyValues.licensePlateState,
        invoiceNumber: (initialValues && initialValues.invoiceNumber) || emptyValues.invoiceNumber,
        submitAction: (initialValues && initialValues.submitAction) || emptyValues.submitAction,
      }}
      onSubmit={async (values, actions) => {
        const { submitAction, ...valuesToSubmit } = values;
        if (submitAction) {
          try {
            await onSubmit(submitAction, valuesToSubmit as ValidatedBBMInfoFormValues);
          } catch (err) {
            console.error(err);
          }
          actions.setSubmitting(false);
        }
      }}
      validationSchema={yup.object({
        invoiceNumber: InvoiceNumberField.validationSchema,
        licensePlate: LicensePlateField.validationSchema,
        licensePlateState: yup
          .mixed()
          .oneOf(LicensePlateState.All.map((lps) => lps.abbreviation))
          .required(REQUIRED_FIELD_ERROR),
      })}
    >
      {(props) => {
        const { licensePlateState, licensePlate: licensePlateData, invoiceNumber } = props.values;
        const isSubmitDisabled = !(licensePlateState && licensePlateData && invoiceNumber);
        return (
          <Form onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
            <FormObserver formType="paybill" />
            <Grid container justifyContent="space-between" rowSpacing={{ xs: 3.5, tiny: 4 }} columnSpacing={2}>
              <Grid item xs={12} lg={12}>
                {loading ? (
                  <Skeleton width="100%">
                    <InvoiceNumberField />
                  </Skeleton>
                ) : (
                  <InvoiceNumberField disabled={props.isSubmitting || disabled} />
                )}
              </Grid>
              <Grid item xs={12} lg={12}>
                {loading ? (
                  <Skeleton width="100%">
                    <LicensePlateField />
                  </Skeleton>
                ) : (
                  <LicensePlateField
                    disabled={props.isSubmitting || disabled || !!licensePlate}
                    inputRef={firstInputRef}
                    {...(licensePlate ? { value: licensePlate } : undefined)}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={12}>
                {loading ? (
                  <Skeleton width="100%">
                    <StateDropdown />
                  </Skeleton>
                ) : (
                  <StateDropdown
                    autoHighlight
                    disabled={disabled || props.isSubmitting}
                    fullWidth
                    autoComplete
                    openOnFocus
                    onBlur={props.handleBlur}
                    required
                    id="license-plate-state"
                    name="licensePlateState"
                    label="License Plate State"
                    placeholder={isXs ? "State" : "License Plate State"}
                    value={props.values.licensePlateState}
                    onChange={(e, v) => props.setFieldValue("licensePlateState", v)}
                    error={props.touched.licensePlateState && Boolean(props.errors.licensePlateState)}
                    helperText={(props.touched.licensePlateState && props.errors.licensePlateState) || ""}
                  />
                )}
              </Grid>
              <Box sx={{ width: "100%", height: { xs: 0, lg: 97 } }} />
              <Grid item xs={12}>
                <BBMInvoiceFormButtons
                  loading={loading}
                  disabled={props.isSubmitting || disabled || isSubmitDisabled}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

type RequiredInfoFormValues = "licensePlate" | "licensePlateState" | "invoiceNumber";

export interface BBMInfoFormValues {
  licensePlate: string;
  licensePlateState: StateAbbreviation | null;
  invoiceNumber?: string;
  submitAction: BBMInfoFormSubmitAction | null;
}

export type ValidatedBBMInfoFormValues = {
  [P in keyof BBMInfoFormValues]: P extends RequiredInfoFormValues
    ? NonNullable<BBMInfoFormValues[P]>
    : BBMInfoFormValues[P];
};

export interface BBMInfoFormProps {
  loading?: boolean;
  disabled?: boolean;
  initialValues?: Partial<BBMInfoFormValues>;
  licensePlate?: string;
  onSubmit?: (submitAction: BBMInfoFormSubmitAction, values: ValidatedBBMInfoFormValues) => Promise<void>;
}

export type BBMInfoFormSubmitAction = "search";

export default BBMInfoForm;
