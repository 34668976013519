import { gql } from "@apollo/client";
import { UserProfile, userProfileFragment } from "common/graphql";

export const GET_USER_PROFILE = gql`
  query GetUserProfile($userId: uuid!) {
    userProfile: auth_user_profile_by_pk(id: $userId) {
      ...userProfileFragment
    }
  }
  ${userProfileFragment}
`;

export interface GetUserProfileData {
  userProfile: UserProfile;
}

export interface GetUserProfileVars {
  userId: string;
}

export default GET_USER_PROFILE;
