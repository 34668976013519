import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { AsYouType, CountryCode, Metadata as PhoneMetadata } from "libphonenumber-js";
import { CountryType } from "./CountryCallingCodeSelect";

/**
 * Custom input component that formats phone numbers based on country code
 */
export const PhoneNumberFormat = forwardRef<PhoneNumberFormatHandle, PhoneNumberFormatProps>((props, ref) => {
  const { country, onChange, ...rest } = props;
  const [inputValue, setInputValue] = useState(props.value.replace(/[+\s]/g, ""));
  const countryCode = country.code;
  const phoneMetadata = useMemo(() => {
    const pm = new PhoneMetadata();
    pm.selectNumberingPlan(countryCode);
    return pm;
  }, [countryCode]);

  const inputRef = useRef<HTMLElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current && inputRef.current.focus();
    },
  }));

  const isPhoneNumberAllowed = (values: NumberFormatValues) => {
    const allowedLengths = phoneMetadata.numberingPlan?.possibleLengths();

    if (allowedLengths) {
      return values.value.length <= Math.max(...allowedLengths);
    }

    return true;
  };

  const formatPhoneNumber = (inputValue: string) => {
    const asYouType = new AsYouType(country.code as CountryCode);
    return asYouType.input(inputValue);
  };

  return (
    <NumberFormat
      {...rest}
      value={inputValue}
      type="tel"
      getInputRef={inputRef}
      format={formatPhoneNumber}
      isAllowed={isPhoneNumberAllowed}
      onValueChange={(values) => {
        const asYouType = new AsYouType(country.code as CountryCode);
        asYouType.input(values.value);
        setInputValue(values.value);
        onChange({ target: { value: asYouType.getNumberValue() || "" } });
      }}
    />
  );
});

/** Ref handle exposed by the PhoneNumberFormat forwarded ref */
export interface PhoneNumberFormatHandle {
  focus: () => void;
}

/** Props passed to initialize a PhoneNumberFormat component */
export interface PhoneNumberFormatProps {
  /** Value of the phone number */
  value: string;
  /** Selected country */
  country: CountryType;
  /** Handler fired upon input change */
  onChange: (event: { target: { value: string } }) => void;
}

export default PhoneNumberFormat;
