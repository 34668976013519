import { gql } from "@apollo/client";
import {
  COUNT_CURRENTLY_REGISTERED_VEHICLES,
  GET_CURRENTLY_REGISTERED_VEHICLES,
  RegisteredVehicle,
  registeredVehicleFragment,
} from "common/graphql";

/** TODO: Validate this mutation */

/**
 * Mutation that remove a registered vehicle in a user's account
 */
export const REMOVE_REGISTERED_VEHICLE = gql`
  mutation RemoveRegisteredVehicle($id: uuid!) {
    update_registered_vehicle_by_pk(pk_columns: { id: $id }, _set: { registered: false }) {
      id
    }
    removedRegisteredVehicle: delete_account_registered_vehicle(where: { registered_vehicle_id: { _eq: $id } }) {
      ...registeredVehicleFragment
    }
  }
  ${registeredVehicleFragment}
`;

/** Variables provided to a {@link REMOVE_REGISTERED_VEHICLE} mutation */
export interface RemoveRegisteredVehicleVars {
  id: string;
}

/** Return type of a {@link REMOVE_REGISTERED_VEHICLE} query */
export interface RemoveRegisteredVehicleData {
  removedRegisteredVehicle: RegisteredVehicle;
}

/** Queries that must be refetched when this query runs */
export const RemoveRegisteredVehicleRefetchQueries = [
  GET_CURRENTLY_REGISTERED_VEHICLES,
  COUNT_CURRENTLY_REGISTERED_VEHICLES,
];

export default REMOVE_REGISTERED_VEHICLE;
