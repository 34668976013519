import React, { useEffect } from "react";
import _range from "lodash/range";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";
import { usePrevious } from "../../../hooks";

const CURRENT_YEAR = new Date().getFullYear();

const YEARS = _range(CURRENT_YEAR + 1, 1899, -1);

const YearDropdownFieldPrivate = <DisableClearable extends boolean | undefined>({
  modelId = null,
  name = "year",
  label = "Year",
  placeholder = "Year",
  disablePortal = true,
  fullWidth = true,
  autoSelect = false,
  autoHighlight = true,
  disableClearable = false,
  openOnFocus = true,
  required = false,
  helperText,
  filterOptions = (options: number[]) => options,
  disabled,
  ...rest
}: YearDropdownFieldProps<DisableClearable>) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta, { setValue }] = useField<number | null>(name);

  const showError = meta.touched && !!meta.error;

  const prevModelId = usePrevious(modelId);

  useEffect(() => {
    if (prevModelId !== null && prevModelId !== modelId) {
      setValue(null);
    }
  }, [prevModelId, modelId, setValue]);

  return (
    <Autocomplete
      {...rest}
      {...{ disablePortal, fullWidth, autoSelect, autoHighlight, disableClearable, openOnFocus }}
      multiple={false}
      onChange={(e, v) => setValue(v)}
      getOptionLabel={(option) => `${option}`}
      options={YEARS}
      value={meta.value}
      filterOptions={filterOptions}
      onBlur={field.onBlur}
      disabled={disabled ?? isSubmitting}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={showError}
          helperText={showError ? meta.error : helperText}
          label={label}
          placeholder={placeholder}
          required={required}
        />
      )}
    />
  );
};

/**
 * Component that renders an autocomplete dropdown allowing a user to select the
 * year in which his vehicle was made.
 */
export const YearDropdownField = Object.assign(YearDropdownFieldPrivate, {
  defaultInitialValue: null as number | null,
  validationSchema: yup.number().nullable(),
});

/** Props passed to initialize a {@link YearDropdownField} component */
export interface YearDropdownFieldProps<DisableClearable extends boolean | undefined>
  extends Omit<
    AutocompleteProps<number, false, DisableClearable, false>,
    "renderInput" | "options" | "getOptionLabel" | "onChange" | "onBlur" | "value"
  > {
  name?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
  modelId?: number | null;
}

export default YearDropdownField;
