export const HASURA_CLAIMS_NAMESPACE = "https://hasura.io/jwt/claims";
export const USER_ID_CLAIM = "x-hasura-user-id";
export const ACCOUNT_ID_CLAIM = "x-hasura-account-id";

export interface UserClaims {
  [HASURA_CLAIMS_NAMESPACE]: {
    [USER_ID_CLAIM]: string;
    [ACCOUNT_ID_CLAIM]: string;
  };
  [s: string]: unknown;
}

export default UserClaims;
