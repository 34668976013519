import { gql } from "@apollo/client";
import { Wallet, walletFragment } from "common/graphql";

export const GET_WALLET_WITH_PAYEE = gql`
  query GetWalletWithPayee($payeeAccountId: uuid) {
    wallet: get_wallet_with_payee(args: { payee_account_id: $payeeAccountId }) {
      ...walletFragment
    }
  }
  ${walletFragment}
`;

export interface GetWalletWithPayeeData {
  wallet: Wallet | null;
}

export interface GetWalletWithPayeeVars {
  payeeAccountId: string;
}
