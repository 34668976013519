import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import Button from "@mui/material/Button";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import CurrencyField from "@app.automotus.io/components/common/CurrencyField";
import LoadingButton from "@mui/lab/LoadingButton";
import WalletRefundRequestReasonDropdownField from "./WalletRefundRequestReasonDropdownField";
import { useTheme, alpha } from "@mui/material/styles";

export const WalletRefundRequestModal: React.FC<WalletRefundRequestModalProps> = ({
  loading = true,
  walletBalance = 0,
  title = "",
  onSubmit = () => undefined,
  onClose = () => undefined,
  redirected = false,
}) => {
  const theme = useTheme();
  return (
    <Box>
      {title && (
        <LoadingSkeleton loading={loading} width="100%">
          <Box mb={4} sx={{ display: "flex", justifyContent: "center", fontWeight: 500 }}>
            <Typography variant="h4" fontWeight={500}>
              {title}
            </Typography>
          </Box>
        </LoadingSkeleton>
      )}
      <Formik<WalletRefundRequestValues>
        initialValues={{ amount: undefined, reason: "", other: "" }}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          amount: yup
            .number()
            .test(
              "Minimum value",
              `Enter an amount greater than $0.
              `,
              (value) => {
                return !!value && +value >= 0;
              },
            )
            .test(
              "Maximum value",
              `The amount you have entered is more than your remaining available balance.`,
              (value) => {
                return !!value && +value <= walletBalance * 100;
              },
            ),
          reason: !redirected ? WalletRefundRequestReasonDropdownField.validationSchema : yup.string().nullable(),
          other: yup
            .string()
            .nullable()
            .when("reason", (reason, schema) => {
              if (reason === "Other") {
                return schema.required("Required field");
              } else {
                return schema; // if the reason is not `Other` then no need to perform validation.
              }
            }),
        })}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
            <LoadingSkeleton loading={loading} width="100%">
              <Box sx={{ width: "100%" }}>
                <CurrencyField
                  sx={{
                    color: "text.secondary",
                    width: "100%",
                  }}
                  variant="standard"
                  error={props.values.amount !== undefined && !!props.errors.amount}
                  placeholder=""
                  value={props.values.amount}
                  currencyCode="usd"
                  onChange={(event, newValue) => {
                    if (event.target.value === "") {
                      props.setFieldValue("amount", undefined);
                      props.setFieldError("amount", undefined);
                      return;
                    }
                    props.setFieldValue("amount", newValue);
                  }}
                  InputProps={{
                    startAdornment: "$",
                    disableUnderline: true,
                    sx: {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      typography: "body3",
                      color: "text.secondary",
                      width: "100%",
                      px: 1.5,
                      py: 2,
                      borderRadius: theme.spacing(0.5),
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: alpha(theme.palette.text.primary, 0.23),
                      "&.Mui-focused": {
                        borderWidth: "2px",
                        borderColor: "primary.main",
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      fontSize: "16px",
                      fontWeight: 400,
                      padding: 0,
                      paddingLeft: "2px",
                    },
                  }}
                />
              </Box>
            </LoadingSkeleton>
            <LoadingSkeleton loading={loading} width="100%">
              <Box sx={{ display: "flex", justifyContent: "center" }} mt={0.375}>
                <Typography sx={{ fontSize: "12px" }} mx={1.75} color="error.main">
                  {props.values.amount !== undefined && props.errors.amount}
                </Typography>
              </Box>
            </LoadingSkeleton>
            <LoadingSkeleton loading={loading} width="100%">
              <Box mt={0.5} sx={{ color: "text.secondary" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: 400 }} textAlign="center">
                  Available Balance
                </Typography>
                <Typography textAlign="center" sx={{ fontSize: "18px", fontWeight: 700 }}>
                  ${walletBalance}
                </Typography>
              </Box>
            </LoadingSkeleton>
            <LoadingSkeleton loading={loading} width="100%">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: alpha(theme.palette.warning.main, 0.08),
                  padding: 1,
                }}
                mt={{ xs: 3, md: 4 }}
              >
                <Typography color="warning.main" variant="body1" textAlign="center">
                  By refunding all or some of your balance, your account will remain open and auto refill upon future
                  park activity
                </Typography>
              </Box>
              {!redirected && (
                <Typography variant="subtitle1" sx={{ mt: { xs: 3, md: 4 }, fontWeight: 500, mb: 0.5 }}>
                  Why are you requesting a refund? *
                </Typography>
              )}
              {!redirected && <WalletRefundRequestReasonDropdownField />}
            </LoadingSkeleton>
            <LoadingSkeleton loading={loading} width="100%">
              <Box mt={3}>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                    fontWeight: 500,
                  }}
                  loading={props.isSubmitting}
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={
                    props.values.amount === undefined ||
                    Boolean(props.errors.amount) ||
                    (!redirected && !props.values.reason) ||
                    (!redirected && props.values.reason === "Other" && !props.values.other)
                  }
                >
                  Submit Request
                </LoadingButton>
              </Box>
            </LoadingSkeleton>
            <LoadingSkeleton loading={loading} width="100%">
              <Box mt={2}>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                    fontWeight: 500,
                  }}
                  variant="outlined"
                  fullWidth
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>
            </LoadingSkeleton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export interface WalletRefundRequestValues {
  amount: number | undefined;
  reason: string;
  other?: string;
}

export interface WalletRefundRequestModalProps {
  loading?: boolean;
  walletBalance?: number;
  onSubmit?: (data: WalletRefundRequestValues, actions: FormikHelpers<WalletRefundRequestValues>) => Promise<void>;
  onClose?: () => void;
  title?: string;
  redirected?: boolean;
}

export default WalletRefundRequestModal;
