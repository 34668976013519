import React from "react";
import { Navigate } from "react-router-dom";
import PasswordlessLoginContainer from "@app.automotus.io/components/Login/PasswordlessLogin";
import { useAuth0 } from "@auth0/auth0-react";
import { otpLogin, otpStart } from "@app.automotus.io/components/auth";
import AuthLayout from "@app.automotus.io/components/Layout/AuthLayout";
import { UserClaims } from "common/models/UserClaims";
import { FullScreenLoader } from "@app.automotus.io/components/FullScreenLoader";
import { useUserProfile } from "@app.automotus.io/components/hooks";

export const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0<UserClaims>();
  const { userProfile, loading } = useUserProfile();
  return (
    <FullScreenLoader loading={loading}>
      {userProfile ? (
        userProfile.onboardingStatus === "complete" ? (
          <Navigate to={"/dashboard"} />
        ) : (
          <Navigate to={`/signup/${userProfile.onboardingStatus}`} />
        )
      ) : (
        <AuthLayout>
          <PasswordlessLoginContainer
            loginWithRedirect={loginWithRedirect}
            requestConfirmationCode={(phoneNumber) => otpStart({ phoneNumber })}
            requestToConfirmCode={(phoneNumber, otp) => otpLogin({ phoneNumber, otp })}
          />
        </AuthLayout>
      )}
    </FullScreenLoader>
  );
};

export default Login;
