import { gql } from "@apollo/client";

export const userPreferencesFragment = gql`
  fragment userPreferencesFragment on auth_user_preferences_payment_product {
    userProfileId: user_profile_id
    emailUpdatesEnabled: email_updates_enabled
  }
`;

export interface UserPreferences {
  userProfileId: string;
  emailUpdatesEnabled: boolean;
}

export default userPreferencesFragment;
