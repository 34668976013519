const OPTIONS = [
  {
    value: "amex",
    label: "American Express",
  },
  {
    value: "diners",
    label: "Diner's Club",
  },
  {
    value: "discover",
    label: "Discover",
  },
  {
    value: "jcb",
    label: "JCB",
  },
  {
    value: "mastercard",
    label: "Mastercard",
  },
  {
    value: "unionpay",
    label: "UnionPay",
  },
  {
    value: "visa",
    label: "Visa",
  },
  {
    value: "unknown",
    label: "Unknown",
  },
] as const;

export type CreditCardBrandOption = typeof OPTIONS[number];

/** Valid credit card brands */
export type CreditCardBrandValue = CreditCardBrandOption["value"];

/** Valid credit card brand labels */
export type CreditCardBrandLabel = CreditCardBrandOption["label"];

const STRIPE_CREDIT_CARD_BRANDS: CreditCardBrandValue[] = [
  "amex",
  "diners",
  "discover",
  "jcb",
  "mastercard",
  "unionpay",
  "visa",
  "unknown",
];

const CREDIT_CARD_BRAND_VALUES_BY_GATEWAY_STRING: Record<string, CreditCardBrandValue> = {
  ...STRIPE_CREDIT_CARD_BRANDS.reduce(
    (stripeBrands, brand) => ({ ...stripeBrands, [brand]: brand }),
    {} as Record<CreditCardBrandValue, CreditCardBrandValue>,
  ),
  AmericanExpress: "amex",
  DinersClub: "diners",
  Discover: "discover",
  JCB: "jcb",
  Mastercard: "mastercard",
  Visa: "visa",
};

/**
 * Returns the {@link CreditCardBrandValue} that corresponds to the provided string,
 * which may represent a brand as defined either by Stripe or Authorize.net. If no
 * matching CreditCardBrandValue is known, returns "unknown".
 * @param s
 */
export function creditCardBrandValueFromString(s: string): CreditCardBrandValue {
  const key = (
    Object.keys(
      CREDIT_CARD_BRAND_VALUES_BY_GATEWAY_STRING,
    ) as (keyof typeof CREDIT_CARD_BRAND_VALUES_BY_GATEWAY_STRING)[]
  ).find((key) => s === key);

  if (key) {
    return CREDIT_CARD_BRAND_VALUES_BY_GATEWAY_STRING[key];
  }

  return "unknown";
}
