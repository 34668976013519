import { gql } from "@apollo/client";
import {
  EventPolicyCompliance,
  eventPolicyComplianceFragment,
} from "common/graphql/fragments/eventPolicyComplianceFragment";

export const doubleParkFragment = gql`
  fragment doubleParkFragment on double_parks {
    startTime: start_time
    endTime: end_time
    rateBreakdowns: event_policy_compliance_records {
      ...eventPolicyComplianceFragment
    }
  }
  ${eventPolicyComplianceFragment}
`;

export interface DoublePark {
  startTime: string;
  endTime: string;
  policyRateDetails: EventPolicyCompliance[];
}
