import React from "react";
import { appName } from "common/constants";
import { styled } from "@mui/system";

const StyledImg = styled("img")({});

export const AppLogoBase: React.FC<AppLogoBaseProps> = ({
  automotusLogoSrc,
  curbPassLogoSrc,
  curbSuiteLogoSrc,
  ...rest
}) => {
  let src: string;
  switch (appName) {
    case "app.automotus.io":
      src = automotusLogoSrc;
      break;
    case "curbpass.io":
      src = curbPassLogoSrc;
      break;
    case "curbsuite.io":
      src = curbSuiteLogoSrc;
      break;
  }

  return <StyledImg {...rest} src={src} alt={"logo"} />;
};

export interface AppLogoBaseProps extends React.ComponentProps<typeof StyledImg> {
  automotusLogoSrc: string;
  curbPassLogoSrc: string;
  curbSuiteLogoSrc: string;
}

export type AppLogoProps = React.ComponentProps<typeof StyledImg>;

export default AppLogoBase;
