import { gql, makeVar, useMutation, useReactiveVar } from "@apollo/client";
import decodeJwt from "jwt-decode";

/**
 * Use a Tableau JWT to authenticate with Tableau server.
 * @return A structure containing the token as well as values indicating whether the token is currently loading or
 * if there was an error fetching it.
 */
export const useTableauToken = (): UseTableauTokenResult => {
  const token = useReactiveVar(tableauTokenVar);
  const [fetchNewToken, { error, loading }] = useMutation<FetchTableauTokenResult>(FETCH_TABLEAU_TOKEN);

  if (!token || willTokenExpireSoon(token)) {
    fetchNewToken({
      onCompleted: ({ getTableauJwt: { jwt: newToken } }) => {
        tableauTokenVar(newToken);
      },
    });
  }

  const refetch = async () => {
    await fetchNewToken();
  };

  return { token, loading, error, refetch };
};

/** Result returned from calling the {@link useTableauToken} hook */
export interface UseTableauTokenResult {
  /** Indicates whether token value is loading */
  loading: boolean;
  /** Error, if any, resulting from fetching the token */
  error?: Error;
  /** Token string */
  token?: string;
  /** Refetch token function */
  refetch: () => Promise<void>;
}

/**
 * Determine whether the provided token will expire within some predefined window considered "soon." Note that times
 * in the past are always considered "soon."
 * @param tokenStr The token whose impending expiration is to be determined.
 * @return True if the token is about to expire or has already expired.
 */
function willTokenExpireSoon(tokenStr: string): boolean {
  const decoded = decodeJwt<DecodedTableauJWT>(tokenStr);
  const nowEpochMs = Date.now();
  const expEpochMs = decoded.exp * 1e3;

  return expEpochMs - nowEpochMs < SOON_INTERVAL_MS;
}

/** Minimum interval between some time and the current time which is considered to be "soon." */
const SOON_INTERVAL_MS = 10e3;

/** A reactive variable used to locally cache a Tableau token */
const tableauTokenVar = makeVar<string | undefined>(undefined);

const FETCH_TABLEAU_TOKEN = gql`
  mutation FetchTableauToken {
    getTableauJwt: get_tableau_jwt {
      jwt
    }
  }
`;

/** Result type of the {@link FETCH_TABLEAU_TOKEN} mutation */
interface FetchTableauTokenResult {
  getTableauJwt: {
    jwt: string;
  };
}

/** Minimal interface of the decoded Tableau JWT */
interface DecodedTableauJWT {
  /** Seconds since Epoch at which token will expire */
  exp: number;
}

export default useTableauToken;
