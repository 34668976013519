import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircle from "@app.automotus.io/components/icons/CheckCircle";
import Button from "@mui/material/Button";

export const WalletRefundConfirmationModal: React.FC<WalletRefundConfirmationModalProps> = ({
  loading = false,
  email = "contact@automotus.co",
  onClose = () => undefined,
}) => {
  return (
    <Box>
      <LoadingSkeleton loading={loading} width="100%">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIcon onClick={onClose} />
        </Box>
      </LoadingSkeleton>
      <LoadingSkeleton loading={loading} width="100%">
        <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
          <Typography variant="h5">Wallet Refund Request Sent</Typography>
        </Box>
      </LoadingSkeleton>
      <LoadingSkeleton loading={loading} width="100%">
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: { xs: 4, tiny: 4, sm: 4, md: 4.5 } }}>
          <CheckCircle />
        </Box>
      </LoadingSkeleton>
      <LoadingSkeleton loading={loading} width="100%">
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: { xs: 4, tiny: 4, sm: 4, md: 4.5 } }}>
          <Typography component="span" variant="body1" textAlign="center">
            {`Your request is being processed by our support team and a confirmation email has been sent to `}
            <Typography component="span" variant="body1" sx={{ fontWeight: "bold" }}>
              {email}
            </Typography>
            {`. A support member will be in touch within 24 hours to confirm the refund request, as well as the final refund
            amount after all pending transactions have been processed. Refunds may take up to 7 business days to be
            reflected and all refunds will be issued back to the card used on file.`}
          </Typography>
        </Box>
      </LoadingSkeleton>
      <LoadingSkeleton loading={loading} width="100%">
        <Box mt={5}>
          <Button variant="contained" fullWidth onClick={onClose}>
            Close
          </Button>
        </Box>
      </LoadingSkeleton>
    </Box>
  );
};

export interface WalletRefundConfirmationModalProps {
  loading?: boolean;
  email?: string;
  onClose?: () => void;
}

export default WalletRefundConfirmationModal;
