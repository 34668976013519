import { gql } from "@apollo/client";
import identityFragment, { Identity } from "common/graphql/fragments/identityFragment";

/**
 * Query that retrieves the identity of a user along with his profile and preferences
 */
export const GET_AUTHORIZED_USER_IDENTITY = gql`
  query GetAuthorizedUserIdentity($subjectIdentifier: String!) {
    identity: auth_identity_by_pk(where: { subject_identifier: { _eq: $subjectIdentifier } }) {
      ...identityFragment
    }
  }
  ${identityFragment}
`;

/** Query variables provided to the {@link GET_AUTHORIZED_USER_IDENTITY} query */
export interface GetAuthorizedUserIdentityVars {
  /** Subject identifier */
  subjectIdentifier: string;
}

/** Query result of the {@link GET_AUTHORIZED_USER_IDENTITY} query */
export interface GetAuthorizedUserIdentityData {
  /** Identity of the user */
  identity: Identity;
}

export default GET_AUTHORIZED_USER_IDENTITY;
