import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MobileNavBarItem } from "@app.automotus.io/components/NavBar/NavBarItem/MobileNavBarItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DrawerMenu from "@app.automotus.io/components/DrawerMenu";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { GetAllInvoicesData } from "common/graphql/queries";
import { NavBarTarget } from "@app.automotus.io/components/NavBar";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";

export const MobileNavBarMenu: React.FC<MobileNavBarMenuProps> = ({ targets, invoicesData, invoicesLoading }) => {
  const { userProfile } = useUserProfile();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isInvoicesShown, setIsInvoicesShown] = useState<boolean>(false);
  const navigate = useNavigate();

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const onCloseSubMenuDrawer = () => {
    setIsInvoicesShown(false);
    onCloseDrawer();
  };

  const onOpenDrawer = () => {
    setIsDrawerOpen(true);
    setIsInvoicesShown(false);
  };

  useEffect(() => {
    isInvoicesShown && setIsDrawerOpen(true);
  }, [isInvoicesShown]);

  return (
    <>
      <IconButton onClick={onOpenDrawer}>
        <Box
          sx={{
            width: 28,
            height: 28,
            borderRadius: (theme) => theme.spacing(0.5),
            border: "1px solid",
            borderColor: "primary.main",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            color: "primary.main",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {userProfile?.givenName?.charAt(0).toUpperCase()}
          {userProfile?.familyName?.charAt(0).toUpperCase()}
        </Box>
      </IconButton>
      {!isInvoicesShown && (
        <DrawerMenu open={isDrawerOpen} onClose={onCloseDrawer} submenu={isInvoicesShown}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            {targets.map((targetProps) => (
              <MobileNavBarItem
                key={targetProps.title}
                title={targetProps.title}
                icon={targetProps.icon}
                onClick={(e) => {
                  if (targetProps.title === "Invoices") {
                    setIsInvoicesShown(true);
                    return;
                  }
                  if (targetProps.onClick) {
                    targetProps.onClick(e);
                  } else {
                    navigate(targetProps.to);
                  }
                }}
              />
            ))}
          </Box>
        </DrawerMenu>
      )}
      {isInvoicesShown && !invoicesLoading && (
        <DrawerMenu open={isInvoicesShown} onClose={onCloseSubMenuDrawer} submenu={isInvoicesShown}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "rgba(0, 0, 0, 0.60)",
                py: 0.75,
                px: 2,
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
              onClick={() => setIsInvoicesShown(false)}
            >
              <KeyboardArrowLeft sx={{ width: 36, height: 36 }} />
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                All Invoices
              </Typography>
            </Box>
            {invoicesData?.invoices.map((invoice) => (
              <MobileNavBarItem
                key={invoice.index}
                title={String(invoice.index).padStart(6, "0")}
                icon={
                  invoice.amount_paid === 0 ? (
                    <Typography variant="caption" color="#E31B0C">
                      Unpaid
                    </Typography>
                  ) : null
                }
                onClick={() => {
                  navigate(`/invoices/${invoice.index}`);
                }}
                submenuItem={isInvoicesShown}
              />
            ))}
          </Box>
        </DrawerMenu>
      )}
    </>
  );
};

export interface MobileNavBarMenuProps {
  invoicesData?: GetAllInvoicesData;
  invoicesLoading?: boolean;
  targets: NavBarTarget[];
}
