import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { AmountSelectInput } from "./AmountSelectInput/AmountSelectInput";
import DesiredBalanceDisplay from "@app.automotus.io/components/CurbPassSettings/DesiredBalanceDisplay/DesiredBalanceDisplay";
import { AutoRefillSlider } from "./AutoRefillSlider/AutoRefillSlider";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import { CurbPassSettingsHeader } from "@app.automotus.io/components/CurbPassSettings/CurbPassSettingsHeader";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

/**
 * Component that displays input fields for modifying curbpass settings. Designed to be used within a form.
 */
export const CurbPassSettings: React.FC<CurbPassSettingsProps> = ({
  autoRefillAt = 525,
  desiredBalance = 1500,
  onChangeAmount = () => undefined,
  onBlurAmount,
  onChangeRefillAt = () => undefined,
  amountError,
  amountHelperText = " ",
  loading = false,
  onCancel,
  isSaved = false,
  isSaving = false,
  isSaveDisabled = false,
  onClickParkingRates,
}) => {
  const isMobile = useIsMobile();
  return (
    <Stack spacing={isMobile ? 3 : 4}>
      <CurbPassSettingsHeader isSaved={isSaved} onClickBack={onCancel} />
      <Stack spacing={3}>
        <Box>
          <Box>
            <LoadingSkeleton loading={loading}>
              <Typography variant={"h7"}>CurbPass Amount*</Typography>
            </LoadingSkeleton>
          </Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant={"caption"}>Select an amount that fits your needs</Typography>
          </LoadingSkeleton>
          <AmountSelectInput
            amount={desiredBalance}
            onChange={onChangeAmount}
            helperText={amountHelperText}
            error={amountError}
            loading={loading}
            disabled={isSaving}
            onBlur={onBlurAmount}
          />
          <Box>
            <LoadingSkeleton loading={loading}>
              <Typography>
                Not sure what to select? See{" "}
                <Link component={"button"} onClick={onClickParkingRates}>
                  <Typography>Parking Rates</Typography>
                </Link>
              </Typography>
            </LoadingSkeleton>
          </Box>
        </Box>
        <Box>
          <Box>
            <LoadingSkeleton loading={loading}>
              <Typography variant={"h7"}>Auto refill Settings</Typography>
            </LoadingSkeleton>
          </Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant={"caption"}>Customize your auto refill settings</Typography>
          </LoadingSkeleton>
          <Stack spacing={2} direction={"row"} sx={{ mt: 1.5 }}>
            <LoadingSkeleton loading={loading}>
              <DesiredBalanceDisplay amount={desiredBalance} />
            </LoadingSkeleton>
            <LoadingSkeleton loading={loading} width={"100%"}>
              <Box sx={{ width: "100%" }}>
                <Typography>Auto refill at</Typography>
                <AutoRefillSlider
                  desiredWalletBalance={desiredBalance}
                  value={autoRefillAt}
                  onChange={onChangeRefillAt}
                  disabled={isSaving}
                />
              </Box>
            </LoadingSkeleton>
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <LoadingSkeleton loading={loading} width={"100%"}>
          <LoadingButton
            loading={isSaving}
            disabled={isSaveDisabled}
            variant={"contained"}
            fullWidth
            sx={{ textTransform: "none" }}
            type={"submit"}
          >
            Save
          </LoadingButton>
        </LoadingSkeleton>
        <LoadingSkeleton loading={loading} width={"100%"}>
          <Button onClick={onCancel} disabled={isSaving} variant={"outlined"} fullWidth sx={{ textTransform: "none" }}>
            Cancel
          </Button>
        </LoadingSkeleton>
      </Stack>
    </Stack>
  );
};

/** Props passed to initialize the {@link CurbPassSettings} component */
export interface CurbPassSettingsProps {
  /** Desired wallet balance expressed in terms of smallest denomination of the currency */
  desiredBalance?: number;
  /** Desired balance at which wallet is refilled, expressed in terms of the smallest denomination of the currency */
  autoRefillAt?: number;
  /** Indicates whether an error should be displayed in the desired balance input */
  amountError?: boolean;
  /** Helper text displayed below the desired balance input */
  amountHelperText?: string;
  /** Change handler fired when desired balance changes */
  onChangeAmount?: (amount: number) => void;
  /** Handler fired when desired balance text input is blurred */
  onBlurAmount?: () => void;
  /** Change handler fired when auto refill at changes */
  onChangeRefillAt?: (refillAt: number) => void;
  /** When true, component will display as loading */
  loading?: boolean;
  /** Handler fired when cancel button is clicked */
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  /** When true, component will display as saving settings */
  isSaving?: boolean;
  /** When true, save button is disabled */
  isSaveDisabled?: boolean;
  /** When false, indicates that current settings have not been saved */
  isSaved?: boolean;
  /** Handler fired when "See Parking Rates" button is clicked */
  onClickParkingRates?: React.MouseEventHandler<HTMLSpanElement>;
}

export default CurbPassSettings;
