import { useEffect, useState, useCallback } from "react";
import { CityLocationInfo } from "@app.automotus.io/components/scenes/SignUp/reactiveVars";
import { useReactiveVar } from "@apollo/client";
import { findNearestLocation } from "common/helpers";
import { CITY_OPTIONS } from "common/constants";
import { useGeoLocation } from "@app.automotus.io/components/hooks/useGeoLocation";
import { activePayeeAccountIdVar } from "common/graphql/cache";
import { useUserProfile } from "@app.automotus.io/components/hooks";

export function useActivePayee(): UseActivePayeeAccountIdResult {
  const activePayeeId = useReactiveVar(activePayeeAccountIdVar);
  const cityOption = CITY_OPTIONS.find(({ payeeAccountId }) => activePayeeId && payeeAccountId === activePayeeId);
  const [payeeInfo, setPayeeInfo] = useState(
    cityOption ? { payeeAccountId: cityOption.payeeAccountId, cityName: cityOption.value } : null,
  );
  const { loading, error: locationError, location } = useGeoLocation();
  const [error, setError] = useState<Error>();
  const { userProfile } = useUserProfile();

  const setActivePayee = useCallback((newActivePayee: CityLocationInfo) => {
    setError(undefined);
    localStorage.setItem("activePayeeAccountId", newActivePayee.payeeAccountId);
    activePayeeAccountIdVar(newActivePayee.payeeAccountId);
    setPayeeInfo(newActivePayee);
  }, []);

  useEffect(() => {
    if (!payeeInfo) {
      // Use the first wallet owned by the user to define the active payee
      if (userProfile?.account?.wallets?.length) {
        const newActivePayee = CITY_OPTIONS.find(
          ({ payeeAccountId }) => payeeAccountId === userProfile.account.wallets[0].payeeAccountId,
        );
        if (newActivePayee) {
          setActivePayee({
            payeeAccountId: newActivePayee.payeeAccountId,
            cityName: newActivePayee.value,
          });
          return;
        }
      }

      if (location) {
        // Otherwise, use the geographically nearest city to the user
        const nearestCity = findNearestLocation(CITY_OPTIONS, location);
        if (!nearestCity) {
          setError(new Error("no nearest payee exists"));
          return;
        }
        setPayeeInfo({
          payeeAccountId: nearestCity.payeeAccountId,
          cityName: nearestCity.value,
        });
      }
    }
  }, [payeeInfo, location, setActivePayee, userProfile]);

  return {
    loading,
    error: error || locationError,
    activePayee: payeeInfo,
    payeeSelectedByUser: !!activePayeeId,
    setActivePayee,
  };
}

export interface UseActivePayeeAccountIdResult {
  loading: boolean;
  error?: Error;
  activePayee: CityLocationInfo | null;
  payeeSelectedByUser: boolean;
  setActivePayee: (newActivePayee: CityLocationInfo) => void;
}

export default useActivePayee;
