import React from "react";
import ErrorPageLayout from "./ErrorPageLayout";
import Typography from "@mui/material/Typography";

/**
 * Renders a page displaying some navigation error to the user. Great
 * for something like a 404 page, for instance.
 */
export const ErrorPage: React.FC<ErrorPageProps> = ({ code, message }) => {
  return (
    <ErrorPageLayout>
      <Typography variant="h4">{code} Error</Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        {message}
      </Typography>
    </ErrorPageLayout>
  );
};

/** Props passed to initialize an {@link ErrorPage} component */
export interface ErrorPageProps {
  /** HTTP error code (e.g. 404) */
  code: string;
  /** Error message */
  message: string;
}

export default ErrorPage;
