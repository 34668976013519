import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import DesktopNavbar from "../PartnerLandingPageDesktopNavbar";
import MobileNavbar from "../PartnerLandingPageMobileNavbar";
import PartnerLandingPageHowItWorks from "../PartnerLandingPageHowItWorks";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import GoogleMapReact from "google-map-react";
import { PartnerLandingPageParkingRatesAndHoursModal } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import {
  GET_LANDING_PAGE_MAP_SETTING,
  GET_PAYEE_ZONES_WITH_LOCATIONS,
  GetLandingPageMapSettingData,
  GetLandingPageMapSettingVars,
  GetPayeeZoneWithLocationsData,
  GetPayeeZoneWithLocationsVars,
} from "common/graphql";
import { useQuery } from "@apollo/client";
import { BottomSheet } from "@app.automotus.io/components/BottomSheet";
import MobileHeader from "./MobileHeader";
import NotificationBanner from "@app.automotus.io/components/NotificationBanner/NotificationBanner";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { otpStart } from "@app.automotus.io/components/auth";
import PhoneNumberInput from "@app.automotus.io/components/common/PhoneNumberInput";
import Alert from "@mui/material/Alert";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import Stack from "@mui/material/Stack";
import { Formik, Form } from "formik";
import { ZoneRegulations } from "@app.automotus.io/components/ZoneRegulations";

const Marker: React.FC<{ lat: number; lng: number }> = () => (
  <Box
    onClick={() => {
      console.log("Zone clicked!");
    }}
    sx={{
      border: "2px solid black",
      borderRadius: "100%",
      width: 20,
      height: 20,
      backgroundColor: "rgb(196, 156, 251)",
    }}
  >
    <Box sx={{ ml: 1, mt: 1, borderRadius: "100%", width: 4, height: 4, backgroundColor: "black" }} />
  </Box>
);

export enum ZoneTabsValue {
  ZONE_MAP,
  ZONE_REGULATIONS,
}

export const LandingPageLayout: React.FC<LandingPageLayoutProps> = ({ partnerName = "omaha" }) => {
  let defaultLocation = null;
  let zoneLocations: number[][] = [];
  const navigate = useNavigate();

  let payeeAccountId = process.env.REACT_APP_OMAHA_ACCOUNT_ID || "";
  if (partnerName === "pittsburgh") {
    payeeAccountId = process.env.REACT_APP_PITTSBURGH_ACCOUNT_ID || "";
  } else if (partnerName === "bethlehem") {
    payeeAccountId = process.env.REACT_APP_BETHLEHEM_ACCOUNT_ID || "";
  } else if (partnerName === "fortlee") {
    payeeAccountId = process.env.REACT_APP_FORT_LEE_ACCOUNT_ID || "";
  }

  const [selectedTab, setSelectedTab] = useState(ZoneTabsValue.ZONE_MAP);

  const {
    data: mapSettingData,
    loading: mapSettingLoading,
    refetch: refetchMapSetting,
    error: mapSettingError,
  } = useQuery<GetLandingPageMapSettingData, GetLandingPageMapSettingVars>(GET_LANDING_PAGE_MAP_SETTING, {
    variables: { payeeAccountId },
  });

  const {
    data: zoneLocationData,
    loading: mapZonesLoading,
    refetch: refetchZoneLocationData,
    error: zoneLocationDataError,
  } = useQuery<GetPayeeZoneWithLocationsData, GetPayeeZoneWithLocationsVars>(GET_PAYEE_ZONES_WITH_LOCATIONS, {
    variables: { payeeAccountId },
  });

  const theme = useTheme();
  const [parkingInfoModalOpen, setParkingInfoModalOpen] = useState(false);
  const [howItWorksSheetOpen, setHowItWorksSheetOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isWide = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));
  const isSmallHeight = useMediaQuery("(max-height: 750px)");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [failedToGetConfirmationCodeError, setFailedToGetConfirmationCodeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidPhoneNumberError, setInvalidPhoneNumberError] = useState(false);

  const error = failedToGetConfirmationCodeError || invalidPhoneNumberError;

  const errorMessage =
    failedToGetConfirmationCodeError || invalidPhoneNumberError ? "Please enter a valid phone number" : "";

  const handleOpenHowItWorksSheet = () => {
    setHowItWorksSheetOpen(true);
  };

  const handleCloseHowItWorksSheet = () => {
    setHowItWorksSheetOpen(false);
  };

  const refetchOnError = () => {
    if (mapSettingError) {
      refetchMapSetting();
    }
    if (zoneLocationDataError) {
      refetchZoneLocationData();
    }
  };

  if (zoneLocationDataError || mapSettingError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  if (mapSettingData && mapSettingData.mapSetting && mapSettingData.mapSetting.length > 0) {
    const { mapSetting } = mapSettingData;
    const { landing_page_google_maps_default_zoom, location_centerpoint } = mapSetting[0];
    defaultLocation = {
      center: {
        lat: location_centerpoint.coordinates[1],
        lng: location_centerpoint.coordinates[0],
      },
      zoom: landing_page_google_maps_default_zoom,
    };
  }

  if (zoneLocationData && zoneLocationData.zones) {
    const { zones } = zoneLocationData;
    zoneLocations = zones.map((zone) => zone.location.coordinates[0]);
  }

  const googleMapKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

  const handleOpenParkingInfoModal = () => {
    setParkingInfoModalOpen(true);
  };

  const handleCloseParkingInfoModal = () => {
    setParkingInfoModalOpen(false);
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: ZoneTabsValue) => {
    setSelectedTab(newValue);
  };

  const handleRegisterButtonClicked = async () => {
    if (!isMobile) {
      navigate("/login");

      return;
    }

    if (!isPossiblePhoneNumber(phoneNumber) || !isValidPhoneNumber(phoneNumber)) {
      setInvalidPhoneNumberError(true);
      return;
    }

    setFailedToGetConfirmationCodeError(false);

    setLoading(true);

    try {
      await otpStart({ phoneNumber });

      navigate(`/login?confirm=true&phoneNumber=%2B${phoneNumber.slice(1)}`);
    } catch (err) {
      console.error(err);
      setFailedToGetConfirmationCodeError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePhoneNumber = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber);
    setInvalidPhoneNumberError(false);
  };

  let desktopLogo = (
    <img
      src={`${process.env.REACT_APP_ASSET_ENDPOINT}/images/park-omaha/logo-colorx44.png`}
      alt={"Park Omaha Logo"}
      style={{ height: 44 }}
    />
  );
  switch (partnerName) {
    case "fortlee":
      desktopLogo = (
        <>
          <img
            src={`${process.env.REACT_APP_ASSET_ENDPOINT}/images/fort-lee/logo.svg`}
            style={{ height: 44, width: 44 }}
          />
          <Typography variant="h7" sx={{ ml: 1.5, color: theme.palette.primary.black }}>
            Fort Lee Smart Loading Zone Program
          </Typography>
        </>
      );
      break;
    case "pittsburgh":
      desktopLogo = (
        <>
          <img
            src={`${process.env.REACT_APP_ASSET_ENDPOINT}/images/pittsburgh/logox72`}
            style={{ height: 44, width: 44 }}
          />
          <Typography variant="h7" sx={{ ml: 1.5, color: theme.palette.primary.black }}>
            Pittsburgh Smart Loading Zone Program
          </Typography>
        </>
      );
      break;
    case "bethlehem":
      desktopLogo = (
        <>
          <img
            src={`${process.env.REACT_APP_ASSET_ENDPOINT}/images/bethlehem/logox88.png`}
            style={{ height: 44, width: 44 }}
          />
          <Typography variant="h7" sx={{ ml: 1.5, color: theme.palette.primary.black }}>
            Bethlehem Smart Loading Zone Program
          </Typography>
        </>
      );
      break;
    default:
      break;
  }

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
        minHeight: (!isMobile && 656) || undefined,
      }}
    >
      {isWide && <DesktopNavbar openParkingInfoModal={handleOpenParkingInfoModal} />}

      <NotificationBanner />

      {isMobile && <MobileHeader partnerName={partnerName} />}
      {!isWide && (
        <MobileNavbar openHowItWorks={handleOpenHowItWorksSheet} openParkingInfoModal={handleOpenParkingInfoModal} />
      )}
      {isMobile && (
        <BottomSheet open={howItWorksSheetOpen} onClose={handleCloseHowItWorksSheet}>
          <PartnerLandingPageHowItWorks />
          <Box sx={{ flex: 1 }} />
          <Button
            variant={"contained"}
            fullWidth
            onClick={handleCloseHowItWorksSheet}
            sx={{
              m: 2,
              height: isSmall ? "30px" : "36px",
              fontSize: isSmall ? "14px" : "16px",
              fontWeight: 600,
              textTransform: "none",
              width: `auto`,
            }}
          >
            Back
          </Button>
        </BottomSheet>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: isWide ? "row" : isMobile ? "column-reverse" : "column",
          height: `calc(100% - ${isWide ? 96 : isMobile ? 81 : 73}px)`,
        }}
      >
        <Box
          sx={{
            width: isWide ? "655px" : "100%",
            position: "relative",
            maxWidth: isWide ? "655px" : "100%",
            height: isWide ? "100%" : "450px",
          }}
        >
          <Box sx={{ height: "100%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                backgroundPosition: isMobile ? "initial" : "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                mixBlendMode: "hard-light",
                opacity: 0.3,
              }}
            />
            <Box
              sx={{
                color: theme.palette.text.primary,
                left: isMobile ? theme.spacing(2) : theme.spacing(5),
                right: isMobile ? theme.spacing(2) : theme.spacing(5),
                display: "flex",
                flexDirection: "column",
                justifyContent: isMobile ? "flex-end" : "flex-start",
                width: isWide ? "auto" : "fit-content",
                paddingLeft: isMobile ? theme.spacing(2) : isWide ? theme.spacing(11) : 0,
                paddingRight: isMobile ? theme.spacing(2) : isWide ? theme.spacing(11) : 0,
                marginLeft: isWide ? 0 : "auto",
                marginRight: isWide ? 0 : "auto",
                paddingTop: { xs: 1.5, tiny: 2, md: isSmallHeight || !isWide ? "50px" : "150px" },
              }}
            >
              {partnerName === "omaha" ? (
                <Box>
                  <Typography variant={isSmall ? "h6" : isMobile ? "h5" : "h2"}>Pull Up,</Typography>
                  <Typography variant={isSmall ? "h6" : isMobile ? "h5" : "h2"}>Get Things Done,</Typography>
                  <Typography variant={isSmall ? "h6" : isMobile ? "h5" : "h2"}>Leave</Typography>
                </Box>
              ) : (
                <Box>
                  {isWide && (
                    <>
                      <Typography variant="h2" sx={{ fontWeight: 400 }}>
                        Save time
                      </Typography>
                      <Typography variant="h2" sx={{ fontWeight: 400 }}>
                        with
                      </Typography>
                      <Typography variant="h2" sx={{ fontWeight: 400 }}>
                        CurbPass
                      </Typography>
                    </>
                  )}
                  {!isWide && !isMobile && (
                    <>
                      <Typography variant="h2" sx={{ fontWeight: 400 }}>
                        Save time with
                      </Typography>
                      <Typography variant="h2" sx={{ fontWeight: 400 }}>
                        CurbPass
                      </Typography>
                    </>
                  )}
                  {isMobile && (
                    <>
                      <Typography variant="h4" sx={{ fontWeight: 500, fontSize: { xs: "1.5rem", tiny: "1.75rem" } }}>
                        {"Save time with "}
                        <Typography
                          component="span"
                          variant="h4"
                          color="primary.main"
                          sx={{ fontWeight: 500, fontSize: { xs: "1.5rem", tiny: "1.75rem" } }}
                        >
                          CurbPass
                        </Typography>
                      </Typography>
                    </>
                  )}
                </Box>
              )}
              <Typography
                variant="subtitle1"
                sx={{ mb: { xs: 1.5, tiny: 2, md: 5 } }}
                fontSize={{ xs: "0.875rem", tiny: "1rem", md: "1.5rem" }}
              >
                Our cameras auto charge your account
              </Typography>
              {isMobile && (
                <Box sx={{ mb: { xs: 3, tiny: 3.5 } }}>
                  <Formik initialValues={{}} onSubmit={() => undefined}>
                    <Form>
                      <PhoneNumberInput
                        autoFocus
                        fullWidth
                        value={phoneNumber}
                        onChange={(e) => handleChangePhoneNumber(e.target.value)}
                        disabled={loading}
                      />
                      {error && (
                        <Alert icon={<InfoIcon sx={{ fontSize: "20px" }} />} severity="error">
                          {errorMessage}
                        </Alert>
                      )}
                    </Form>
                  </Formik>
                </Box>
              )}
              <Stack spacing={2}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleRegisterButtonClicked}
                  sx={{
                    textTransform: "none",
                    fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                    lineHeight: { xs: "28px", md: "26px" },
                    minHeight: { xs: "30px", tiny: "36px", lg: "42px" },
                    fontWeight: { xs: 600, md: 500 },
                  }}
                >
                  {partnerName === "pittsburgh"
                    ? "Register Now - free first park included"
                    : "Register for a free trial"}
                </Button>
                {!isMobile && (
                  <>
                    <Divider>
                      <Typography
                        variant={"body1"}
                        sx={{ color: theme.palette.text.secondary, fontSize: "0.875rem", lineHeight: "20px" }}
                      >
                        or
                      </Typography>
                    </Divider>
                  </>
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleRegisterButtonClicked}
                  sx={{
                    mb: isMobile ? 1.25 : undefined,
                    textTransform: "none",
                    fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                    lineHeight: { xs: "28px", md: "26px" },
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                    fontWeight: { xs: 600, md: 500 },
                  }}
                >
                  Login
                </Button>
              </Stack>
            </Box>
            {isWide && (
              <Box
                sx={{
                  position: isSmallHeight ? "initial" : "absolute",
                  left: theme.spacing(5),
                  right: theme.spacing(5),
                  bottom: theme.spacing(2.5),
                  marginLeft: isSmallHeight ? theme.spacing(5) : 0,
                  py: isSmallHeight ? 2.5 : 0,
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.primary.white,
                }}
              >
                {desktopLogo}
              </Box>
            )}
          </Box>
        </Box>
        {isMobile && (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="zone map and regulation tabs"
            sx={{
              ".MuiTabs-indicator": {
                top: 0,
              },
            }}
          >
            <Tab
              label="Zone Map"
              value={ZoneTabsValue.ZONE_MAP}
              sx={{ width: "50%", fontSize: { tiny: "1rem" }, fontWeight: 500, textTransform: "none" }}
            />
            <Tab
              label="Zone Regulations"
              value={ZoneTabsValue.ZONE_REGULATIONS}
              sx={{ width: "50%", fontSize: { tiny: "1rem" }, fontWeight: 500, textTransform: "none" }}
            />
          </Tabs>
        )}
        {(!isMobile || selectedTab === ZoneTabsValue.ZONE_MAP) && (
          <Box
            sx={{
              flex: isWide ? 1 : "inherit",
              height: isWide ? "auto" : `calc(100% - 450px - ${isMobile ? 0 : 68}px)`,
            }}
          >
            {!mapSettingLoading && !mapZonesLoading && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapKey }}
                defaultCenter={defaultLocation?.center}
                defaultZoom={defaultLocation?.zoom}
                options={{
                  gestureHandling: "greedy",
                }}
              >
                {zoneLocations.map(([lng, lat], ind) => (
                  <Marker lat={lat} lng={lng} key={ind} />
                ))}
              </GoogleMapReact>
            )}
          </Box>
        )}
        {isMobile && selectedTab === ZoneTabsValue.ZONE_REGULATIONS && (
          <Box sx={{ height: "calc(100% - 450px)", overflowX: "hidden", overflowY: "auto" }}>
            <ZoneRegulations payeeAccountId={payeeAccountId} />
          </Box>
        )}
        {!isWide && !isMobile && (
          <Box
            sx={{
              position: isSmallHeight ? "initial" : "absolute",
              left: theme.spacing(5),
              right: theme.spacing(5),
              bottom: theme.spacing(1.5),
              marginLeft: isSmallHeight ? theme.spacing(5) : 0,
              py: isSmallHeight ? 2.5 : 0,
              display: "flex",
              alignItems: "center",
              color: theme.palette.primary.white,
              justifyContent: "center",
            }}
          >
            {desktopLogo}
          </Box>
        )}
      </Box>
      <PartnerLandingPageParkingRatesAndHoursModal
        open={parkingInfoModalOpen}
        onClose={handleCloseParkingInfoModal}
        payeeAccountId={payeeAccountId}
      />
    </Box>
  );
};

export interface LandingPageLayoutProps {
  partnerName?: string;
}
export default LandingPageLayout;
