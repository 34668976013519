import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { PaymentInfo, paymentInfoVar, ProfileInfo, profileInfoVar, VehicleInfo, vehicleInfoVar } from "./reactiveVar";

export interface FormObserverProps {
  formType: string;
}

export const FormObserver: React.FC<FormObserverProps> = ({ formType }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (formType === "profile") {
      profileInfoVar(values as ProfileInfo);
    } else if (formType === "payment") {
      paymentInfoVar(values as PaymentInfo);
    } else if (formType === "vehicle") {
      vehicleInfoVar(values as VehicleInfo);
    }
  }, [formType, values]);

  return null;
};
