import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LoadingSkeleton from "../../LoadingSkeleton";
import { TransactionPaymentData } from "../../TransactionPayments";
import InvoiceTransactionCardView from "../InvoiceTransactionCardView";
import Typography from "@mui/material/Typography";

export const InvoiceTransactionsCardList: React.FC<InvoiceTransactionsCardListProps> = ({
  loading = false,
  data = [],
}) => {
  return (
    <LoadingSkeleton width="100%" loading={loading}>
      {data &&
        data.length !== 0 &&
        data.map((item) => (
          <Box key={item.id} mb={3}>
            <InvoiceTransactionCardView loading={loading} data={item} />
          </Box>
        ))}
      {(!data || data.length === 0) && (
        <Paper sx={{ p: 2 }}>
          <Typography color="text.primary">There are currently no transactions to report</Typography>
        </Paper>
      )}
    </LoadingSkeleton>
  );
};

export interface InvoiceTransactionsCardListProps {
  loading?: boolean;
  data?: TransactionPaymentData[];
}

export default InvoiceTransactionsCardList;
