import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoadingSkeleton from "../../LoadingSkeleton";
import Description from "../../icons/Description";
import AccessTimeRounded from "../../icons/AccessTimeRounded";
import { convertDateToString, convertTimeToString } from "common/format";
import { DepositLogData } from "../DepositLog";
import { formatCurrency } from "common/format";

export const DepositLogCardView: React.FC<DepositLogCardViewProps> = ({
  loading = false,
  data = {
    id: -1,
    date: new Date(),
    time: new Date(),
    description: "-",
    payment: "-",
    amount: 0,
    balance: 0,
  },
}) => {
  return (
    <LoadingSkeleton width="100%" loading={loading}>
      <Paper>
        <Box p={2} sx={{ display: "flex", borderBottom: "1px solid #EEEEEE", color: "text.primary" }}>
          <Description />
          <Typography variant="body1" sx={{ ml: 2.5 }}>
            {data.description}
          </Typography>
        </Box>
        <Box p={2} sx={{ display: "flex", borderBottom: "1px solid #EEEEEE", color: "text.primary" }}>
          <AccessTimeRounded />
          <Box sx={{ ml: 2.5, width: "100%" }}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1">{convertDateToString(data.date)}</Typography>
              <Typography variant="body1">${formatCurrency(data.amount)}</Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mt: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 300 }}>
                {convertTimeToString(data.time)}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 300 }}>
                ${formatCurrency(data.balance)}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* TODO: reenable payment field */}
        {/*<Box p={2} sx={{ display: "flex", color: "text.secondary" }}>*/}
        {/*  <PaymentRounded />*/}
        {/*  <Typography variant="body1" sx={{ ml: 2.5 }}>*/}
        {/*    {data.payment}*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
      </Paper>
    </LoadingSkeleton>
  );
};

export interface DepositLogCardViewProps {
  loading?: boolean;
  data?: DepositLogData;
}

export default DepositLogCardView;
