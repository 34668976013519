import React from "react";
import { fieldToTextField } from "formik-mui";
import * as yup from "yup";
import { PhoneNumberInput, PhoneNumberInputProps } from "@app.automotus.io/components/common/PhoneNumberInput";
import { useField, useFormikContext } from "formik";

export const PhoneNumberField: PhoneNumberFieldComponent = (props) => {
  const propsWithDefaults = {
    required: true,
    fullWidth: true,
    id: "phoneNumber",
    name: "phoneNumber",
    label: "Phone Number",
    ...props,
  };
  const form = useFormikContext();
  const [field, meta] = useField(propsWithDefaults.name);

  return (
    <PhoneNumberInput
      {...fieldToTextField({ ...propsWithDefaults, meta, form, field })}
      onChange={(e) => {
        const { value } = e.target;
        form.setFieldValue(propsWithDefaults.name, value || "");
      }}
    />
  );
};

const phoneNumberRegex = /^\+[0-9]{1,2}[0-9]{10}$/;

const validationSchema = yup
  .string()
  .required("Valid phone number required.")
  .matches(phoneNumberRegex, "Valid phone number required.");

PhoneNumberField.validationSchema = validationSchema;

PhoneNumberField.defaultInitialValue = "";

export type PhoneNumberFieldProps = Omit<PhoneNumberInputProps, "name" | "value" | "error">;

export interface PhoneNumberFieldComponent extends React.FC<PhoneNumberFieldProps> {
  validationSchema: typeof validationSchema;
  defaultInitialValue: string;
}

export default PhoneNumberField;
