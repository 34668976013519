import React from "react";
import TextField from "@mui/material/TextField";
import VehicleCategory, { VehicleCategoryValue } from "../../../common/VehicleCategory";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import * as yup from "yup";
import { useField, useFormikContext } from "formik";

const OPTIONS = VehicleCategory.All.map((vc) => vc.value);

/**
 * Component that allows a user to select a vehicle category.
 * @param props Props passed to instantiate the component
 */
const VehicleCategoryDropdownFieldPrivate = <DisableClearable extends boolean | undefined>({
  name = "vehicleCategory",
  label = "Vehicle Category",
  placeholder = "Vehicle Category*",
  multiple = false,
  disablePortal = true,
  fullWidth = true,
  autoSelect = false,
  autoHighlight = true,
  disableClearable = true,
  openOnFocus = true,
  required = true,
  helperText,
  disabled,
  ...rest
}: VehicleCategoryDropdownFieldProps<DisableClearable>) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta, helpers] = useField<VehicleCategoryValue>(name);

  const showError = meta.touched && !!meta.error;

  return (
    <Autocomplete<VehicleCategoryValue, false, typeof disableClearable, false>
      {...rest}
      {...{ multiple, disablePortal, fullWidth, autoSelect, autoHighlight, disableClearable, openOnFocus }}
      value={meta.value}
      onChange={(e, v) => v !== null && helpers.setValue(v)}
      onBlur={field.onBlur}
      disabled={disabled ?? isSubmitting}
      options={OPTIONS}
      getOptionLabel={(v) => VehicleCategory.FromValue(v).label}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={showError}
          helperText={showError ? meta.error : helperText}
          label={label}
          placeholder={placeholder}
          required={required}
        />
      )}
    />
  );
};

const validationSchema = yup.mixed().oneOf(OPTIONS, "Please select a valid option").required("Required field");

const defaultInitialValue = null as VehicleCategoryValue | null;

export const VehicleCategoryDropdownField = Object.assign(VehicleCategoryDropdownFieldPrivate, {
  validationSchema,
  defaultInitialValue,
});

export type VehicleCategoryDropdownFieldComponent = typeof VehicleCategoryDropdownField;

export interface VehicleCategoryDropdownFieldProps<DisableClearable extends boolean | undefined = true>
  extends Omit<
    AutocompleteProps<VehicleCategoryValue, false, DisableClearable, false>,
    "renderInput" | "options" | "getOptionLabel" | "onChange" | "onBlur" | "value"
  > {
  name?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
}

export default VehicleCategoryDropdownField;
