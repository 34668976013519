import React from "react";
import automotusLogoBlueSrc from "./automotusLogoBlue.svg";
import curbPassLogoBlueSrc from "./curbPassLogoBlue.svg";
import curbSuiteLogoBlueSrc from "./curbSuiteLogoBlue.svg";
import AppLogoBase, { AppLogoProps } from "@app.automotus.io/components/images/AppLogo/AppLogoBase";

export const LogoBlue: React.FC<AppLogoProps> = (props) => (
  <AppLogoBase
    automotusLogoSrc={automotusLogoBlueSrc}
    curbPassLogoSrc={curbPassLogoBlueSrc}
    curbSuiteLogoSrc={curbSuiteLogoBlueSrc}
    {...props}
  />
);

export default LogoBlue;
