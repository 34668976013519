import { gql } from "@apollo/client";

export const GET_ALL_VEHICLE_MODELS_FOR_MAKE = gql`
  query GetAllVehicleModelsForMake($makeId: Int!) @cached(ttl: 300) {
    models: car2db_model(where: { make_id: { _eq: $makeId } }, order_by: [{ name: asc }]) {
      makeId: make_id
      modelId: model_id
      name
    }
  }
`;

export interface GetAllVehicleModelsForMakeVars {
  makeId: number;
}

export interface GetAllVehicleModelsForMakeData {
  models: VehicleModel[];
}

export interface VehicleModel {
  makeId: number;
  modelId: number;
  name: string;
}

export default GET_ALL_VEHICLE_MODELS_FOR_MAKE;
