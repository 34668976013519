import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

export type VehicleStatusNotification = "critical" | "warning" | "voided";

const VehicleStatusContainer = styled(Box)<{ status: VehicleStatusNotification }>(({ status }) => {
  return {
    display: "flex",
    padding: "8px 16px",
    alignItems: "center",
    alignSelf: "stretch",
    ...(status === "critical" && {
      color: "#E31B0C",
      backgroundColor: "rgba(244, 67, 54, 0.08)",
    }),
    ...(status === "warning" && { color: "#ED6C02", backgroundColor: "rgba(237, 108, 2, 0.08)" }),
    ...(status === "voided" && { color: "rgba(0,0,0,0.6)", backgroundColor: "rgba(0, 0, 0, 0.09)" }),
  };
});

export const VehicleInvoiceCardStatus: React.FC<{
  message: React.ReactNode;
  status?: VehicleStatusNotification;
}> = ({ message, status = "voided" }) => (
  <VehicleStatusContainer status={status}>
    <Typography
      sx={{
        display: "flex",
        padding: "0px 12px 0px 0px",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <InfoOutlined />
    </Typography>
    <Typography
      sx={{
        display: "flex",
        padding: "4px 0px",
        alignItems: "flex-start",
        flex: "1 0 0",
      }}
    >
      {message}
    </Typography>
  </VehicleStatusContainer>
);

export default VehicleInvoiceCardStatus;
