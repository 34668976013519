import { gql } from "@apollo/client";

/**
 * Searches for vehicle makes by the name of the make.
 */
export const GET_ALL_VEHICLE_MAKES = gql`
  query GetAllVehicleMakes @cached(ttl: 300) {
    makes: car2db_make(order_by: [{ name: asc }]) {
      makeId: make_id
      name
    }
  }
`;

export interface VehicleMake {
  makeId: number;
  name: string;
}

export interface GetAllVehicleMakesData {
  makes: VehicleMake[];
}

export default GET_ALL_VEHICLE_MAKES;
