import { AuthData, DispatchDataFn, DispatchDataResponse, PaymentData } from "react-acceptjs";
import AcceptJsDispatchDataError from "@app.automotus.io/components/hooks/useAcceptJs/AcceptJsDispatchDataError";
import { useEffect } from "react";
import { AuthorizeNetDataDescriptor } from "api/adaptors/AuthorizeNet";
import { useLazyQuery } from "@apollo/client";
import {
  GET_GATEWAY_AUTHORIZATION_METADATA,
  GetGatewayAuthorizationMetadataData,
  GetGatewayAuthorizationMetadataVars,
} from "@app.automotus.io/components/hooks/useAcceptJs/GetGatewayAuthorizationMetadata";
import { useScript } from "@app.automotus.io/components/hooks/useScript";
import { useActivePayee } from "@app.automotus.io/components/hooks/useActivePayee";

type SecureData = { authData: AuthData } & PaymentData;

// Extend global object
declare global {
  interface Window {
    Accept: {
      dispatchData: DispatchDataFn;
    };
  }
}

/**
 * Returns an object containing a function that can be used to exchange secure payment data (for example, card or bank information)
 * provided by a user for opaque data that can safely reach Automotus servers without being considered PII.
 *
 * The dispatchData function included in the value returned by this hook should be used to perform this exchange,
 * but the function will not be available for use immediately when the hook returns a value. Users
 * must examine the value returned by the hook to ensure that the Accept.js script has loaded successfully
 * before attempting to call the dispatchData function.
 *
 * In any non-production environment, the dispatchData function will execute a call to the Accept.js sandbox
 * environment, otherwise calls will be made to the production environment.
 */
export function useAcceptJs(): UseAcceptJsResult {
  const acceptJsEnvironment =
    process.env.REACT_APP_STAGE === "prod" || process.env.REACT_APP_ACCEPTJS_ENVIRONMENT === "production"
      ? "PRODUCTION"
      : "SANDBOX";
  const scriptUrl =
    acceptJsEnvironment === "PRODUCTION"
      ? "https://js.authorize.net/v1/Accept.js"
      : "https://jstest.authorize.net/v1/Accept.js";
  const [scriptLoaded, scriptError] = useScript(scriptUrl);
  const { activePayee, error: activePayeeError, loading: activePayeeLoading } = useActivePayee();
  const [
    getGatewayAuthorizationMetadata,
    { data: gatewayAuthMetadata, loading: gatewayAuthMetadataLoading, error: gatewayAuthMetadataError },
  ] = useLazyQuery<GetGatewayAuthorizationMetadataData, GetGatewayAuthorizationMetadataVars>(
    GET_GATEWAY_AUTHORIZATION_METADATA,
  );

  useEffect(() => {
    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    (async () => {
      if (activePayee) {
        await getGatewayAuthorizationMetadata({
          variables: { payeeAccountId: activePayee.payeeAccountId },
          context: {
            fetchOptions: { signal: abortSignal },
          },
        });
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [activePayee, getGatewayAuthorizationMetadata]);

  const dispatchData = (paymentData: PaymentData) => {
    const authData = {
      clientKey: gatewayAuthMetadata?.gatewayAuthorizationMetadata[0].acceptJsClientKey || "",
      apiLoginID: gatewayAuthMetadata?.gatewayAuthorizationMetadata[0].acceptJsLoginId || "",
    };

    const payload: SecureData = paymentData.cardData
      ? { cardData: paymentData.cardData, authData }
      : { bankData: paymentData.bankData, authData };
    return new Promise<AcceptJsOpaqueData>((resolve, reject) => {
      if (window) {
        window.Accept.dispatchData(payload, (response: DispatchDataResponse) => {
          if (response.messages.resultCode === "Ok") {
            return resolve(response.opaqueData as AcceptJsOpaqueData);
          }
          reject(new AcceptJsDispatchDataError(response.messages.message));
        });
      }
    });
  };

  const loading = !scriptLoaded || gatewayAuthMetadataLoading || activePayeeLoading;

  return {
    dispatchData,
    loading,
    error: scriptError || !!gatewayAuthMetadataError || !!activePayeeError,
  };
}

/**
 * Exchanges secure payment data provided by a user for opaque (i.e. non-PCI) data for submission to
 * Authorize.net
 * @param paymentData Secure payment data provided by the user
 * @return A promise that resolves to opaque data
 * @throws An {@link AcceptJsDispatchDataError} if the call to Accept.js failed
 */
export type AcceptJsDispatchDataFunc = (paymentData: PaymentData) => Promise<AcceptJsOpaqueData>;

export interface AcceptJsOpaqueData {
  dataDescriptor: AuthorizeNetDataDescriptor;
  dataValue: string;
}

/**
 * Result returned by the {@link useAcceptJs} hook
 */
export interface UseAcceptJsResult {
  /** Indicates if the Accept.js script or other dependencies are loading */
  loading: boolean;
  /** Indicates if the Accept.js script or other dependencies could not load */
  error: boolean;
  /** Function used to dispatch data. See {@link AcceptJsDispatchDataFunc} for details. */
  dispatchData: AcceptJsDispatchDataFunc;
}

export default useAcceptJs;
