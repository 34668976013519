import React from "react";
import { Navigate, useMatch } from "react-router-dom";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { FullScreenLoader } from "@app.automotus.io/components/FullScreenLoader";
import { redirectToApplicationByRoleType } from "@app.automotus.io/components/auth";

/**
 * Component protected by Auth0 authentication. If user is already logged in, but has not yet completed onboarding,
 * redirects the user to the appropriate onboarding page.
 * @param component The component to protect
 * @param componentProps Props passed to the component
 */
export const withAuthorizedRedirection = <P,>(Component: React.ComponentType<P>): React.FC<P> => {
  return (props: P) => {
    const { userProfile, loading } = useUserProfile();
    const signUpMatch = useMatch("/signup/:stage");

    if (loading || !userProfile) {
      return <FullScreenLoader loading={loading} />;
    }

    redirectToApplicationByRoleType(userProfile);

    const onboardingStatus = userProfile.onboardingStatus;

    if (onboardingStatus === "complete" || signUpMatch) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <Component {...(props as any)} />;
    }

    return <Navigate to={`/signup/${onboardingStatus}`} />;
  };
};

export default withAuthorizedRedirection;
