import React, { useRef } from "react";
import SupportIcon from "@app.automotus.io/components/icons/SupportIconMobile";
import { MobileNavBarMenu } from "@app.automotus.io/components/NavBar/NavBarMenu";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import LogoBlue from "@app.automotus.io/components/images/AppLogo/logoBlue";

export const PartnerLandingPageMobileNavbar: React.FC<NavbarProps> = ({ openHowItWorks, openParkingInfoModal }) => {
  const toolbarRef = useRef(null);

  const NAV_LINKS = [
    {
      title: "Zone Regulations",
      to: "/",
      onClick: openParkingInfoModal,
    },
    {
      title: "How it Works",
      to: "/",
      onClick: openHowItWorks,
    },
    {
      title: "Support",
      to: "/support",
      onClick: () => {
        window.open("/support", "_blank");
      },
      icon: <SupportIcon />,
    },
  ];

  return (
    <AppBar
      position={"sticky"}
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <Toolbar
        sx={{
          height: { xs: 54, lg: 88 },
          px: { xs: 2, md: 5 },
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        ref={toolbarRef}
      >
        <LogoBlue sx={{ height: { xs: 22, lg: 24 }, width: "auto" }} />
        <MobileNavBarMenu targets={NAV_LINKS} />
      </Toolbar>
    </AppBar>
  );
};

export interface Interval {
  startMinutes: number;
  endMinutes: number;
  pricePerMinute: number;
}

export interface ParkingRates {
  currency: string;
  intervals: Interval[];
}
export interface NavbarProps {
  openHowItWorks: () => void;
  openParkingInfoModal: () => void;
}

export default PartnerLandingPageMobileNavbar;
