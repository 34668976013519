import React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatCurrency, convertTimeToString, formatSessionDateRange } from "common/format";
import LoadingSkeleton from "../../LoadingSkeleton";
import { TransactionPaymentData } from "../../TransactionPayments";
import AccessTimeRounded from "@mui/icons-material/AccessTimeRounded";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";

const TransactionStatus = ({ violation, status }: { violation?: boolean; status: string }) => {
  const statusString = status === "under_review" ? "Under Review" : violation ? "Violation" : "";
  return statusString ? (
    <Box
      py={0.5}
      pr={2}
      sx={{ justifyContent: "flex-end", display: "flex", borderBottom: "1px solid #EEEEEE", color: "text.primary" }}
    >
      <Typography variant="body4" color={statusString === "Violation" ? "error.main" : "text.primary"}>
        {statusString}
      </Typography>
    </Box>
  ) : null;
};

export const InvoiceTransactionCardView: React.FC<InvoiceTransactionCardViewProps> = ({ loading = false, data }) => {
  const navigate = useNavigate();
  if (!data) {
    return null;
  }

  return (
    <LoadingSkeleton width="100%" loading={loading}>
      <Paper
        onClick={() => {
          navigate(`/transactions/${data.id}`);
        }}
      >
        <TransactionStatus violation={data.violation} status={data.status} />
        <Box p={2} sx={{ display: "flex", borderBottom: "1px solid #EEEEEE", color: "text.primary" }}>
          <AccessTimeRounded sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          <Box sx={{ ml: 2, width: "100%" }}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1">{formatSessionDateRange(data.startTime, data.endTime)}</Typography>
              <Typography variant="body1" fontSize={"20px"} fontWeight={500}>{`${
                data.amount > 0 ? "-" : ""
              }$${formatCurrency(data.amount)}`}</Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body4" sx={{ fontWeight: 400, fontSize: "12px" }}>
                {convertTimeToString(data.startTime)} to {convertTimeToString(data.endTime)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box p={2} sx={{ display: "flex", borderBottom: "1px solid #EEEEEE", color: "text.primary" }}>
          <LocationOnOutlined sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          <Box sx={{ ml: 2, width: "100%" }}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1" fontSize={"14px"}>
                {data.address}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1" fontSize={"14px"}>
                {data.city}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box p={2} sx={{ display: "flex" }}>
          <DirectionsCar sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          <Box sx={{ ml: 2, width: "100%" }}>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: data.violation ? "space-between" : "flex-start" }}
            >
              <Typography variant="body1" fontSize={"14px"}>
                {data.vehicle}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </LoadingSkeleton>
  );
};

export interface InvoiceTransactionCardViewProps {
  loading?: boolean;
  data?: TransactionPaymentData;
}

export default InvoiceTransactionCardView;
