import { CitationDetail, EventPolicyComplianceDetail } from "common/graphql";
import flatMap from "lodash/flatMap";

export interface CitationTimestamps {
  sessionStartedAt: Date;
  sessionEndedAt: Date;
  sessionStartedAtLocal: Date;
  sessionEndedAtLocal: Date;
  violationStartedAtLocal: Date;
  violationEndedAtLocal: Date;
}

/**
 * Returns relevant timestamps for a citation including localized timestamps for start, end, and when the related curb
 * session transitioned into the violation state
 * @param citation
 */
export function getCitationTimestamps(citation: CitationDetail): CitationTimestamps {
  let violationStartedAtLocal = new Date(citation.sessionTransaction.sessionStartedAtLocal);
  let violationEndedAtLocal = new Date(citation.sessionTransaction.sessionEndedAtLocal);

  if (citation.park) {
    const complianceDetails = flatMap(
      citation?.park?.policyRateDetails || [],
      "details",
    ) as EventPolicyComplianceDetail[];
    const violationComplianceDetail = complianceDetails.find((detail) => detail.rate === null && detail.price === null);
    violationStartedAtLocal = new Date(
      violationComplianceDetail
        ? violationComplianceDetail.rateStartAt.replace("+00:00", "")
        : citation?.park?.startTimeLocal || 0,
    );
    violationEndedAtLocal = new Date(
      violationComplianceDetail
        ? violationComplianceDetail.rateEndAt.replace("+00:00", "")
        : citation?.park?.endTimeLocal || 0,
    );
  }

  return {
    sessionStartedAt: new Date(citation.sessionTransaction.sessionStartedAt),
    sessionEndedAt: new Date(citation.sessionTransaction.sessionEndedAt),
    sessionStartedAtLocal: new Date(citation.sessionTransaction.sessionStartedAtLocal),
    sessionEndedAtLocal: new Date(citation.sessionTransaction.sessionEndedAtLocal),
    violationStartedAtLocal,
    violationEndedAtLocal,
  };
}

export default getCitationTimestamps;
