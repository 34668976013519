import { gql } from "@apollo/client";

export const GET_PAYEE_TRANSACTIONS_SUMMARY = gql`
  query GetPayeeTransactionsSummary($since: date, $until: date) {
    dailySummaries: financial_transaction_log_daily_summary(
      where: { date: { _gte: $since, _lte: $until } }
      order_by: [{ date: desc }]
    ) {
      date
      potentialRevenueAmount: potential_revenue
      registeredTransactionsAmount: registered_price
      walletTransactionsAmount: gateway_transaction_price
      currency
    }
  }
`;

/** Variables passed to the {@link GET_PAYEE_TRANSACTIONS_SUMMARY} query */
export interface GetPayeeTransactionsSummaryVars {
  /** Inclusive start date of the current period expressed as a string with the format yyyy-mm-dd */
  since: string;
  /** Inclusive end date of the current period expressed as a string with the format yyyy-mm-dd */
  until: string;
}

export interface GetPayeeTransactionsSummaryData {
  /** Daily transaction summaries */
  dailySummaries: PayeeTransactionsDailySummary[] | null;
}

export interface PayeeTransactionsDailySummary {
  /** Date (in the payee's timezone) summarized */
  date: string;
  /** Number of first park transactions that occurred on the day */
  numFirstParks: number;
  /** Number of unregistered vehicle violations that occurred on the day */
  numUnregisteredViolations: number;
  /** Number of policy violations that occurred on the day */
  numPolicyViolations: number;
  /** Sum total potential revenue (assuming all transactions are paid for including unregistered) */
  potentialRevenueAmount: number;
  /** Sum total amount billed (expressed in terms of the smallest denomination of the currency) of registered vehicle transactions that occurred on the day */
  registeredTransactionsAmount: number;
  /** Sum total amount that could have been billed (expressed in terms of the smallest denomination of the currency) of unregistered vehicle transactions that occurred on the day */
  unregisteredTransactionsAmount: number;
  /** Sum total amount of wallet transactions that occurred on the day */
  walletTransactionsAmount: number;
  /** Currency in which amounts are expressed */
  currency: string;
}
