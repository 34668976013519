import React, { ChangeEvent } from "react";
import { useField, useFormikContext } from "formik";
import { TextField, TextFieldProps } from "formik-mui";
import startCase from "lodash/startCase";

/**
 * A form field that automatically formats its input in Start Case (capitalizing the first letter of
 * each word)
 *
 * Must be used inside of a Formik context.
 */
export const AutoCapitalizeField: React.FC<AutoCapitalizeFieldProps> = ({ name, ...props }) => {
  const form = useFormikContext();
  const [field, meta, helpers] = useField(name);

  return (
    <TextField
      {...props}
      meta={meta}
      form={form}
      field={{
        ...field,
        onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          helpers.setValue(startCase(e.target.value)),
      }}
    />
  );
};

/** Props passed to initialize an {@link AutoCapitalizeField} */
export interface AutoCapitalizeFieldProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  /** Name of the field */
  name: string;
}

export default AutoCapitalizeField;
