import React from "react";
import Grid, { GridProps } from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { CreditCardIcon } from "@app.automotus.io/components/icons/CreditCardIcon";
import { creditCardBrandValueFromString } from "common/adaptors";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Formik } from "formik";
import * as yup from "yup";
import ExpiryField from "@app.automotus.io/components/forms/PaymentMethodFields/ExpiryField";
import ZipCodeField from "@app.automotus.io/components/forms/PaymentMethodFields/ZipCodeField";
import { CustomerPaymentProfile } from "api/adaptors/AuthorizeNet";
import Stack from "@mui/material/Stack";

const emptyCardMethodValues = {
  expiry: "",
  zipCode: "",
};

export const UpdatePaymentMethodForm: React.FC<UpdatePaymentMethodFormProps> = ({
  loading = false,
  submitting = false,
  onSubmit = () => Promise.resolve(),
  onClose = () => undefined,
  paymentMethod,
}) => {
  const { cardType = "unknown", cardLast4 = "0000" } = paymentMethod?.payment?.creditCard || {};

  return (
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({
          action: "update",
          ...values,
          makeDefault: paymentMethod?.defaultPaymentProfile || false,
          paymentMethodId: paymentMethod?.customerPaymentProfileId || "",
          cardNumber: `XXXX${cardLast4}`,
        });
        setSubmitting(false);
      }}
      initialValues={{ ...emptyCardMethodValues }}
      validationSchema={yup.object().shape({
        expiry: ExpiryField.validationSchema,
        zipCode: ZipCodeField.validationSchema,
      })}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} onReset={props.handleReset}>
          <Box>
            <Grid container justifyContent={"space-between"} columnSpacing={2} rowSpacing={2}>
              <CardField loading={loading} title={"Card Number"} xs={12} sx={{ mb: 2 }}>
                <Grid item xs={10}>
                  <LoadingSkeleton loading={loading}>
                    <Typography
                      sx={{ color: submitting ? "grey.400" : undefined }}
                      variant={"body1"}
                    >{`●●●● ●●●● ●●●● ${cardLast4}`}</Typography>
                  </LoadingSkeleton>
                </Grid>
                <Grid item container justifyContent="flex-end" xs={2}>
                  <LoadingSkeleton loading={loading} width={30}>
                    <Typography variant={"body1"} sx={{ color: submitting ? "grey.400" : undefined }}>
                      <CreditCardIcon size={"xl" as unknown as "lg"} brand={creditCardBrandValueFromString(cardType)} />
                    </Typography>
                  </LoadingSkeleton>
                </Grid>
              </CardField>
              <Grid item xs={6}>
                <LoadingSkeleton loading={loading} width="100%">
                  <ExpiryField autoFocus />
                </LoadingSkeleton>
              </Grid>
              <Grid item xs={6}>
                <LoadingSkeleton loading={loading} width="100%">
                  <ZipCodeField />
                </LoadingSkeleton>
              </Grid>
              <Grid item xs={12} sx={{ mt: { xs: 3.75, md: 2.25 } }}>
                <Stack direction={"column"} spacing={2}>
                  <LoadingButton type="submit" variant={"contained"} disabled={loading} loading={submitting} fullWidth>
                    Update Payment Method
                  </LoadingButton>
                  <LoadingButton variant="outlined" disabled={loading || submitting} fullWidth onClick={onClose}>
                    Cancel
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export interface UpdatePaymentMethodFormProps {
  loading?: boolean;
  submitting?: boolean;
  onClose?: () => void;
  paymentMethod?: CustomerPaymentProfile;
  onSubmit?: UpdatePaymentMethodFormSubmitFunc;
}

export type UpdatePaymentMethodFormSubmitFunc = (params: {
  action: "update";
  zipCode: string;
  paymentMethodId: string;
  cardNumber: string;
  expiry: string;
  makeDefault: boolean;
}) => Promise<void>;

const CardField: React.FC<CardFieldProps> = ({ loading, title, children, ...gridProps }) => (
  <Grid item container {...gridProps}>
    <Box sx={{ mb: 1 }}>
      <LoadingSkeleton loading={loading} height={22} width={`${title.length}ch`}>
        <Typography variant={"body3"}>{title}</Typography>
      </LoadingSkeleton>
    </Box>
    <Grid
      item
      container
      justifyContent={"space-between"}
      flexDirection={"row"}
      alignItems={"center"}
      sx={{ borderRadius: 2, border: 1, borderColor: "grey.400", py: 1.5, px: 2, height: 50 }}
    >
      {children}
    </Grid>
  </Grid>
);

interface CardFieldProps extends GridProps {
  title: string;
  loading?: boolean;
}

export default UpdatePaymentMethodForm;
