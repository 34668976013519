import { useEffect, useState } from "react";
import { Position } from "common/helpers";
import { makeVar, useReactiveVar } from "@apollo/client";

const locationVar = makeVar<Position | undefined>(undefined);

export function useGeoLocation(): UseGeoLocationResult {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const location = useReactiveVar(locationVar);

  useEffect(() => {
    const abortController = new AbortController();

    if (!location) {
      (async () => {
        try {
          setLoading(true);
          const res = await fetch(
            `https://www.googleapis.com/geolocation/v1/geolocate?key=${
              process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
            }`,
            {
              method: "POST",
              body: "{}",
              headers: {
                "Content-Type": "application/json",
              },
              signal: abortController.signal,
            },
          );

          if (res.ok) {
            const resBody: GeoLocationResponse = await res.json();
            locationVar({ lat: resBody.location.lat, long: resBody.location.lng });
          } else {
            setError(new Error(res.statusText));
          }
        } catch (err) {
          if (err instanceof Error) {
            setError(err);
          } else {
            setError(new Error(JSON.stringify(err)));
          }
        } finally {
          setLoading(false);
        }
      })();
    }

    return () => {
      abortController.abort();
    };
  }, [location]);

  return { loading, error, location };
}

export interface UseGeoLocationResult {
  loading: boolean;
  error?: Error;
  location?: Position;
}

interface GeoLocationResponse {
  location: {
    lat: number;
    lng: number;
  };
  accuracy: number;
}

export default useGeoLocation;
