import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Form, Formik } from "formik";
import * as yup from "yup";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import CloseAccountReasonDropdownField from "@app.automotus.io/components/modals/CloseAccountRequestModal/CloseAccountReasonDropdownField";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import { useFormikContext } from "formik";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { appName } from "common/constants";

interface Nudge {
  message: string;
  primaryCTAText: string;
  secondaryCTAText: string;
  primaryCTA?: () => void;
}

interface NudgeDialogProps {
  nudges: { [key: string]: Nudge };
  setNeedCustomerSupport: Dispatch<SetStateAction<boolean>>;
}

const NudgeDialog: React.FC<NudgeDialogProps> = ({ nudges, setNeedCustomerSupport }) => {
  const {
    values: { reason },
    isSubmitting,
  } = useFormikContext<{ reason: string; other: string }>();
  const [isNudgeDialogOpen, setIsNudgeDialogOpen] = useState(false);

  useEffect(() => {
    if (!isSubmitting) {
      setNeedCustomerSupport(false);
      if (Object.keys(nudges).includes(reason)) {
        setIsNudgeDialogOpen(true);
      }
    }
  }, [nudges, reason, isSubmitting, setNeedCustomerSupport]);

  return (
    <Dialog
      open={isNudgeDialogOpen}
      PaperProps={{
        sx: { borderRadius: 1.5, minWidth: { lg: 450 }, maxWidth: { lg: 600 } },
      }}
      onBackdropClick={() => setIsNudgeDialogOpen(false)}
    >
      <DialogTitle sx={{ fontSize: 20, fontWeight: 500 }}>Did you know?</DialogTitle>
      <DialogContentText sx={{ py: 1, px: 3, fontSize: 16, fontWeight: 400, color: "text.primary" }}>
        {nudges[reason]?.message}
      </DialogContentText>
      <DialogActions sx={{ display: "flex", flexDirection: { xs: "column", tiny: "row" }, alignItems: "flex-end" }}>
        <Button
          sx={{ textTransform: "none", fontSize: 16, fontWeight: 500 }}
          onClick={() => {
            setIsNudgeDialogOpen(false);
          }}
        >
          {nudges[reason]?.secondaryCTAText}
        </Button>
        <Button
          sx={{ textTransform: "none", fontSize: 16, fontWeight: 500 }}
          onClick={() => {
            setIsNudgeDialogOpen(false);
            const primaryCTA = nudges[reason]?.primaryCTA;
            if (primaryCTA) {
              primaryCTA();
            }
          }}
        >
          {nudges[reason]?.primaryCTAText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CloseAccountRequestModal: React.FC<CloseAccountRequestModalProps> = ({
  loading = true,
  onKeepAccount = () => undefined,
  onSendCloseRequest = () => undefined,
  onShowRefundRequestDialog = () => undefined,
  onShowCurbPassSettingsDialogOpen = () => undefined,
  title = "",
  submittingCloseRequest = false,
}) => {
  const isMobile = useIsMobile();
  const [needCustomerSupport, setNeedCustomerSupport] = useState(false);
  const nudges: { [key: string]: Nudge } = useMemo(
    () => ({
      "I do not plan on using the zones again": {
        message:
          "To prevent additional fees from future usage, we'd suggest keeping the account open and requesting a refund of your balance. Would you like to request a refund instead?",
        primaryCTAText: "Request Refund",
        secondaryCTAText: "No",
        primaryCTA: onShowRefundRequestDialog,
      },
      "Use of the zones is too expensive": {
        message:
          "Parking rates are progressive which means the shorter your park, the cheaper your cost! Additionally you can reduce the balance of your wallet. Would you like to configure your wallet settings instead?",
        primaryCTAText: "Yes",
        secondaryCTAText: "Not Interested",
        primaryCTA: onShowCurbPassSettingsDialogOpen,
      },
      "I only wanted to try it out": {
        message:
          "We are launching our service in many other locations throughout the city and offer promotions from time to time. We recommend requesting a refund instead.",
        primaryCTAText: "Request Refund",
        secondaryCTAText: "Not Interested",
        primaryCTA: onShowRefundRequestDialog,
      },
      Other: {
        message:
          "We'd love to hear more! Would you like a member from the CurbPass support team to reach out to address any concerns you have first?",
        primaryCTAText: "Yes",
        secondaryCTAText: "No",
        primaryCTA: () => setNeedCustomerSupport(true),
      },
    }),
    [onShowRefundRequestDialog, setNeedCustomerSupport, onShowCurbPassSettingsDialogOpen],
  );

  return (
    <Box sx={{ display: { xs: "flex", lg: "block" }, flexDirection: "column", height: { xs: "100%", lg: "auto" } }}>
      {title && (
        <LoadingSkeleton loading={loading} width="100%">
          <Box mb={1} sx={{ display: "flex", justifyContent: "center", fontWeight: 500 }}>
            <Typography variant="h4">{title}</Typography>
          </Box>
        </LoadingSkeleton>
      )}
      <Formik
        enableReinitialize
        initialValues={{ reason: "", other: "" }}
        onSubmit={() => {
          onSendCloseRequest(needCustomerSupport);
        }}
        validationSchema={yup.object({
          reason: CloseAccountReasonDropdownField.validationSchema,
          other: yup
            .string()
            .nullable()
            .when("reason", (reason, schema) => {
              if (reason === "Other") {
                return schema.required("Required field");
              } else {
                return schema; // if the reason is not `Other` then no need to perform validation.
              }
            }),
        })}
        validateOnChange
        validateOnBlur
      >
        {(props) => (
          <Form
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}
            style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "space-between" }}
          >
            <NudgeDialog nudges={nudges} setNeedCustomerSupport={setNeedCustomerSupport} />
            <Box>
              <LoadingSkeleton loading={loading} width="100%">
                <Typography variant="subtitle1" sx={{ mt: 3, fontWeight: 500, mb: 0.5 }}>
                  Why are you closing your account? *
                </Typography>
                <CloseAccountReasonDropdownField openOnFocus={false} />
              </LoadingSkeleton>
            </Box>
            <Box>
              <LoadingSkeleton loading={loading} width="100%">
                <Alert
                  icon={<InfoIcon sx={{ fontSize: "20px", color: "primary.main" }} />}
                  severity="warning"
                  sx={{
                    mt: isMobile ? 3 : 20,
                    mb: 2,
                    p: 2,
                    color: "primary.main",
                    alignItems: "center",
                    backgroundColor: "rgba(13, 85, 191, 0.08)",
                  }}
                >
                  Your remaining CurbPass balance will be refunded within 5 to 7 days.
                </Alert>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading} width="100%">
                <Box mt={2}>
                  <LoadingButton
                    sx={{
                      textTransform: "none",
                      fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                      height: { xs: "30px", tiny: "36px", lg: "42px" },
                      fontWeight: 500,
                    }}
                    disabled={!props.values.reason || (props.values.reason === "Other" && !props.values.other)}
                    loading={submittingCloseRequest}
                    variant="contained"
                    fullWidth
                    type="submit"
                    onClick={props.submitForm}
                  >
                    Submit Request
                  </LoadingButton>
                </Box>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading} width="100%">
                <Box mt={2}>
                  <Button
                    sx={{
                      textTransform: "none",
                      fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                      height: { xs: "30px", tiny: "36px", lg: "42px" },
                      fontWeight: 500,
                    }}
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      props.resetForm();
                      onKeepAccount();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </LoadingSkeleton>
            </Box>
          </Form>
        )}
      </Formik>
      {appName !== "curbsuite.io" && (
        <LoadingSkeleton loading={loading} width="100%">
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            variant="body1"
            mt={2}
          >
            <Link variant={"body1"} component={RouterLink} to={"/support"} target={"_blank"}>
              Need Help?
            </Link>
          </Typography>
        </LoadingSkeleton>
      )}
    </Box>
  );
};

export interface CloseAccountRequestModalProps {
  title?: string;
  loading?: boolean;
  onKeepAccount?: () => void;
  onSendCloseRequest?: (needCustomerSupport?: boolean) => void;
  onShowRefundRequestDialog?: () => void;
  onShowCurbPassSettingsDialogOpen?: () => void;
  submittingCloseRequest?: boolean;
}

export default CloseAccountRequestModal;
