import { StateAbbreviation, StateFullName } from "./types";

/**
 * Encapsulates the set of possible license plate states with their display names
 * and abbreviations.
 */
export class LicensePlateState {
  public static readonly Alabama = new LicensePlateState({ fullName: "Alabama", abbreviation: "AL" });
  public static readonly Alaska = new LicensePlateState({ fullName: "Alaska", abbreviation: "AK" });
  public static readonly Arizona = new LicensePlateState({ fullName: "Arizona", abbreviation: "AZ" });
  public static readonly Arkansas = new LicensePlateState({ fullName: "Arkansas", abbreviation: "AR" });
  public static readonly California = new LicensePlateState({ fullName: "California", abbreviation: "CA" });
  public static readonly Colorado = new LicensePlateState({ fullName: "Colorado", abbreviation: "CO" });
  public static readonly Connecticut = new LicensePlateState({ fullName: "Connecticut", abbreviation: "CT" });
  public static readonly Delaware = new LicensePlateState({ fullName: "Delaware", abbreviation: "DE" });
  public static readonly DistrictOfColumbia = new LicensePlateState({
    fullName: "District of Columbia",
    abbreviation: "DC",
  });
  public static readonly Florida = new LicensePlateState({ fullName: "Florida", abbreviation: "FL" });
  public static readonly Georgia = new LicensePlateState({ fullName: "Georgia", abbreviation: "GA" });
  public static readonly Hawaii = new LicensePlateState({ fullName: "Hawaii", abbreviation: "HI" });
  public static readonly Idaho = new LicensePlateState({ fullName: "Idaho", abbreviation: "ID" });
  public static readonly Illinois = new LicensePlateState({ fullName: "Illinois", abbreviation: "IL" });
  public static readonly Indiana = new LicensePlateState({ fullName: "Indiana", abbreviation: "IN" });
  public static readonly Iowa = new LicensePlateState({ fullName: "Iowa", abbreviation: "IA" });
  public static readonly Kansas = new LicensePlateState({ fullName: "Kansas", abbreviation: "KS" });
  public static readonly Kentucky = new LicensePlateState({ fullName: "Kentucky", abbreviation: "KY" });
  public static readonly Louisiana = new LicensePlateState({ fullName: "Louisiana", abbreviation: "LA" });
  public static readonly Maine = new LicensePlateState({ fullName: "Maine", abbreviation: "ME" });
  public static readonly Maryland = new LicensePlateState({ fullName: "Maryland", abbreviation: "MD" });
  public static readonly Massachusetts = new LicensePlateState({ fullName: "Massachusetts", abbreviation: "MA" });
  public static readonly Michigan = new LicensePlateState({ fullName: "Michigan", abbreviation: "MI" });
  public static readonly Minnesota = new LicensePlateState({ fullName: "Minnesota", abbreviation: "MN" });
  public static readonly Mississippi = new LicensePlateState({ fullName: "Mississippi", abbreviation: "MS" });
  public static readonly Missouri = new LicensePlateState({ fullName: "Missouri", abbreviation: "MO" });
  public static readonly Montana = new LicensePlateState({ fullName: "Montana", abbreviation: "MT" });
  public static readonly Nebraska = new LicensePlateState({ fullName: "Nebraska", abbreviation: "NE" });
  public static readonly Nevada = new LicensePlateState({ fullName: "Nevada", abbreviation: "NV" });
  public static readonly NewHampshire = new LicensePlateState({ fullName: "New Hampshire", abbreviation: "NH" });
  public static readonly NewJersey = new LicensePlateState({ fullName: "New Jersey", abbreviation: "NJ" });
  public static readonly NewMexico = new LicensePlateState({ fullName: "New Mexico", abbreviation: "NM" });
  public static readonly NewYork = new LicensePlateState({ fullName: "New York", abbreviation: "NY" });
  public static readonly NorthCarolina = new LicensePlateState({ fullName: "North Carolina", abbreviation: "NC" });
  public static readonly NorthDakota = new LicensePlateState({ fullName: "North Dakota", abbreviation: "ND" });
  public static readonly Ohio = new LicensePlateState({ fullName: "Ohio", abbreviation: "OH" });
  public static readonly Oklahoma = new LicensePlateState({ fullName: "Oklahoma", abbreviation: "OK" });
  public static readonly Oregon = new LicensePlateState({ fullName: "Oregon", abbreviation: "OR" });
  public static readonly Pennsylvania = new LicensePlateState({ fullName: "Pennsylvania", abbreviation: "PA" });
  public static readonly RhodeIsland = new LicensePlateState({ fullName: "Rhode Island", abbreviation: "RI" });
  public static readonly SouthCarolina = new LicensePlateState({ fullName: "South Carolina", abbreviation: "SC" });
  public static readonly SouthDakota = new LicensePlateState({ fullName: "South Dakota", abbreviation: "SD" });
  public static readonly Tennessee = new LicensePlateState({ fullName: "Tennessee", abbreviation: "TN" });
  public static readonly Texas = new LicensePlateState({ fullName: "Texas", abbreviation: "TX" });
  public static readonly Utah = new LicensePlateState({ fullName: "Utah", abbreviation: "UT" });
  public static readonly Vermont = new LicensePlateState({ fullName: "Vermont", abbreviation: "VT" });
  public static readonly Virginia = new LicensePlateState({ fullName: "Virginia", abbreviation: "VA" });
  public static readonly Washington = new LicensePlateState({ fullName: "Washington", abbreviation: "WA" });
  public static readonly WestVirginia = new LicensePlateState({ fullName: "West Virginia", abbreviation: "WV" });
  public static readonly Wisconsin = new LicensePlateState({ fullName: "Wisconsin", abbreviation: "WI" });
  public static readonly Wyoming = new LicensePlateState({ fullName: "Wyoming", abbreviation: "WY" });
  public static readonly All = [
    LicensePlateState.Alabama,
    LicensePlateState.Alaska,
    LicensePlateState.Arizona,
    LicensePlateState.Arkansas,
    LicensePlateState.California,
    LicensePlateState.Colorado,
    LicensePlateState.Connecticut,
    LicensePlateState.Delaware,
    LicensePlateState.DistrictOfColumbia,
    LicensePlateState.Florida,
    LicensePlateState.Georgia,
    LicensePlateState.Hawaii,
    LicensePlateState.Idaho,
    LicensePlateState.Illinois,
    LicensePlateState.Indiana,
    LicensePlateState.Iowa,
    LicensePlateState.Kansas,
    LicensePlateState.Kentucky,
    LicensePlateState.Louisiana,
    LicensePlateState.Maine,
    LicensePlateState.Maryland,
    LicensePlateState.Massachusetts,
    LicensePlateState.Michigan,
    LicensePlateState.Minnesota,
    LicensePlateState.Mississippi,
    LicensePlateState.Missouri,
    LicensePlateState.Montana,
    LicensePlateState.Nebraska,
    LicensePlateState.Nevada,
    LicensePlateState.NewHampshire,
    LicensePlateState.NewJersey,
    LicensePlateState.NewMexico,
    LicensePlateState.NewYork,
    LicensePlateState.NorthCarolina,
    LicensePlateState.NorthDakota,
    LicensePlateState.Ohio,
    LicensePlateState.Oklahoma,
    LicensePlateState.Oregon,
    LicensePlateState.Pennsylvania,
    LicensePlateState.RhodeIsland,
    LicensePlateState.SouthCarolina,
    LicensePlateState.SouthDakota,
    LicensePlateState.Tennessee,
    LicensePlateState.Texas,
    LicensePlateState.Utah,
    LicensePlateState.Vermont,
    LicensePlateState.Virginia,
    LicensePlateState.Washington,
    LicensePlateState.WestVirginia,
    LicensePlateState.Wisconsin,
    LicensePlateState.Wyoming,
  ];

  public static readonly ByAbbreviation: Record<StateAbbreviation, LicensePlateState> = Object.freeze(
    LicensePlateState.All.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.abbreviation]: cur,
      };
    }, {} as Record<StateAbbreviation, LicensePlateState>),
  );

  public static FromAbbreviation(abbrev: StateAbbreviation): LicensePlateState {
    return this.ByAbbreviation[abbrev];
  }

  /** Full name of the state */
  public readonly fullName: StateFullName;
  /** Abbreviation/code of the state */
  public readonly abbreviation: StateAbbreviation;

  constructor({ fullName, abbreviation }: LicensePlateStateProps) {
    this.fullName = fullName;
    this.abbreviation = abbreviation;
  }
}

interface LicensePlateStateProps {
  fullName: StateFullName;
  abbreviation: StateAbbreviation;
}

export default LicensePlateState;
