import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import { useTermsOfService } from "@app.automotus.io/components/hooks/useTermsOfService/useTermsOfService";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { BottomSheet } from "@app.automotus.io/components/BottomSheet";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { TermsOfServiceWrapper } from "@app.automotus.io/components/TermsOfService";

export interface SignUpTermsOfServiceCheckboxProps {
  linkTo: string;
  linkState?: Record<string, unknown>;
}

export const SignUpTermsOfServiceCheckbox: React.FC<SignUpTermsOfServiceCheckboxProps> = ({ linkTo, linkState }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [isTosOpen, setIsTosOpen] = useState<boolean>(false);
  const { publishSnackbarMessage } = useSnackPackContext();
  const { accept, decline, isAccepted } = useTermsOfService({
    onError: (op, err) => {
      console.error(err);
      if (op === "accept") {
        publishSnackbarMessage({ message: "Failed to accept Terms of Service", severity: "error" });
      } else {
        publishSnackbarMessage({ message: "Failed to decline Terms of Service", severity: "error" });
      }
    },
  });

  const handleChange = () => {
    isAccepted ? decline() : accept();
  };

  const handleChangeOp = async (op: "accept" | "decline") => {
    const action = op === "accept" ? accept : decline;

    try {
      await action();
      setIsTosOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCloseTosModal = () => {
    setIsTosOpen(false);
  };

  const handleOpenTosModal = () => {
    setIsTosOpen(true);
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox sx={{ fontSize: "1.125rem" }} checked={isAccepted} onChange={handleChange} />}
          label={
            <Typography color={theme.palette.text.primary}>
              I agree to the{" "}
              <Link
                component={RouterLink}
                to={linkTo}
                state={linkState}
                onClick={() => {
                  if (isMobile) {
                    handleOpenTosModal();
                  }
                }}
              >
                Terms of Service
              </Link>
            </Typography>
          }
        />
      </FormGroup>
      {isMobile && (
        <BottomSheet open={isTosOpen} onClose={handleCloseTosModal} marginTopSmall={75} marginTopMedium={79}>
          <AppBar
            sx={{
              height: "52px",
              position: "relative",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <Box>
                <Typography variant="body3" sx={{ fontWeight: 500, ml: 1 }}>
                  Terms Of Service
                </Typography>
              </Box>
              <Box>
                <IconButton edge="end" color="inherit" onClick={handleCloseTosModal} aria-label="close">
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "100px",
                      backgroundColor: "rgba(0, 0, 0, 0.135)",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "14px" }} />
                  </Box>
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ px: 3, pt: 0, mb: -2.5 }}>
            <TermsOfServiceWrapper variant="select" onChange={handleChangeOp} />
          </DialogContent>
        </BottomSheet>
      )}
    </>
  );
};

export default SignUpTermsOfServiceCheckbox;
