import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/**
 * Component that renders discount component for CurbPass Setup.
 */
export const DiscountComponent: React.FC<DiscountComponentProps> = ({ title, description, ...rest }) => {
  return (
    <Box sx={{ p: 1.5, borderRadius: 2, backgroundColor: "rgba(13, 85, 191, 0.08)" }} {...rest}>
      <Typography variant="h7">{title}</Typography>
      <Typography>{description}</Typography>
    </Box>
  );
};

export interface DiscountComponentProps {
  /** Title of the component */
  title: string;
  /** Description of the component */
  description: string;
}

export default DiscountComponent;
